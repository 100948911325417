import React, { ChangeEvent, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

interface socialListArr {
  text?: string;
  value?: any;
  id?: any;
  key?: number;
}
interface BaseSelectProps {
  name?: string;
  id?: string;
  firstValue?: string;
  className?: string;
  label?: string;
  link?: string;
  linkText?: string;
  selectItems: socialListArr[];
  selectedItem?: string;
  onChange?: (selectedValue: any) => void; // Функция обратного вызова
}

const BaseSelect = ({ name, id, firstValue, className, label, link, linkText, selectItems, onChange, selectedItem }: BaseSelectProps) => {
  const [selected, setSelected] = useState(firstValue || '');
  const handleChange = (event: any) => {
    const selectedValue = event.target.value;
    setSelected(selectedValue);
    if (onChange) {
      onChange(selectedValue); // Вызываем функцию обратного вызова при изменении
    }
  };

  useEffect(() => {
    if (selectedItem) {
      setSelected(selectedItem);
    }
  }, [selectedItem]);

  return (
    <div className="select_base_wrap">
      {label && (
        <div className="label_box_row label_box_text">
          <div className="select_base_row_text">{label}</div>
          {link && (
            <NavLink to={link} className="label_box_row_link" end target="_blank">
              {linkText}
            </NavLink>
          )}
        </div>
      )}
      <select value={selected} className={`select_base ${className ? className : ''}`} name={name} id={id} onChange={handleChange}>
        {firstValue && (
          <option value={firstValue} disabled hidden>
            {firstValue}
          </option>
        )}
        {selectItems?.map((item: any, index: number) => (
          <option value={item.id} key={index} id={item.id} data-price={item.value}>
            {item.text}
          </option>
        ))}
      </select>
    </div>
  );
};

export default BaseSelect;
