import React, { CSSProperties, ChangeEvent, ChangeEventHandler, HTMLInputTypeAttribute, ReactNode } from 'react';

type TextAreaProps = {
  children?: ReactNode;
  className?: string;
  inputClassName?: string;
  id?: string;
  label?: string;
  name?: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  style?: CSSProperties;
  type?: HTMLInputTypeAttribute;
  value?: string;
  hasLink?: boolean;
  hasActivateLink?: boolean;
  textLink?: string;
  innerLink?: string;

  isReadonly?: boolean;
  isCount?: boolean;
  isCountText?: string;
  isCountSum?: string;
};

const TextArea = ({
  children,
  className,
  inputClassName,
  id,
  label,
  name,
  onChange,
  placeholder,
  style,
  type,
  value,
  hasActivateLink,
  hasLink,
  textLink,
  innerLink,
  isReadonly,
  isCount,
  isCountText,
  isCountSum
}: TextAreaProps) => {
  return <textarea name={name} id={id} cols={30} rows={7} placeholder={placeholder} className={className} onChange={onChange} value={value ? value : ''}></textarea>;
};

export default TextArea;
