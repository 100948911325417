import React, { FormEvent, ReactNode } from 'react';

type BaseLoginForm = {
  children?: ReactNode;
  onSubmit?: (event: FormEvent<HTMLFormElement>) => void;
};

const AdminLoginForm = ({ children, onSubmit }: BaseLoginForm) => {
  return <form onSubmit={onSubmit}>{children}</form>;
};

export default AdminLoginForm;
