import React from 'react';
import { NavLink } from 'react-router-dom';

type MenuBtnActionProps = {
  isPersonal?: boolean;
  isUserLogIn?: boolean;
  btnText?: string;
  btnLink?: string;
  linkClicked?: () => void;
};

const MenuBtnAction = ({ btnLink, isUserLogIn, btnText, isPersonal, linkClicked }: MenuBtnActionProps) => {
  return (
    <NavLink to={`${btnLink ? btnLink : '/personal/balance'}`} className={`nav_call_action ${!isPersonal ? 'nav_up_balance' : ''}`} onClick={linkClicked}>
      <span className="nav_call_action_text">{btnText}</span>
      <span className={`nav_call_action_icon ${!isPersonal ? 'nav_up_balance_icon' : ''}`}>
        {!isPersonal ? (
          '+'
        ) : isUserLogIn ? (
          <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
            <rect width="52" height="52" rx="26" fill="#E73D74" />
            <path
              d="M26.3294 25.8976H26.331C29.8883 25.8976 32.7834 23.0052 32.7845 19.4502C32.7845 17.7299 32.1134 16.1108 30.893 14.8915C29.6726 13.6722 28.053 13 26.3321 13H26.3305C22.7732 13 19.8781 15.8923 19.877 19.4474C19.877 21.1677 20.548 22.7862 21.7685 24.0061C22.9883 25.2254 24.608 25.8976 26.3294 25.8976ZM26.3305 14.0314H26.3316C27.7772 14.0314 29.1383 14.5963 30.163 15.621C31.1877 16.6457 31.752 18.0057 31.752 19.4496C31.7515 22.4365 29.3195 24.8656 26.3305 24.8656H26.3294C24.8837 24.8656 23.5227 24.3007 22.4979 23.276C21.4732 22.2513 20.9089 20.8913 20.9089 19.4474C20.9094 16.4606 23.3414 14.0314 26.3305 14.0314Z"
              fill="white"
            />
            <path
              d="M34.7125 30.8975C33.0845 29.4869 30.8355 28.6779 28.5414 28.6774L24.1156 28.6763H24.1134C22.3531 28.6763 20.6473 29.1377 19.1795 30.0112C17.2735 31.146 15.0011 33.4245 15 37.8364C15 38.4246 15.4782 38.9028 16.0659 38.9034L36.5862 38.9078C37.1739 38.9078 37.6521 38.4297 37.6521 37.8414C37.6521 34.9652 36.6357 32.5638 34.7119 30.8969L34.7125 30.8975ZM36.5862 37.8764L16.0664 37.872C16.0475 37.872 16.0319 37.8564 16.0319 37.837C16.0331 34.6839 17.2696 32.3492 19.7071 30.8981C21.0148 30.1196 22.5383 29.7082 24.1134 29.7082H24.1156L28.5414 29.7093C32.4334 29.7104 36.6218 32.2553 36.6212 37.842C36.6212 37.8614 36.6057 37.877 36.5868 37.877L36.5862 37.8764Z"
              fill="white"
            />
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none" style={{ borderRadius: '50%' }}>
            <rect width="52" height="52" rx="26" fill="#fff" stroke="#E73D74"></rect>
            <path
              d="M26.3294 25.8976H26.331C29.8883 25.8976 32.7834 23.0052 32.7845 19.4502C32.7845 17.7299 32.1134 16.1108 30.893 14.8915C29.6726 13.6722 28.053 13 26.3321 13H26.3305C22.7732 13 19.8781 15.8923 19.877 19.4474C19.877 21.1677 20.548 22.7862 21.7685 24.0061C22.9883 25.2254 24.608 25.8976 26.3294 25.8976ZM26.3305 14.0314H26.3316C27.7772 14.0314 29.1383 14.5963 30.163 15.621C31.1877 16.6457 31.752 18.0057 31.752 19.4496C31.7515 22.4365 29.3195 24.8656 26.3305 24.8656H26.3294C24.8837 24.8656 23.5227 24.3007 22.4979 23.276C21.4732 22.2513 20.9089 20.8913 20.9089 19.4474C20.9094 16.4606 23.3414 14.0314 26.3305 14.0314Z"
              fill="#E73D74"
            ></path>
            <path
              d="M34.7125 30.8975C33.0845 29.4869 30.8355 28.6779 28.5414 28.6774L24.1156 28.6763H24.1134C22.3531 28.6763 20.6473 29.1377 19.1795 30.0112C17.2735 31.146 15.0011 33.4245 15 37.8364C15 38.4246 15.4782 38.9028 16.0659 38.9034L36.5862 38.9078C37.1739 38.9078 37.6521 38.4297 37.6521 37.8414C37.6521 34.9652 36.6357 32.5638 34.7119 30.8969L34.7125 30.8975ZM36.5862 37.8764L16.0664 37.872C16.0475 37.872 16.0319 37.8564 16.0319 37.837C16.0331 34.6839 17.2696 32.3492 19.7071 30.8981C21.0148 30.1196 22.5383 29.7082 24.1134 29.7082H24.1156L28.5414 29.7093C32.4334 29.7104 36.6218 32.2553 36.6212 37.842C36.6212 37.8614 36.6057 37.877 36.5868 37.877L36.5862 37.8764Z"
              fill="#E73D74"
            ></path>
          </svg>
        )}
      </span>
    </NavLink>
  );
};

export default MenuBtnAction;
