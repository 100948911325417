import React, { useState } from 'react';
import MainAccordionItem from '../../main_page/MainAccordionItem';
import { NavLink } from 'react-router-dom';

type Props = {};

// Определение общего типа для элементов в массиве socialDataState
type SocialReviewItem = {
  _id: string;
  parentId: string;
  name: string;
  type: string;
  active: boolean;
  updated: number;
  text: string;
};

// Определение типа для пропсов компонента AdminReview
type PersonalFaqProps = {
  socialDataState: SocialReviewItem[];
  typeString: string;
};

const PersonalFaq = ({ socialDataState, typeString }: PersonalFaqProps) => {
  const [active, setActive] = useState<string | null>(null);
  const handleToggle = (index: string) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };
  return (
    <div className="personal_col personal_col_wide personal_col_content">
      <div className="personal_col_heading">
        <div className="personal_col_heading_wrap">
          <img src="/content/personal/inner/faq.png" alt="" />
          <div className="personal_col_heading_title">Помощь</div>
        </div>
      </div>
      <div className="personal_col_inner_padding">
        <div className="main_faq">
          <div className="inner_col_content_subtitle">
            <div className="inner_col_content_subtitle_top">Популярные вопросы</div>
            <div className="inner_col_content_subtitle_bottom">
              Если не нашли ответ на свой вопрос, <NavLink to={`https://t.me/media_booster_ru`} className="link" target="_blank"><span className="link">напишите нам</span></NavLink>.
            </div>
          </div>
          <div className="main_faq_wrap">
            {socialDataState.length > 0 && socialDataState.map((faq, index) => <MainAccordionItem key={index} active={active} handleToggle={handleToggle} faq={faq} />)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalFaq;
