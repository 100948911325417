import React from 'react';
import { Navigate } from 'react-router-dom';
import PersonalLayout from './PersonalLayout';
import { useUserData } from '../../hooks/UserDataContext';
import { Authentification } from '../helpers/auth';

interface PersonalMenuItem {
  name: string;
  title: string;
  iconSrc: string;
  link: string;
}

type PrivateRouteProps = {
  userName?: string;
  isLogged?: boolean;
  personalMenuList: PersonalMenuItem[];
};

const PrivateRoute = ({ isLogged, personalMenuList, userName }: PrivateRouteProps) => {
  // Получаю инфу о юзере из контекста
  const { personalEmail, personalName, personalPhone, personalBalance, fetchUserDataContext, token, userId, isUserLogin } = useUserData();
  return Authentification.getInstance().getToken() ? <PersonalLayout personalMenuList={personalMenuList} userName={personalName} isLogged={isUserLogin} /> : <Navigate to="/login" />;
};

export default PrivateRoute;
