import { Navigate } from "react-router-dom";
import { Authentification } from "./auth";

export async function fetchApi(method: string, route: string, payload: object, file: boolean = false) {
  const object: { method: string; headers: { 'content-type'?: string }; body?: any } = {
    method: method,
    headers: {
      'content-type': 'application/json;charset=UTF-8'
    }
  };

  if (file) {
    object.headers = {};
    object.body = payload;
  } else if (method !== 'GET') {
    object.body = JSON.stringify(payload);
  }

  if (route.indexOf('?') > -1) {
    if (route.indexOf("key=") > -1) route += '';
    else route += "&key=" + Authentification.getInstance().getToken();
  } else {
    route += "?key=" + Authentification.getInstance().getToken();
  }
  // console.log("Route: " + route)
  if (route.charAt(0) === '/') route = route.substr(1);

  try {
    const response = await window.fetch('/api/' + route, object);
    const answer = await response.json();

    switch (response.status) {
      case 200:
        if (response.ok) {
          if (answer) {
            if ((Array.isArray(answer) && answer[answer.length - 1] && answer[answer.length - 1].newToken) ||
              (typeof answer == 'object' && answer.newToken)) {
              localStorage.removeItem('token');
              let newToken = '';
              if (Array.isArray(answer)) {
                const newTokenItem = answer.pop()
                console.log("--------------------------------ANSWER IS ARRAY--------------------------------------")
                console.log(newTokenItem)
                newToken = newTokenItem.newToken;
              } else {
                newToken = answer.newToken
              }
              if (newToken) {
                localStorage.setItem('token', newToken);
                Authentification.getInstance().setToken(newToken);
                console.log("!!!!!!!!!!!!!!!!!!!!!!____________________NEW TOKEN_____________!!!!!!!!!!!!!!!")
              } else {
                Authentification.getInstance().logout();
              }
            }
            // console.log(answer)
            return answer;
          } else {
            return true;
          }
        } else {
          console.log(response)
          return false
        }
      case 403:
        if (answer.message) {
          console.log(answer.message);
        }
        if (Authentification.getInstance().getToken()) {
          Authentification.getInstance().checkToken().then((result) => {
            if (!result) {
              Authentification.getInstance().logout();
            }
          })
        } else {
          Authentification.getInstance().logout()
        }
        break;
      case 500:
        if (answer.message) {
          console.log(answer.message);
        }
        return false;
      default:
        console.log(answer)
        return { code: "404", ...answer }
        // alert("Что-то пошло не так, попробуйте позже..")
        break;
    }
  } catch (e) {
    console.log(e);
    return false;
  }

}
