import React from 'react';
import ServicesGuaranty from '../components/Services/ServicesGuaranty';
import { Helmet } from 'react-helmet-async';
import InnerBreadcrumbs from '../components/ui-kit/InnerBreadcrumbs';

type Props = {};

const Guarantees = (props: Props) => {
  return (
    <>
      <Helmet>
        <title>{'Наши гарантии'}</title>
        <meta name="description" content={'Наши гарантии'} />
        {/* Другие мета-теги */}
      </Helmet>
      <InnerBreadcrumbs chapterLink={'/guarantees'} chapterString={'Наши гарантии'} />
      <div className="inner_col_content_subtitle">
        <div className="inner_col_content_subtitle_top">Гарантии безопасного SMM продвижения!</div>
        <div className="inner_col_content_subtitle_bottom">Наша главная задача - это качественные и безопасные продвижения в популярных социальных сетях, мессенеджерах и медиа.</div>
      </div>
      <ServicesGuaranty />
    </>
  );
};

export default Guarantees;
