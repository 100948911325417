import React from 'react';

type Props = {};

const Advantages = (props: Props) => {
  return (
    <section className="main_section main_advantages_section">
      <div className="container">
        <div className="main_advantages">
          <div className="main_advantages_item">
            <div className="main_advantages_item_title">6 лет</div>
            <div className="main_advantages_item_text">
              на рынке<br></br>смм услуг
            </div>
          </div>
          <div className="main_advantages_item">
            <div className="main_advantages_item_title">213 850</div>
            <div className="main_advantages_item_text">выполненых заказов</div>
          </div>
          <div className="main_advantages_item">
            <div className="main_advantages_item_title">9 из 10</div>
            <div className="main_advantages_item_text">клиентов заказывают услуги повторно</div>
          </div>
          <div className="main_advantages_item">
            <div className="main_advantages_item_title">100%</div>
            <div className="main_advantages_item_text">
              безопасно<br></br>для ваших соц сетей
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Advantages;
