import React, { useEffect, useRef, useState } from 'react';
import BtnOutline from '../ui-kit/BtnOutline';
import DOMPurify from 'dompurify';

type ServicesTextProps = {
  innerText?: string;
};

const ServicesText = ({ innerText }: ServicesTextProps) => {
  const [expanded, setExpanded] = useState(false);
  const [maxHeight, setMaxHeight] = useState(''); // Состояние для установки максимальной высоты

  const reviewsWrapRef = useRef<HTMLDivElement>(null);

  // текст одного элемента
  const [socialItemText, setSocialItemText] = useState<any>(null);

  useEffect(() => {
    // После монтирования компонента или изменения состояния expanded
    // Вычисляем высоту блока и устанавливаем максимальную высоту
    if (reviewsWrapRef.current) {
      const reviewsWrapHeight = reviewsWrapRef.current.scrollHeight;
      setMaxHeight(expanded ? `${reviewsWrapHeight}px` : '');
    }
  }, [expanded]);

  const handleShowAllClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (innerText != null && innerText.trim().length > 0) {
      // магическим образом превращаю текст в HTML
      // const sanitizedHtml = DOMPurify.sanitize(innerText);
      setSocialItemText(innerText);
    } else {
      setSocialItemText('');
    }
  }, [innerText]);

  return (
    <div className="services_text">
      <div className="services_text_content" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(socialItemText) }} />
      {/* <div className="services_text_content" ref={reviewsWrapRef} style={{ maxHeight: maxHeight }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(socialItemText) }} /> */}
      {/* {socialItemText && socialItemText.length > 0 && (
        <BtnOutline className="btn_outline btn_bg_transparent btn_wide_full reviews_show_all reviews_vieo_show_all" btnText={expanded ? 'Скрыть' : 'Показать всё'} onClick={handleShowAllClick} />
      )} */}
    </div>
  );
};

export default ServicesText;
