import React, { FormEvent, ReactNode } from 'react';

type BaseLoginForm = {
  classMame?: string;
  children?: ReactNode;
  onSubmit?: (event: FormEvent<HTMLFormElement>) => void;
};

const AdminLoginForm = ({ children, onSubmit, classMame }: BaseLoginForm) => {
  return (
    <form onSubmit={onSubmit} className={`personal_form ${classMame}`}>
      {children}
    </form>
  );
};

export default AdminLoginForm;
