import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AdminLogin from './components/AdminLogin';
import AdminInner from './components/AdminInner';
import AdminLayout from './components/AdminLayout';

type AdminProps = {
  isLogged: boolean;
  token: string;
};

const Admin = ({ isLogged, token }: AdminProps) => {
  const [message, setMessage] = useState('');
  const { userName } = useParams();

  useEffect(() => {
    if (userName) {
      setMessage('В url пользователь следующий: ' + userName);
    } else {
      setMessage('Текущая страница не является страницей пользователя');
    }
  }, [userName]);

  return (
    <>
      {/* <p>{message}</p> */}
      {isLogged === true ? <AdminInner adminToken={token} /> : <AdminLogin />}
    </>
  );
};

export default Admin;
