import React, { ChangeEvent, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

// Определение типа для объекта страницы
type PageType = {
  h1: string;
  title: string;
  updated: number;
  description: string;
  breadcrumbs: string;
  text: string;
  seoUrl: string;
  showOnMain: boolean;
  excerpt: string;
  banner: {
    text: string;
    image: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
  icon: {
    big: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
    small: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
};

// Определение общего типа для элементов в массиве socialDataState
type SocialDataItem = {
  _id: string;
  name: string;
  __v: number;
  active: boolean;
  activeApi: boolean;
  category: string;
  page: PageType;
  parentId: string;
  rate: string;
  service: string;
  socialMedia: string;
  type: string;
};

interface ReviewSelectProps {
  name?: string;
  id?: string;
  firstValue?: string;
  className?: string;
  label?: string;
  link?: string;
  linkText?: string;
  selectItems: SocialDataItem[];
  selectedItem?: string;
  onChange?: (selectedValue: any) => void; // Функция обратного вызова
}

const ReviewSelect = ({ name, id, firstValue, className, label, link, linkText, selectItems, onChange, selectedItem }: ReviewSelectProps) => {
  const [selected, setSelected] = useState(firstValue || '');
  const handleChange = (event: any) => {
    const selectedValue = event.target.value;
    setSelected(selectedValue);
    if (onChange) {
      onChange(selectedValue); // Вызываем функцию обратного вызова при изменении
    }
  };

  useEffect(() => {
    if (selectedItem) {
      setSelected(selectedItem);
    }
  }, [selectedItem]);

  return (
    <div className="select_base_wrap">
      {label && (
        <div className="label_box_row label_box_text">
          <div className="select_base_row_text">{label}</div>
          {link && (
            <NavLink to={link} className="label_box_row_link" end target="_blank">
              {linkText}
            </NavLink>
          )}
        </div>
      )}
      <select value={selected ? selected : ''} className={`select_base ${className ? className : ''}`} name={name} id={id} onChange={handleChange}>
        {firstValue && (
          <option value={firstValue} disabled hidden>
            {firstValue}
          </option>
        )}
        {selectItems?.map((item: any, index: number) => (
          <option value={item._id} key={index} id={item._id}>
            {item.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default ReviewSelect;
