import React, { useEffect, useState } from 'react';
import ServicesInnerList from '../Services/ServicesInnerList';
import BlogItemsList from './BlogItemsList';
import { useNavigate, useParams } from 'react-router-dom';
import BlogInnerItem from './BlogInnerItem';
import { Helmet } from 'react-helmet-async';
import InnerBreadcrumbs from '../ui-kit/InnerBreadcrumbs';

// Определение типа для объекта страницы
type PageType = {
  h1: string;
  title: string;
  updated: number;
  description: string;
  breadcrumbs: string;
  text: string;
  seoUrl: string;
  showOnMain: boolean;
  excerpt: string;
  banner: {
    text: string;
    image: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
  icon: {
    big: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
    small: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
};

// Определение общего типа для элементов в массиве socialDataState
type SocialDataItem = {
  _id: string;
  name: string;
  __v: number;
  active: boolean;
  activeApi: boolean;
  category: string;
  page: PageType;
  parentId: string;
  rate: string;
  service: string;
  socialMedia: string;
  type: string;
};

// Определение типа для Path
type pathItem = {
  _id: string;
  elementId: string;
  path: string;
  seo_url: string;
};

// Определение типа для пропсов компонента AdminBlog
type BlogInnerProps = {
  socialDataState: SocialDataItem[];
  pathDataState: pathItem[];
  pathString: string;
  typeString?: string;
};

const BlogInner = ({ pathDataState, pathString, socialDataState }: BlogInnerProps) => {
  const navigate = useNavigate();
  const { seo_url } = useParams();
  const [_id, setId] = useState<any>(null);
  const [pathItem, setPathItem] = useState<any>(null);

  // жду выполнения запроса фетча
  const [isLoading, setIsLoading] = useState(true);
  // жду завершения обновления элемента
  const [isLoadingData, setIsLoadingData] = useState(false);

  // объект одного элемента
  const [socialItem, setSocialItem] = useState<any>(null);

  // тип текущего элемента
  const [socialItemType, setSocialItemType] = useState<any>(null);

  // список категорий соц. сети
  const [socialDataCategoryArr, setSocialDataCategoryArr] = useState<any[]>([]);

  // объекты всех услуг
  const [socialItemsArr, setSocialItemsArr] = useState<any>(null);

  // Функция для получения массива категорий
  const getCategoryOptions = (data: SocialDataItem[]): {}[] => {
    const allArticles = {
      text: 'Все статьи',
      link: '/blog', // Здесь нужно указать ссылку на основную страницу блога
      id: 'all_articles',
      icon: '', // Можно указать иконку для "Все статьи", если есть
      parent: '' // У "Все статьи" нет родительской категории, поэтому пустая строка
    };
    const categoryOptions = data
      .filter((item: SocialDataItem) => item.type === 'category') // Отфильтровать только категории
      .map((category: SocialDataItem) => ({
        text: category.name,
        link: '/blog/' + category.page.seoUrl,
        id: category._id,
        icon: category.page.icon.small.filepath
      }));
    // Вставляем "Все статьи" как первый элемент в массив категорий
    categoryOptions.unshift(allArticles);

    return categoryOptions;
  };

  // Функция для получения массива элементов
  const getServicesElements = (data: SocialDataItem[]): {}[] => {
    const socialSortItem = data.filter((item: SocialDataItem) => item.type != 'category' && item.type != 'root_category');
    const categoryOptionsArr = socialSortItem.filter((item) => item.parentId === socialItem._id);
    let categoryOptions: {}[] = [];
    const categoryOptionsAll = data
      .filter((item: SocialDataItem) => item.type === 'category' && item._id === socialItem._id) // Отфильтровать только категории
      .map((category: SocialDataItem) => ({
        text: category.name,
        link: category.page.seoUrl,
        id: category._id,
        icon: category.page.icon.small.filepath,
        parent: category.parentId,
        categoryName: category.name
      }));
    categoryOptionsAll.forEach((serviceItem: any) => {
      const itemsWithCategoryName = categoryOptionsArr.map((item) => ({
        ...item,
        link: '/blog/' + (item.parentId != '0' ? serviceItem.link + '/' : '/') + item.page.seoUrl,
        categoryName: serviceItem.categoryName,
        categoryIcon: serviceItem.icon
      }));
      categoryOptions = [...categoryOptions, ...itemsWithCategoryName];
    });
    return categoryOptions;
  };

  useEffect(() => {
    if (pathDataState) {
      console.log(pathDataState);
      const resultPath = pathString + seo_url;
      const pathItem = pathDataState.find((item) => item.seo_url === resultPath);
      if (pathItem) {
        setId(pathItem.elementId);
        setPathItem(pathItem);
      }
    }
  }, [socialDataState, seo_url, pathDataState]);

  useEffect(() => {
    if (_id) {
      const fetchSocialItem = async () => {
        try {
          const socialItem = socialDataState.find((item) => item._id === _id);
          if (!socialItem) {
            // navigate('/404');
          } else {
            setSocialItem(socialItem);
            setSocialItemType(socialItem.type);
          }
        } catch (error) {
          console.error('Error fetching social item:', error);
          navigate('/404');
        } finally {
          setIsLoading(false);
          setIsLoadingData(false);
        }
      };

      if (socialDataState.length > 0) {
        fetchSocialItem();
      }
    }
  }, [socialDataState, _id]);

  useEffect(() => {
    console.log('her');
    if (socialItem) {
      // заполняю массив категориями для их выбора в элементе
      const categories = getCategoryOptions(socialDataState);
      setSocialDataCategoryArr(categories);

      // заполняю массив с элементами
      const elements = getServicesElements(socialDataState);
      setSocialItemsArr(elements);
    }
  }, [socialItem]);
  return (
    socialItem && (
      <>
        <Helmet>
          <title>{socialItem.page.title ? socialItem.page.title : socialItem.name}</title>
          <meta name="description" content={socialItem.page.description ? socialItem.page.description : socialItem.name} />
          {/* Другие мета-теги */}
        </Helmet>
        {socialItemType === 'category' ? (
          <>
            <InnerBreadcrumbs pathElement={pathItem} _id={_id} chapterLink={'/blog'} chapterString={'Блог'} />
            <div className="inner_single_top">
              <div className="inner_single_top_content">
                <h1 className="inner_single_top_content_title">{socialItem.page.h1 ? socialItem.page.h1 : socialItem.name}</h1>
                <div className="inner_single_top_content_text">{socialItem.page.banner.text ? socialItem.page.banner.text : ''}</div>
              </div>
              <div className="inner_single_top_content_img">
                <img
                  src={socialItem.page.banner.image.filepath ? socialItem.page.banner.image.filepath : '/content/not/dig_grey.png'}
                  alt={socialItem.page.h1 ? socialItem.page.h1 : socialItem.name}
                />
              </div>
            </div>
            <div className="single_content">
              <div className="inner_section main_popular_services_wrap single_content_menu">
                <ServicesInnerList menuList={socialDataCategoryArr} />
              </div>
              <BlogItemsList menuList={socialItemsArr} />
            </div>
          </>
        ) : (
          <>
            <BlogInnerItem socialDataState={socialDataState} pathDataState={pathDataState} pathString={'/blog/'} />
          </>
        )}
      </>
    )
  );
};

export default BlogInner;
