import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';

import Admin from './admin/Admin';
import PersonalFaq from './content/components/personal/sections/PersonalFaq';
import PersonalSetting from './content/components/personal/sections/PersonalSetting/PersonalSetting';
import PersonalBalance from './content/components/personal/sections/PersonalBalance/PersonalBalance';
import PersonalOrderCreate from './content/components/personal/sections/PersonalOrderCreate/PersonalOrderCreate';
import PersonalLogin from './content/components/personal/PersonalLogin';
import PrivateRoute from './content/components/personal/PrivateRoute';
import RemoveTrailingSlash from './content/components/ui-kit/RemoveTrailingSlash';

import PersonalRegister from './content/components/personal/PersonalRegister';
import Services from './content/components/Services/Services';
import InnerLayout from './content/components/InnerLayout';
import ServicesInner from './content/components/Services/ServicesInner';
import ServicesInnerItem from './content/components/Services/ServicesInnerItem';
import SmmTools from './content/components/SmmTools/SmmTools';

import SmmToolsInner from './content/components/SmmTools/SmmToolsInner';
import Blog from './content/components/Blog/Blog';
import BlogInner from './content/components/Blog/BlogInner';
import BlogInnerItem from './content/components/Blog/BlogInnerItem';

import Contact from './content/Pages/Contact';
import Faq from './content/Pages/Faq';
import Guarantees from './content/Pages/Guarantees';
import Home from './content/Pages/Home';
import NotFound from './content/Pages/NotFound';
import Personal from './content/Pages/Personal';
import Promo from './content/Pages/Promo';
import Reviews from './content/Pages/Reviews';
import ThankYouPage from './content/Pages/ThankYouPage';
import AdminLayout from './admin/components/AdminLayout';
import Layout from './Routes/Layout';
import AdminSocial from './admin/components/AdminSocial/AdminSocial';
import AdminSocialItem from './admin/components/AdminSocial/AdminSocialItem';
import AdminBlog from './admin/components/AdminBlog/AdminBlog';
import AdminBlogItem from './admin/components/AdminBlog/AdminBlogItem';
import AdminTools from './admin/components/AdminTools/AdminTools';
import AdminToolsItem from './admin/components/AdminTools/AdminToolsItem';
import AdminReview from './admin/components/AdminReview/AdminReview';
import AdminReviewItem from './admin/components/AdminReview/AdminReviewItem';

// import reviewData from './static-data/reviewData.json';
// import faqData from './static-data/faqData.json';
import pageData from './static-data/pageData.json';
import settingsData from './static-data/settingsData.json';
import menuData from './static-data/menuData.json';
import { fetchApi } from './content/components/helpers/api';
import AdminFaq from './admin/components/AdminFaq/AdminFaq';
import AdminFaqItem from './admin/components/AdminFaq/AdminFaqItem';
import AdminPage from './admin/components/AdminPage/AdminPage';
import AdminPageItem from './admin/components/AdminPage/AdminPageItem';
import AdminSetting from './admin/components/AdminSetting/AdminSetting';
import AdminClientMenu from './admin/components/AdminClientMenu/AdminClientMenu';
import AdminClientMenuItem from './admin/components/AdminClientMenu/AdminClientMenuItem';
import AdminBlogItemNew from './admin/components/AdminBlog/AdminBlogItemNew';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import AdminToolsItemNew from './admin/components/AdminTools/AdminToolsItemNew';
import AdminReviewItemNew from './admin/components/AdminReview/AdminReviewItemNew';
import AdminFaqItemNew from './admin/components/AdminFaq/AdminFaqItemNew';
import { Authentification } from './content/components/helpers/auth';
import OrderError from './content/Pages/OrderError';
import OrderSuccess from './content/Pages/OrderSuccess';
import AdminUsers from './admin/components/AdminUsers/AdminUsers';
import AdminUsersItem from './admin/components/AdminUsers/AdminUsersItem';
import AdminOrders from './admin/components/AdminOrders/AdminOrders';
import AdminOrdersItem from './admin/components/AdminOrders/AdminOrdersItem';
import AdminRoute from './admin/AdminRoute';
import ServicesInnerItemElement from './content/components/Services/ServicesInnerItemElement';
import { useUserData } from './content/hooks/UserDataContext';
import AdminCatalogCategory from './admin/components/AdminCatalog/AdminCatalogCategory/AdminCatalogCategory';
import AdminCatalogCategoryNew from './admin/components/AdminCatalog/AdminCatalogCategory/AdminCatalogCategoryNew';
import AdminCatalogItem from './admin/components/AdminCatalog/AdminCatalogItem/AdminCatalogItem';
import AdminCatalogItemList from './admin/components/AdminCatalog/AdminCatalogItem/AdminCatalogItemList';
import AdminCatalogItemNew from './admin/components/AdminCatalog/AdminCatalogItem/AdminCatalogItemNew';
import AdminCatalogCategoryList from './admin/components/AdminCatalog/AdminCatalogCategory/AdminCatalogCategoryList';
import AdminCatalogSocial from './admin/components/AdminCatalog/AdminCatalogSocial/AdminCatalogSocial';
import AdminCatalogSocialList from './admin/components/AdminCatalog/AdminCatalogSocial/AdminCatalogSocialList';
import AdminCatalogSocialNew from './admin/components/AdminCatalog/AdminCatalogSocial/AdminCatalogSocialNew';
import PersonalForgot from './content/components/personal/PersonalForgot';

type AppProps = {
  // adminToken?: string;
};

type PersonalProps = {
  // userToken?: string;
};

const personalMenu = [
  {
    name: 'Кабинет',
    title: 'Кабинет',
    component: 'Personal',
    iconSrc: '/content/personal/menu/01.png',
    link: '/personal'
  },
  {
    name: 'Создать заказ',
    title: 'Создать заказ',
    component: 'PersonalOrderCreate',
    iconSrc: '/content/personal/menu/02.png',
    link: '/personal/order-create'
  },
  {
    name: 'Пополнить',
    title: 'Пополнение счета',
    component: 'PersonalbBalance',
    iconSrc: '/content/personal/menu/03.png',
    link: '/personal/balance'
  },
  {
    name: 'Настройки',
    title: 'Настройки',
    component: 'PersonalbSetting',
    iconSrc: '/content/personal/menu/04.png',
    link: '/personal/setting'
  },
  {
    name: 'Помощь',
    title: 'Помощь',
    component: 'PersonalbFaq',
    iconSrc: '/content/personal/menu/05.png',
    link: '/personal/faq'
  },
  {
    name: 'Выйти',
    title: 'Кабинет',
    component: 'Personal',
    iconSrc: '/content/personal/menu/06.png',
    link: '/logout'
  }
];

const Application = ({}: AppProps, {}: PersonalProps) => {
  const [userName, setUserName] = useState('');
  const [userToken, setUserToken] = useState('');
  const [userId, setUserId] = useState('');
  useEffect(() => {
    const token = Authentification.getInstance().getToken();
    if (token) {
      const login = Authentification.getInstance().getLogin();
      const id = Authentification.getInstance().getUserId();
      setUserName(login ? login : '');
      setUserToken(token ? token : '');
      setUserId(id ? id : '');
    }
  });
  useEffect(() => {
    const checkToken = setInterval(() => {
      const token = Authentification.getInstance().getToken();
      if (token) {
        const login = Authentification.getInstance().getLogin();
        setUserName(login ? login : '');
        setUserToken(token ? token : '');
      }
    }, 15000);
    return () => clearInterval(checkToken);
  }, [userToken]);

  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const location = useLocation();

  const [pathDataState, setPathDataState] = useState<any[]>([]);
  const [socialDataState, setSocialDataState] = useState<any[]>([]);
  const [blogDataState, setBlogDataState] = useState<any[]>([]);
  const [toolsDataState, setToolsDataState] = useState<any[]>([]);
  const [reviewDataState, setReviewDataState] = useState<any[]>([]);
  const [faqDataState, setFaqDataState] = useState<any[]>([]);
  const [pageDataState, setPageDataState] = useState<any[]>([]);
  const [settingsDataState, setSettingsDataState] = useState<any[]>([]);
  const [menuDataState, setMenuDataState] = useState<any[]>([]);
  const [userDataState, setUserDataState] = useState<any[]>([]);
  const [orderDataState, setOrderDataState] = useState<any[]>([]);

  const fetchData = async () => {
    const token = Authentification.getInstance().getToken();
    if (token) {
      setUserToken(token);
      const id = Authentification.getInstance().getUserId();
      if (id) {
        setUserId(id);
      }
    }

    // Получаю данные всех Меню
    const pathAPI = await fetchApi('GET', 'path/get', {});
    setPathDataState(pathAPI);

    // Получаю данные всех Меню
    const menuAPI = await fetchApi('GET', 'menu/get_all', {});
    setMenuDataState(menuAPI);

    // Получаю список пользователей
    const userAPI = await fetchApi('GET', 'users/list', {});
    setUserDataState(userAPI);

    // Получаю список заказов
    const ordersAPI = await fetchApi('GET', '/services/orders/admin', {});
    setOrderDataState(ordersAPI);

    // Получаю данные Блога (статей, категорий и тд)
    const blogAPI = await fetchApi('GET', 'blog/get_all', {});
    setBlogDataState(blogAPI);

    // Получаею данные SMM Tools
    const toolsAPI = await fetchApi('GET', 'tools/get', {});
    setToolsDataState(toolsAPI);

    // Получаю данные отзывов
    const reviewAPI = await fetchApi('GET', 'review/get', {});
    setReviewDataState(reviewAPI);

    // Получаю данные FAQ
    const faqAPI = await fetchApi('GET', 'faq/get', {});
    setFaqDataState(faqAPI);

    // Получаю данные соц сетей (категорий, элементов и тд)
    const socialAPI = await fetchApi('GET', 'services/list', {});
    setSocialDataState(socialAPI);
  };

  // Отдельно функция для обновления Социлок
  const fetchSocialData = async () => {
    const newSocialAPI = await fetchApi('GET', 'services/list', {});
    setSocialDataState(newSocialAPI);
  };

  // Отдельно функция для обновления Блога
  const fetchBlogData = async () => {
    const newDataBlog = await fetchApi('GET', 'blog/get_all', {});
    setBlogDataState(newDataBlog);
  };

  // Отдельно функция для обновления Smm tools
  const fetchToolsData = async () => {
    const newDataTools = await fetchApi('GET', 'tools/get', {});
    setToolsDataState(newDataTools);
  };

  // Отдельно функция для обновления отзывов
  const fetchRewriewsData = async () => {
    const newRewriewsTools = await fetchApi('GET', 'review/get', {});
    setReviewDataState(newRewriewsTools);
  };

  // Отдельно функция для обновления FAQ
  const fetchFaqsData = async () => {
    const newFaqTools = await fetchApi('GET', 'faq/get', {});
    setFaqDataState(newFaqTools);
  };

  // Отдельно функция для обновления FAQ
  const fetchMenuData = async () => {
    const newMenuTools = await fetchApi('GET', 'menu/get_all', {});
    setMenuDataState(newMenuTools);
  };

  // Отдельно функция для обновления пользователей
  const fetchUserData = async () => {
    const token = Authentification.getInstance().getToken();
    if (token) {
      // Получаю список пользователей
      const newUserAPI = await fetchApi('GET', 'users/list', {});
      setUserDataState(newUserAPI);
    }
  };
  // Получите значения из контекста
  const { fetchUserDataContext } = useUserData();

  useEffect(() => {
    fetchData();
    // Имитация асинхронной загрузки данных (если это нужно)
    // setReviewDataState(reviewData);
    // setFaqDataState(faqData);
    setPageDataState(pageData);
    setSettingsDataState(settingsData);
    // setMenuDataState(menuData);
    return () => {
      console.log('rebuild');
      fetchUserDataContext();
    };
  }, [userToken]); // пока не разобрался, как можно вызвать фетч только один раз

  return (
    <>
      <RemoveTrailingSlash />
      <Layout isMobile={isMobile} isUserLogin={userToken ? true : false} userName={userToken ? userName : ''} menuDataState={menuDataState}>
        <Routes>
          <Route path="/" element={<Home socialDataState={socialDataState} isMobile={isMobile} faqDataState={faqDataState} menuDataState={menuDataState} isLogged={userToken ? true : false} />} />

          <Route path="/thank_you_page" element={<ThankYouPage isMobile={isMobile} menuDataState={menuDataState} />} />

          <Route path="/login" element={<PersonalLogin isLogged={userToken ? true : false} />} />

          <Route path="/register" element={<PersonalRegister isLogged={userToken ? true : false} />} />
          <Route path="/forgot" element={<PersonalForgot isLogged={userToken ? true : false} />} />
          <Route path="order">
            <Route index element={<NotFound isMobile={isMobile} menuDataState={menuDataState} />} />
            <Route path="success" element={<OrderSuccess isMobile={isMobile} menuDataState={menuDataState} />} />
            <Route path="error" element={<OrderError isMobile={isMobile} menuDataState={menuDataState} />} />
          </Route>

          <Route path="/personal" element={<PrivateRoute isLogged={userToken ? true : false} personalMenuList={personalMenu} userName={userName} />}>
            <Route index element={<Personal userId={userId} userToken={userToken} orderData={orderDataState} />} />
            <Route path="order-create" element={<PersonalOrderCreate socialDataState={socialDataState} isLogged={userToken ? true : false} />} />
            <Route path="balance" element={<PersonalBalance />} />
            <Route path="setting" element={<PersonalSetting />} />
            <Route path="faq" element={<PersonalFaq socialDataState={faqDataState} typeString={'faq'} />} />
          </Route>

          <Route path="/services" element={<InnerLayout isMobile={isMobile} menuDataState={menuDataState} />}>
            <Route index element={<Services socialDataState={socialDataState} socialReviewState={reviewDataState} faqDataState={faqDataState} pathDataState={pathDataState} />} />
            <Route path=":seo_url">
              <Route
                index
                element={<ServicesInner socialDataState={socialDataState} socialReviewState={reviewDataState} faqDataState={faqDataState} pathDataState={pathDataState} pathString={'/services/'} />}
              />
              <Route path=":seo_url">
                <Route
                  index
                  element={
                    <ServicesInnerItem socialDataState={socialDataState} socialReviewState={reviewDataState} faqDataState={faqDataState} pathDataState={pathDataState} pathString={'/services/'} />
                  }
                />
                <Route
                  path=":seo_url"
                  element={
                    <ServicesInnerItemElement
                      socialDataState={socialDataState}
                      socialReviewState={reviewDataState}
                      faqDataState={faqDataState}
                      pathDataState={pathDataState}
                      pathString={'/services/'}
                    />
                  }
                />
              </Route>
            </Route>
          </Route>

          {/* <Route path="/services" element={<InnerLayout isMobile={isMobile} menuDataState={menuDataState} />}>
            <Route index element={<Services socialDataState={socialDataState} socialReviewState={reviewDataState} faqDataState={faqDataState} pathDataState={pathDataState} />} />
            <Route path=":seo_url">
              <Route
                index
                element={<ServicesInner socialDataState={socialDataState} socialReviewState={reviewDataState} faqDataState={faqDataState} pathDataState={pathDataState} pathString={'/services/'} />}
              />
              <Route path=":seo_url">
                <Route
                  index
                  element={
                    <ServicesInnerItem socialDataState={socialDataState} socialReviewState={reviewDataState} faqDataState={faqDataState} pathDataState={pathDataState} pathString={'/services/'} />
                  }
                />
                <Route
                  path=":seo_url"
                  element={
                    <ServicesInnerItemElement
                      socialDataState={socialDataState}
                      socialReviewState={reviewDataState}
                      faqDataState={faqDataState}
                      pathDataState={pathDataState}
                      pathString={'/services/'}
                    />
                  }
                />
              </Route>
            </Route>
          </Route> */}

          <Route path="/smm-tools" element={<InnerLayout isMobile={isMobile} menuDataState={menuDataState} />}>
            <Route index element={<SmmTools socialDataState={toolsDataState} />} />
            <Route path=":seo_url" element={<SmmToolsInner socialDataState={toolsDataState} pathDataState={pathDataState} />} />
          </Route>

          <Route path="/blog" element={<InnerLayout isMobile={isMobile} menuDataState={menuDataState} />}>
            <Route index element={<Blog socialDataState={blogDataState} />} />
            <Route path=":seo_url">
              <Route index element={<BlogInner socialDataState={blogDataState} pathDataState={pathDataState} pathString={'/blog/'} />} />
              <Route path=":seo_url" element={<BlogInnerItem socialDataState={blogDataState} pathDataState={pathDataState} pathString={'/blog/'} />} />
            </Route>
          </Route>

          <Route path="/guarantees" element={<InnerLayout isMobile={isMobile} menuDataState={menuDataState} />}>
            <Route index element={<Guarantees />} />
          </Route>

          <Route path="/faq" element={<InnerLayout isMobile={isMobile} menuDataState={menuDataState} />}>
            <Route index element={<Faq socialDataState={faqDataState} typeString={'faq'} />} />
          </Route>

          <Route path="/promo" element={<InnerLayout isMobile={isMobile} menuDataState={menuDataState} />}>
            <Route index element={<Promo />} />
          </Route>

          <Route path="/contact" element={<InnerLayout isMobile={isMobile} menuDataState={menuDataState} />}>
            <Route index element={<Contact />} />
          </Route>

          <Route path="/reviews" element={<InnerLayout isMobile={isMobile} menuDataState={menuDataState} />}>
            <Route index element={<Reviews socialDataState={reviewDataState} socialAllServices={socialDataState} typeString={'review'} />} />
          </Route>

          <Route path="/admin" element={<AdminRoute isLogged={userToken ? true : false} />}>
            {/* <Route path="/admin" element={<AdminLayout />}> */}
            <Route index element={<Admin isLogged={userToken ? true : false} token={userToken} />} />
            {/*  */}
            <Route path="blog">
              <Route index element={<AdminBlog socialDataState={blogDataState} typeString={'category'} updateDataState={fetchBlogData} />} />

              <Route path="edit/:_id" element={<AdminBlogItem socialDataState={blogDataState} typeString={'category'} updateDataState={fetchBlogData} />} />

              <Route path="new" element={<AdminBlogItemNew socialDataState={blogDataState} typeString={'category'} updateDataState={fetchBlogData} />} />

              <Route path="items">
                <Route index element={<AdminBlog socialDataState={blogDataState} typeString={'item'} updateDataState={fetchBlogData} />} />
                <Route path="edit/:_id" element={<AdminBlogItem socialDataState={blogDataState} typeString={'item'} updateDataState={fetchBlogData} />} />
                <Route path="new" element={<AdminBlogItemNew socialDataState={blogDataState} typeString={'item'} updateDataState={fetchBlogData} />} />
              </Route>
            </Route>
            {/*  */}
            <Route path="tools">
              <Route index element={<AdminTools socialDataState={toolsDataState} typeString={'tools'} />} />

              <Route path="edit/:_id" element={<AdminToolsItem socialDataState={toolsDataState} typeString={'tools'} updateDataState={fetchToolsData} />} />

              <Route path="new" element={<AdminToolsItemNew socialDataState={toolsDataState} typeString={'tools'} updateDataState={fetchToolsData} />} />
            </Route>
            {/*  */}
            <Route path="reviews">
              <Route index element={<AdminReview socialDataState={reviewDataState} socialAllServices={socialDataState} typeString={'review'} updateDataState={fetchRewriewsData} />} />

              <Route path="edit/:_id" element={<AdminReviewItem socialDataState={reviewDataState} socialAllServices={socialDataState} typeString={'review'} updateDataState={fetchRewriewsData} />} />

              <Route path="new" element={<AdminReviewItemNew socialReviewState={reviewDataState} socialDataState={socialDataState} typeString={'review'} updateDataState={fetchRewriewsData} />} />
            </Route>
            {/*  */}
            <Route path="faq">
              <Route index element={<AdminFaq socialDataState={faqDataState} typeString={'faq'} />} />

              <Route path="edit/:_id" element={<AdminFaqItem socialDataState={faqDataState} typeString={'faq'} />} />

              <Route path="new" element={<AdminFaqItemNew socialDataState={faqDataState} typeString={'faq'} updateDataState={fetchFaqsData} />} />
            </Route>
            {/*  */}
            <Route path="pages">
              <Route index element={<AdminPage socialDataState={pageDataState} typeString={'page'} />} />

              <Route path="edit/:_id" element={<AdminPageItem socialDataState={pageDataState} typeString={'page'} />} />
            </Route>
            {/*  */}
            <Route path="orders">
              <Route index element={<AdminOrders socialDataState={orderDataState} />} />

              <Route path="edit/:_id" element={<AdminOrdersItem socialDataState={userDataState} orderDataState={orderDataState} updateDataState={fetchUserData} />} />
            </Route>
            {/*  */}
            <Route path="users">
              <Route index element={<AdminUsers socialDataState={userDataState} />} />

              <Route path="edit/:_id" element={<AdminUsersItem socialDataState={userDataState} orderDataState={orderDataState} updateDataState={fetchUserData} />} />
            </Route>
            {/*  */}
            <Route path="menus">
              <Route index element={<AdminClientMenu socialDataState={menuDataState} typeString={'menu'} />} />

              <Route path="edit/:_id" element={<AdminClientMenuItem socialDataState={menuDataState} typeString={'menu'} updateDataState={fetchMenuData} />} />
            </Route>
            {/*  */}
            <Route path="settings">
              <Route index element={<AdminSetting socialDataState={settingsDataState} typeString={'settings'} />} />
            </Route>

            {/*  */}
            <Route path="social">
              {/* <Route index element={<AdminSocial socialDataState={socialDataState} typeString={'root_category'} />} /> */}
              <Route index element={<AdminSocial socialDataState={socialDataState} typeString={'root_category'} />} />
              <Route
                path="edit/:_id"
                element={
                  <AdminSocialItem
                    socialDataState={socialDataState}
                    typeString={'root_category'}
                    // updateDataState={fetchSocialData}
                    updateDataState={fetchSocialData}
                  />
                }
              />

              <Route path="category">
                <Route index element={<AdminSocial socialDataState={socialDataState} typeString={'category'} />} />
                <Route path="edit/:_id" element={<AdminSocialItem socialDataState={socialDataState} typeString={'category'} updateDataState={fetchSocialData} />} />
              </Route>

              <Route path="items">
                <Route index element={<AdminSocial socialDataState={socialDataState} typeString={'items'} />} />
                <Route path="edit/:_id" element={<AdminSocialItem socialDataState={socialDataState} typeString={'items'} updateDataState={fetchSocialData} />} />
              </Route>
            </Route>
            {/*  */}
            <Route path="catalog">
              <Route index element={<AdminCatalogSocialList />} />

              <Route path="edit/:_id" element={<AdminCatalogSocial updateDataState={fetchSocialData} />} />

              {/* <Route path="new" element={<AdminCatalogSocialNew />} /> */}

              <Route path=":_rootId/category_list">
                <Route index element={<AdminCatalogCategoryList />} />
                <Route path="edit/:_id" element={<AdminCatalogCategory updateDataState={fetchSocialData} />} />
                {/* <Route path="new" element={<AdminCatalogCategoryNew />} /> */}
                <Route path=":_parentId/item_list">
                  <Route index element={<AdminCatalogItemList />} />
                  <Route path="edit/:_id" element={<AdminCatalogItem updateDataState={fetchSocialData} />} />
                </Route>
              </Route>

              <Route path=":_rootId/item_list">
                <Route index element={<AdminCatalogItemList />} />
                <Route path="edit/:_id" element={<AdminCatalogItem updateDataState={fetchSocialData} />} />
              </Route>

              <Route path="category">
                <Route index element={<AdminCatalogCategoryList />} />
                <Route path="edit/:_id" element={<AdminCatalogCategory updateDataState={fetchSocialData} />} />
                {/* <Route path="new" element={<AdminCatalogCategoryNew />} /> */}
                <Route path=":_parentId/item_list">
                  <Route index element={<AdminCatalogItemList />} />
                  <Route path="edit/:_id" element={<AdminCatalogItem updateDataState={fetchSocialData} />} />
                </Route>
              </Route>

              <Route path="items">
                <Route index element={<AdminCatalogItemList />} />
                <Route path="edit/:_id" element={<AdminCatalogItem updateDataState={fetchSocialData} />} />
              </Route>
            </Route>
            {/*  */}
          </Route>

          <Route path="*" element={<NotFound isMobile={isMobile} menuDataState={menuDataState} />} />
        </Routes>
      </Layout>
    </>
  );
};

export default Application;
