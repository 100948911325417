import React from 'react';
import { Link, NavLink } from 'react-router-dom';

type BtnOutlineProps = {
  btnLink?: string;
  btnText?: string;
  className?: string;
  type?: 'submit' | 'reset' | 'button';
  disabled?: boolean;
  // onClick?: () => void;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  newWindow?: boolean;
};

const BtnOutline = ({ btnLink, btnText, className, type, disabled, onClick, newWindow }: BtnOutlineProps) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onClick) {
      onClick(event);
    }
  };
  return (
    <>
      {btnLink !== undefined && btnLink.length > 0 ? (
        <NavLink to={`${btnLink}`} className={'btn_link'}  target={newWindow ? "_blank" : undefined}>
          <button onClick={handleClick} className={`btn ${className ? className : ''}`} type={type} disabled={disabled}>
            <span>{btnText}</span>
          </button>
        </NavLink>
      ) : (
        <button onClick={handleClick} className={`btn ${className ? className : ''}`} type={type} disabled={disabled}>
          <span>{btnText}</span>
        </button>
      )}
    </>
  );
};

export default BtnOutline;
