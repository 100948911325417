import React, { FormEvent, useEffect, useRef, useState } from 'react';
import BtnOutline from '../../../ui-kit/BtnOutline';
import InputBase from '../../../ui-kit/input/InputBase';
import InnerForm from '../../../ui-kit/form/InnerForm';
import { fetchApi } from '../../../helpers/api';
import { Authentification } from '../../../helpers/auth';
import { sendOrder } from '../../../helpers/paymentSystem';
import { format } from 'date-fns';

type Props = {};

const paymentMethod = [
  {
    id: 1,
    name: 'Payeer',
    slug: 'Payeer',
    icon: '/content/footer/payeer.png',
    description: 'Оплата карта/сбп'
  },
  {
    id: 2,
    name: 'Cryptomus',
    slug: 'Cryptomus',
    icon: '/content/footer/cryptomus.png',
    description: 'Оплата криптой'
  },
  {
    id: 3,
    name: 'Yookassa',
    slug: 'Yookassa',
    icon: '/content/footer/yookassa.svg',
    description: 'Оплата карта'
  }
];

const paymentMethodHistory = [
  {
    id: 1,
    name: 'SberPay',
    icon: '/content/footer/sber.png',
    sum: '1 000 ₽',
    date: '20.11.2023 13:00:00'
  },
  {
    id: 2,
    name: 'СБП',
    icon: '/content/footer/sbp.png',
    sum: '30 000 ₽',
    date: '20.11.2023 14:00:00'
  },
  {
    id: 3,
    name: 'SberPay',
    icon: '/content/footer/sber.png',
    sum: '10 000 ₽',
    date: '22.11.2023 13:00:00'
  }
];

const PersonalBalance = (props: Props) => {
  // объект одного элемента
  const [summ, setSumm] = useState<any>('');
  const [paymentMethodItem, setPaymentMethodItem] = useState<any>(null);

  const [paymentHistory, setPaymentHistory] = useState<any>(null);
  // жду выполнения запроса фетча
  const [isLoading, setIsLoading] = useState(true);

  const balanceForm = useRef<HTMLDivElement>(null);

  const fetchPaymentHistory = async () => {
    try {
      const paymentHistoryArr = await fetchApi('GET', `balance/payments`, {});
      const filteredPaymentHistoryArr = paymentHistoryArr.filter((item: any) => item.type === 'deposit').filter((item: any) => item.acquiring);

      const sordedPaymentHistoryArr = filteredPaymentHistoryArr.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime());

      setPaymentHistory(sordedPaymentHistoryArr);
    } catch (error) {
      console.error('Error fetching social item:', error);
      // navigate('/404');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPaymentHistory();
    if (balanceForm.current) {
      balanceForm.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  // функция формирования массива для сохранения
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const [paymentError, setPaymentError] = useState<string>('');
  const [summError, setSummError] = useState<string>('');

  // функция формирования массива для сохранения
  const handleClickMethod = (id: number, name: string) => {
    console.log({ id, name });
    setPaymentMethodItem({ id, name });
    setPaymentError('');
  };

  // функция формирования массива для сохранения
  const handleSubmitClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    if (summ && summ.length > 0 && Number(summ) >= 500) {
      if (paymentMethodItem && paymentMethodItem.id) {
        const payment = await fetchApi('POST', '/balance/deposit', { amount: summ, acquiring: paymentMethodItem.name });
        if (payment) {
          sendOrder(payment, paymentMethodItem.name);
        }
      } else {
        setPaymentError('Выберите способ оплаты!');
      }
    } else {
      setSummError('Введите сумму! (от 500 RUB)');
    }
  };

  useEffect(() => {
    // console.log(summ);
  }, [summ]);

  return (
    <>
      <div className="personal_col personal_col_content">
        <div className="personal_col_heading">
          <div className="personal_col_heading_wrap">
            <div className="personal_col_icon">+</div>
            <div className="personal_col_heading_title">Пополнить баланс</div>
          </div>
        </div>
        <div className="personal_col_inner_padding" ref={balanceForm}>
          <div className="personal_input_row">
            <InputBase
              type={'text'}
              name={'personal_upBalance'}
              placeholder={'Введите сумму (от 500 RUB)'}
              className={`${summError.length > 0 && 'error'}`}
              inputClassName="base_input_wide"
              label={'Сумма пополнения'}
              value={summ}
              onChange={(event) => {
                const value = event.target.value;
                if (!/^\d*$/.test(value)) return;
                if (value === '0' && value.length === 1) return;

                setSumm(value);

                if (parseInt(value, 10) < 500) {
                  setSummError('Введите сумму! (от 500 RUB)');
                } else {
                  setSummError('');
                }
              }}
            />
            {summError.length > 0 && <div className="base_input_wide_error">{summError}</div>}
            <div className="personal_balance_fast_fill_wrap">
              <div className="personal_balance_fast_fill_item" onClick={() => setSumm('3000')}>
                3000 ₽
              </div>
              <div className="personal_balance_fast_fill_item" onClick={() => setSumm('5000')}>
                5000 ₽
              </div>
              <div className="personal_balance_fast_fill_item" onClick={() => setSumm('10000')}>
                10 000 ₽
              </div>
              <div className="personal_balance_fast_fill_item" onClick={() => setSumm('20000')}>
                20 000 ₽
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="personal_col personal_col_content">
        <div className="personal_col_heading">
          <div className="personal_col_heading_wrap">
            <div className="personal_col_heading_title">Платежный метод</div>
          </div>
        </div>
        {paymentMethod.map((item: any, index: number) => (
          <div
            className={`personal_row personal_row_pointer personal_row_hover ${paymentMethodItem?.id === item.id ? 'personal_row-menu-active' : ''}`}
            data-payment-id={item.id}
            key={index}
            onClick={(event) => handleClickMethod(item.id, item.slug)}
          >
            <div className="personal_row_soc">
              <div className="personal_menu_item">
                <div className="personal_menu_item_img_wrap">
                  <img src={item.icon} alt={item.name} />
                </div>
                <div className="personal_menu_link">{item.name}</div>
              </div>
              <div className='personal_menu_item_description'>{item.description ? item.description : ''}</div>
            </div>
          </div>
        ))}
        {paymentError.length > 0 && <div className="base_input_wide_error personal_row personal_row_pointer">{paymentError}</div>}
        <div className="personal_col_inner_padding">
          <BtnOutline className="btn_wide_full" btnText="Оплатить" type={'button'} onClick={handleSubmitClick} />
        </div>
      </div>
      <div className="personal_col personal_col_content">
        <div className="personal_col_heading">
          <div className="personal_col_heading_wrap">
            <div className="personal_col_heading_title">История пополнений</div>
          </div>
        </div>
        <div className="personal_row_history">
          <div className="personal_row_history_th personal_row_history_date">Дата и время</div>
          <div className="personal_row_history_th personal_row_history_sum">Сумма</div>
          <div className="personal_row_history_th personal_row_history_method">Метод</div>
        </div>
        {isLoading
          ? 'Loading...'
          : paymentHistory?.toReversed().map((item: any, index: number) => (
            item?.status == "wait" && item?.link? (<a href={item?.link}>
              <div className="personal_row_history" data-history-id={item.id} key={index}>
                <div className="personal_row_history_td personal_row_history_date">
                  {format(new Date(item.date), 'dd.MM.yyyy, HH:mm')}
                  <br></br>({item.status == "wait" ? "Ожидание" :
                    item.status == "paid" || item.status == "paid_over" || item.status == "wrong_amount" || item.status == "succeeded" ? "Завершён" :
                    item.status == "canceled" ? "Отменён" : ""
                  })
                </div>
                <div className="personal_row_history_td personal_row_history_sum">{item.amount} ₽</div>
                <div className="personal_row_history_td personal_row_history_method">
                  <img src={item?.acquiring === 'Payeer' ? '/content/footer/payeer.png' : item?.acquiring === 'Cryptomus' ? '/content/footer/cryptomus.png' : item?.acquiring === 'Yookassa' ? '/content/footer/yookassa.svg' :'/content/footer/payeer.png'} />
                </div>
              </div></a>) :
              (<div className="personal_row_history" data-history-id={item.id} key={index}>
                <div className="personal_row_history_td personal_row_history_date">
                  {format(new Date(item.date), 'dd.MM.yyyy, HH:mm')}
                  <br></br>({item.status == "wait" ? "Ожидание" :
                    item.status == "paid" || item.status == "paid_over" || item.status == "wrong_amount" || item.status == "succeeded" ? "Завершён" :
                    item.status == "canceled" ? "Отменён" : ""
                  })
                </div>
                <div className="personal_row_history_td personal_row_history_sum">{item.amount} ₽</div>
                <div className="personal_row_history_td personal_row_history_method">
                  <img src={item?.acquiring === 'Payeer' ? '/content/footer/payeer.png' : item?.acquiring === 'Cryptomus' ? '/content/footer/cryptomus.png' : item?.acquiring === 'Yookassa' ? '/content/footer/yookassa.svg' :'/content/footer/payeer.png'} />
                </div>
              </div>)
            ))}
      </div>
    </>
  );
};

export default PersonalBalance;
