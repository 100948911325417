import React, { useContext } from 'react';
import HeaderMenu from './HeaderMenu';
import Logo from './Logo';
import MenuBtnAction from './MenuBtnAction';
import { fetchApi } from '../../helpers/api';
import { ScrollContext } from '../../../../Routes/Layout';
import { useUserData } from '../../../hooks/UserDataContext';

const logoSrc = '/content/img/nav/logo.png';
const siteName = 'MediaBoost'; // тут можно под сео сделать типа 'title для главной' из админки

// Определение типа для объекта страницы
type Items = {
  name: string;
  link: string;
  icon: {
    parentId?: number;
    id: string;
    filename: string;
    filetype: string;
    datetime: string;
    filesize: string;
    filepath: string;
  };
  index: string;
};

// Определение общего типа для элементов в массиве socialDataState
type MenuProps = {
  _id: string;
  name: string;
  type: string;
  subtype: string;
  items: Items[];
};

type HeaderProps = {
  mainMenuState: MenuProps[];
};

const Header = ({ mainMenuState }: HeaderProps) => {
  // Получаю инфу о юзере из контекста
  const { personalEmail, personalName, personalPhone, personalBalance, fetchUserDataContext, token, userId, isUserLogin } = useUserData();
  return (
    <>
      <div className="nav_wrap">
        <div className="container">
          <div className="nav">
            <Logo imgSrc={logoSrc} siteName={siteName} className="nav_logo" />
            <HeaderMenu mainMenuState={mainMenuState} />
            <MenuBtnAction isUserLogIn={false} btnText="Пополнить баланс" />
            <MenuBtnAction
              isUserLogIn={isUserLogin}
              isPersonal={true}
              btnText={isUserLogin ? (personalName && personalName.length === 0 || !personalName ? 'Личный кабинет' : personalName) : 'Войти'}
              btnLink={isUserLogin ? '/personal' : '/login'}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
