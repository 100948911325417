import React from 'react';
import { NavLink } from 'react-router-dom';
import LeftMenuTop from './LeftMenuTop';
import LeftMenuBottom from './LeftMenuBottom';

type Props = {};

// Определение типа для объекта страницы
type Items = {
  name: string;
  link: string;
  icon: {
    parentId?: number;
    id: string;
    filename: string;
    filetype: string;
    datetime: string;
    filesize: string;
    filepath: string;
  };
  index: string;
};

// Определение общего типа для элементов в массиве socialDataState
type MenuProps = {
  _id: string;
  name: string;
  type: string;
  subtype: string;
  items: Items[];
};

type FooterProps = {
  leftMenuTopList: Items[];
  leftMenuBottomList: Items[];
  linkClicked?: () => void;
};

const LeftMenu = ({ leftMenuTopList, leftMenuBottomList, linkClicked }: FooterProps) => {
  return (
    <>
      <LeftMenuTop menuList={leftMenuTopList} menuTitle={`Раскрутка`} linkClicked={linkClicked}/>
      <LeftMenuBottom menuList={leftMenuBottomList} menuTitle={`Дополнительно`} linkClicked={linkClicked} />
    </>
  );
};

export default LeftMenu;
