import React, { useEffect, useState } from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import Footer from '../template_parts/footer_parts/Footer';
import Header from '../template_parts/header_parts/Header';
import PersonalLeftCol from './PersonalLeftCol';
import PersonalMenu from './PersonalMenu';
import { useUserData } from '../../hooks/UserDataContext';

interface PersonalMenuItem {
  name: string;
  title: string;
  iconSrc: string;
  link: string;
}

interface PersonalLayoutProps {
  userIsLogged?: boolean;
  userToken?: string;
  userName?: string;
  isLogged: boolean;
  personalMenuList: PersonalMenuItem[];
}

const PersonalLayout = ({ personalMenuList, userName }: PersonalLayoutProps) => {
  const currentLocation = useLocation();
  const [currentTitle, setCurrentTitle] = useState('');

  useEffect(() => {
    for (let i = 0; i < personalMenuList.length; i++) {
      if (personalMenuList[i].link === currentLocation.pathname) {
        setCurrentTitle(personalMenuList[i].title);
      }
    }
  }, [currentLocation.pathname, personalMenuList]);

  return (
    <>
      <div className="personal personal_lay">
        <div className="container">
          <div className="personal_wrap">
            <div className="personal_title">{currentTitle}</div>
            <div className="personal_wrap_grid mobile_scroll">
              <div className="personal_col">
                <PersonalLeftCol currentLocation={currentLocation.pathname} personalMenuList={personalMenuList} userName={userName} />
              </div>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalLayout;
