import React from 'react';
import { NavLink, NavigateFunction, useNavigate } from 'react-router-dom';
import { Authentification } from '../helpers/auth';
import { fetchApi } from '../helpers/api';

interface PersonalMenuItem {
  name: string;
  title: string;
  iconSrc: string;
  link: string;
}

interface PersonalMenuProps {
  currentLocation?: string;
  personalMenuList: PersonalMenuItem[];
}

const clickHandler = async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, item: any, navigate: NavigateFunction) => {
  if (item.link === '/logout') {
    event.preventDefault();
    await fetchApi("GET","logout",{})
    if (Authentification.getInstance().logout()) {
      navigate('/')
      window.location.reload(); // Перезагрузка страницы
    }
  }
}

const PersonalMenu = ({ personalMenuList, currentLocation }: PersonalMenuProps) => {
const navigate = useNavigate();
  return (
    <>
      {personalMenuList.map((item: any) => (
        <NavLink to={item.link} key={item.link} className={`personal_row personal_row-menu ${currentLocation === item.link ? 'personal_row-menu-active' : ''}`} onClick={(event) => clickHandler(event, item, navigate)}>
          <div className="personal_menu_item">
            <img src={item.iconSrc} alt={item.name} />
            <div className="personal_menu_link">{item.name}</div>
          </div>
        </NavLink>
      ))}
    </>
  );
};

export default PersonalMenu;
