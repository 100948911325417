import React, { useEffect, useState } from 'react';
import { NavLink, Navigate, generatePath, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import BtnBase from '../admin-ui-kit/btn/BtnBase';
import { fetchApi } from '../../../content/components/helpers/api';

// Определение типа для объекта страницы
type PageType = {
  h1: string;
  title: string;
  updated: number;
  description: string;
  breadcrumbs: string;
  text: string;
  seoUrl: string;
  banner: {
    text: string;
    image: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
  icon: {
    big: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
    small: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
};

// Определение общего типа для элементов в массиве socialDataState
type SocialDataItem = {
  _id: string;
  parentId: string;
  name: string;
  type: string;
  active: boolean;
  page: PageType;
};

// Определяем тип для функции обновления данных
type UpdateSocialDataStateFunction = () => Promise<void>;

// Определение типа для пропсов компонента AdminBlog
type AdminBlogProps = {
  socialDataState: SocialDataItem[];
  typeString: string;
  updateDataState: UpdateSocialDataStateFunction;
};

const AdminBlog = ({ socialDataState, typeString, updateDataState }: AdminBlogProps) => {
  const [socialDataStateArr, setSocialDataStateArr] = useState<any[]>([]);
  const [socialTypeString, setSocialTypeString] = useState(typeString);

  const [customSocialArr, setCustomSocialArr] = useState<any[]>([]);

  // жду выполнения запроса фетча
  const [isLoading, setIsLoading] = useState(true);

  // получаю название родительской категории
  const getParentCategoryName = (parentId: string): string => {
    if (parentId === '0') {
      return 'Без категории';
    }
    const parentCategory = socialDataState.find((category) => category._id === parentId);
    return parentCategory ? parentCategory.name : '';
  };

  useEffect(() => {
    setSocialTypeString(typeString);
  }, [typeString]);

  useEffect(() => {
    const fetchSocialItem = async () => {
      try {
        const filteredArray = socialDataState.filter((item) => item.type === socialTypeString);
        const sortedArray = filteredArray.slice().sort((a, b) => a.name.localeCompare(b.name));
        setCustomSocialArr(sortedArray);
      } catch (error) {
        console.error('Error fetching social item:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (socialDataState.length > 0) {
      fetchSocialItem();
    }
  }, [socialDataState, socialTypeString]);

  // Отдельно функция для обновления отзывов
  const deleteElement = async (id: any) => {
    const deleteBlogItem = await fetchApi('GET', `blog/delete/${id}`, {});
    if (deleteBlogItem) {
      alert(`Элемент (id: ${id}) удалён.`);
    }
    updateDataState();
  };

  return (
    <>
      <div className="admin_heading">
        <div className="admin_heading_title">{socialTypeString === 'category' ? 'Блог - Категории' : socialTypeString === 'tool' ? 'SMM-инструменты' : 'Блог - Статьи'}</div>
        <BtnBase btnLink="new" className={'admin_submit'} btnText={'Создать'} type={'submit'} />
      </div>
      <>
        {isLoading ? (
          <div>Loading...</div>
        ) : !customSocialArr ? (
          <div>Items not found</div>
        ) : (
          <div className="admin_table_wrap">
            <table className="admin_table_list">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>ID</th>
                  {socialTypeString === 'item' && <th>Сategory</th>}
                  <th>Status</th>
                  <th>Updated</th>
                  <th></th>
                  {socialTypeString === 'item' && <th></th>}
                </tr>
              </thead>
              <tbody>
                {customSocialArr.map((item) => (
                  <tr key={item._id}>
                    <td>{item.name}</td>
                    <td>{item._id}</td>
                    {socialTypeString === 'item' && <td className="category_td">{getParentCategoryName(item.parentId)}</td>}
                    <td>
                      {item.activeApi === false ? (
                        <span className="status_el status_disabled">Disabled</span>
                      ) : item.active ? (
                        <span className="status_el status_active">Active</span>
                      ) : (
                        <span className="status_el status_disabled">Disabled</span>
                      )}
                    </td>
                    <td>{format(new Date(item.page.updated), 'dd.MM.yyyy, HH:mm')}</td>
                    <td>
                      <NavLink to={`edit/${item._id}`}>Edit</NavLink>
                    </td>
                    {socialTypeString === 'item' && (
                      <td>
                        <a
                          className="del_item"
                          onClick={(e) => {
                            e.preventDefault();
                            deleteElement(item._id);
                          }}
                          href="#"
                        >
                          Delete
                        </a>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </>
    </>
  );
};

export default AdminBlog;
