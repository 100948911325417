import React, { CSSProperties, ChangeEvent, HTMLInputTypeAttribute, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

type InputBaseProps = {
  children?: ReactNode;
  className?: string;
  inputClassName?: string;
  id?: string;
  label?: string;
  name?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  style?: CSSProperties;
  type: HTMLInputTypeAttribute;
  value?: string;
  hasLink?: boolean;
  hasActivateLink?: boolean;
  textLink?: string;
  innerLink?: string;

  isReadonly?: boolean;
  disabled?: boolean;
  autocomplete?: string;
  isCount?: boolean;
  isCountText?: string;
  isCountSum?: string;
};

const InputBase = ({
  children,
  className,
  inputClassName,
  id,
  label,
  name,
  onChange,
  placeholder,
  style,
  type,
  value,
  hasActivateLink,
  hasLink,
  textLink,
  innerLink,
  isReadonly,
  disabled,
  autocomplete,
  isCount,
  isCountText,
  isCountSum
}: InputBaseProps) => {
  return (
    <>
      {label ? (
        <label className={`label_box ${className ? className : ''}`} style={style}>
          {hasLink === true ? (
            <div className="label_box_row">
              <span className="label_box_text label_box_row_text">{label}</span>
              <NavLink to={innerLink ? innerLink : '#'} className="label_box_row_link">
                {textLink}
              </NavLink>
            </div>
          ) : (
            <span className="label_box_row label_box_text">{label}</span>
          )}
          {hasActivateLink === true ? (
            <div className="label_input_row">
              <input
                className={`base_input base_input_activate ${inputClassName}`}
                type={type}
                name={name}
                value={value}
                id={id}
                placeholder={placeholder}
                onChange={onChange}
                readOnly={isReadonly}
                disabled={disabled}
                autoComplete={autocomplete}
              />
              <span className="label_input_row_activate_text label_box_row_link">Активировать</span>
            </div>
          ) : (
            <>
              {isCount === true ? (
                <div className="isCount_wrap">
                  <div className="isCount_wrap_inner">
                    <input
                      className={`base_input ${inputClassName}`}
                      type={type}
                      name={name}
                      value={value}
                      id={id}
                      placeholder={placeholder}
                      onChange={onChange}
                      readOnly={isReadonly}
                      disabled={disabled}
                      autoComplete={autocomplete}
                    />
                    <div className="isCount_sum">{isCountSum}</div>
                  </div>
                  <div className="isCount_text">{isCountText}</div>
                </div>
              ) : (
                <>
                  <input
                    className={`base_input ${inputClassName}`}
                    type={type}
                    name={name}
                    value={value}
                    id={id}
                    placeholder={placeholder}
                    onChange={onChange}
                    readOnly={isReadonly}
                    disabled={disabled}
                    autoComplete={autocomplete}
                  />
                </>
              )}
            </>
          )}
        </label>
      ) : (
        <input
          className={`base_input ${inputClassName}`}
          type={type}
          name={name}
          value={value}
          id={id}
          placeholder={placeholder}
          onChange={onChange}
          readOnly={isReadonly}
          disabled={disabled}
          autoComplete={autocomplete}
        />
      )}
    </>
  );
};

export default InputBase;
