import React, { useEffect, useState } from 'react';
import { NavLink, Navigate, generatePath, useParams } from 'react-router-dom';
import { format } from 'date-fns';

// Определение общего типа для элементов в массиве socialDataState
type SocialDataItem = {
  _id: string;
  login: string;
  password: string;
  active: boolean;
  email: string;
  role: {
    registred: boolean;
  };
};

// Определение типа для пропсов компонента AdminUsers
type AdminUsersProps = {
  socialDataState: SocialDataItem[];
};

const AdminUsers = ({ socialDataState }: AdminUsersProps) => {
  const [socialDataStateArr, setSocialDataStateArr] = useState<any[]>([]);

  // жду выполнения запроса фетча
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (socialDataState && socialDataState.length > 0) {
      setSocialDataStateArr(socialDataState);
      setIsLoading(false);
    }
  }, [socialDataState]);

  return (
    <>
      <div className="admin_heading">{'Пользователи'}</div>
      <>
        {isLoading ? (
          <div>Loading...</div>
        ) : !socialDataState ? (
          <div>Items not found</div>
        ) : (
          <div className="admin_table_wrap">
            <table className="admin_table_list">
              <thead>
                <tr>
                  <th>Login</th>
                  <th>ID</th>
                  <th>Email</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {socialDataState.map((item) => (
                  <tr key={item._id}>
                    <td>{item.login}</td>
                    <td>{item._id}</td>
                    <td>{item.email}</td>
                    <td>{item.active === false ? <span className="status_el status_disabled">Disabled</span> : <span className="status_el status_active">Active</span>}</td>
                    <td>
                      <NavLink to={`edit/${item._id}`}>Edit</NavLink>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </>
    </>
  );
};

export default AdminUsers;
