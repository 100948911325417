import { format } from 'date-fns';
import React, { FormEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchApi } from '../../../../content/components/helpers/api';
import BtnBase from '../../admin-ui-kit/btn/BtnBase';
import InnerForm from '../../admin-ui-kit/form/InnerForm';
import BaseSelect from '../../admin-ui-kit/input/BaseSelect';
import FileUploadWithPreview from '../../admin-ui-kit/input/FileUploadWithPreview';
import InputLabelFor from '../../admin-ui-kit/input/InputLabelFor';
import Switch from '../../admin-ui-kit/input/Switch';
import TextareaLabelFor from '../../admin-ui-kit/input/TextareaLabelFor';
import ServiceSelect from '../../admin-ui-kit/search/ServiceSelect';

// Определение типа для объекта страницы
type PageType = {
  h1: string;
  title: string;
  updated: number;
  description: string;
  breadcrumbs: string;
  text: string;
  seoUrl: string;
  banner: {
    text: string;
    image: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
  icon: {
    big: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
    small: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
};

// Определение общего типа для элементов в массиве socialDataState
type SocialDataItem = {
  _id: string;
  parentId: string;
  name: string;
  customName: string;
  type: string;
  active: boolean;
  category: string;
  page: PageType;
  markup: object;
};

// Определяем тип для функции обновления данных
type UpdateSocialDataStateFunction = () => Promise<void>;

// Определение типа для пропсов компонента AdminSocial
type AdminCatalogItemProps = {
  socialDataState?: SocialDataItem[];
  typeString?: string;
  updateDataState: UpdateSocialDataStateFunction;
};

const AdminCatalogItem = ({ updateDataState, typeString }: AdminCatalogItemProps) => {
  const navigate = useNavigate();
  const { _id } = useParams();

  // жду выполнения запроса фетча
  const [isLoading, setIsLoading] = useState(true);

  // жду завершения обновления элемента
  const [isLoadingData, setIsLoadingData] = useState(false);

  // объект одного элемента
  const [socialItem, setSocialItem] = useState<any>(null);

  // массив с данными для сохранения
  const [savedItemArr, setSavedItemArr] = useState<any>(null);

  // список категорий для выбора
  const [socialDataCategoryArr, setSocialDataCategoryArr] = useState<any[]>([]);

  const [socialDataState, setSocialDataState] = useState<any[]>([]);

  const [rootAPIState, setRootAPIState] = useState<any[]>([]);
  // список категорий для выбора
  const [rootAPIStateArr, setRootAPIStateArr] = useState<any[]>([]);

  // информация о привязанной рут-категории (чтобы вывести только нужные услуги)
  const [rootArrInfo, setRootArrInfo] = useState<any[]>([]);

  // выбранный API сервис
  const [selectedAPIService, setSelectedAPIService] = useState<any[]>([]);

  //
  const [itemName, setItemName] = useState<any>(null);
  const [itemCustomName, setItemCustomName] = useState<any>(null);
  const [itemActive, setItemActive] = useState<any>(null);
  const [itemShowOnMain, setItemShowOnMain] = useState<any>(null);
  const [itemH1, setItemH1] = useState<any>(null);
  const [itemTitle, setItemTitle] = useState<any>(null);
  const [itemDescription, setItemDescription] = useState<any>(null);
  const [itemBreadcrumbs, setItemBreadcrumbs] = useState<any>(null);
  const [itemText, setItemText] = useState<any>(null);
  const [itemExcerpt, setItemExcerpt] = useState<any>(null);
  const [itemSeoUrl, setItemSeoUrl] = useState<any>(null);
  const [itemBannerText, setItemBannerText] = useState<any>(null);
  const [itemMarkup, setItemMarkup] = useState<any>(null);
  const [itemMarkupType, setItemMarkupType] = useState<any>(null);

  const [bannerId, setBannerId] = useState<any>(null);
  const [bannerFilename, setBannerFilename] = useState<any>(null);
  const [bannerFiletype, setBannerFiletype] = useState<any>(null);
  const [bannerFilesize, setBannerFilesize] = useState<any>(null);
  const [bannerFilepath, setBannerFilepath] = useState<any>(null);
  const [bannerDatetime, setBannerDatetime] = useState<any>(null);

  const [smallId, setSmallId] = useState<any>(null);
  const [smallFilename, setSmallFilename] = useState<any>(null);
  const [smallFiletype, setSmallFiletype] = useState<any>(null);
  const [smallFilesize, setSmallFilesize] = useState<any>(null);
  const [smallFilepath, setSmallFilepath] = useState<any>(null);
  const [smallDatetime, setSmallDatetime] = useState<any>(null);

  const [bigId, setBigId] = useState<any>(null);
  const [bigFilename, setBigFilename] = useState<any>(null);
  const [bigFiletype, setBigFiletype] = useState<any>(null);
  const [bigFilesize, setBigFilesize] = useState<any>(null);
  const [bigFilepath, setBigFilepath] = useState<any>(null);
  const [bigDatetime, setBigDatetime] = useState<any>(null);

  // Информация о всех категориях (созданных вручную)
  const fetchCatalogRootData = async () => {
    try {
      const newDataCatalogRoot = await fetchApi('GET', 'admin/services/get_categories', {});
      setSocialDataState(newDataCatalogRoot);
    } catch (error) {
      console.error('Error fetching social item:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Информация о текущем элементе
  const fetchCatalogRootItem = async () => {
    try {
      const newDataCatalogRootItem = await fetchApi('GET', `admin/services/get_item/${_id}`, {});
      setSocialItem(newDataCatalogRootItem);
    } catch (error) {
      console.error('Error fetching social item:', error);
      navigate('/404');
    } finally {
      setIsLoading(false);
      setIsLoadingData(false);
    }
  };

  // Получаю список элементов, которые пренадлежат рут категории (соц. сети)
  const fetchItemInfo = async (id: any) => {
    try {
      const itemInfo = await fetchApi('GET', `admin/catalog/get_element/${id}`, {});
      try {
        if (itemInfo && itemInfo.serviceId) {
          const serviceInfo = await fetchApi('GET', `admin/services/get_items/${itemInfo.serviceId}`, {});
          serviceInfo.sort((a: any, b: any) => a.serviceProvider.localeCompare(b.serviceProvider));
          setRootArrInfo(serviceInfo);
        } else {
          console.error("no social item to fetch");
        }
      } catch (error) {
        console.error('Error fetching social item:', error);
        navigate('/404');
      } finally {
        setIsLoading(false);
        setIsLoadingData(false);
      }
    } catch (error) {
      console.error('Error fetching social item:', error);
      navigate('/404');
    } finally {
      setIsLoading(false);
      setIsLoadingData(false);
    }
  };

  const transliterateToUrl = (text: string): string => {
    return text
      .replace(/([а-яё])|([\s\*-])|([^a-z\d])/gi, (all, ch, space, words) => {
        if (space || words) {
          return space || space == '*' || words == '*' ? '-' : '';
        }
        const code = ch.charCodeAt(0);
        const index = code === 1025 || code === 1105 ? 0 : code > 1071 ? code - 1071 : code - 1039;
        const t = ['yo', 'a', 'b', 'v', 'g', 'd', 'e', 'zh', 'z', 'i', 'y', 'k', 'l', 'm', 'n', 'o', 'p', 'r', 's', 't', 'u', 'f', 'h', 'c', 'ch', 'sh', 'shch', '', 'y', '', 'e', 'yu', 'ya'];
        return t[index];
      })
      .toLowerCase();
  };

  useEffect(() => {
    fetchCatalogRootData();
  }, []);

  useEffect(() => {
    fetchCatalogRootItem();
  }, [socialDataState, _id, navigate]);

  useEffect(() => {
    if (socialItem) {
      if (socialItem.serviceId) {
        setSelectedAPIService(socialItem.serviceId);
      }
      fetchItemInfo(socialItem.parentId);
    }
  }, [socialItem]);

  useEffect(() => {
    if (socialItem) {
      setItemName(socialItem.name);
      setItemCustomName(socialItem.customName ? socialItem.customName : '');
      setItemActive(socialItem.active);
      setItemShowOnMain(socialItem.page.showOnMain);
      setItemH1(socialItem.page.h1);
      setItemTitle(socialItem.page.title);
      setItemDescription(socialItem.page.description);
      setItemBreadcrumbs(socialItem.page.breadcrumbs);
      setItemText(socialItem.page.text);
      setItemExcerpt(socialItem.page.excerpt);
      setItemSeoUrl(socialItem.page.seoUrl ? socialItem.page.seoUrl : transliterateToUrl(socialItem.name));
      setItemBannerText(socialItem.page.banner.text);
      setItemMarkup(socialItem.markup?.size ? socialItem.markup.size : '-');
      setItemMarkupType(socialItem.markup?.type ? socialItem.markup.type : "percent");

      setBannerId(socialItem.page.banner.image.id ? socialItem.page.banner.image.id : '');
      setBannerFilename(socialItem.page.banner.image.filename ? socialItem.page.banner.image.filename : '');
      setBannerFiletype(socialItem.page.banner.image.filetype ? socialItem.page.banner.image.filetype : '');
      setBannerFilesize(socialItem.page.banner.image.filesize ? socialItem.page.banner.image.filesize : '');
      setBannerFilepath(socialItem.page.banner.image.filepath ? socialItem.page.banner.image.filepath : '');
      setBannerDatetime(socialItem.page.banner.image.datetime ? socialItem.page.banner.image.datetime : '');

      setSmallId(socialItem.page.icon.small.id ? socialItem.page.icon.small.id : '');
      setSmallFilename(socialItem.page.icon.small.filename ? socialItem.page.icon.small.filename : '');
      setSmallFiletype(socialItem.page.icon.small.filetype ? socialItem.page.icon.small.filetype : '');
      setSmallFilesize(socialItem.page.icon.small.filesize ? socialItem.page.icon.small.filesize : '');
      setSmallFilepath(socialItem.page.icon.small.filepath ? socialItem.page.icon.small.filepath : '');
      setSmallDatetime(socialItem.page.icon.small.datetime ? socialItem.page.icon.small.datetime : '');

      setBigId(socialItem.page.icon.big.id ? socialItem.page.icon.big.id : '');
      setBigFilename(socialItem.page.icon.big.filename ? socialItem.page.icon.big.filename : '');
      setBigFiletype(socialItem.page.icon.big.filetype ? socialItem.page.icon.big.filetype : '');
      setBigFilesize(socialItem.page.icon.big.filesize ? socialItem.page.icon.big.filesize : '');
      setBigFilepath(socialItem.page.icon.big.filepath ? socialItem.page.icon.big.filepath : '');
      setBigDatetime(socialItem.page.icon.big.datetime ? socialItem.page.icon.big.datetime : '');
    }
  }, [socialItem]);

  // Функция для сохранения данных про баннер
  const handleImageSaveBanner = (imageData: any) => {
    setBannerId(imageData.id);
    setBannerFilename(imageData.filename);
    setBannerFiletype(imageData.filetype);
    setBannerFilesize(imageData.filesize);
    setBannerFilepath(imageData.filepath);
    setBannerDatetime(imageData.datetime);
  };

  // Функция для сохранения данных про маленькую иконку
  const handleImageSaveIconSmall = (imageData: any) => {
    setSmallId(imageData.id);
    setSmallFilename(imageData.filename);
    setSmallFiletype(imageData.filetype);
    setSmallFilesize(imageData.filesize);
    setSmallFilepath(imageData.filepath);
    setSmallDatetime(imageData.datetime);
  };

  // Функция для сохранения данных про маленькую иконку
  const handleImageSaveIconBig = (imageData: any) => {
    setBigId(imageData.id);
    setBigFilename(imageData.filename);
    setBigFiletype(imageData.filetype);
    setBigFilesize(imageData.filesize);
    setBigFilepath(imageData.filepath);
    setBigDatetime(imageData.datetime);
  };

  // функция формирования массива для сохранения
  const handeSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const savedItemArrObjs = {
      _id: socialItem._id,
      name: socialItem.name,
      customName: itemCustomName,
      active: itemActive,
      activeApi: socialItem.activeApi,
      category: socialItem.category,
      markup: {
        type: itemMarkupType,
        size: itemMarkup
      },
      page: {
        h1: itemH1,
        title: itemTitle,
        updated: socialItem.page.updated,
        description: itemDescription,
        breadcrumbs: itemBreadcrumbs,
        text: itemText,
        seoUrl: transliterateToUrl(itemSeoUrl),
        showOnMain: itemShowOnMain,
        excerpt: itemExcerpt,
        banner: {
          text: itemBannerText,
          image: {
            id: bannerId,
            filename: bannerFilename,
            filetype: bannerFiletype,
            filesize: bannerFilesize,
            filepath: bannerFilepath,
            datetime: bannerDatetime
          }
        },
        icon: {
          big: {
            id: bigId,
            filename: bigFilename,
            filetype: bigFiletype,
            filesize: bigFilesize,
            filepath: bigFilepath,
            datetime: bigDatetime
          },
          small: {
            id: smallId,
            filename: smallFilename,
            filetype: smallFiletype,
            filesize: smallFilesize,
            filepath: smallFilepath,
            datetime: smallDatetime
          }
        }
      },
      parentId: socialItem.parentId,
      rate: socialItem.rate,
      service: socialItem.service,
      socialMedia: socialItem.socialMedia,
      type: socialItem.type
    };
    setSavedItemArr(savedItemArrObjs);

    const saveItem = async () => {
      try {
        if (savedItemArrObjs) {
          setIsLoadingData(true);
          const response = await fetchApi('POST', '/services/save_item', savedItemArrObjs);
          if (response) {
            setSocialItem(response);
            updateDataState();
            alert('Элемент обновлен!');
          }
        }
      } catch (error) {
        console.error('Error saving item:', error);
        // Обработка ошибки, например, отображение сообщения об ошибке для пользователя
      } finally {
        setIsLoadingData(false);
      }
    };

    saveItem();
  };

  const changeMarkupType = () => {
    if (itemMarkupType == "percent") {
      setItemMarkupType("constant");
    } else {
      setItemMarkupType("percent");
    }
  }

  // Функция для получения массива категорий
  const getCategoryOptions = (data: SocialDataItem[]): { text: string; value: string }[] => {
    const categoryOptions = [
      ...data
        .filter((item: SocialDataItem) => item.type === 'category') // Отфильтровать только категории
        .map((category: SocialDataItem) => ({
          text: category.name, // Использовать название категории в качестве текста
          value: category._id // Использовать _id категории в качестве значения
        }))
    ];

    return categoryOptions;
  };

  // заполняю массив категориями для их выбора в элементе
  useEffect(() => {
    const categories = getCategoryOptions(socialDataState);
    setSocialDataCategoryArr(categories);
  }, [socialDataState]);

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : !socialItem ? (
        <div>Item not found</div>
      ) : (
        <InnerForm onSubmit={handeSubmit}>
          <div className="admin_heading">
            <div className="admin_heading_title">{itemName}</div>
            <BtnBase className={'admin_submit'} btnText={'Обновить'} type={'submit'} />
          </div>
          <div className={`admin_container ${isLoadingData ? 'loading' : ''}`}>
            <div className="admin_form">
              <div className="admin_form_section_wrap">
                <div className="admin_form_section_title">Системные данные</div>
                <div className="admin_form_row">
                  <Switch text={'Активно'} id={'active'} name={'active'} isChecked={itemActive} onChange={() => setItemActive(!itemActive)} />
                </div>
                <div className="admin_form_row">
                  <Switch text={'Отображать на главной'} id={'show_main'} name={'show_main'} isChecked={itemShowOnMain} onChange={() => setItemShowOnMain(!itemShowOnMain)} />
                </div>
                <div className="admin_form_row">
                  <Switch text={'Активно - API'} id={'activeApi'} name={'activeApi'} isChecked={socialItem.activeApi} disabled={true} />
                </div>
                <div className="admin_form_row">
                  <InputLabelFor label={'Name API'} value={socialItem.name ? socialItem.name : ''} id={'name'} name={'name'} type={'text'} readOnly={true} disabled={true} />
                </div>
                  <div className="admin_form_row">
                    <InputLabelFor label={'Произвольное название'} value={itemCustomName ? itemCustomName : ''} id={'customName'} name={'customName'} type={'text'} onChange={(event) => setItemCustomName(event.target.value)} />
                  </div>
                <div className="admin_form_row">
                  <InputLabelFor label={'ID'} value={socialItem._id ? socialItem._id : ''} id={'id'} name={'id'} type={'text'} readOnly={true} disabled={true} />
                </div>
                <div className="admin_form_row">
                  <InputLabelFor
                    label={'Дата обновления'}
                    value={format(new Date(socialItem.page.updated), 'dd.MM.yyyy, HH:mm')}
                    id={'updated'}
                    name={'updated'}
                    type={'text'}
                    readOnly={true}
                    disabled={true}
                  />
                </div>
                <div className="admin_form_row">
                  <InputLabelFor
                    label={'SEO URL'}
                    value={itemSeoUrl ? itemSeoUrl : transliterateToUrl(socialItem.name)}
                    id={'seo_url'}
                    name={'seo_url'}
                    type={'text'}
                    onChange={(event) => setItemSeoUrl(event.target.value)}
                  />
                </div>
                  <div className="admin_form_row">
                    <InputLabelFor
                      label={'Наценка'}
                      value={itemMarkup}
                      id={'markup'}
                      name={'markup'}
                      type={'text'}
                      onChange={(event) => setItemMarkup(event.target.value)}
                    />
                    <Switch text={'В процентах'} id={'markup_type'} name={'markup_type'} isChecked={itemMarkupType == "percent"} onChange={changeMarkupType} />
                  </div>
              </div>
              <div className="admin_form_section_wrap">
                <div className="admin_form_section_title">Содержимое</div>
                <div className="admin_form_row">
                  <TextareaLabelFor label={'Краткое описание'} value={itemExcerpt ? itemExcerpt : ''} id={'excerpt'} name={'excerpt'} onChange={(event) => setItemExcerpt(event.target.value)} />
                </div>
                <div className="admin_form_row admin_form_row_upload">
                  <FileUploadWithPreview
                    labelText={'Большое изображение'}
                    savedId={bannerId}
                    savedFilename={bannerFilename}
                    savedFiletype={bannerFiletype}
                    savedFilesize={bannerFilesize}
                    savedFilepath={bannerFilepath}
                    savedDatetime={bannerDatetime}
                    saveImage={handleImageSaveBanner}
                  />
                </div>
                <div className="admin_form_row">
                  <TextareaLabelFor
                    label={'Контент в баннере'}
                    value={itemBannerText ? itemBannerText : ''}
                    id={'innerBannerText'}
                    name={'innerBannerText'}
                    onChange={(event) => setItemBannerText(event.target.value)}
                  />
                </div>
                <div className="admin_form_section_wrap_grid">
                  <div className="admin_form_section_wrap_grid_child">
                    <div className="admin_form_row">
                      <FileUploadWithPreview
                        labelText={'Иконка маленькая'}
                        savedId={smallId}
                        savedFilename={smallFilename}
                        savedFiletype={smallFiletype}
                        savedFilesize={smallFilesize}
                        savedFilepath={smallFilepath}
                        savedDatetime={smallDatetime}
                        saveImage={handleImageSaveIconSmall}
                      />
                    </div>
                  </div>
                  <div className="admin_form_section_wrap_grid_child">
                    <div className="admin_form_row">
                      <FileUploadWithPreview
                        labelText={'Иконка большая'}
                        savedId={bigId}
                        savedFilename={bigFilename}
                        savedFiletype={bigFiletype}
                        savedFilesize={bigFilesize}
                        savedFilepath={bigFilepath}
                        savedDatetime={bigDatetime}
                        saveImage={handleImageSaveIconBig}
                      />
                    </div>
                  </div>
                </div>
                <div className="admin_form_row">
                  <TextareaLabelFor label={'Текстовый блок'} value={itemText ? itemText : ''} id={'text'} name={'text'} onChange={(event) => setItemText(event.target.value)} />
                </div>
              </div>
              <div className="admin_form_section_wrap">
                <div className="admin_form_section_title">Мета</div>
                <div className="admin_form_row">
                  <InputLabelFor label={'Заголовок - H1'} value={itemH1 ? itemH1 : ''} id={'h1'} name={'h1'} type={'text'} onChange={(event) => setItemH1(event.target.value)} />
                </div>
                <div className="admin_form_row">
                  <InputLabelFor
                    label={'Хлебные крошки'}
                    value={itemBreadcrumbs ? itemBreadcrumbs : ''}
                    id={'breadcrumbs'}
                    name={'breadcrumbs'}
                    type={'text'}
                    onChange={(event) => setItemBreadcrumbs(event.target.value)}
                  />
                </div>
                <div className="admin_form_row">
                  <InputLabelFor label={'Meta-title'} value={itemTitle ? itemTitle : ''} id={'meta-title'} name={'meta-title'} type={'text'} onChange={(event) => setItemTitle(event.target.value)} />
                </div>
                <div className="admin_form_row">
                  <InputLabelFor
                    label={'Meta-description'}
                    value={itemDescription ? itemDescription : ''}
                    id={'meta-description'}
                    name={'meta-description'}
                    type={'text'}
                    onChange={(event) => setItemDescription(event.target.value)}
                  />
                </div>
              </div>
              <BtnBase className={'admin_submit admin_submit_center'} btnText={'Обновить'} type={'submit'} />
            </div>
          </div>
        </InnerForm>
      )}
    </>
  );
};

export default AdminCatalogItem;
