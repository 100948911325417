// global.ts

import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

  /* @font-face {
    font-family: 'Montserrat';
    src: url('./src/fonts/Montserrat-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url('./src/fonts/Montserrat-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url('./src/fonts/Montserrat-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url('./src/fonts/Montserrat-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url('./src/fonts/Montserrat-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url('./src/fonts/Montserrat-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url('./src/fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url('./src/fonts/Montserrat-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url('./src/fonts/Montserrat-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url('./src/fonts/Montserrat-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url('./src/fonts/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url('./src/fonts/Montserrat-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url('./src/fonts/Montserrat-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url('./src/fonts/Montserrat-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url('./src/fonts/Montserrat-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url('./src/fonts/Montserrat-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url('./src/fonts/Montserrat-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url('./src/fonts/Montserrat-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
  } */

  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

  * {
    outline: none !important;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* ... */
  }

  html {
    /* overflow-x: hidden;
    margin-right: calc(-1 * (100vw - 100%)); */
    scroll-behavior: smooth;
  }

  /* *::before,
  *::after {
    content: '';
  } */

  body {
    font-family: 'Montserrat', sans-serif;
    background: linear-gradient(288deg, #D9E6FF 0%, #FFF 100%);
    color: #5F5F5F;
  }

  .container {
    max-width: 1560px;
    margin: 0 auto;
    padding: 0px 10px;
  }

  .text {
    color: #5F5F5F;
    font-size: 15px;
    font-weight: 500;
    line-height: normal;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  a {
    color: inherit;
    display: block;
    transition: .3s;
    text-decoration: none;
  }

  .link {
    color: #4083FD;
    transition: .3s;
    cursor: pointer;
    display: initial;
  }
  .link:hover {
    opacity: .7;
  }

  button {
    font: inherit;
    cursor: pointer;
    transition: .3s
  }

  button[disabled] {
    pointer-events: none;
    background-color: #D6D6D6;
    color: #FFF;
    border: 1px solid #D6D6D6;
  }
  .btn_mb_22 {
    margin-bottom: 22px;
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 227px;
    height: 52px;
    border-radius: 10px;
    border: 1px solid #4083FD;
    background-color: #4083FD;
    color: #FFF;
    text-align: center;
    font-size: 15px;
    font-weight: 700;
  }
  .btn:hover {
    border: 1px solid #4083FD;
    background-color: #FFF;
    color: #4083FD;
  }
  .btn_outline {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 227px;
    height: 52px;
    border-radius: 10px;
    border: 1px solid #4083FD;
    background-color: #FFF;

    color: #4083FD;
    text-align: center;
    font-size: 15px;
    font-weight: 700;
  }
  .btn_outline:hover {
    color: #FFF;
    background-color: #4083FD;
    /* border-color: #FFF; */
  }
  .btn_bg_transparent {
    background-color: transparent;
    backdrop-filter: blur(25px);
  }
  .btn_bg_gradient {
    position: relative;
    border: none;
    overflow: hidden;
    background-color: transparent;
  }
  .btn_bg_gradient > span {
    position: relative;
    z-index: 2;
  }
  .btn_bg_gradient::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(91deg, #4D57D6 0.31%, #B324A0 30.94%, #E73D74 63.65%, #FEDA77 100%);
    opacity: 1;
    transition: .3s;
    z-index: 1;
  }
  .btn_bg_gradient:hover::before {
    opacity: 0;
  }
  .btn_bg_gradient:hover {
    border: none;
    background-color: #4083FD;
    color: #FFF;
  }
  .btn_bg_red {
    border: 1px solid #E73D74;
    background-color: #E73D74;
    color: #FFF;
  }
  .btn_wide {
    width: 304px;
  }
  .btn_wide_full {
    width: 100%;
  }
  .service_btn_mob {
    display: none;
  }
  .shadow_border {
    border: 1px solid #BFBFBF;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
  }
  .border_rounded {
    border-radius: 10px;
  }

  /* header start */
  .nav_wrap {
    background: linear-gradient(288deg, rgba(255, 255, 255, 1) 100%, #FFF 0%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 0px;
  }
  .nav_logo {
    margin-right: 12px;
  }
  .nav_items {
    display: flex;
    align-items: center;
    margin-right: 35px;
  }
  .nav_item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    padding: 16px 0px;
  }
  .nav_item a.active,
  .nav_item a:hover {
    color: #000;
  }
  .nav_item a.active {
    font-weight: 700;
  }
  .nav_fast_order {
    background-color: transparent;
  }
  .nav_call_action {
    display: flex;
    align-items: center;
    border: none;
    border-color: unset;
    border-width: unset;
    background-color: unset;
  }
  .nav_call_action:not(.nav_up_balance) {
    max-width: 175px;
  }
  .nav_call_action:not(.nav_up_balance) .nav_call_action_text {
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
  }
  .nav_call_action_text {
    display: block;
    margin-right: 15px;

    color: #000;
    font-size: 15px;
    font-weight: 500;
  }
  .nav_call_action_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 52px;
    height: 52px;
    border-radius: 100%;
    transition: .3s;
    opacity: 1;
  }
  .nav_call_action:hover .nav_call_action_icon {
    opacity: .7;
  }
  .nav_up_balance {
    margin-right: 30px;
  }
  .nav_up_balance_icon {
    transform: rotate(0deg);
    transition: inherit;
    color: #FFF;
    font-size: 40px;
    font-weight: 300;
    line-height: normal;
    background: #4083FD;
  }
  .nav_up_balance:hover .nav_up_balance_icon {
    transform: rotate(90deg);
  }
  /* header end */

  /* footer start */
  .footer {
    padding: 40px 0px;
    border-top: 1px solid #BFBFBF;
    background: #4083FD;
    color: #FFF;
  }
  .footer .text {
    color: inherit;
  }

  .footer_wrap {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
  }

  .footer_col {
    padding-left: 22px;
  }
  .footer_logo {
    margin-bottom: 40px;
  }
  .footer_logo_mob {
    display: none;
  }
  .footer_siteinfo {
    margin-bottom: 34px;
    font-size: 24px;
    font-weight: 400;
  }
  .footer_siteinfo_text:first-child {
    margin-bottom: 6px;
  }
  .footer_description {
    font-size: 15px;
    font-weight: 400;
  }
  .footer_menu_title {
    padding: 13px 0px;
    font-size: 15px;
    font-weight: 800;
  }
  .footer_menu_item {
    padding: 13px 0px;
    color: #FFF;
    font-size: 15px;
    font-weight: 400;
    opacity: 1;
  }
  .footer_menu_item:hover {
    opacity: .7;
  }
  .footer_need_help {
    margin-top: 8px;
    margin-bottom: 25px;
  }
  .footer_need_help:hover {
    color: #FFF;
    background-color: #4083FD;
    border-color: #FFF;
  }
  .footer_payment_list {
    display: flex;
    align-items: center;
  }
  .footer_payment_item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    margin-right: 20px;
    background-color: #fff;
    border-radius: 100%;
    overflow: hidden;
  }
  .footer_payment_item:last-child {
    margin-right: 0px;
  }
  /* footer end */

  /* start personal */
  .personal_wrap {
    padding: 80px 0px;
  }
  .personal_title {
    margin-bottom: 50px;
    color: #000;
    text-align: center;
    font-size: 48px;
    font-weight: 700;
  }
  .personal_wrap_grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    /* height: 100vh; */
    height: 725px;
    min-height: 725px;
  }
  .personal_col {
    border-radius: 10px;
    border: 1px solid #BFBFBF;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%), #FFF; 
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
    overflow: hidden;
  }
  .personal_row {
    padding: 8px 30px;
    border-bottom: 1px solid #BFBFBF;
  }
  .personal_row.base_input_wide_error {
    margin-top: 0px;
        border: 1px solid #E73D74;
    box-shadow: 0px 0px 0px 1px rgba(231, 61, 116, 1) inset;
  }
  .personal_row_bg-transparent {
    background: transparent;
  }
  .personal_info {
    padding: 25px 30px 20px;
  }
  .personal_name {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .personal_name > svg {
    margin-right: 20px;
  }
  .personal_name_text {
    color: #000;
    font-size: 15px;
    font-weight: 500;
  }
  .personal_row-menu {
    background-color: #FFF;
    transition: .3s;
  }
  .personal_row-menu:hover {
    background-color: #4083FD;
  }
  .personal_menu_item {
    display: flex;
    align-items: center;
  }
  .personal_menu_item > img {
    margin-right: 20px;
    filter: drop-shadow(0 1px 5px rgba(0,0,0,.1));
  }
  .personal_menu_item_description {
    display: block;
    color: #ccc;
  }
  .personal_menu_link {
    color: #5F5F5F;
    font-size: 15px;
    font-weight: 500;
    transition: inherit;
  }
  .personal_row-menu:hover .personal_menu_link {
    color: #fff;
  }
  .personal_row-menu-active {
    background-color: #4083FD;
  }
  .personal_row-menu-active .personal_menu_link {
    color: #fff;
  }
  .personal_col_wide {
    grid-column: span 3;
  }
  .personal_col_content {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%),rgb(255 255 255 / 50%);
    overflow-y: auto;
    transition: 0.3s;
    /* background-color: #fafcff; */
  }
  .personal_col_content.loading {
    opacity: .7;
  }
  .personal_col_heading {
    display: flex;
    align-items: center;
    /* min-height: 70px; */
    height: 70px;
    padding: 12px 12px 12px 33px;
    background-color: #fff;
    border-bottom: 1px solid #BFBFBF;
  }
  .personal_col_inner {
    /* background: linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%), rgb(255 255 255 / 50%); */
  }
  .personal_col_inner_padding {
    padding: 30px 33px 26px;
  }
  .personal_col_heading_wrap {
    display: flex;
    align-items: center;
  }
  .personal_col_heading_wrap > img {
    margin-right: 22px;
  }
  .personal_col_heading_title {
    color: #5F5F5F;
    font-size: 15px;
    font-weight: 700;
  }

  .personal_order_list_row {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
  .order_row {
    grid-template-columns: 1fr 1fr 1fr 170px 170px;
  }
  .personal_order_list_td {
    padding: 20px 10px;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
  }
  .personal_order_list_td .order_more,
  .payment_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    max-width: 170px;
    margin: 0 auto;
    border: 1px solid #4083FD;
    border-radius: 10px;
    color: #4083FD;
    font-size: 15px;
    font-weight: 700;
    background-color: transparent;
    cursor: pointer;
    transition: .25s;
  }
  .personal_order_list_td .order_more:hover,
  .payment_btn:hover {
    color: #FFF;
    background-color: #4083FD;
  }
  .payment_btn {
    margin: 0;
  }
  .cancel_btn {
    border: 1px solid #e73d74;
    background-color: transparent;
    color: #e73d74;
  }
  .cancel_btn:hover {
    background-color: #e73d74;
    color: #FFF;
  }
  .personal_order_list_td .order_more.order_more_active {
    background-color: #e73d74;
    border: 1px solid #e73d74;
    color: #FFF;
  }
  .personal_order_list_td_th {
    padding: 10px;
    color: #5F5F5F;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
  }
  .personal_order_list_status {
    align-self: center;
    padding: 5px 10px;
  }
  .personal_order_list_status > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    max-width: 170px;
    margin: 0 auto;

    border-radius: 10px;

    color: #FFF;
    font-size: 15px;
    font-weight: 700;
  }
  .personal_order_prod {
    display: flex;
    flex-wrap: wrap;
    grid-column: 1/-1;
    padding-top: 10px;
    border-top: 1px dashed #BFBFBF;
  }
  .personal_order_prod:last-child {
    padding-bottom: 10px;
    border-bottom: 1px dashed #BFBFBF;
  }
  .personal_order_prod_th {
    flex-basis: 110px;
    padding: 5px 10px;
    font-size: 15px;
    font-weight: 700;
  }
  .personal_order_prod_td {
    flex-basis: calc(100% - 110px);
    padding: 5px 10px;
    text-align: left;
    font-size: 15px;
    font-weight: 500;
  }
  .personal_order_prod_title {
    font-weight: 700;
  }
  .personal_order_list_status_progress,
  .personal_order_list_status_ready {
    /* padding: 20px 10px; */
  }
  .personal_order_list_status_progress {
    background-color: #E73D74;
  }
  .personal_order_list_status_ready {
    background-color: #4083FD;
  }
  .personal_col_step {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    margin-right: 25px;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    border-radius: 100%;
    background: #E73D74;
  }
  .personal_row_soc {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .personal_menu_item_arrow {
    color: #BFBFBF;
    font-size: 32px;
    font-weight: 400;
  }
  .personal_row_pointer {
    cursor: pointer;
  }
  .personal_row_hover {
    transition: .3s;
  }
  .personal_row_hover:hover {
    /* background-color: #FFF; */
    background-color: #4083FD;
    color: #FFF;
  }
  .personal_row_hover:hover .personal_menu_link {
    color: #FFF;
  }
  .personal_row_hover.active {
    background-color: #4083FD;
    color: #FFF;
  }
  .personal_row_hover.active .personal_menu_link {
    color: #FFF;
  }
  .personal_row_hover.active .personal_menu_item_arrow {
    color: #FFF;
  }
  .label_box {

  }
  .label_box_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 11px;
  }
  .label_box_row_link {
    color: #4083FD;
    font-size: 15px;
    font-weight: 400;
  }
  .label_box_row_link:hover {
    color: #6EA1FF;
  }
  .label_box_text {
    color: #5F5F5F;
    font-size: 15px;
    font-weight: 700;
  }
  .label_input_row {
    position: relative;
  }
  .base_input {
    height: 50px;
    padding: 0px 16px;
    padding-left: 28px;
    border-radius: 10px;
    border: 1px solid #BFBFBF;
    background: #FFF;

    font-size: 15px;
    font-weight: 400;
    color: #000;
    font-family: 'Montserrat';
    transition: .3s;
  }
  .base_input:focus {
    background: #D4E4FF;
  }
  .base_input::placeholder {
    color: #BFBFBF;
  }
  .base_input_wide[disabled] {
    opacity: .5;
  }
  .base_input_wide {
    width: 100%;
  }
  .base_input_activate {
    padding-right: 130px;
  }
  .label_box.error .base_input {
    border: 1px solid #E73D74;
    box-shadow: 0px 0px 0px 1px rgba(231, 61, 116, 1) inset;
  }
  .personal_input_row {
    margin-bottom: 22px;
  }
  .personal_input_row:last-child {
    margin-bottom: 0px;
  }
  .personal_balance_fast_fill_wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 14px;
    margin-top: 17px;
  }
  .personal_balance_fast_fill_item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-radius: 10px;
    border: 1px solid #4083FD;

    color: #4083FD;
    text-align: center;
    font-size: 15px;
    font-weight: 700;

    cursor: pointer;
    transition: .2s;
  }
  .personal_balance_fast_fill_item:hover {
    opacity: .7;
  }
  .label_input_row_activate_text {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 12px;
    transition: .3s;
    cursor: pointer;
  }
  .select_base {
    height: 50px;
    padding: 0px 16px;
    padding-left: 28px;
    border-radius: 10px;
    border: 1px solid #BFBFBF;
    background: #FFF;

    font-size: 15px;
    font-weight: 400;
    color: #000;
    font-family: 'Montserrat';
    transition: .3s;
  }
  .select_base.error {
    border: 1px solid #E73D74;
    box-shadow: 0px 0px 0px 1px rgba(231, 61, 116, 1) inset;
  }

  .isCount_wrap_inner {
    display: flex;
    align-items: center;
    margin-bottom: 11px;
  }
  .base_input_count {
    text-align: center;
    max-width: 200px;
    margin-right: 13px;
  }
  .base_input_wide_error {
    margin-top: 8px;
  }
  .base_input_count.error {
    border: 1px solid #E73D74;
    box-shadow: 0px 0px 0px 1px rgba(231, 61, 116, 1) inset;
  }
  .base_input.error {
    border: 1px solid #E73D74;
    box-shadow: 0px 0px 0px 1px rgba(231, 61, 116, 1) inset;
  }
  .isCount_sum {
    color: #5F5F5F;
    font-size: 15px;
    font-weight: 400;
  }
  .isCount_text {
    color: #5F5F5F;
    font-size: 13px;
    font-weight: 400;
  }
  .personal_col_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 46px;
    margin-right: 14px;
    border-radius: 100%;
    transition: .3s;
    opacity: 1;

    transform: rotate(0deg);
    color: #FFF;
    font-size: 40px;
    font-weight: 300;
    line-height: normal;
    background: #E73D74;
  }
  .personal_col_heading_wrap .personal_col_icon_img {
    margin-right: 14px;
  }
  .personal_input_row_payment_item {

  }
  .personal_menu_item_img_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 52px;
    height: 52px;
    padding-left: 1px;
    border-radius: 100%;
    background-color: #fff;
  }
  .personal_menu_item_img_wrap {
    margin-right: 20px;
  }
  .personal_row_history {
    display: flex;
    flex-wrap: nowrap;
    color: #5F5F5F;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
  }
  .personal_row_history_th {
    height: 35px;
    font-size: 13px;
  }
  .personal_row_history_td {
    height: 69px;
  }
  .personal_row_history_th,
  .personal_row_history_td {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .personal_row_history > div {
    border-bottom: 1px solid #BFBFBF;
  }
  .personal_row_history_date {
    flex-basis: 48%;
  }
  .personal_row_history_sum {
    flex-basis: 33%;
  }
  .personal_row_history_method {
    flex-basis: 20%;
  }

  .personal_login {
    max-width: 370px;
    margin: 0 auto;
    padding: 28px 33px;

    border-radius: 10px;
    border: 1px solid #BFBFBF;
    background-color: #FFF;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
  }
  .form_text_row {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-wrap: nowrap;
    margin-top: 11px;
    font-size: 15px;
  }
  .form_text_row_text {
    margin-right: 11px;
  }
  .personal_input_row {
    font-size: 15px;
  }

  /* main page */
  .main_section {
    margin-bottom: 97px;
  }
  .main_section_title {
    margin-bottom: 24px;
    color: #000;
    text-align: center;
    font-size: 48px;
    font-weight: 700;
  }
  .main_section_title_mb {
    margin-bottom: 97px;
    color: #4083FD;
    text-align: center;
    font-size: 40px;
    font-weight: 600;
  }
  .main_section_title_non_mb {
    margin-bottom: 54px;
  }
  /* main banner */
  .main_banner_wrap {
    padding-top: 35px;
  }
  .main_banner {
    position: relative;
  }
  .main_banner_img {
    display: block;
    margin-top: -12px;
    margin-right: 42px;
    margin-left: auto;
  }
  .main_banner_content {
    position: absolute;
    top: 230px;
    left: 0px;
    max-width: 624px;
  }
  .main_banner_title {
    margin-bottom: 17px;
    color: #5F5F5F;
    font-size: 40px;
    font-weight: 600;
  }
  .main_banner_subtitle {
    margin-bottom: 40px;
    color: #5F5F5F;
    font-size: 24px;
    font-weight: 400;
  }
  .main_banner_social_wrap {
    display: flex;
    justify-content: space-between;
  }
  .main_banner_social_item {
    position: relative;
    top: 0px;
    margin-right: 8px;
    margin-left: 8px;
    transition: .2s;
  }
  .main_banner_social_item:first-child {
    margin-left: 0px;
  }
  .main_banner_social_item:last-child {
    margin-right: 0px;
  }
  .main_banner_social_item:hover {
    top: -4px;
  }
  .main_banner_social_item img {
    filter: drop-shadow(0 1px 2px rgba(0,0,0,.05));
  }
  .main_banner_btn_wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 18px;
    margin-top: 48px;
  }
  .main_banner_social_top {
    position: absolute;
    top: 0px;
    right: 0px;
  }
  .main_banner_social_top_title {
    margin-bottom: 8px;
    text-align: right;
    color: #5F5F5F;
    font-size: 15px;
    font-weight: 400;
  }
  .main_banner_social_top_wrap {
    display: flex;
    justify-content: flex-end;
  }
  .main_banner_social_top_item {
    position: relative;
    top: 0px;
    margin-right: 5.5px;
    margin-left: 5.5px;
    transition: .2s;
  }
  .main_banner_social_top_item:first-child {
    margin-left: 0px;
  }
  .main_banner_social_top_item:last-child {
    margin-right: 0px;
  }
  .main_banner_social_top_item:hover {
    top: -3px;
  }

  /* end main banner */

  /* main quick order */
  .main_personal_wrap_grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    min-height: 725px;
  }
  .main_personal_wrap_grid .personal_col_content {
    height: 725px;
    overflow-y: auto;
  }
  .main_personal_wrap_grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    min-height: 725px;
  }
  /* end main quick order */

  /* main advantages */
  .main_advantages_section {
    margin-top: -318px;
  }
  .main_advantages {
    display: grid;
    /* grid-template-columns: repeat(4, 1fr); */
    
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-gap: 20px;
  }
  .main_advantages_item {
    /* height: 295px; */
    padding: 34px 26px 38px;

    border-radius: 10px;
    border: 1px solid #BFBFBF;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%), rgba(255, 255, 255, 0.10);
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(25px);

    text-align: center;
  }
  .main_advantages_item_title {
    margin-bottom: 11px;
    color: #5F5F5F;
    font-size: 64px;
    font-weight: 700;
  }
  .main_advantages_item_text {
    color: #5F5F5F;
    font-size: 36px;
    font-weight: 400;
  }
  /* end main advantages */

  /* main services section */
  .main_popular_services_wrap {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-gap: 20px;
  }
  .main_popular_services_item {
    border-radius: 10px;
    border: 1px solid #BFBFBF;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%), rgba(255, 255, 255, 0.10);
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    transition: .3s;
    cursor: pointer;
  }
  .main_popular_services_item:hover {
    border-color: transparent;
    box-shadow: 0px 0px 3px 1px #4083FD;
  }
  .main_popular_services_item:hover button {
    border: 1px solid #4083FD;
    background-color: #FFF;
    color: #4083FD;
  }
  .main_popular_services_top {
    display: flex;
    align-items: center;
    padding: 12px 33px;
    border-bottom: 1px solid #BFBFBF;
    background: #FFF;
  }
  .main_popular_services_top img {
    margin-right: 14px;
    filter: drop-shadow(0 1px 2px rgba(0,0,0,.05));
  }
  .main_popular_services_title, .main_popular_services_name, .main_popular_services_text {
    color: #5F5F5F;
    font-size: 15px;
    font-weight: 700;
  }
  .main_popular_services_content {
    padding: 29px 33px 26px;
  }
  .main_popular_services_name {
    margin-bottom: 11px;
  }
  .main_popular_services_text {
    margin-bottom: 16px;
    font-weight: 400;
  }
  /* end main services section */

  /* main review methods */
  .main_review_methods_wrap {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(739px, 1fr));
    grid-column-gap: 20px;
    grid-row-gap: 36px;
  }
  .main_review_methods_item_title {
    margin-bottom: 32px;
    color: #5F5F5F;
    font-size: 36px;
    font-weight: 700;
  }
  .main_review_methods_item_img {
    position: relative;
    height: 307px;
    margin-bottom: 31px;

    border-radius: 10px;
    border: 1px solid #BFBFBF;
    background: linear-gradient(91deg, #4083FD -0.56%, #E73D74 87.06%);
    background-blend-mode: normal, multiply;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(25px);

    overflow: hidden;
  }
  .main_review_methods_item_img:before {
    position: absolute;
    content: '';
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 1;
    /* background-color: rgba(255, 255, 255, 0.80); */
  }
  .main_review_methods_item_text {
    padding-right: 20px;
    color: #5F5F5F;
    font-size: 24px;
    font-weight: 400;
  }
  .main_review_methods_item_rev_mobile {
    display: none;
  }
  .main_review_methods_item_rev {
    /* display: flex;
    align-items: center; */
    padding-top: 120px;
    padding-left: 52px;
  }
  .main_review_methods_item_rev_user {
    display: flex;
    align-items: flex-start;
  }
  .main_review_methods_item_rev_user > svg {
    flex-shrink: 0;
    margin-right: -10px;
  }
  .main_review_methods_item_rev_layout {
    position: relative;
    height: 307px;

    padding: 33px 30px 36px 104px;
    border-radius: 10px;

    color: #5F5F5F;
    font-size: 24px;
    font-weight: 400;

    filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.25));
  }
  .main_review_methods_item_rev_layout_bg {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1;
  }
  .main_review_methods_item_rev_layout > div {
    position: relative;
    z-index: 2;
  }
  .main_review_methods_item_rev_layout_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 43px;
  }
  .main_review_methods_item_rev_layout_top_stars {
    margin-right: 12px;
  }
  .main_review_methods_item_rev_layout_top_text {
    /* color: #5F5F5F;
    font-size: 24px;
    font-weight: 400; */
  }
  .main_review_methods_item_rev_layout_text {
    /* color: #5F5F5F;
    font-size: 24px;
    font-weight: 400; */
  }
  /* end main review methods */

  /* main be visible */
  .main_be_visible_wrap {
    display: flex;
    align-items: flex-start;
    color: #5F5F5F;
  }
  .main_be_visible_content {
    max-width: 620px;
    margin-right: 140px;
  }
  .main_be_visible_content_list {
    margin-bottom: 86px;
    padding-left: 40px;
    font-size: 40px;
    font-weight: 500;
  }
  .main_be_visible_content_list li {
    margin-bottom: 48px;
  }
  .main_be_visible_content_list li:last-child {
    margin-bottom: 0px;
  }
  .main_be_visible_content_bottom_text {
    margin-bottom: 54px;
    font-size: 40px;
    font-weight: 700;
  }
  .main_be_visible_content_bottom_wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
  .main_be_visible_result {
    display: flex;
  }
  .main_be_visible_result_mobile {
    display: none;
  }
  .main_be_visible_result_text {
    margin-bottom: 10px;
    text-align: center;
    font-size: 36px;
    font-weight: 400;
  }
  /* end main be visible */

  /* main second advantages */
  .second_advantages_wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
  .second_advantages_item {
    position: relative;
    height: 306px;
    padding: 74px 80px 64px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid #BFBFBF;
    background-color: #E73D74;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
    color: #FFF;
    overflow: hidden;
  }
  .second_advantages_item:last-child {
    background-color: #4083FD;
  }
  .second_advantages_item svg {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }
  .second_advantages_title {
    position: relative;
    margin-bottom: 30px;
    text-align: center;
    font-size: 48px;
    font-weight: 700;
    z-index: 2;
  }
  .second_advantages_text {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    z-index: 2;
  }
  /* end main second advantages */

  /* main referal info */
  .referal_info {
    position: relative;
    margin-top: 330px;
    padding: 42px 56px;
    border-radius: 10px;
    border: 1px solid #BFBFBF;
    background: linear-gradient(92deg, #4083FD -1.09%, rgba(64, 131, 253, 0.00) 100%);
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
    color: #FFF;
  }
  .referal_info > img {
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
  .mobile_pc {
    display: none;
  }
  /* .referal_info_wrap {
    max-width: 600px;
  } */
  .referal_info_title {
    max-width: 600px;
    margin-bottom: 22px;
    font-size: 36px;
    font-weight: 700;
  }
  .referal_info_text {
    max-width: 830px;
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: 400;
  }
  /* end main referal info */

  /* main faq */
  .main_faq_wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
  .main_faq_item_toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    /* padding: 26px 38px; */
    padding: 10px 38px;
    cursor: pointer;
    background-color: #FFF;
    transition: 0.3s;
  }
  .main_faq_item_toggle.active {
    background-color: #4083FD;
  }
  .main_faq_item_icon {
    width: 25px;
    height: 25px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E73D74;
    border-radius: 100%;
    transition: 0.3s;

    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
  }
  .main_faq_item_toggle.active .main_faq_item_icon {
    /* transform: rotate(180deg); */
    transform: rotate(135deg);
    color: #fff;
  }
  .main_faq_item {
    /* margin-bottom: 20px; */
    height: fit-content;
    border-radius: 10px;
    border: 1px solid #BFBFBF;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%), #FFF;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
    overflow: hidden;
  }
  /* .main_faq_item:last-child {
    margin-bottom: 0;
  } */
  .main_faq_item_title {
    position: relative;
    color: #5F5F5F;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    transition: 0.3s;
  }
  .main_faq_item_toggle.active .main_faq_item_title {
    color: #fff;
  }
  .main_faq_item_body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 26px 38px;
  }
  .main_faq_item_text {
    margin-bottom: 0;
    color: #5F5F5F;
    font-size: 15px;
    font-weight: 400;
  }
  .main_faq_item_collapse {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
  }
  .main_faq_item_collapse.show {
    height: auto;
  }
  /* endmain faq */

  /* main callback */
  .main_callback_section {
    padding-top: 330px;
  }
  .main_callback_section .referal_info {
    margin-top: 0px;
  }
  .main_callback_section .referal_info {
    background: linear-gradient(108deg, #E73D74 0%, #FFF 100%);
  }
  .main_callback_section .referal_info > img {
    right: 96px;
  }
  /* end main callback */

  /* end main page */

  /* inner container wrap */
  .inner {
    padding: 80px 0px;
  }
  .inner_wrap {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 20px;
  }
  .inner_col {
    position: sticky;
    top: 20px;

    height: fit-content;
    padding-bottom: 17px;
    overflow: hidden;
    font-size: 15px;
    color: #5F5F5F;
    /* background: linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%),rgb(255 255 255 / 50%); */
    background-color: #fafcff;

    z-index: 2;
  }
  .th_title {
    padding: 26px 0px 26px 30px;
    background-color: #FFF;
    font-weight: 700;
  }
  .th_title_row {
    padding: 12px 0px 12px 30px;
    background-color: #FFF;
  }
  .tr_link {
    display: flex;
    align-items: center;
    padding: 12px 0px 12px 30px;
    color: inherit;
  }
  .tr_link > img {
    margin-right: 20px;
    filter: drop-shadow(0 1px 2px rgba(0,0,0,.05));
  }
  .tr_link:hover {
    opacity: .7;
  }
  .tr_link.active {
    color: #000;
    opacity: 1;
    font-weight: 700;
  }
  .inner_col_content {

  }
  .inner_col_content_title {
    margin-bottom: 48px;
    color: #000;
    text-align: center;
    font-size: 48px;
    font-weight: 700;
  }
  .inner_section {
    margin-bottom: 60px;
  }
  .inner_section_title {
    margin-bottom: 0px;
  }
  /* end inner container wrap */

  /* services */
  
  .inner_col_content_nested_services {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }
  .inner_col_content_nested_services_item {
    padding: 34px 34px 28px;
    color: #5F5F5F;
    text-align: center;
    font-size: 15px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%), #FFF;
    transition: .3s;
  }
  .inner_col_content_nested_services_item_title {
    margin-bottom: 24px;
    font-weight: 700;
  }
  .inner_col_content_nested_services_item_img {
    margin: 0 auto 24px;
  }
  .inner_col_content_nested_services_item_text {
    margin-bottom: 24px;
    /* font-weight: 400; */
  }
  .inner_col_content_nested_services_item:hover {
    /* border-color: #4083FD;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0); */
    border-color: transparent;
    box-shadow: 0px 0px 3px 1px #4083FD;
  }
  .inner_col_content_nested_services_item:hover .inner_col_content_nested_services_item_btn {
    background-color: #FFF;
    color: #4083FD;
  }
  .services_start_wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    margin-bottom: 40px;
  }
  .services_start_item {

  }
  .services_start_item_text {
    margin-bottom: 40px;
    color: #FFF;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
  }
  .services_start_item_text.registration:hover {
    background: #FFF;
    color: #4083FD;
    border-radius: 10px;
  }
  .services_start_item > img {
    filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.25));
  }
  .services_section .inner_section_title {
    margin-bottom: 60px;
  }
  .start_services_link {
    padding: 12px 0px;
    color: #FFF;
    text-align: center;
    font-size: 24px;
    font-weight: 700;


    border-radius: 10px;
    background: linear-gradient(119deg, #4D57D6 0%, #B324A0 31.3%, #E73D74 64.72%, #FEDA77 101.85%);
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
  }
  .start_services_link:hover {
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.45);
  }
  .services_start_container {
    padding: 64px 0px 80px;
    position: relative;
    z-index: 2;
  }
  .services_start_bg {
    position: absolute;
    left: 0;
    /* width: 100vw; */
    width: 100%;
    height: 580px;
    z-index: 1;
  }
  .services_start_bg:before,
  .services_start_bg:after {
    content: '';
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
  }
  .services_start_bg:before {
    background: linear-gradient(76deg, #FFF -0.57%, #4083FD 100%);
    z-index: 1;
  }
  .services_start_bg:after {
    background-image: url(/content/services/start_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 2;
  }
  /* end services */

  /* inner reviews */
  .inner_faq_section .container {
    max-width: 100%;
    padding: 0px;
  }
  .reviews_video_section_wrap {
    margin-bottom: 32px;
  }
  .reviews_section .inner_section_title {
    margin-bottom: 60px;
  }
  .reviews_wrap {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    /* max-height: calc(228px * 2 + 30px); */
    /* overflow-y: hidden; */
    margin-bottom: 10px;
    padding-bottom: 0px;

    transition: .3s;
  }
  /* .reviews_wrap:before {
    content: '';
    position: absolute;
    width: 100%;
    bottom: 0px;
    background: linear-gradient(0deg, #D9D9D9 0%, rgba(217, 217, 217, 0.00) 100%);
    height: 465px;
  } */
  .review_item {
    height: 228px;
    overflow: hidden;
  }
  .review_item iframe {
    display: block;
    max-width: 100%;
    height: 100%;
  }
  .reviews_show_all {
    max-width: 304px;
    margin: 0 auto;
  }
  .reviews_text_item {
    padding: 20px 22px;

    color: #5F5F5F;
    font-size: 15px;
    font-weight: 400;

    background: linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%), #FFF;
  }
  .reviews_text_top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 13px;
  }
  .reviews_text_user {
    display: flex;
    align-items: center;
  }
  .reviews_text_icon {
    margin-right: 16px;
  }
  .reviews_text_name {
    max-width: 150px;
    font-weight: 600;
  }
  .reviews_text_tag {
    margin-bottom: 15px;
  }
  .reviews_text_tag a,
  .reviews_text_tag span {
    display: inline-block;
    color: #4083FD;
    transition: .2s;
  }
  .reviews_text_tag a:hover,
  .reviews_text_tag span:hover {
    opacity: .7;
  }
  .reviews_text_wrap {
    /* max-height: calc(235px * 2 + 30px); */
  }
  .services_text {
    color: #5F5F5F;
    font-size: 24px;
    font-weight: 400;
  }
  .services_text_content {
    /* max-height: calc(400px); */
    margin-bottom: 20px;
    padding: 0px 30px;
    /* overflow-y: hidden; */
    transition: .3s;
    font-size: 22px;
  }
  .services_text_content a {
    display: inline-block;
  }
  .services_text_content p {
    margin-bottom: 22px;
    line-height: 1.2;
  }
  .services_text_content h2 {
    margin-bottom: 24px;
    line-height: 1.3;
  }
  .services_text_content ol,
  .services_text_content ul {
    margin-bottom: 22px;
    padding-left: 28px;
    line-height: 1.2;
  }
  .services_text_content ol li,
  .services_text_content ul li {
    margin-bottom: 18px;
  }
  .services_text_content ol li:last-child,
  .services_text_content ul li:last-child {
    margin-bottom: 0px;
  }
  .services_text_content blockquote {
    margin-bottom: 22px;
    padding: 10px 20px;
    border-radius: 10px;
    border: 1px solid #BFBFBF;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%),#FFF;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
  }
  /* end inner reviews */

  /* inner single */
  .inner_single_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  .inner_single_top_content {
    max-width: 520px;
    margin-right: 25px;
    padding-top: 65px;
  }
  .inner_single_top_content_title {
    margin-bottom: 42px;
    font-size: 40px;
    font-weight: 600;
  }
  .inner_single_top_content_text {
    margin-bottom: 42px;
    font-size: 24px;
    font-weight: 400;
  }
  .single_content {
    margin-bottom: 60px;
  }
  .single_content_menu_item {
    display: flex;
    align-items: center;
    padding: 12px 33px;
    color: #5F5F5F;
    font-size: 15px;

    border-radius: 10px;
    border: 1px solid #BFBFBF;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%), #FFF;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(25px);

    transition: .3s;
  }
  .single_content_menu_item img {
    margin-right: 14px;
    filter: drop-shadow(0 1px 2px rgba(0,0,0,.05));
  }
  .single_content_menu_item_title {
    font-size: 15px;
    font-weight: 700;
  }
  .single_content_menu_item:hover {
    border-color: transparent;
    box-shadow: 0px 0px 3px 1px #4083FD;
  }
  .single_content_menu_item.active {
    color: #FFF;
    background: #4083FD;
    border-color: #BFBFBF;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
  }
  .single_guaranty {
    margin-bottom: 60px;
  }
  .single_guaranty .second_advantages_wrap:first-child {
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 20px;
  }
  .single_guaranty .second_advantages_item {
    position: relative;
    height: 306px;
    padding: 43px 10px;
  }
  .single_guaranty .second_advantages_item .second_advantages_title {
    font-size: 40px;
  }
  .second_advantages_item_blue {
    background-color: #4083FD;
  }
  .single_guaranty .second_advantages_text {
    font-size: 20px;
  }
  .single_guaranty .second_advantages_wrap:last-child .second_advantages_item:last-child {
    background-color: #E73D74;
  }
  .main_popular_services_img {
    margin-bottom: 24px;
  }
  .main_popular_services_img img {
    margin: 0 auto;
  }
  .single_content_wrap .main_popular_services_name {
    margin-bottom: 24px;
  }
  .main_popular_services_price {
    width: 100%;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;

    border-radius: 10px;
    background: #FFF;
    color: #BFBFBF;
    text-align: center;
    font-size: 15px;
    font-weight: 700;
  }
  .single_content_wrap .main_popular_services_item {
    display: flex;
    flex-direction: column;
  }
  .single_content_wrap .main_popular_services_content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }
  .main_popular_services_content_wrap {
    
  }
  .inner_col_content_subtitle {
    margin-bottom: 24px;
    padding: 0px 30px;
  }
  .inner_col_content_subtitle_top {
    margin-bottom: 35px;
    font-size: 40px;
    font-weight: 600;
  }
  .inner_col_content_subtitle_bottom {
    margin-bottom: 60px;
    font-size: 24px;
    font-weight: 400;
  }
  /* end inner single */

  /* smm tools */
  .smm_tools_inner_wrap {
    padding: 30px;
    background-color: #fafcff;
    color: #5F5F5F;
    font-size: 24px;
  }
  .smm_tools_inner_wrap .inner_col_content_subtitle_top {
    margin-bottom: 18px;
  }
  .smm_tools_inner_info {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    font-size: 15px;
  }
  .smm_tools_inner_info_img {
    margin-right: 12px;
    filter: drop-shadow(0 1px 2px rgba(0,0,0,.05));
  }
  /* end smm tools */

  /* contact */
  .contact_info_wrap {
    margin-bottom: 34px;
  }
  .contact_row {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-size: 15px;
  }
  .contact_row a {
    color: #4083FD;
  }
  .contact_row a:hover {
    opacity: .7;
  }
  .contact_row:last-child {
    margin-bottom: 0px;
  }
  .contact_row_btn {
    display: flex;
    align-items: center;
  }
  .contact_row_btn_mob {
    display: none;
  }
  .contact_row_btn img {
    margin-left: 13px;
  }
  .contact_row_btn:last-child {
    margin-top: 34px;
  }
  /* end contact */
  
  /* promo */
  .promo_wrap {

  }

  .promo_wrap .main_personal_wrap_grid {
    min-height: unset;
    margin-bottom: 94px;
  }
  
  .promo_item {
    padding: 34px 34px 28px;
    color: #5F5F5F;
    text-align: center;
    font-size: 15px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%), #FFF;
    transition: .3s;
  }
  .promo_col_text {
    padding: 37px 33px;
    color: #5F5F5F;
    font-size: 15px;
    font-weight: 700;
  }
  .promo_col_text_row_big {
    margin-top: 11px;
    font-size: 36px;
  }
  .promo_col_text_row_light {
    font-weight: 400;
  }
  .promo_col_text_row:not(:last-child) {
    margin-bottom: 50px;
  }
  .promo_col_text_row_low_mb:not(:last-child) {
    margin-bottom: 32px;
  }
  .promo_input_wrap {
    margin-top: 8px;
  }
  .promo_input_wrap *:not(:last-child) {
    margin-bottom: 14px;
  }
  .promo_wrap .personal_order_list_td {
    padding: 20px 2px;
  }
  .promo_wrap .personal_order_list_td_th {
    padding: 10px 2px;
  }
  .promo_content {
    position: relative;
    margin-bottom: 100px;
    padding: 43px 57px;
    border-radius: 10px;
    border: 1px solid #BFBFBF;
    background: linear-gradient(108deg, #E73D74 0%, #FFF 100%);
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
  }
  .promo_content > img {
    position: absolute;
    top: -80px;
    right: -100px;
    width: 47%;
    z-index: 1;
  }
  .promo_content_text {
    position: relative;
    /* max-width: 703px; */
    max-width: 72%;

    color: #FFF;
    font-size: 24px;
    font-weight: 400;
    z-index: 2;
  }
  .promo_content_title {
    margin-bottom: 22px;
    font-size: 36px;
    font-weight: 700;
  }
  .promo_content_text_inner p,
  .promo_content_text_inner li {
    margin-bottom: 26px;
  }
  .promo_content_info {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    padding: 68px;
    border-radius: 10px;
    border: 1px solid #BFBFBF;
    background-image: url(/content/promo_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
    
    color: #5F5F5F;
    text-align: center;
  }
  .promo_content_info_top {
    font-size: 64px;
    font-weight: 700;
  }
  .promo_content_info_descr {
    margin: 0 auto;
    max-width: 225px;
    font-size: 36px;
    font-weight: 400;
  }
  /* end promo */

  /* blog */
  .blog_item_wrap {
    display: flex;
    flex-direction: column;
    color: #5F5F5F;
    background-color: #fff;
    overflow: hidden;
  }
  .blog_item_wrap > img {
    max-height: 214px;
    width: auto;
    object-fit: cover;
  }
  .blog_item {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 22px;
    background-color: #FFF;
  }
  .blog_item:before {
    position: absolute;
    content: '';
    height: 90px;
    width: 100%;
    top: -90px;
    left: 0px;
    background: linear-gradient(0deg, #FFF 0.19%, rgba(255, 255, 255, 0.00) 99.81%);
    /* transform: rotate(180deg); */
  }
  .blog_item_name {
    margin-bottom: 18px;
    font-size: 36px;
    font-weight: 600;
  }
  .blog_item_bottom {
    display: flex;
    align-items: center;
    font-size: 15px;
  }
  .blog_item_bottom img {
    margin-right: 20px;
  }
  /* end blog */

  /* review */
  .review_textarea {
    padding-top: 16px;
    padding-bottom: 16px;
    height: 170px;
    resize: none;
  }
  .review_row {
    margin-bottom: 24px;
  }
  .review_row_flex {
    display: flex;
    align-items: center;
  }
  .review_select_list {
    display: flex;
  }
  .review_wrap .review_select_list {
    flex: 1;
    margin-right: 84px;
    gap: 24px;
  }
  .review_select_list .select_base_wrap {
    flex: 1;
  }
  /* end review */

  /* 404 */
  .not_found_wrap {
    position: relative;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin: 0 auto;
  }
  .not_found_wrap img {
    content: '';
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }
  .not_found_wrap button {
    position: relative;
    z-index: 2;
  }
  .not_found_title {
    margin-bottom: 45px;
    color: #4083FD;
    text-align: center;
    font-size: 48px;
    font-weight: 600;
  }
  /* end 404 */

  .thank_you_page {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .thank_you_page > img {
    margin-bottom: 32px;
  }


  /* MEDIA */

  /* main */
  @media (max-width: 1600px) {
    .container {
      max-width: 1260px;
    }
    .text {
      font-size: 14px;
    }
    .nav_item {
      width: auto;
      max-width: 90px;
      padding: 16px 14px;
    }
    .btn_outline {
      /* width: 170px; */
    }
    .nav_items .nav_fast_order {
      margin-left: 35px;
    }
    .nav_up_balance {
      margin-right: 15px;
    }
    .nav_call_action_icon {
      width: 40px;
      height: 40px;
    }
    .nav_up_balance_icon {
      font-size: 30px;
    }

    /* main banner */
    .main_banner_title {
      font-size: 32px;
    }
    .main_banner_subtitle {
      font-size: 20px;
    }
    .main_banner_social_wrap {
      justify-content: flex-start;
    }
    .main_banner_social_item {
      width: 45px;
      margin-right: 6px;
      margin-left: 6px;
    }
    .main_banner_img {
      width: 60%;
    }
    .main_advantages {
      grid-template-columns: repeat(4, 1fr);
    }
    .main_advantages_item_title {
      font-size: 40px;
    }
    .main_advantages_item_text {
      font-size: 26px;
    }
    .main_advantages_item {
      padding: 22px 26px;
    }
    .main_section_title {
      font-size: 38px;
    }
    .main_section {
      margin-bottom: 60px;
    }
    .main_popular_services_wrap {
      grid-template-columns: repeat(4, 1fr);
    }
    .main_popular_services_title, .main_popular_services_name, .main_popular_services_text {
      font-size: 14px;
    }
    .btn {
      height: 45px;
      font-size: 14px;
    }
    .main_popular_services_content {
      padding: 24px 26px;
    }
    .main_section_title_mb {
      margin-bottom: 60px;
      font-size: 30px;
    }
    .main_review_methods_item_img {
      margin-bottom: 20px;
    }

    .main_review_methods_wrap {
      grid-template-columns: repeat(2, 1fr);
    }
    .main_review_methods_item_title {
      margin-bottom: 24px;
      font-size: 28px;
    }
    .main_review_methods_item_text {
      font-size: 18px;
    }

    .main_review_methods_item_rev_layout {
      font-size: 16px;
    }
    .main_review_methods_item_rev_layout_top {
      margin-bottom: 18px;
    }
    .main_review_methods_item_rev_layout_top_stars {
      width: 150px;
    }

    .main_be_visible_content_list {
      font-size: 24px;
    }
    .main_be_visible_content_list li {
      margin-bottom: 20px;
    }

    .main_be_visible_content_bottom_text {
      font-size: 34px;
    }

    .second_advantages_title {
      font-size: 38px;
    }
    .second_advantages_text {
      font-size: 20px;
    }
    .second_advantages_item {
      height: 250px;
      padding: 38px 60px;
    }

    .referal_info_wrap {
      max-width: 50%;
    }
    .referal_info > img {
      width: 50%;
    }

    .main_faq_item_toggle {
      height: 55px;
    }
    .main_callback_section .referal_info>img {
      right: 24px;
    }

    .footer_siteinfo {
      margin-bottom: 22px;
      font-size: 20px;
    }
    .footer_description {
      font-size: 13px;
    }
    .footer_menu_item {
      padding: 10px 0px;
    }

    .nav_logo {
      margin-right: 30px;
    }

    .nav_call_action_text {
      font-size: 14px;
    }

    .referal_info {
      margin-top: 140px;
    }
    
    .promo_content_title {
      font-size: 22px;
    }
    .promo_content_text {
      font-size: 15px;
    }
    .promo_content_info {
      padding: 20px;
    }
    .promo_content_info_top {
      font-size: 38px;
    }
    .promo_content_info_descr {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
    }
    .promo_content_info_item {
      display: flex;
      flex-direction: column;
    }
    .promo_content {
      margin-bottom: 60px;
    }
    .promo_content>img {
      top: -40px;
    }
    .promo_wrap .main_personal_wrap_grid {
      margin-bottom: 60px;
    }
    .promo_col_text {
      padding: 20px
    }
  }
  @media (max-width: 1280px) {
    .promo_content>img {
      display: none;
    }
    .promo_content_text {
      max-width: 100%;
    }
    .promo_content_text > img {
      margin: 0 auto 20px;
    }
    .promo_content_text > button {
      margin: 0 auto;
    }
    .promo_wrap .main_personal_wrap_grid {
      margin-bottom: 30px;
    }
    .promo_content {
      margin-bottom: 30px;
    }
    .container {
      max-width: 720px;
    }
    .nav_items .nav_fast_order {
      margin-left: 10px;
      width: 125px;
    }
    .btn {
      height: 38px;
      font-size: 13px;
    }
    .nav_item {
      padding: 10px 9px;
    }
    .text {
      font-size: 13px;
    }
    .nav_logo {
      width: 65px;
      margin-right: 10px;
    }
    .nav_call_action_text {
      margin-right: 5px;
      display: none;
      font-size: 13px;
    }
    .nav_call_action_icon {
      width: 30px;
      height: 30px;
    }

    .main_advantages_item {
      padding: 14px 8px;
    }
    .main_advantages_item_title {
      font-size: 20px;
    }
    .main_advantages_item_text {
      font-size: 16px;
    }

    .main_banner_img {
      width: 50%;
    }
    .main_banner_content {
      max-width: 50%;
      top: 10px;
    }
    .main_banner_social_top_title {
      font-size: 13px;
    }
    .main_banner_social_top_item {
      margin-right: 3.5px;
      margin-left: 3.5px;
    }
    .main_banner_social_top_item img {
      width: 25px;
    }
    
    .main_banner_subtitle {
      margin-bottom: 12px;
    }
    .main_banner_btn_wrap {
      margin-top: 12px;
    }

    .main_advantages_section {
      margin-top: -115px;
    }

    .main_section {
      margin-bottom: 30px;
    }

    .main_section_title {
      margin-bottom: 16px;
      font-size: 30px;
    }

    .personal_col_heading {
      height: 55px;
      padding: 12px;
    }
    .personal_col_step {
      width: 22px;
      height: 22px;
      margin-right: 12px;
      font-size: 13px;
    }
    .personal_col_heading_title {
      font-size: 13px;
    }
    .personal_row {
      padding: 8px 12px;
    }
    .personal_menu_item > img {
      width: 30px;
    }
    .personal_menu_link {
      font-size: 13px;
    }
    .personal_menu_item_arrow {
      font-size: 20px;
    }

    .main_personal_wrap_grid {
      min-height: 425px;
    }
    .main_personal_wrap_grid .personal_col_content {
      height: 425px;
    }
    .personal_col_inner_padding {
      padding: 10px;
    }

    .label_box_text {
      flex-direction: column;
      align-items: flex-start;

      font-size: 13px;
    }
    .label_box_row {
      flex-direction: column;
      align-items: flex-start;
    }
    .label_box_row_link {
      font-size: 13px;
    }
    .select_base_wrap {
      position: relative;
    }

    .select_base {
      height: 34px;
      padding: 0px 0px;
      padding-left: 10px;
    }
    .base_input:not(textarea) {
      height: 34px;
    }
    .base_input {
      padding: 0px 0px;
      padding-left: 10px;
      font-size: 13px;
    }
    .select_base {
      font-size: 13px;
    }
    .base_input_count {
      max-width: 70%;
      padding-left: 4px;
    }
    .isCount_sum {
      font-size: 13px;
    }

    .main_advantages,
    .main_popular_services_wrap,
    .second_advantages_wrap,
    .main_faq_wrap,
    .main_personal_wrap_grid {
      grid-gap: 10px;
    }
    .label_box_row {
      margin-bottom: 6px;
    }
    .isCount_wrap_inner {
      margin-bottom: 6px;
    }

    .main_popular_services_top {
      padding: 12px;
    }
    .main_popular_services_top img {
      width: 30px;
      height: auto;
    }
    .main_popular_services_content {
      padding: 12px;
    }
    .main_popular_services_title, .main_popular_services_name, .main_popular_services_text {
      font-size: 13px;
    }
    .main_section_title_mb {
      margin-bottom: 30px;
      font-size: 18px;
    }
    .main_review_methods_item_title {
      margin-bottom: 12px;
      font-size: 16px;
    }
    .main_review_methods_item_img {
      height: 170px;
      margin-bottom: 10px;
    }
    .main_review_methods_item_text {
      font-size: 15px;
    }
    .main_review_methods_item_rev_user>svg {
      width: 50px;
    }

    .main_review_methods_wrap {
      grid-column-gap: 10px;
      grid-row-gap: 16px;
    }
    .main_review_methods_item_rev_layout {
      padding: 10px 10px 10px 44px;
      font-size: 13px;
    }
    .main_review_methods_item_rev_layout_top {
      margin-bottom: 4px;
    }
    .main_review_methods_item_rev {
      padding: 0px;
    }
    .main_review_methods_item_rev_layout_top_stars {
      width: 120px;
      height: auto;
    }

    .main_be_visible_result_text {
      font-size: 24px;
    }
    .main_be_visible_content_list {
      margin-bottom: 18px;
      padding-left: 22px;
      font-size: 16px;
    }
    .main_be_visible_content_list li {
      margin-bottom: 14px;
    }
    .main_be_visible_content {
      margin-right: 20px;
    }
    .main_be_visible_content_bottom_text {
      font-size: 20px;
    }

    .second_advantages_title {
      margin-bottom: 8px;
      font-size: 20px;
    }
    .second_advantages_text {
      font-size: 16px;
    }
    .second_advantages_item {
      height: 175px;
      padding: 20px;
    }

    .referal_info {
      margin-top: 20px;
      padding: 24px 34px;
    }
    .referal_info_title {
      font-size: 20px;
    }
    .referal_info_text {
      font-size: 16px;
    }

    .main_faq_item_toggle {
      padding: 6px 12px;
    }
    .main_faq_item_title {
      margin-right: 10px;
      font-size: 13px;
    }
    .main_faq_item_body {
      padding: 6px 12px;
    }
    .main_faq_item_text {
      font-size: 13px;
    }

    .main_callback_section {
      padding-top: 90px;
    }
    .main_callback_section .referal_info>img {
      width: 45%;
    }

    .footer_wrap {
      grid-template-columns: repeat(2, 1fr);
    }

    .second_advantages_item svg {
      height: 100%;
    }
  }
  @media (max-width: 768px) {
    .btn_link {
      max-width: 100%;
    }
    .container {
      max-width: 100%;
      padding: 0px 20px;
    }
    .page_content {
      padding-top: 78px;
    }
    .nav_wrap {
      position: fixed;
      top: 0px;
      width: 100%;
      backdrop-filter: blur(25px);
      z-index: 99;
    }
    .nav {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      height: 78px;
    }
    .nav .nav_fast_order {
      width: 108px;
      height: 52px;
      justify-self: center;
    }
    .nav_logo {
      width: auto;
    }
    .nav_toggle {
      position: relative;
      justify-self: flex-end;
      width: 52px;
      height: 52px;
      border-radius: 100%;
      background-color: #FFF;
      box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.05);
      cursor: pointer;
    }
    .nav_toggle span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 20px;
      height: 2px;
      border-radius: 4px;
      background-color: #E73D74;
      transition: .2s;
    }
    .nav_toggle span:before,
    .nav_toggle span:after {
      content: '';
      position: absolute;
      width: 20px;
      height: 2px;
      border-radius: 4px;
      background-color: #E73D74;
      transition: .2s;
    }
    .nav_toggle span:before {
      top: -6px;
    }
    .nav_toggle span:after {
      top: 6px;
    }
    .nav_toggle.active span {
      background-color: #FFF;
    }
    .nav_toggle.active span:before {
      top: 0px;
      transform: rotate(45deg);
    }
    .nav_toggle.active span:after {
      top: 0px;
      transform: rotate(-45deg);
    }

    .mobile_menu_wrap {
      position: fixed;
      top: 78px;
      left: -200%;
      width: 100%;
      height: calc(100dvh - 78px);
      z-index: 98;
    }
    .mobile_menu_wrap.active {
      left: 0px;
      overflow-y: scroll;
    }
    .mobile_menu_wrap.active .nav_items_mobile {
      right: 0px;
    }

    .mobile_menu_bg {
      position: fixed;
      top: 78px;
      left: -200%;
      width: 100%;
      height: calc(100dvh - 78px);
      z-index: 1;
      opacity: 0;
      background: #000;
      transition: .3s opacity;
    }
    .mobile_menu_wrap.active .mobile_menu_bg {
      left: 0px;
      overflow-y: scroll;
      opacity: 0.5;
    }


    .nav_items_mobile {
      position: relative;
      right: -100%;
      display: block;
      max-width: 260px;
      margin-right: 0px;
      margin-left: auto;
      padding: 22px 22px 12px;

      border-radius: 10px 0px 0px 10px;
      border: 1px solid #BFBFBF;
      border-top: 0px;
      background: linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF;
      box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
      backdrop-filter: blur(25px);
      z-index: 2;
      transition: .4s;
      .nav_call_action {
        flex-direction: row-reverse;
        justify-content: flex-end;
        margin-bottom: 20px;
      }
      .nav_call_action_text {
        font-size: 15px;
      }
      .nav_up_balance {
          margin-right: 0px;
          /* margin-bottom: 20px; */
        .nav_call_action_icon {
          display: none;
        }
        .nav_call_action_text {
          font-weight: 700;
        }
      }
      .nav_call_action_text {
        display: block;
      }
      .text {
        font-size: 15px;
      }
    }
    .nav_call_action_icon {
      width: 52px;
      height: 52px;
      margin-right: 18px;
    }
    .nav_item {
      display: block;
      width: 100%;
      max-width: 100%;
      padding: 10px 0px;
    }
    .text {
      font-size: 14px;
    }
    .mobile_menu_wrap .th_title {
      padding: 15px 0px;
    }
    .mobile_menu_wrap .tr_link {
      padding: 8px 0px;
    }
    .th_title {
      padding: 15px 0px;
    }
    .tr_link {
      padding: 8px 0px;
    }
    .btn {
      height: 50px;
      font-size: 15px;
    }

    .main_banner_social_top {
      display: none;
    }
    .main_banner_wrap {
      margin-bottom: 30px;
    }
    .main_banner_content {
      position: relative;
      max-width: 70%;
    }
    .main_banner_img {
      position: absolute;
      right: 0px;
      width: 60%;
      margin: 0;
    }
    .main_banner_title,
    .main_banner_subtitle {
      max-width: calc(100% - 90px);
    }
    .main_banner_title {
      margin-bottom: 26px;
      font-size: 24px;
    }
    .main_banner_subtitle {
      margin-bottom: 26px;
      font-size: 15px;
    }
    .main_banner_social_wrap {
      flex-wrap: wrap;
    }
    .main_banner_social_item {
      flex-shrink: 0;
      width: 35px;
      margin: 0px 12px 12px 0px;
    }
    .main_banner_social_item:last-child {
      margin-right: 0px;
    }
    .main_banner_btn_wrap {
      position: relative;
      display: block;
      max-width: 300px;
      margin: 0 auto 30px;
      z-index: 2;
    }
    .main_banner_btn_wrap {
    }
    .main_banner_btn_wrap > *:first-child {
      margin-bottom: 20px;
    }

    .main_advantages {
      grid-template-columns: repeat(2, 1fr);
    }
    .main_advantages_item {
      /* max-width: 180px; */
      padding: 18px 8px;
    }
    .main_advantages_item_title {
      font-size: 32px;
    }
    .main_advantages_item_text {
      font-size: 15px;
    }

    .main_advantages_section {
      margin-top: 0px;
    }

    .main_section {
      margin-bottom: 20px;
    }
    .main_section_title {
      margin-bottom: 20px;
      font-size: 24px;
    }

    .main_personal_wrap_grid {
      grid-template-columns: 1fr;
      min-height: auto;
    }

    .label_box_row {
      flex-direction: row;
      align-items: center;
      margin-bottom: 12px;
    }
    .personal_col_heading {
      height: auto;
      padding: 15px 20px;
    }
    .personal_row {
      padding: 10px 20px;
    }
    .personal_col_inner_padding {
      padding: 20px;
    }
    .personal_menu_item>img {
      width: 40px;
    }
    .personal_col_step {
      width: 40px;
      height: 40px;
      font-size: 15px;
    }

    .main_popular_services_wrap {
      grid-template-columns: 1fr;
    }
    .main_popular_services_top {
      padding: 15px 20px;
    }
    .main_popular_services_top img {
      width: 40px;
    }
    .main_popular_services_content {
      padding: 20px 20px;
    }

    .main_section_title_mb {
      margin-bottom: 20px;
      font-size: 24px;
    }
    .main_review_methods_wrap {
      grid-template-columns: 1fr;
      grid-gap: 20px;
    }
    .main_review_methods_item_title {
      margin-bottom: 15px;
      font-size: 15px;
      text-align: center;
    }
    .main_review_methods_item_img {
      height: 150px;
      margin-bottom: 15px;
    }
    .main_review_methods_item_text {
      max-width: calc(100% - 40px);
      margin: 0 auto;
    }

    .main_review_methods_item_rev {
      display: none;
    }
    .main_review_methods_item_rev_mobile {
      display: block;
      padding: 19px 22px 24px 22px;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%),#FFF;
      font-size: 15px;
    }
    .main_review_methods_item_rev_mobile_top {
      display: flex;
      /* justify-content: space-between; */
      align-items: center;
      margin-bottom: 14px;
    }
    .main_review_methods_item_rev_mobile_top > svg {
      width: 50px;
      height: 50px;
      margin-right: 16px;
    }
    .main_review_methods_item_rev_mobile_top_text {
      font-size: 14px;
      font-weight: 700;
    }

    .main_be_visible_wrap {
      display: block;
    }
    .main_be_visible_content {
      max-width: 100%;
      margin-right: 0px;
    }
    .main_be_visible_content_list {
      margin-bottom: 20px;
      font-size: 24px;
    }
    .main_be_visible_content_list li {
      margin-bottom: 20px;
      font-weight: 400;
    }
    .main_be_visible_content_bottom_text {
      margin-bottom: 20px;
      font-size: 24px;
      text-align: center;
    }
    .main_be_visible_content_bottom_wrap {
      display: block;
      max-width: 300px;
      margin: 0 auto 20px;
    }
    .main_be_visible_content_bottom_wrap>*:first-child {
      margin-bottom: 20px;
    }
    .main_be_visible_result:not(.main_be_visible_result_mobile) {
      display: none;
    }
    .main_be_visible_result_mobile {
      position: relative;
      display: block;
      max-width: 354px;
      margin: 0 auto 20px;
      padding-top: 165px;
    }
    .main_be_visible_result_text {
      margin-bottom: 8px;
      padding: 0px 16px;
    }
    .main_be_visible_result_item {
      max-width: 260px
    }
    .main_be_visible_result_item:first-child {
      position: absolute;
      top: 0px;
      left: 0px;
    }
    .main_be_visible_result_item:last-child {
      position: relative;
      margin-left: auto;
      z-index: 2;
    }
    .main_be_visible_result_item:first-child .main_be_visible_result_text {
      text-align: left;
    }
    .main_be_visible_result_item:last-child .main_be_visible_result_text {
      text-align: right;
    }

    .desc_pc {
      display: none;
    }
    .mobile_pc {
      position: absolute;
      right: 0px;
      bottom: 0px;
      display: block;
      z-index: 1;
    }
    .referal_info {
      max-height: 572px;
      padding: 26px 29px 23px;
    }
    .referal_info_wrap {
      max-width: 100%;
    }
    .referal_info_title {
      margin-bottom: 12px;
      font-size: 24px;
    }
    .referal_info_text {
      margin-bottom: 318px;
      font-size: 15px;
    }
    .referal_info_wrap > *:not(img) {
      position: relative;
      z-index: 2;
    }
    .btn_wide {
      max-width: 100%;
    }

    .main_faq_wrap {
      grid-template-columns: 1fr;
      grid-gap: 20px;
    }
    .main_faq_item_toggle {
      height: 70px;
      padding: 4px 38px 4px 18px;
    }
    .main_faq_item_body {
      padding: 6px 18px;
    }
    .main_faq_item_title {
      font-size: 15px;
    }

    .main_callback_section {
      padding-top: 10px;
    }
    .main_callback_section .referal_info_title {
      margin-bottom: 24px;
    }
    .main_callback_section .referal_info_text {
      height: 144px;
      margin-bottom: 8px;
    }
    .main_callback_section .referal_info_title, .main_callback_section .referal_info_text {
      max-width: 400px;
    }
    .main_callback_section .referal_info > img {
      width: auto;
    }

    .footer_wrap {
      display: flex;
      flex-direction: column;
    }
    .footer_col_2 {
      order: 0;
    }
    .footer_col_3 {
      order: 1;
    }
    .footer_col_4 {
      order: 2;
    }
    .footer_col_1 {
      order: 3;
      text-align: center;
    }
    .footer_logo {
      display: none;
    }
    .footer_logo_mob {
      display: block;
    }
    .footer_col {
      padding-left: 0px;
      text-align: center;
    }
    .footer_logo_mob {
      margin: 0 auto 40px;
    }
    .footer_menu_wrap {
      /* text-align: center; */
    }
    .footer_wrap {
      grid-gap: 10px;
    }
    .footer_need_help {
      max-width: 304px;
      margin: 8px auto 25px;
    }
    .footer_payment_list {
      justify-content: center;
    }
    .footer_siteinfo {
      margin-top: 12px;
    }

    .review_row_flex {
      flex-direction: column;
      align-items: stretch;
    }
    .review_wrap .review_select_list {
      margin-right: 0px;
      margin-bottom: 12px;
    }
    .review_row_flex button {
      width: 100%;
    }
  }
  @media (max-width: 625px) {
    .main_callback_section .referal_info_title {
      max-width: 228px;
    }
    .main_callback_section .referal_info_text {
      max-width: 145px;
    }
  }
  @media (max-width: 550px) {
    .second_advantages_wrap {
      grid-template-columns: 1fr;
      grid-gap: 20px;
    }
    .second_advantages_item:last-child {
      grid-row: 1;
    }
    .second_advantages_item {
      height: 300px;
      padding: 43px 20px 20px;
    }
    .second_advantages_title {
      font-size: 48px;
    }
    .second_advantages_text {
      font-size: 20px;
    }

    .main_banner_img {
      width: 70%;
    }
    
    .referal_info_title, .referal_info_text {
      max-width: 228px;
    }
  }
  @media (max-width: 445px) {
    .referal_info_text {
      margin-bottom: 92%;
    }
  }
  @media (max-width: 424px) {
    .nav_logo {
      width: 65px;
    }
    .main_advantages {
      grid-template-columns: repeat(2, 1fr);
      grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    }
    .main_banner_title, .main_banner_subtitle {
      max-width: 195px;
    }
    .main_banner_content {
      max-width: 80%;
    }
    .main_banner_social_wrap {
      max-width: 270px;
    }
    .main_banner_img {
      width: 100%;
      right: -100px;
    }
    .main_banner_container {
      overflow: hidden;
    }
    .main_be_visible_content_list {
      font-size: 20px;
    }
    .main_be_visible_result_item {
      max-width: 72%;
    }
    .main_be_visible_result_text {
      font-size: 20px;
    }
    
    .second_advantages_item {
      height: 280px;
      padding: 43px 20px;
    }
    .second_advantages_title {
      font-size: 34px;
    }
    .second_advantages_text {
      font-size: 17px;
    }
    .main_callback_section .referal_info>img {
      width: 50%;
    }

  }
  /* end main */

  /* inner page */
  @media (max-width: 1600px) {
    .inner_col {
      height: 500px;
      overflow-y: scroll;
    }

    .inner_col_content_title {
      margin-bottom: 30px;
      font-size: 42px;
    }
    .inner_col_content_nested_services_item {
      padding: 18px;
    }
    .inner_col_content_nested_services_item_img {
      width: 160px;
    }
    .inner_section {
      margin-bottom: 30px;
    }
    .services_section .inner_section_title {
      margin-bottom: 30px;
    }
    
    .services_start_bg {
      width: 100%;
      height: 100%;
      max-height: unset;
    }
    .services_start_container {
      padding: 30px 0px 40px;
    }
    .services_start_item_text {
      margin-bottom: 18px;
      font-size: 18px;
    }
    .inner_col_content_nested_services {
      grid-gap: 15px;
    }
    .services_start_wrap {
      grid-gap: 15px;
      margin-bottom: 24px;
    }

    .reviews_section .inner_section_title {
      margin-bottom: 30px;
    }
    .reviews_wrap {
      grid-gap: 15px;
      max-height: unset;
      margin-bottom: 20px;
      overflow: unset;
    }
    .reviews_text_item {
      padding: 18px;
      font-size: 13px;
    }
    .reviews_text_top {
      margin-bottom: 8px;
    }
    .reviews_text_icon {
      flex-shrink: 0;
      margin-right: 8px;
    }
    .reviews_text_user {
      margin-right: 4px;
    }

    .services_text_content {
      font-size: 18px;
    }

    .inner .main_popular_services_wrap {
      grid-template-columns: repeat(3, 1fr);
    }
    .inner_single_top {
      margin-bottom: 30px;
    }
    .inner_single_top_content {
      padding-top: 25px;
    }
    .inner_single_top_content_title {
      margin-bottom: 25px;
      font-size: 38px;
    }
    .inner_single_top_content_text {
      margin-bottom: 25px;
      font-size: 20px;
    }

    .single_content {}
    .main_popular_services_img img {
      width: 160px;
      margin: 0 auto;
    }
    .single_content_menu {
      margin-bottom: 20px;
    }
    .single_content_menu_item {
      padding: 12px;
    }
    .single_content_menu_item img {
      width: 35px;
    }
    .main_popular_services_price {
      margin-bottom: 18px;
    }
    .single_guaranty .second_advantages_item .second_advantages_title {
      margin-bottom: 12px;
      font-size: 34px;
    }

    .blog_item_name {
      font-size: 18px;
    }
    .blog_item_bottom {
      font-size: 13px;
    }
    .blog_item_bottom img {
      width: 35px;
      margin-right: 10px;
    }
  }
  @media (max-width: 1280px) {
    .contact_wrap .inner_single_top_content_img {
      align-self: flex-start;
    }
    .contact_info_wrap {
      margin-bottom: 30px;
    }
    .contact_row_btn:last-child {
      margin-top: 15px;
    }
    .inner .container,
    .personal_lay .container {
      max-width: 1099px;
    }

    .personal_row_history {
      font-size: 13px;
    }
    .personal_row_history_td {
      height: 50px;
    }
    .personal_col {
      height: fit-content;
    }

    .inner_col_content_nested_services_item_text {
      margin-bottom: 14px;
      font-size: 14px;
    }

    .th_title {
      padding: 15px 10px;
    }
    .tr_link {
      padding: 8px 10px;
    }
    .single_guaranty .second_advantages_item .second_advantages_title {
      margin-bottom: 8px;
      font-size: 20px;
    }
    .single_guaranty .second_advantages_text {
      font-size: 16px;
    }
    .single_guaranty .second_advantages_item {
      height: 265px;
    }

    .inner_col_content_subtitle_top {
      font-size: 30px;
      margin-bottom: 14px;
    }
    .smm_tools_inner_info {
      margin-bottom: 30px;
    }
    .smm_tools_inner_content {
      font-size: 15px;
    }
    .smm_tools_inner_wrap {
      padding: 18px;
    }

    .inner_col {
      height: 450px;
    }

    .inner_col_content_subtitle_bottom {
      font-size: 20px;
      margin-bottom: 30px;
    }

    .single_guaranty .second_advantages_wrap:first-child {
      margin-bottom: 10px;
    }

    .blog_item {
      padding: 10px;
    }
  }
  @media (max-width: 1120px) {
    .inner .container {
      max-width: 850px;
    }

    .reviews_text_item {
      padding: 12px;
    }
    .reviews_text_top {
      flex-direction: column-reverse;
    }
    .reviews_text_date {
      margin-left: auto;
      margin-bottom: 4px;
    }

    .main_faq_wrap {
      grid-template-columns: 1fr;
    } 

    .inner_single_top_content_title {
      margin-bottom: 20px;
      font-size: 24px;
    }
    .inner_single_top_content_text {
      font-size: 15px;
    }
    .inner_single_top_content {
      margin-right: 5px;
    }

    .main_popular_services_price {
      margin-bottom: 12px;
      font-size: 13px;
    }

    .personal_wrap_grid {
      min-height: auto
    }
    .personal_name>svg {
      flex-shrink: 0;
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
    .personal_order_list_td:not(.personal_order_list_status) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .personal_order_list_td {
      padding: 10px 5px;
      font-size: 13px;
    }
    .personal_order_list_status>div {
      font-size: 13px;
    }
  }
  @media (max-width: 1024px) {
    .promo_content {
      padding: 14px;
    }
    .promo_content_title {
      font-size: 18px;
      margin-bottom: 18px;
    }
    .promo_content_text_inner p, .promo_content_text_inner li {
      font-size: 13px;
      margin-bottom: 10px;
    }
    .promo_content_text_inner ul {
      padding-left: 18px;
    }
    .promo_content_info {
      grid-template-columns: repeat(1, 1fr);
    }
    .not_found_title {
      font-size: 35px;
    }
    .review_item iframe {
      width: 100%;
    }
    .services_start_item_text {
      font-size: 16px;
    }
    .inner .container {
      max-width: 850px;
    }
    
    .personal_lay .container {
      max-width: 960px;
    }

    .inner .single_content_menu_item_title {
      font-size: 13px;
    }
    .inner .single_content_menu_item img {
      margin-right: 5px;
    }
    .inner .main_popular_services_top img {
      margin-right: 10px;
    }
    .inner .main_popular_services_img img {
      width: 110px;
    }
    .single_content_wrap .main_popular_services_img {
      margin-bottom: 12px;
    }
    .single_content_wrap .main_popular_services_name {
      margin-bottom: 12px;
    }
    .single_content_wrap .main_popular_services_text {
      margin-bottom: 12px;
    }

    .personal_wrap_grid {
      display: flex;
      flex-direction: column;
      /* grid-template-columns: repeat(1, 1fr); */
    }

  }
  @media (max-width: 768px) {
    .inner {
      padding: 35px 0px;
    }
    .inner_col_content_title {
      font-size: 24px;
    }
    .inner_col_content_nested_services {
      grid-template-columns: repeat(2, 1fr);
    }

    .services_start_wrap {
      grid-template-columns: 1fr;
      grid-gap: 30px;
    }
    .services_start_item_text {
      margin-bottom: 24px;
      font-size: 24px;
    }
    .services_start_item>img {
      margin: 0 auto;
    }

    .reviews_section .inner_section_title {
      margin-bottom: 20px;
    }

    .reviews_wrap {
      grid-template-columns: 1fr;
    }
    
    .services_text_content {
      font-size: 15px;
    }

    .inner_single_top {
      margin-bottom: 20px;
    }
    .service_btn_mob {
      display: flex;
      margin: 0 auto 30px;
    }
    .inner_single_top_content > .btn_link,
    .inner_single_top_content > .btn_wide {
      display: none;
    }

    
    .contact_row_btn {
      display: none;
    }
    .contact_row_btn_mob {
      display: flex;
      justify-content: center;
    }
  }
  @media (max-width: 625px) {
    .inner_col_content_nested_services {
      grid-template-columns: repeat(1, 1fr);
    }
    .inner .single_content_menu {
      grid-template-columns: repeat(1, 1fr);
    }
    .inner .single_content_wrap {
      grid-template-columns: repeat(2, 1fr);
    }

    .single_guaranty .second_advantages_wrap,
    .single_guaranty .second_advantages_wrap:first-child {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 15px;
    }
    .single_guaranty .second_advantages_wrap:first-child {
      margin-bottom: 15px;
    }

    .main_faq_item_title {
      font-size: 13px;
    }

    .personal_order_list_td {
      font-size: 10px;
    }
    .personal_order_list_status>div {
      width: 74px;
      font-size: 10px;
      white-space: nowrap;
      /* font-weight: normal; */
    }
  }

  @media (max-width: 550px) {
    .inner .single_content_wrap {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  @media (max-width: 425px) {
    
  }
  /* end inner page */

  /* end MEDIA */

  .order_wrap .btn_link {
    display: flex;
    justify-content: center;
  }

  .review_login_wrap {
    padding: 20px 22px;
    color: #5F5F5F;
    font-size: 15px;
    font-weight: 400;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%),#FFF;
    border-radius: 10px;
    border: 1px solid #BFBFBF;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
  }
  .review_login_wrap_text {
    margin-bottom: 12px;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
  }
  .review_login_wrap .btn {
    margin: 0 auto;
  }

  @media (max-width: 1120px) {
    .mobile_scroll {
      height: auto;
    }
    .mobile_scroll .personal_col {
      min-height: 360px;
    }
  }

  .inner_single_breadcrumbs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 18px;
  }
  .inner_single_breadcrumbs_arr {
    padding: 0px 4px;
  }
  .inner_single_breadcrumbs_item {
    display: flex;
    align-items: center;
  }
  .inner_single_breadcrumbs_item a {
    text-decoration: underline;
    color: #4083FD;
    transition: .3s;
  }.inner_single_breadcrumbs_item a:hover {
    opacity: .6;
  }
  .inner_single_breadcrumbs_item_last {
    font-weight: 500;
  }

  .nav_item_wrap {
    display: flex;
    align-items: center;
  }
  .nav_item_wrap .parent_arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 18px;
    padding-bottom: 1px;
    transition: .3s;
    transform: rotate(180deg);
  }
  .nav_item_has_child {
    position: relative;
  }
  .nav_item_child {
    display: none;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);

    width: 100%;

    padding: 6px 10px;

    border-radius: 6px;
    border: 1px solid #BFBFBF;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%), rgba(255, 255, 255, 0.10);
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(25px);

    z-index: 2;


    min-width: 100%;
    width: fit-content;
  }

  .nav_item_child > svg {
    position: absolute;
    top: -7px;
    left: 50%;
    transform: translateX(-50%);
  }
  .nav_item_child > svg path {
    fill: #BFBFBF;
  }
  .nav_item_child a {
    margin-bottom: 6px;
  }
  .nav_item_child a:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 769px) {
    .nav_item_child {
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }

    .nav_item_has_child:hover .nav_item_child {
      display: block;
      opacity: 1;
      z-index: 100;
    }
    .nav_item_has_child:hover .parent_arrow {
      transform: rotate(0deg);
    }
  }

  @media (max-width: 768px) {
    .nav_item_wrap > a {
      flex: 1;
    }
    .nav_item_wrap .parent_arrow {
      width: 30px;
    }
    .nav_item_child {
      position: relative;
      top: unset;
      left: unset;
      transform: unset;
      margin-top: 6px;
      opacity: 1;
    }
    .nav_item_child > svg {
      display: none;
    }

    .nav_item_has_child.active .parent_arrow {
      transform: rotate(0deg);
    }
    .nav_item_has_child.active .nav_item_child {
      display: block; 
    }
  }

  .pagen {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .pagen_item {
    width: 52px;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #4083FD;
    border-radius: 10px;
    color: #4083FD;
    background-color: #f6f9ff;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    transition: .2s;
  }
  .pagen_item_prev,
  .pagen_item_next {
    font-size: 36px;
    font-weight: 300;
  }
  .pagen_item_first,
  .pagen_item_last {
    font-size: 28px;
    font-weight: 300;
  }
  .pagen_item:not(.active_pagen):hover {
    background-color: #FFF;
  }
  .pagen_item.active_pagen {
    color: #FFF;
    background-color: #4083FD;
    pointer-events: none;
  }

  .loading_elements {
    transition: .3s;
    opacity: .4;
    pointer-events: none;
  }
  .pagen_disabled {
    opacity: .4;
    pointer-events: none;
  }

`;
