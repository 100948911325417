import { fetchApi } from './api';

interface User {
  login: string;
  email?: string;
  name?: string;
  password: string;
}

interface Roles {
  admin?: boolean;
  registred?: boolean;
  moderator?: boolean;
}

interface AuthData {
  data: {
    token: string;
    // Другие свойства, если они есть
  };
}

interface NewUserResponse {
  _id: string;
  login: string;
  email: string;
  role: any;
}

interface ForgotResponse {
  status: boolean;
  message: string;
}

export class Authentification {
  private userId: string;
  private token: string;
  private name: string;
  private role: Roles;
  private email: string;
  private static instance: Authentification;

  private constructor() {
    const token = localStorage.getItem('token');
    const name = localStorage.getItem('name');
    const role = localStorage.getItem('role');
    const id = localStorage.getItem('userId');
    const email = localStorage.getItem('email');
    this.token = token ? token : '';
    this.name = name ? name : '';
    this.role = role ? JSON.parse(role) : {};
    this.userId = id ? id : '';
    this.email = email ? email : '';
  }

  public static getInstance(): Authentification {
    if (!Authentification.instance) {
      Authentification.instance = new Authentification();
    }
    return Authentification.instance;
  }

  public async registration(user: User) {
    let newUser: NewUserResponse = {} as NewUserResponse;
    if (user.login && user.email && user.password) {
      newUser = await fetchApi('POST', 'registration', { login: user.login ? user.login : user.email, email: user.email, password: user.password });
      console.log(newUser)
    } else {
      return Promise.reject(new Error('User login, email and password required!'));
    }
    return newUser;
  }

  public async forgot(user: {email: string}) {
    let forgot: ForgotResponse = {} as ForgotResponse;
    if (user.email) {
      forgot = await fetchApi('POST', 'forgot', { email: user.email });
      console.log(forgot)
    } else {
      return Promise.reject(new Error('User email required!'));
    }
    return forgot;
  }

  public async login(userData: User) {
    if (!userData.login || !userData.password) {
      return Promise.reject(new Error('User login and password is required!'));
    }
    let authData = await fetchApi('POST', 'login', { login: userData.login, password: userData.password });
    console.log('authdata');
    console.log(authData);
    if (authData) {
      // const userData = JSON.parse(authData);
      localStorage.setItem('token', authData.token);
      localStorage.setItem('name', authData.login);
      localStorage.setItem('role', JSON.stringify(authData.role));
      localStorage.setItem('userId', authData._id);
      localStorage.setItem('email', authData.email);
      this.token = authData.token;
      this.name = authData.login;
      this.token = authData.role;
      this.userId = authData.userId;
      return true;
    } else {
      return false;
    }
  }

  public async checkToken() {
    let answer = await fetchApi('POST', 'checkAuthorization', { userId: this.userId, token: this.token });
    if (answer && typeof answer === 'object' && answer.status) {
      if (answer.newToken) {
        localStorage.setItem('token', answer.newToken);
        this.token = answer.newToken;
      }
      return true
    } else {
      this.logout();
      return false;
    }
  }

  public getToken() {
    const localToken = localStorage.getItem('token');
    if (this.token == '' || (this.token !== localToken && localToken)) {
      if (localToken) {
        this.token = localToken;
        return this.token;
      } else {
        return false;
      }
    } else return this.token;
  }

  public setToken(token: string) {
    this.token = token;
    localStorage.setItem('token', token);
  }

  public getLogin() {
    if (this.name == '') return false;
    else return this.name;
  }

  public getUserId() {
    const localUserId = localStorage.getItem('userId');
    if (this.userId == '' || (this.userId !== localUserId && localUserId)) {
      if (localUserId) {
        this.userId = localUserId;
        return this.userId;
      } else {
        return false;
      }
    } else return this.userId;
  }

  public getUserEmail() {
    const localUserEmail = localStorage.getItem('email');
    if (this.email == '' || (this.email !== localUserEmail && localUserEmail)) {
      if (localUserEmail) {
        this.userId = localUserEmail;
        return this.email;
      } else {
        return false;
      }
    } else return this.email;
  }

  public isAdmin() {
    return this.role.admin ? true : false;
  }

  public logout() {
    this.token = '';
    this.name = '';
    this.role = {};
    this.userId = '';
    this.email = '';
    localStorage.removeItem('token');
    localStorage.removeItem('name');
    localStorage.removeItem('role');
    localStorage.removeItem('userId');
    localStorage.removeItem('email');
    return true;
  }
}
