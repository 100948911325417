import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import BtnOutline from '../ui-kit/BtnOutline';
import ServicesVideoReviewsWrap from './ServicesVideoReviewsWrap';
import ServicesTextReviewsWrap from './ServicesTextReviewsWrap';
import MainFaq from '../main_page/MainFaq';
import ServicesText from './ServicesText';
import ServicesSocList from './ServicesSocList';
import { Helmet } from 'react-helmet-async';
import InnerBreadcrumbs from '../ui-kit/InnerBreadcrumbs';

// Определение типа для объекта страницы
type PageType = {
  h1: string;
  title: string;
  updated: number;
  description: string;
  breadcrumbs: string;
  text: string;
  seoUrl: string;
  showOnMain: boolean;
  excerpt: string;
  sort: number;
  banner: {
    text: string;
    image: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
  icon: {
    big: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
    small: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
};

// Определение общего типа для элементов в массиве socialDataState
type SocialDataItem = {
  _id: string;
  name: string;
  customName: string;
  __v: number;
  active: boolean;
  activeApi: boolean;
  category: string;
  page: PageType;
  parentId: string;
  rate: string;
  service: string;
  socialMedia: string;
  type: string;
};

// Определение общего типа для элементов в массиве socialDataState
type SocialReviewItem = {
  _id: string;
  parentId: string;
  name: string;
  type: string;
  active: boolean;
  isNew: boolean;
  updated: number;
  date: number;
  text: string;
};

// Определение общего типа для элементов в массиве socialDataState
type SocialFaqItem = {
  _id: string;
  parentId: string;
  name: string;
  type: string;
  active: boolean;
  updated: number;
  text: string;
};

// Определение типа для Path
type pathItem = {
  _id: string;
  path: string;
  seo_url: string;
};

// Определение типа для пропсов компонента AdminBlog
type ServicesProps = {
  socialDataState: SocialDataItem[];
  socialReviewState: SocialReviewItem[];
  faqDataState: SocialFaqItem[];
  pathDataState: pathItem[];
  typeString?: string;
};

const Services = ({ pathDataState, socialDataState, socialReviewState, faqDataState }: ServicesProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const bgRef = useRef<HTMLDivElement | null>(null);

  const [socialDataStateArr, setSocialDataStateArr] = useState<any[]>([]);
  const [customSocialArr, setCustomSocialArr] = useState<any[]>([]);

  useEffect(() => {
    const handleResize = () => {
      const containerHeight = containerRef.current?.offsetHeight;
      if (containerHeight !== undefined) {
        bgRef.current!.style.height = `${containerHeight}px`;
      }
    };

    // Вызывать функцию при монтировании компонента
    handleResize();

    // Добавлять слушатель события изменения размеров окна
    window.addEventListener('resize', handleResize);

    // Убирать слушатель события при размонтировании компонента
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Пустой массив зависимостей означает, что эффект будет запущен только при монтировании и размонтировании компонента

  useEffect(() => {
    if (socialDataState) {
      const filteredArray = socialDataState.filter((item) => item.type === 'root_category');
      console.log(filteredArray);
      const sortedArray = filteredArray.slice().sort((a, b) => {return a.page?.sort == b.page?.sort ? a.socialMedia.localeCompare(b.socialMedia) : (a.page.sort?a.page.sort:100) - (b.page.sort?b.page.sort:100)});
      setCustomSocialArr(sortedArray);
    }
  }, [socialDataState]);

  return (
    <>
      <Helmet>
        <title>{'Услуги'}</title>
        <meta name="description" content={'Услуги'} />
        {/* Другие мета-теги */}
      </Helmet>
      <InnerBreadcrumbs chapterLink={'/services'} chapterString={'Услуги'} />
      <div className="inner_col_content_title">{`Услуги`}</div>
      <div className="inner_col_content_nested">
        <div className="inner_col_content_nested_services inner_section" id="startService">
          <ServicesSocList servicesSocList={customSocialArr} />
        </div>
        <div className="inner_section services_section">
          <div className="main_section_title inner_section_title">Как начать продвижение?</div>
          <div className="services_start_bg" ref={bgRef}></div>
          <div className="services_start_container" ref={containerRef}>
            <div className="services_start_wrap">
              <div className="services_start_item">
                <div className="services_start_item_text registration"><NavLink to={'/register'}>Зарегистрируйтесь </NavLink></div>
                <img src="/content/services/start_01.png" alt="" />
              </div>
              <div className="services_start_item">
                <div className="services_start_item_text">Пополните баланс</div>
                <img src="/content/services/start_02.png" alt="" />
              </div>
              <div className="services_start_item">
                <div className="services_start_item_text">Оформите задачу</div>
                <img src="/content/services/start_03.png" alt="" />
              </div>
            </div>
            <a href="#startService" className="start_services_link">
              Начать раскрутку
            </a>
          </div>
        </div>

        <div className="inner_section reviews_section">
          <div className="main_section_title inner_section_title">Отзывы</div>
          <ServicesVideoReviewsWrap showAll={false} />
          <ServicesTextReviewsWrap showAll={false} socialDataState={socialDataState} socialAllServices={socialReviewState} typeString={'review'} />
        </div>
        <ServicesText />
        <div className="inner_section inner_faq_section">
          <MainFaq socialDataState={faqDataState} typeString={'faq'} />
        </div>
      </div>
    </>
  );
};

export default Services;
