// Layout.tsx
import React, { ReactNode, createContext, useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import HeaderMobile from '../content/components/template_parts/header_parts/HeaderMobile';
import Header from '../content/components/template_parts/header_parts/Header';
import Footer from '../content/components/template_parts/footer_parts/Footer';
import GlobalStyles from '../styles/global';
import AdminStyles from '../styles/admin';

export const ScrollContext = createContext<{
  scrollToMainQuickOrder: () => void;
  mainQuickOrderRef: React.MutableRefObject<HTMLDivElement | null>;
}>({
  scrollToMainQuickOrder: () => {},
  mainQuickOrderRef: { current: null }
});

// Передаю менюхи
type Items = {
  name: string;
  link: string;
  icon: {
    parentId?: number;
    id: string;
    filename: string;
    filetype: string;
    datetime: string;
    filesize: string;
    filepath: string;
  };
  index: string;
};

// Определение общего типа для элементов в массиве socialDataState
type HeaderProps = {
  _id: string;
  name: string;
  type: string;
  subtype: string;
  items: Items[];
};

type LayoutProps = {
  isMobile: boolean;
  children: ReactNode;
  isUserLogin?: boolean;
  userName?: string;
  mainMenu?: object;
  menuDataState: HeaderProps[];
};

const Layout = ({ children, isMobile, isUserLogin, userName, mainMenu, menuDataState }: LayoutProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  // Проверяем, если пользователь в административной части
  const isAdmin = location.pathname.startsWith('/admin');

  const [mainMenuState, setMainMenuState] = useState<any>(null);
  const [footerOneMenuState, setFooterOneMenuState] = useState<any>(null);
  const [footerTwoMenuState, setFooterTwoMenuState] = useState<any>(null);

  useEffect(() => {
    if (menuDataState) {
      // Заполняю главное меню
      const foundMainMenu = menuDataState.find((item) => item.subtype === 'main_menu');
      if (foundMainMenu) {
        const sortedArr = foundMainMenu.items.sort((a, b) => parseInt(a.index) - parseInt(b.index));
        setMainMenuState(sortedArr);
      }

      // Заполняю футер меню
      const foundFooterOneMenu = menuDataState.find((item) => item.subtype === 'footer_menu_1');
      const foundFooterTwoMenu = menuDataState.find((item) => item.subtype === 'footer_menu_2');
      if (foundFooterOneMenu && foundFooterTwoMenu) {
        setFooterOneMenuState(foundFooterOneMenu.items.sort((a, b) => parseInt(a.index) - parseInt(b.index)));
        setFooterTwoMenuState(foundFooterTwoMenu.items.sort((a, b) => parseInt(a.index) - parseInt(b.index)));
      }
    }
  }, [menuDataState]);

  const mainQuickOrderRef = useRef<HTMLDivElement>(null);

  const scrollToMainQuickOrder = () => {
    // Перенаправляем пользователя на главную страницу
    navigate('/');
    // Выполняем скролл после перенаправления
    setTimeout(() => {
      if (mainQuickOrderRef.current) {
        mainQuickOrderRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 500); // Задержка перед скроллом (время перехода на главную страницу)
  };

  return (
    <>
      <ScrollContext.Provider value={{ scrollToMainQuickOrder, mainQuickOrderRef }}>
        {!isAdmin && (isMobile ? <HeaderMobile mainMenuState={mainMenuState} menuDataState={menuDataState} /> : <Header mainMenuState={mainMenuState} />)}
        <div className={isAdmin ? 'admin_content' : 'page_content'}>{children}</div>
        {!isAdmin && <Footer footerOneMenuState={footerOneMenuState} footerTwoMenuState={footerTwoMenuState} />}
        {isAdmin && <AdminStyles />}
        {!isAdmin && <GlobalStyles />}
      </ScrollContext.Provider>
    </>
  );
};

export default Layout;
