import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

type SubItems = {
  name: string;
  link: string;
  index: string;
};

type MenuLinkProps = {
  menuLink: string;
  menuText: string;
  className?: string;
  subItems?: SubItems[];
  newWindow?: boolean;
  linkClicked?: () => void;
};

const MenuLink = ({ menuLink, menuText, className, subItems, newWindow, linkClicked }: MenuLinkProps) => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const childRef = useRef<HTMLDivElement | null>(null);

  const toggleActive = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    if (childRef.current) {
      // if (isActive) {
      //   childRef.current.style.maxHeight = `${childRef.current.scrollHeight + 8}px`;
      // } else {
      //   childRef.current.style.maxHeight = '0px';
      // }
    }
  }, [isActive]);

  return (
    <>
      <div className={`${className ? className : ''} ${subItems && subItems.length > 0 ? 'nav_item_has_child' : ''} ${isActive ? 'active' : ''}`}>
        <div className="nav_item_wrap">
          <NavLink to={`${menuLink}`} target={newWindow ? "_blank" : undefined} onClick={linkClicked}>{menuText}</NavLink>
          {subItems && subItems.length > 0 && (
            <span className="parent_arrow" onClick={toggleActive}>
              <svg xmlns="http://www.w3.org/2000/svg" width="8" height="7" viewBox="0 0 8 7" fill="none">
                <path
                  d="M3.15882 1.3085C3.55246 0.696177 4.44754 0.696177 4.84118 1.3085L7.50951 5.45924C7.93734 6.12475 7.4595 7 6.66833 7H1.33167C0.5405 7 0.0626581 6.12475 0.490487 5.45924L3.15882 1.3085Z"
                  fill="#84B1DE"
                />
              </svg>
            </span>
          )}
        </div>
        {subItems && subItems.length > 0 && (
          <div className="nav_item_child" ref={childRef}>
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
              <path d="M3.37253 1.27845C4.17017 0.161756 5.82982 0.161756 6.62747 1.27845L10 6H0L3.37253 1.27845Z" fill="white" />
            </svg>
            {subItems &&
              subItems.map((item: any, index: number) => (
                <NavLink className={`nav_item_child_link`} key={index} to={`${item.link}`} onClick={linkClicked}>
                  {item.name}
                </NavLink>
              ))}
          </div>
        )}
      </div>
    </>
  );
};

export default MenuLink;
