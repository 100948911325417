import React, { useContext, useEffect, useState } from 'react';
import HeaderMenu from './HeaderMenu';
import Logo from './Logo';
import MenuBtnAction from './MenuBtnAction';
import { useNavigate } from 'react-router-dom';
import BtnOutline from '../../ui-kit/BtnOutline';
import HeaderMenuMobile from './HeaderMenuMobile';
import { ScrollContext } from '../../../../Routes/Layout';

const logoSrc = '/content/img/nav/logo.png';
const siteName = 'MediaBoost'; // тут можно под сео сделать типа 'title для главной' из админки

// Определение типа для объекта страницы
type Items = {
  name: string;
  link: string;
  icon: {
    parentId?: number;
    id: string;
    filename: string;
    filetype: string;
    datetime: string;
    filesize: string;
    filepath: string;
  };
  index: string;
};

// Определение общего типа для элементов в массиве socialDataState
type MenuProps = {
  _id: string;
  name: string;
  type: string;
  subtype: string;
  items: Items[];
};

// Определение общего типа для элементов в массиве socialDataState
type HeaderProps = {
  _id: string;
  name: string;
  type: string;
  subtype: string;
  items: Items[];
};

type HeaderMobileProps = {
  isUserLogin?: boolean;
  userName?: string;
  menuDataState: HeaderProps[];
  mainMenuState: MenuProps[];
};

const HeaderMobile = ({ mainMenuState, menuDataState, isUserLogin, userName }: HeaderMobileProps) => {
  const navigate = useNavigate();

  const btnNavigate = (link: string) => () => {
    navigate(link);
  };

  const [isActiveMobileMenu, setIsActiveMobileMenu] = useState(false);

  const handleToggleMobileClick = () => {
    setIsActiveMobileMenu(!isActiveMobileMenu);
  };

  useEffect(() => {
    if (isActiveMobileMenu) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isActiveMobileMenu]);

  const { scrollToMainQuickOrder, mainQuickOrderRef } = useContext(ScrollContext); // Получаем функцию и ref из контекста

  return (
    <>
      <div className="nav_wrap">
        <div className="container">
          <div className="nav">
            <Logo imgSrc={logoSrc} siteName={siteName} className="nav_logo" />
            <BtnOutline className="btn_outline nav_fast_order" onClick={scrollToMainQuickOrder} btnText="Быстрый заказ" />
            <div className={`nav_toggle ${isActiveMobileMenu ? 'active' : ''}`} onClick={handleToggleMobileClick}>
              <span></span>
            </div>
          </div>
        </div>
      </div>
      <HeaderMenuMobile mainMenuState={mainMenuState} menuDataState={menuDataState} isActiveMobileMenu={isActiveMobileMenu} setIsActiveMobileMenu={setIsActiveMobileMenu} />
    </>
  );
};

export default HeaderMobile;
