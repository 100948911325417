import React, { FormEvent, useEffect, useState } from 'react';
import BtnBase from '../ui-kit/btn/BtnBase';
import InputBase from '../ui-kit/input/InputBase';
import BtnOutline from '../ui-kit/BtnOutline';
import PersonalRegisterForm from '../ui-kit/form/PersonalRegisterForm';
import { Authentification } from '../helpers/auth';
import { useNavigate } from 'react-router-dom';
import { fetchApi } from '../helpers/api';

type Props = {
  isLogged: boolean;
};

const PersonalRegister = (props: Props) => {
  const [useremail, setUseremail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const [emailError, setEmailError] = useState<string>('');
  const [passError, setPassError] = useState<string>('');

  const navigate = useNavigate();

  if (props.isLogged) {
    navigate('/personal');
  }

  useEffect(()=>{
    const registration_form = document.getElementById('root');
    registration_form?.scrollIntoView({ behavior: 'smooth' });
  },[])

  const handeSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (password.length > 0) {
      if (password === repeatPassword) {
        setPassError('');
      } else {
        setPassError('Пароли не совпадают');
      }
    } else {
      setPassError('Введите пароль');
    }
    if (!emailPattern.test(useremail)) {
      setEmailError('Введите корректный email');
    } else {
      setEmailError('');
      const isEmailFree = await fetchApi('POST', 'users/check_email', { email: useremail });
      if (isEmailFree.message && isEmailFree.message === 'User not found') {
        setEmailError('');
        if (password.length > 0) {
          if (password === repeatPassword) {
            const user = await Authentification.getInstance().registration({ login: username, email: useremail, password: password });
            console.log(user)
            if (user._id) {
              alert('Вы успешно зарегистрированы! Войдите, используя свои логин и пароль');
              navigate('/login');
            } else {
              alert('Что-то пошло не так, попробуйте позже');
            }
            //todo: Показать пользователю, что он зарегестрирован, отправить на форму логина (опционально - на почту за подтверждением, после чего на форму логина)
          } else {
            setPassError('Пароли не совпадают');
          }
        } else {
          setPassError('Введите пароль');
        }
      } else {
        setEmailError('Введённый Email уже занят');
      }
    }
  };
  return (
    <div className="personal">
      <div className="container">
        <div className="personal_wrap">
          <div className="personal_title">Регистрация</div>
          <div className="personal_login personal_form">
            <PersonalRegisterForm onSubmit={handeSubmit} classMame="personal_login_form" id="registration_form">
              <div className="personal_input_row">
                <InputBase
                  type={'text'}
                  name={'username'}
                  value={username}
                  placeholder={'Введите логин'}
                  className=""
                  inputClassName="base_input_wide"
                  label={'Логин'}
                  autocomplete={'off'}
                  onChange={(event) => setUsername(event.target.value)}
                />
              </div>
              <div className="personal_input_row">
                <InputBase
                  type={'text'}
                  name={'useremail'}
                  value={useremail}
                  placeholder={'Введите данные'}
                  className=""
                  inputClassName={`base_input_wide ${emailError.length > 0 && 'error'}`}
                  label={'Email'}
                  onChange={(event) => {
                    setUseremail(event.target.value);
                    setEmailError('');
                  }}
                />
                {emailError.length > 0 && <div className="base_input_wide_error">{emailError}</div>}
              </div>
              <div className="personal_input_row">
                <InputBase
                  label={'Пароль'}
                  name={'password'}
                  type={'password'}
                  placeholder={'Введите пароль'}
                  className=""
                  inputClassName={`base_input_wide ${passError.length > 0 && 'error'}`}
                  autocomplete={'off'}
                  onChange={(event) => {
                    setPassword(event.target.value);
                    setPassError('');
                  }}
                />
              </div>
              <div className="personal_input_row">
                <InputBase
                  label={'Подтвердите пароль'}
                  name={'password'}
                  type={'password'}
                  placeholder={'Подтвердите пароль'}
                  className=""
                  inputClassName={`base_input_wide ${passError.length > 0 && 'error'}`}
                  autocomplete={'off'}
                  onChange={(event) => {
                    setRepeatPassword(event.target.value);
                    setPassError('');
                  }}
                />
                {passError.length > 0 && <div className="base_input_wide_error">{passError}</div>}
              </div>
              {/* <div className="personal_input_row">
                <InputBase name={'captcha'} type={'text'} placeholder={'КАПТЧА ТЕСТ'} className="" inputClassName="base_input_wide" />
              </div> */}
              <div className="personal_input_row">
                <div className="form_text_row_register">
                  Регистрируясь и пользуясь сайтом в дальнейшем, вы соглашаетесь с{' '}
                  <a href="/agreement_mediabooster_ru.pdf" className="label_box_row_link" target="_blank">
                    условиями лицензионного соглашения (оферты)
                  </a>
                </div>
              </div>
              <BtnOutline className="btn_wide_full login_btn" btnText="Регистрация" type={'submit'} />
              <div className="form_text_row">
                <div className="form_text_row_text">Есть аккаунт?</div>
                <a href="/login" className="label_box_row_link">
                  Войти
                </a>
              </div>
            </PersonalRegisterForm>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalRegister;
