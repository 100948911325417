import React, { FormEvent, ReactNode } from 'react';

type SendReviewForm = {
  classMame?: string;
  children?: ReactNode;
  onSubmit?: (event: FormEvent<HTMLFormElement>) => void;
};
type Props = {};

const SendReviewForm = ({ children, onSubmit, classMame }: SendReviewForm) => {
  return (
    <form onSubmit={onSubmit} className={`personal_form ${classMame}`}>
      {children}
    </form>
  );
};

export default SendReviewForm;
