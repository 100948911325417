import React, { FormEvent, useState } from 'react';
import InputBase from '../ui-kit/input/InputBase';
import BtnOutline from '../ui-kit/BtnOutline';
import PersonalRegisterForm from '../ui-kit/form/PersonalRegisterForm';
import { useNavigate } from 'react-router-dom';
import { fetchApi } from '../helpers/api';
import { Authentification } from '../helpers/auth';

type Props = {
  isLogged: boolean;
};

const PersonalForgot = (props: Props) => {
  const [useremail, setUseremail] = useState('');

  const [emailError, setEmailError] = useState<string>('');

  const navigate = useNavigate();

  if (Authentification.getInstance().getToken()) {
    navigate('/personal');
  }

  const handeSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(useremail)) {
      setEmailError('Введите корректный email');
    } else {
      setEmailError('');
      const isEmailFree = await fetchApi('POST', 'users/check_email', { email: useremail });
      if (!isEmailFree.message || isEmailFree.message !== 'User not found') {
        setEmailError('');
        const forgot = await Authentification.getInstance().forgot({ email: useremail });
        if (forgot.status) {
          alert('Новый пароль отправлен вам на почту');
          navigate('/login');
        } else {
          if (forgot.message) {
            alert(forgot.message)
          } else {
            alert('Что-то пошло не так, попробуйте позже');
          }
        }
      } else {
        setEmailError('Проверьте правильность ввода Email');
      }
    }
  };
  return (
    <div className="personal">
      <div className="container">
        <div className="personal_wrap">
          <div className="personal_title">Восстановление пароля</div>
          <div className="personal_login personal_form">
            <PersonalRegisterForm onSubmit={handeSubmit} classMame="personal_login_form">
              <div className="personal_input_row">
                <InputBase
                  type={'text'}
                  name={'useremail'}
                  value={useremail}
                  placeholder={'Введите данные'}
                  className=""
                  inputClassName={`base_input_wide ${emailError.length > 0 && 'error'}`}
                  label={'Email'}
                  onChange={(event) => {
                    setUseremail(event.target.value);
                    setEmailError('');
                  }}
                />
                {emailError.length > 0 && <div className="base_input_wide_error">{emailError}</div>}
              </div>
              {/* <div className="personal_input_row">
                <InputBase name={'captcha'} type={'text'} placeholder={'КАПТЧА ТЕСТ'} className="" inputClassName="base_input_wide" />
              </div> */}
              <BtnOutline className="btn_wide_full login_btn" btnText="Отправить" type={'submit'} />
              <div className="form_text_row">
                <div className="form_text_row_text">Есть аккаунт?</div>
                <a href="/login" className="label_box_row_link">
                  Войти
                </a>
              </div>
            </PersonalRegisterForm>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalForgot;
