import React from 'react';
import { NavLink } from 'react-router-dom';
import BtnOutline from '../ui-kit/BtnOutline';

// Определение типа для объекта страницы
type PageType = {
  h1: string;
  title: string;
  updated: number;
  description: string;
  breadcrumbs: string;
  text: string;
  seoUrl: string;
  showOnMain: boolean;
  excerpt: string;
  banner: {
    text: string;
    image: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
  icon: {
    big: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
    small: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
};

// Определение общего типа для элементов в массиве socialDataState
type SocialDataItem = {
  _id: string;
  name: string;
  customName: string;
  __v: number;
  active: boolean;
  activeApi: boolean;
  category: string;
  page: PageType;
  parentId: string;
  rate: string;
  service: string;
  socialMedia: string;
  type: string;
};

// Определение типа для пропсов компонента AdminBlog
type ServicesItemsListProps = {
  menuList: SocialDataItem[];
  typeString?: string;
};
const ServicesItemsList = ({ menuList }: ServicesItemsListProps) => {
  return (
    menuList && (
      <>
        {menuList.map((item: any, index: number) => (
          <React.Fragment key={item._id}>
            {item.menu_link || item.page.seoUrl ? (
              <NavLink to={item.menu_link || `/${item.page.seoUrl}`} className={'main_popular_services_item'}>
                <div className="main_popular_services_top">
                  <img src={item.page.icon.small.filepath ? item.page.icon.small.filepath : '/content/not/icon_small.svg'} alt={item.menu_name} width="46" height="46" />
                  <div className="main_popular_services_title">{item.category}</div>
                </div>
                <div className="main_popular_services_content">
                  <div className="main_popular_services_name">{item.page.h1 ? item.page.h1 : item.customName ? item.customName : item.name}</div>
                  <div className="main_popular_services_content_wrap">
                    {(item.page.excerpt && item.page.excerpt.length) > 0 && <div className="main_popular_services_text">{item.page.excerpt}</div>}
                    {/* <div className="main_popular_services_img">
                          <img src={item.page.icon.big.filepath ? item.page.icon.big.filepath : '/content/not/icon_red.png'} alt={item.page.h1 ? item.page.h1 : item.name} />
                        </div> */}
                    <div className="main_popular_services_price">
                      {item.rate / 1000} RUB (от {item.min} до {item.max})
                    </div>
                    <BtnOutline className="btn_wide_full inner_col_content_nested_services_item_btn" btnText="Оплатить" />
                  </div>
                </div>
              </NavLink>
            ) : (
              <div className={'main_popular_services_item'}>
                <div className="main_popular_services_top">
                  <img src={item.page.icon.small.filepath ? item.page.icon.small.filepath : '/content/not/icon_small.svg'} alt={item.page.h1 ? item.page.h1 : item.customName ? item.customName : item.name} width="46" height="46" />
                  <div className="main_popular_services_title">{item.category}</div>
                </div>
                <div className="main_popular_services_content">
                  <div className="main_popular_services_name">{item.page.h1 ? item.page.h1 : item.customName ? item.customName : item.name}</div>
                  <div className="main_popular_services_content_wrap">
                    <div className="main_popular_services_img">
                      <img src={item.page.icon.big.filepath ? item.page.icon.big.filepath : '/content/not/icon_red.png'} alt={item.page.h1 ? item.page.h1 : item.customName ? item.customName : item.name} />
                    </div>
                    <div className="main_popular_services_price">
                      {item.rate / 1000} RUB (от {item.min} до {item.max})
                    </div>
                    {(item.excerpt && item.excerpt.length) > 0 && <div className="main_popular_services_text">{item.excerpt}</div>}
                    <BtnOutline className="btn_wide_full inner_col_content_nested_services_item_btn" btnText="Оплатить" />
                  </div>
                </div>
              </div>
            )}
          </React.Fragment>
        ))}
      </>
    )
  );
};

export default ServicesItemsList;
