import React, { FormEvent, useEffect, useState } from 'react';
import Switch from '../admin-ui-kit/input/Switch';
import InnerForm from '../admin-ui-kit/form/InnerForm';
import InputBase from '../admin-ui-kit/input/InputBase';
import InputLabelFor from '../admin-ui-kit/input/InputLabelFor';
import TextareaLabelFor from '../admin-ui-kit/input/TextareaLabelFor';
import BtnBase from '../admin-ui-kit/btn/BtnBase';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';

// Определение типа для объекта страницы

// Определение общего типа для элементов в массиве socialDataState
type SocialDataItem = {
  _id: string;
  parentId: string;
  name: string;
  type: string;
  active: boolean;
  updated: number;
  text: string;
};

// Определение типа для пропсов компонента AdminSocial
type AdminFaqItemProps = {
  socialDataState: SocialDataItem[];
  typeString: string;
};

const AdminFaqItem = ({ socialDataState, typeString }: AdminFaqItemProps) => {
  const { _id } = useParams();

  const [socialTypeString, setSocialTypeString] = useState(typeString);

  const [socialDataStateArr, setSocialDataStateArr] = useState<any[]>([]);
  const [socialItem, setSocialItem] = useState<any>(null);

  const navigate = useNavigate();

  useEffect(() => {
    // Имитация асинхронной загрузки данных (если это нужно)
    setTimeout(() => {
      setSocialDataStateArr(socialDataState);
      // Проверяем существование _id в вашем JSON
      const socialItem = socialDataState.find((item) => item._id === _id);
      if (!socialItem) {
        navigate('/404');
      } else {
        const [extractedItem] = [socialItem];
        setSocialItem(extractedItem);
      }
    }, 500);
  }, [socialDataState]);

  const [itemName, setItemName] = useState<any>(null);
  const [itemActive, setItemActive] = useState<any>(null);
  const [itemUpdated, setItemUpdated] = useState<any>(null);
  const [itemText, setItemText] = useState<any>(null);

  useEffect(() => {
    if (socialItem) {
      setItemName(socialItem.name);
      setItemActive(socialItem.active);
      setItemUpdated(socialItem.updated);
      setItemText(socialItem.text);
    }
  }, [socialItem]);

  const [savedItemArr, setSavedItemArr] = useState<any>(null);

  const handeSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setSavedItemArr({
      _id: socialItem._id,
      parentId: socialItem.parentId,
      name: itemName,
      type: socialItem.type,
      active: itemActive,
      updated: itemUpdated,
      text: itemText
    });
  };
  useEffect(() => {
    if (savedItemArr) {
      console.log(savedItemArr);
    }
  }, [savedItemArr]);

  return (
    socialItem && (
      <>
        <InnerForm onSubmit={handeSubmit}>
          <div className="admin_heading">
            <div className="admin_heading_title">{itemName}</div>
            <BtnBase className={'admin_submit'} btnText={'Обновить'} type={'submit'} />
          </div>
          <div className="admin_container">
            <div className="admin_form">
              <div className="admin_form_section_wrap">
                <div className="admin_form_section_title">Системные данные</div>
                <div className="admin_form_row">
                  <Switch text={'Активно'} id={'active'} name={'active'} isChecked={itemActive} onChange={() => setItemActive(!itemActive)} />
                </div>
                <div className="admin_form_row">
                  <InputLabelFor label={'ID'} value={socialItem._id} id={'id'} name={'id'} type={'text'} readOnly={true} disabled={true} />
                </div>
                <div className="admin_form_row">
                  <InputLabelFor
                    label={'Дата обновления'}
                    value={format(new Date(socialItem.updated), 'dd.MM.yyyy, HH:mm')}
                    id={'itemUpdated'}
                    name={'itemUpdated'}
                    type={'text'}
                    readOnly={true}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="admin_form_section_wrap">
                <div className="admin_form_section_title">Содержимое</div>
                <div className="admin_form_row">
                  <InputLabelFor label={'Название вопроса'} value={itemName} id={'faq_name'} name={'faq_name'} type={'text'} onChange={(event) => setItemName(event.target.value)} />
                </div>
                <div className="admin_form_row">
                  <TextareaLabelFor label={'Текстовый блок'} value={itemText} id={'text'} name={'text'} onChange={(event) => setItemText(event.target.value)} />
                </div>
              </div>
              <BtnBase className={'admin_submit admin_submit_center'} btnText={'Обновить'} type={'submit'} />
            </div>
          </div>
        </InnerForm>
      </>
    )
  );
};

export default AdminFaqItem;
