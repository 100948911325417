import { format } from 'date-fns';
import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import InnerBreadcrumbs from '../ui-kit/InnerBreadcrumbs';

// Определение типа для объекта страницы
type PageType = {
  h1: string;
  title: string;
  updated: number;
  description: string;
  breadcrumbs: string;
  text: string;
  seoUrl: string;
  showOnMain: boolean;
  excerpt: string;
  banner: {
    text: string;
    image: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
  icon: {
    big: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
    small: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
};

// Определение общего типа для элементов в массиве socialDataState
type SocialDataItem = {
  _id: string;
  name: string;
  __v: number;
  active: boolean;
  activeApi: boolean;
  category: string;
  page: PageType;
  parentId: string;
  rate: string;
  service: string;
  socialMedia: string;
  type: string;
};

// Определение типа для Path
type pathItem = {
  _id: string;
  elementId: string;
  path: string;
  seo_url: string;
};

// Определение типа для пропсов компонента AdminBlog
type SmmToolsInnerProps = {
  socialDataState: SocialDataItem[];
  pathDataState: pathItem[];
  pathString?: string;
  typeString?: string;
};

const SmmToolsInner = ({ pathDataState, pathString, typeString, socialDataState }: SmmToolsInnerProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { seo_url } = useParams();
  const [_id, setId] = useState<any>(null);
  const [pathItem, setPathItem] = useState<any>(null);

  // жду выполнения запроса фетча
  const [isLoading, setIsLoading] = useState(true);
  // жду завершения обновления элемента
  const [isLoadingData, setIsLoadingData] = useState(false);

  // объект одного элемента
  const [socialItem, setSocialItem] = useState<any>(null);

  useEffect(() => {
    if (pathDataState) {
      const resultPath = location.pathname;
      const pathItem = pathDataState.find((item) => item.seo_url === resultPath);
      if (pathItem) {
        setId(pathItem.elementId);
        setPathItem(pathItem);
      }
    }
  }, [seo_url, pathDataState]);

  useEffect(() => {
    if (_id) {
      const fetchSocialItem = async () => {
        try {
          const socialItem = socialDataState.find((item) => item._id === _id);
          if (!socialItem) {
            navigate('/404');
          } else {
            setSocialItem(socialItem);
          }
        } catch (error) {
          console.error('Error fetching social item:', error);
          navigate('/404');
        } finally {
          setIsLoading(false);
          setIsLoadingData(false);
        }
      };

      if (socialDataState.length > 0) {
        fetchSocialItem();
      }
    }
  }, [socialDataState, _id, navigate]);

  return (
    socialItem && (
      <>
        <Helmet>
          <title>{socialItem.page.title ? socialItem.page.title : socialItem.name}</title>
          <meta name="description" content={socialItem.page.description ? socialItem.page.description : socialItem.name} />
          {/* Другие мета-теги */}
        </Helmet>
        <InnerBreadcrumbs pathElement={pathItem} _id={_id} chapterLink={'/smm-tools'} chapterString={'SMM инструменты'} />
        <div className="smm_tools_inner_wrap shadow_border border_rounded">
          <h1 className="smm_tools_inner_top inner_col_content_subtitle_top">{socialItem.page.h1 ? socialItem.page.h1 : socialItem.name}</h1>
          <div className="smm_tools_inner_info">
            <img
              src={socialItem.page.icon.small.filepath ? socialItem.page.icon.small.filepath : '/content/tools.png'}
              alt={socialItem.page.h1 ? socialItem.page.h1 : socialItem.name}
              className="smm_tools_inner_info_img"
              width="46"
              height="46"
            ></img>
            <div className="smm_tools_inner_info_text">
              <strong>{`SMM инструменты`} / </strong>
              {format(new Date(socialItem.page.updated), 'dd.MM.yyyy')}
            </div>
          </div>
          {socialItem.page.text && <div className="smm_tools_inner_content" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(socialItem.page.text) }}></div>}
        </div>
      </>
    )
  );
};

export default SmmToolsInner;
