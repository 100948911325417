import React, { useEffect, useState } from 'react';
import BtnOutline from '../ui-kit/BtnOutline';

type Props = {};

// Определение типа для объекта страницы
type PageType = {
  h1: string;
  title: string;
  updated: number;
  description: string;
  breadcrumbs: string;
  text: string;
  seoUrl: string;
  showOnMain: boolean;
  excerpt: string;
  banner: {
    text: string;
    image: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
  icon: {
    big: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
    small: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
};

// Определение общего типа для элементов в массиве socialDataState
type SocialDataItem = {
  _id: string;
  name: string;
  __v: number;
  active: boolean;
  activeApi: boolean;
  category: string;
  page: PageType;
  parentId: string;
  rate: string;
  service: string;
  socialMedia: string;
  type: string;
};

// Определение типа для пропсов компонента AdminBlog
type PopularServicesProps = {
  socialDataState: SocialDataItem[];
};

const PopularServices = ({ socialDataState }: PopularServicesProps) => {
  // элементы
  const [socialElArr, setSocialElArr] = useState<any>(null);

  // Функция для получения массива элементов
  const getServicesElements = (data: SocialDataItem[]): {}[] => {
    // const socialSortItemAll = data.filter((item) => item.parentId === _id);
    const socialSortItemAll = data;
    const socialSortItem = data.filter((item: SocialDataItem) => item.type != 'category' && item.type != 'root_category');
    let categoryOptions: {}[] = [];
    const categoryOptionsAll = socialSortItemAll
      .filter((item: SocialDataItem) => item.type === 'category') // Отфильтровать только категории
      .map((category: SocialDataItem) => ({
        text: category.name,
        link: category.page.seoUrl,
        id: category._id,
        icon: category.page.icon.small.filepath,
        parent: category.parentId
      }));
    categoryOptionsAll.forEach((serviceItem: any) => {
      let socialRoot = data.find((item) => item._id === serviceItem.parent);
      let rootLink = socialRoot?.page.seoUrl;

      const updatedServices = socialSortItem
        .filter((item) => item.parentId === serviceItem.id)
        .filter((item) => item.page.showOnMain === true)
        .map(function (mapItem: SocialDataItem) {
          let url = mapItem.page.seoUrl; // Объявление переменной внутри функции map
          let categoryItem = socialSortItemAll.find((itemInner) => itemInner._id === mapItem.parentId);
          let catLink = categoryItem?.page.seoUrl;
          return {
            ...mapItem,
            page: {
              ...mapItem.page,
              seoUrl: `services/${rootLink}/${catLink}/${url}`, // Переопределение seoUrl
              excerpt: categoryItem?.name,
              // excerpt: mapItem && mapItem.page.excerpt.length > 0 ? mapItem.page.excerpt : categoryItem?.name,
              icon: {
                small: socialRoot?.page.icon.small
              }
            },
            socialMedia: socialRoot?.name
          };
        });

      categoryOptions = [...categoryOptions, ...updatedServices];

      // categoryOptions = [...categoryOptions, ...socialSortItem.filter((item) => item.parentId === serviceItem.id)];
    });

    return categoryOptions;
  };

  useEffect(() => {
    if (socialDataState) {
      const filteredArr = getServicesElements(socialDataState);
      if (filteredArr) {
        setSocialElArr(filteredArr);
      }
    }
  }, [socialDataState]);

  return (
    socialElArr &&
    socialElArr.length > 0 && (
      <div className="main_section main_popular_services_section">
        <div className="container">
          <div className="main_popular_services">
            <div className="main_section_title">Популярные услуги</div>
            <div className="main_popular_services_wrap">
              {socialElArr?.slice(0, 8).map((item: any, index: number) => (
                <div className="main_popular_services_item" key={index}>
                  <div className="main_popular_services_top">
                    <img src={item.page.icon.small.filepath} alt={item.socialMedia} width={46} height={46} />
                    <div className="main_popular_services_title">{item.socialMedia}</div>
                  </div>
                  <div className="main_popular_services_content">
                    <div className="main_popular_services_name">{item.name}</div>
                    <div className="main_popular_services_text">{item.page.excerpt}</div>
                    <BtnOutline className="btn_wide_full" btnText="Оплатить" type={'button'} btnLink={`/${item.page.seoUrl}`} />
                  </div>
                </div>
              ))}
              {/* <div className="main_popular_services_item">
              <div className="main_popular_services_top">
                <img src="/content/main_banner/icons/telega.svg" alt="Telegram" width={46} height={46} />
                <div className="main_popular_services_title">Telegram</div>
              </div>
              <div className="main_popular_services_content">
                <div className="main_popular_services_name">1000 подписчиков за 300$</div>
                <div className="main_popular_services_text">Подписчики с минимальным шансом от списания Подписчики с минимальным шансом от списания</div>
                <BtnOutline className="btn_wide_full" btnText="Оплатить" type={'submit'} />
              </div>
            </div>
            <div className="main_popular_services_item">
              <div className="main_popular_services_top">
                <img src="/content/main_banner/icons/telega.svg" alt="Telegram" width={46} height={46} />
                <div className="main_popular_services_title">Telegram</div>
              </div>
              <div className="main_popular_services_content">
                <div className="main_popular_services_name">1000 подписчиков за 300$</div>
                <div className="main_popular_services_text">Подписчики с минимальным шансом от списания Подписчики с минимальным шансом от списания</div>
                <BtnOutline className="btn_wide_full" btnText="Оплатить" type={'submit'} />
              </div>
            </div>
            <div className="main_popular_services_item">
              <div className="main_popular_services_top">
                <img src="/content/main_banner/icons/telega.svg" alt="Telegram" width={46} height={46} />
                <div className="main_popular_services_title">Telegram</div>
              </div>
              <div className="main_popular_services_content">
                <div className="main_popular_services_name">1000 подписчиков за 300$</div>
                <div className="main_popular_services_text">Подписчики с минимальным шансом от списания Подписчики с минимальным шансом от списания</div>
                <BtnOutline className="btn_wide_full" btnText="Оплатить" type={'submit'} />
              </div>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default PopularServices;
