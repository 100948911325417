import React, { FormEvent, useEffect, useState } from 'react';
import Switch from '../admin-ui-kit/input/Switch';
import InnerForm from '../admin-ui-kit/form/InnerForm';
import InputBase from '../admin-ui-kit/input/InputBase';
import InputLabelFor from '../admin-ui-kit/input/InputLabelFor';
import TextareaLabelFor from '../admin-ui-kit/input/TextareaLabelFor';
import BtnBase from '../admin-ui-kit/btn/BtnBase';
import FileUploadWithPreview from '../admin-ui-kit/input/FileUploadWithPreview';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import BaseSelect from '../admin-ui-kit/input/BaseSelect';
import { fetchApi } from '../../../content/components/helpers/api';

// Определение типа для объекта страницы
type PageType = {
  h1: string;
  title: string;
  updated: number;
  description: string;
  breadcrumbs: string;
  text: string;
  seoUrl: string;
  banner: {
    text: string;
    image: string;
  };
  icon: {
    big: string;
    small: string;
  };
};

// Определение общего типа для элементов в массиве socialDataState
type SocialDataItem = {
  _id: string;
  parentId: string;
  name: string;
  type: string;
  active: boolean;
  category: string;
  page: PageType;
};

// Определяем тип для функции обновления данных
type UpdateSocialDataStateFunction = () => Promise<void>;

// Определение типа для пропсов компонента AdminSocial
type AdminToolsItemNewProps = {
  socialDataState: SocialDataItem[];
  typeString: string;
  updateDataState: UpdateSocialDataStateFunction;
};

const AdminToolsItemNew = ({ socialDataState, typeString, updateDataState }: AdminToolsItemNewProps) => {
  const navigate = useNavigate();
  const { _id } = useParams();

  // жду выполнения запроса фетча
  const [isLoading, setIsLoading] = useState(true);

  // жду завершения обновления элемента
  const [isLoadingData, setIsLoadingData] = useState(false);

  // типа одного элемента
  const [socialTypeString, setSocialTypeString] = useState(typeString);

  const [socialItem, setSocialItem] = useState<any>(null);
  const [socialDataStateArr, setSocialDataStateArr] = useState<any[]>([]);

  // массив с данными для сохранения
  const [savedItemArr, setSavedItemArr] = useState<any>(null);

  //
  const [itemName, setItemName] = useState<any>(null);
  const [itemType, setItemType] = useState<any>(null);
  const [itemActive, setItemActive] = useState<any>(null);
  const [itemH1, setItemH1] = useState<any>(null);
  const [itemTitle, setItemTitle] = useState<any>(null);
  const [itemDate, setItemDate] = useState<any>(null);
  const [itemDescription, setItemDescription] = useState<any>(null);
  const [itemBreadcrumbs, setItemBreadcrumbs] = useState<any>(null);
  const [itemText, setItemText] = useState<any>(null);
  const [itemExcerpt, setItemExcerpt] = useState<any>(null);
  const [itemBannerText, setItemBannerText] = useState<any>(null);
  const [itemSeoUrl, setItemSeoUrl] = useState<any>(null);

  const [bannerId, setBannerId] = useState<any>(null);
  const [bannerFilename, setBannerFilename] = useState<any>(null);
  const [bannerFiletype, setBannerFiletype] = useState<any>(null);
  const [bannerFilesize, setBannerFilesize] = useState<any>(null);
  const [bannerFilepath, setBannerFilepath] = useState<any>(null);
  const [bannerDatetime, setBannerDatetime] = useState<any>(null);

  const [smallId, setSmallId] = useState<any>(null);
  const [smallFilename, setSmallFilename] = useState<any>(null);
  const [smallFiletype, setSmallFiletype] = useState<any>(null);
  const [smallFilesize, setSmallFilesize] = useState<any>(null);
  const [smallFilepath, setSmallFilepath] = useState<any>(null);
  const [smallDatetime, setSmallDatetime] = useState<any>(null);

  const [bigId, setBigId] = useState<any>(null);
  const [bigFilename, setBigFilename] = useState<any>(null);
  const [bigFiletype, setBigFiletype] = useState<any>(null);
  const [bigFilesize, setBigFilesize] = useState<any>(null);
  const [bigFilepath, setBigFilepath] = useState<any>(null);
  const [bigDatetime, setBigDatetime] = useState<any>(null);

  useEffect(() => {
    setItemName('');
    setItemType(socialTypeString);
    setItemActive(true);
    setItemH1('');
    setItemTitle('');
    setItemDate(Date.now());
    setItemDescription('');
    setItemBreadcrumbs('');
    setItemText('');
    setItemExcerpt('');
    setItemSeoUrl('');
    setItemBannerText('');

    setBannerId('');
    setBannerFilename('');
    setBannerFiletype('');
    setBannerFilesize('');
    setBannerFilepath('');
    setBannerDatetime('');

    setSmallId('');
    setSmallFilename('');
    setSmallFiletype('');
    setSmallFilesize('');
    setSmallFilepath('');
    setSmallDatetime('');

    setBigId('');
    setBigFilename('');
    setBigFiletype('');
    setBigFilesize('');
    setBigFilepath('');
    setBigDatetime('');
  }, [socialTypeString]);

  // Функция для сохранения данных про маленькую иконку
  const handleImageSaveIconSmall = (imageData: any) => {
    setSmallId(imageData.id);
    setSmallFilename(imageData.filename);
    setSmallFiletype(imageData.filetype);
    setSmallFilesize(imageData.filesize);
    setSmallFilepath(imageData.filepath);
    setSmallDatetime(imageData.datetime);
  };

  // Функция для сохранения данных про маленькую иконку
  const handleImageSaveIconBig = (imageData: any) => {
    setBigId(imageData.id);
    setBigFilename(imageData.filename);
    setBigFiletype(imageData.filetype);
    setBigFilesize(imageData.filesize);
    setBigFilepath(imageData.filepath);
    setBigDatetime(imageData.datetime);
  };

  const handeSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setSavedItemArr({
      // _id: socialItem._id,
      parentId: '',
      name: itemName,
      type: itemType,
      active: itemActive,
      page: {
        h1: itemH1,
        title: itemTitle,
        updated: Date.now(),
        description: itemDescription,
        breadcrumbs: itemBreadcrumbs,
        text: itemText,
        seoUrl: itemSeoUrl,
        excerpt: itemExcerpt,
        banner: {
          text: itemBannerText,
          image: {
            id: bannerId,
            filename: bannerFilename,
            filetype: bannerFiletype,
            filesize: bannerFilesize,
            filepath: bannerFilepath,
            datetime: bannerDatetime
          }
        },
        icon: {
          big: {
            id: bigId,
            filename: bigFilename,
            filetype: bigFiletype,
            filesize: bigFilesize,
            filepath: bigFilepath,
            datetime: bigDatetime
          },
          small: {
            id: smallId,
            filename: smallFilename,
            filetype: smallFiletype,
            filesize: smallFilesize,
            filepath: smallFilepath,
            datetime: smallDatetime
          }
        }
      }
    });
  };

  useEffect(() => {
    const saveItem = async () => {
      try {
        setIsLoadingData(true);
        let response;

        response = await fetchApi('POST', `/tools/new`, savedItemArr);

        if (response && !response.error) {
          // Успешно сохранено, выполните необходимые действия
          updateDataState();
          navigate('/admin/tools'); // Редирект на страницу инструмента
        } else {
          console.error('Failed to save item:', response.error);
          // Обработка ошибки, например, отображение сообщения об ошибке для пользователя
        }
      } catch (error) {
        console.error('Error saving item:', error);
        // Обработка ошибки, например, отображение сообщения об ошибке для пользователя
      } finally {
        setIsLoadingData(false);
      }
    };

    if (savedItemArr) {
      saveItem();
    }
  }, [savedItemArr, typeString, updateDataState, navigate]);

  return (
    <>
      <InnerForm onSubmit={handeSubmit}>
        <div className="admin_heading">
          <div className="admin_heading_title">Добавить элемент</div>
          <BtnBase className={'admin_submit'} btnText={'Обновить'} type={'submit'} />
        </div>
        <div className="admin_container">
          <div className="admin_form">
            <div className="admin_form_section_wrap">
              <div className="admin_form_section_title">Системные данные</div>
              <div className="admin_form_row">
                <Switch text={'Активно'} id={'active'} name={'active'} isChecked={itemActive} onChange={() => setItemActive(!itemActive)} />
              </div>
              <div className="admin_form_row">
                <InputLabelFor label={'Название'} value={itemName} id={'name'} name={'name'} type={'text'} onChange={(event) => setItemName(event.target.value)} />
              </div>
              <div className="admin_form_row">
                <InputLabelFor label={'SEO URL'} value={itemSeoUrl} id={'seo_url'} name={'seo_url'} type={'text'} onChange={(event) => setItemSeoUrl(event.target.value)} />
              </div>
            </div>
            <div className="admin_form_section_wrap">
              <div className="admin_form_section_title">Содержимое</div>
              <div className="admin_form_section_wrap_grid">
                <div className="admin_form_section_wrap_grid_child">
                  <div className="admin_form_row">
                    <FileUploadWithPreview
                      labelText={'Маленькая иконка'}
                      savedId={smallId}
                      savedFilename={smallFilename}
                      savedFiletype={smallFiletype}
                      savedFilesize={smallFilesize}
                      savedFilepath={smallFilepath}
                      savedDatetime={smallDatetime}
                      saveImage={handleImageSaveIconSmall}
                    />
                  </div>
                </div>
                <div className="admin_form_section_wrap_grid_child">
                  <div className="admin_form_row">
                    <FileUploadWithPreview
                      labelText={'Большая иконка'}
                      savedId={bigId}
                      savedFilename={bigFilename}
                      savedFiletype={bigFiletype}
                      savedFilesize={bigFilesize}
                      savedFilepath={bigFilepath}
                      savedDatetime={bigDatetime}
                      saveImage={handleImageSaveIconBig}
                    />
                  </div>
                </div>
              </div>
              <div className="admin_form_row">
                <TextareaLabelFor label={'Текстовый блок'} value={itemText} id={'text'} name={'text'} onChange={(event) => setItemText(event.target.value)} />
              </div>
              <div className="admin_form_row">
                <TextareaLabelFor label={'Краткое описание'} value={itemExcerpt ? itemExcerpt : ''} id={'excerpt'} name={'excerpt'} onChange={(event) => setItemExcerpt(event.target.value)} />
              </div>
            </div>
            <div className="admin_form_section_wrap">
              <div className="admin_form_section_title">Мета</div>
              <div className="admin_form_row">
                <InputLabelFor label={'Заголовок - H1'} value={itemH1} id={'h1'} name={'h1'} type={'text'} onChange={(event) => setItemH1(event.target.value)} />
              </div>
              <div className="admin_form_row">
                <InputLabelFor label={'Хлебные крошки'} value={itemBreadcrumbs} id={'breadcrumbs'} name={'breadcrumbs'} type={'text'} onChange={(event) => setItemBreadcrumbs(event.target.value)} />
              </div>
              <div className="admin_form_row">
                <InputLabelFor label={'Meta-title'} value={itemTitle} id={'meta-title'} name={'meta-title'} type={'text'} onChange={(event) => setItemTitle(event.target.value)} />
              </div>
              <div className="admin_form_row">
                <InputLabelFor
                  label={'Meta-description'}
                  value={itemDescription}
                  id={'meta-description'}
                  name={'meta-description'}
                  type={'text'}
                  onChange={(event) => setItemDescription(event.target.value)}
                />
              </div>
            </div>
            <BtnBase className={'admin_submit admin_submit_center'} btnText={'Обновить'} type={'submit'} />
          </div>
        </div>
      </InnerForm>
    </>
  );
};

export default AdminToolsItemNew;
