import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import BtnOutline from '../../ui-kit/BtnOutline';
import MenuLink from '../../ui-kit/MenuLink';
import { ScrollContext } from '../../../../Routes/Layout';

// Определение типа для объекта страницы
type Items = {
  name: string;
  link: string;
  icon: {
    parentId?: number;
    id: string;
    filename: string;
    filetype: string;
    datetime: string;
    filesize: string;
    filepath: string;
  };
  index: string;
};

// Определение общего типа для элементов в массиве socialDataState
type MenuProps = {
  _id: string;
  name: string;
  type: string;
  subtype: string;
  items: Items[];
};

type HeaderMenuProps = {
  isUserLogin?: boolean;
  userName?: string;
  mainMenuState: MenuProps[];
};

const HeaderMenu = ({ mainMenuState }: HeaderMenuProps) => {
  const navigate = useNavigate();

  const btnNavigate = (link: string) => () => {
    navigate(link);
  };

  const { scrollToMainQuickOrder, mainQuickOrderRef } = useContext(ScrollContext); // Получаем функцию и ref из контекста
  return (
    <div className="nav_items">
      {mainMenuState && mainMenuState.map((item: any, index: number) => <MenuLink className="nav_item text" menuLink={item.link} menuText={item.name} key={index} subItems={item.subItems} />)}
      <BtnOutline className="btn_outline nav_fast_order" onClick={scrollToMainQuickOrder} btnText="Быстрый заказ" />
    </div>
  );
};

export default HeaderMenu;
