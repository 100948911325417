import React from 'react';
import BtnOutline from '../components/ui-kit/BtnOutline';
import InputBase from '../components/ui-kit/input/InputBase';

type Props = {};

const Promo = (props: Props) => {
  return (
    <div className="promo_wrap">
      <div className="inner_col_content_title">{`Партнёрская программа`}</div>
      <div className="main_personal_wrap_grid">
        <div className="personal_col personal_col_content">
          <div className="personal_col_heading">
            <div className="personal_col_heading_wrap">
              <img src="/content/promo_icon.png" alt="Партнерский кабинет" />
              <div className="personal_col_heading_title">Партнерский кабинет</div>
            </div>
          </div>
          <div className="promo_col promo_col_text">
            <div className="promo_col_text_row">
              <div>Всего заработано:</div>
              <div className="promo_col_text_row_big">{`О`} ₽</div>
            </div>
            <div className="promo_col_text_row">
              <div>Доступно к выводу:</div>
              <div className="promo_col_text_row_big">{`О`} ₽</div>
            </div>
          </div>
        </div>
        <div className="personal_col personal_col_content">
          <div className="personal_col_heading">
            <div className="personal_col_heading_wrap">
              <div className="personal_col_heading_title">Партнерские условия</div>
            </div>
          </div>
          <div className="promo_col promo_col_text">
            <div className="promo_col_text_row promo_col_text_row_low_mb">
              <div>Всего заработано:</div>
              <div className="promo_col_text_row_light">{`5`}% от всех заказов</div>
            </div>
            <div className="promo_col_text_row promo_col_text_row_low_mb">
              <div>Ваша партнерская ссылка:</div>
              <div className="promo_col_text_row_light">реферальная ссылка</div>
              <div className="promo_input_wrap">
                <InputBase type={'text'} name={'personal_upBalance'} placeholder={'реферальная ссылка'} className="" inputClassName="base_input_wide" value={`test`} isReadonly={true} />
                <BtnOutline className="btn_wide_full btn_outline" btnText="Скопировать" />
                <BtnOutline className="btn_wide_full " btnText="Вывести средства" />
              </div>
            </div>
          </div>
        </div>
        <div className="personal_col personal_col_content">
          <div className="personal_col_heading">
            <div className="personal_col_heading_wrap">
              <img src="/content/promo_icon.png" alt="Партнерский кабинет" />
              <div className="personal_col_heading_title">Партнерский кабинет</div>
            </div>
          </div>
          <div className="personal_col_inner">
            <div className="personal_order_list_heading personal_order_list_row">
              <div className="personal_order_list_td personal_order_list_td_th">#</div>
              <div className="personal_order_list_td personal_order_list_td_th">Дата</div>
              <div className="personal_order_list_td personal_order_list_td_th">Пользователь</div>
              <div className="personal_order_list_td personal_order_list_td_th">Сумма</div>
            </div>
            <div className="personal_order_list_content">
              <div className="personal_order_list_row">
                <div className="personal_order_list_td">0000</div>
                <div className="personal_order_list_td">20.11 13:00</div>
                <div className="personal_order_list_td">site.com/page</div>
                <div className="personal_order_list_td">1000</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="promo_content">
        <img src="/content/promo_right.png" alt="Как вы можете продвигать наш сайт и зарабатывать" />
        <div className="promo_content_text">
          <div className="promo_content_title">Как вы можете продвигать наш сайт и зарабатывать:</div>
          <div className="promo_content_text_inner">
            <p>Как вы можете продвигать наш сайт и зарабатывать:</p>
            <ul>
              <li>Переслать ссылку вашим знакомым которые также развивают соц сети</li>
              <li>Если у вас есть свой канал или блог вы можете создать пост/видео с демонстрацией нашего сайта и оставить вашу реферальную ссылку</li>
              <li>Размещать на различных форумах отзовиках описание нашего сайта и вашу реферальную ссылку</li>
              <li>
                С каждого реферала который зарегистрировался по вашей реферальной ссылке вы заработаете 5% от суммы его заказа (например рефералы оформили заказов на 100 000 рублей, ваше
                вознаграждение составит 5000 рублей)
              </li>
              <li>Полученную прибыть вы можете вывести на карту либо использовать как баланс на нашем сайте</li>
            </ul>
          </div>
          <img src="/content/promo_right_mob.png" alt="Как вы можете продвигать наш сайт и зарабатывать" />
          <BtnOutline className="btn_wide " btnText="Стать партнёром" />
        </div>
      </div>
      <div className="promo_content_info">
        <div className="promo_content_info_item">
          <div className="promo_content_info_top">18</div>
          <div className="promo_content_info_descr">количество рефралов</div>
        </div>
        <div className="promo_content_info_item">
          <div className="promo_content_info_top">39 450 ₽</div>
          <div className="promo_content_info_descr">всего заработано</div>
        </div>
        <div className="promo_content_info_item">
          <div className="promo_content_info_top">9 310 ₽</div>
          <div className="promo_content_info_descr">доступно к выводу</div>
        </div>
      </div>
    </div>
  );
};

export default Promo;
