import React, { useEffect, useState } from 'react';
import { NavLink, matchRoutes, useLocation, useNavigate, useParams } from 'react-router-dom';
import MainFaq from '../main_page/MainFaq';
import BtnOutline from '../ui-kit/BtnOutline';
import ServicesText from './ServicesText';
import ServicesTextReviewsWrap from './ServicesTextReviewsWrap';
import ServicesInnerList from './ServicesInnerList';
import ServicesGuaranty from './ServicesGuaranty';
import ServicesItemsList from './ServicesItemsList';
import { Helmet } from 'react-helmet-async';
import { Authentification } from '../helpers/auth';
import MainQuickOrder from '../main_page/MainQuickOrder';
import PersonalOrderCreate from '../personal/sections/PersonalOrderCreate/PersonalOrderCreate';
import { fetchApi } from '../helpers/api';
import InnerBreadcrumbs from '../ui-kit/InnerBreadcrumbs';

// Определение типа для объекта страницы
type PageType = {
  h1: string;
  title: string;
  updated: number;
  description: string;
  breadcrumbs: string;
  text: string;
  seoUrl: string;
  showOnMain: boolean;
  excerpt: string;
  banner: {
    text: string;
    image: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
  icon: {
    big: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
    small: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
};

// Определение общего типа для элементов в массиве socialDataState
type SocialDataItem = {
  _id: string;
  name: string;
  customName: string;
  __v: number;
  active: boolean;
  activeApi: boolean;
  category: string;
  page: PageType;
  parentId: string;
  rate: string;
  service: string;
  socialMedia: string;
  type: string;
};

// Определение общего типа для элементов в массиве socialDataState
type SocialReviewItem = {
  _id: string;
  parentId: string;
  name: string;
  type: string;
  active: boolean;
  isNew: boolean;
  updated: number;
  date: number;
  text: string;
};

// Определение общего типа для элементов в массиве socialDataState
type SocialFaqItem = {
  _id: string;
  parentId: string;
  name: string;
  type: string;
  active: boolean;
  updated: number;
  text: string;
};

// Определение типа для Path
type pathItem = {
  _id: string;
  elementId: string;
  path: string;
  seo_url: string;
};

// Определение типа для пропсов компонента AdminBlog
type ServicesInnerItemElementProps = {
  socialDataState: SocialDataItem[];
  socialReviewState: SocialReviewItem[];
  faqDataState: SocialFaqItem[];
  pathDataState: pathItem[];
  pathString: string;
  typeString?: string;
};

const ServicesInnerItemElement = ({ pathDataState, pathString, socialDataState, socialReviewState, faqDataState, typeString }: ServicesInnerItemElementProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { seo_url } = useParams();
  const [_id, setId] = useState<any>(null);
  const [pathItem, setPathItem] = useState<any>(null);
  const [rootId, setRootId] = useState<any>(null);

  // жду выполнения запроса фетча
  const [isLoading, setIsLoading] = useState(true);
  // жду завершения обновления элемента
  const [isLoadingData, setIsLoadingData] = useState(false);

  // объект одного элемента
  const [socialItem, setSocialItem] = useState<any>(null);

  // список категорий соц. сети
  const [socialDataCategoryArr, setSocialDataCategoryArr] = useState<any[]>([]);

  // объекты всех услуг
  const [socialItemsArr, setSocialItemsArr] = useState<any>(null);

  const [socialDataCategoryArrFull, setSocialDataCategoryArrFull] = useState<any[]>([]);

  const [socialSortItemParentArr, setSocialSortItemParentArr] = useState<any>([]);

  const [userToken, setUserToken] = useState('');
  useEffect(() => {
    const token = Authentification.getInstance().getToken();
    if (token) {
      setUserToken(token ? token : '');
    }
  }, []);

  // Функция для получения массива категорий
  const getCategoryOptions = async (data: SocialDataItem[]): Promise<{ text: string; link: string; id: string; icon: string }[]> => {
    const socialSortItem = data.filter((item) => item.parentId === socialItem.parentId);
    let socialSortItemParent: any = null;

    const fetchCatalogRootItem = async () => {
      try {
        const newDataCatalogRootItem = await fetchApi('GET', `services/get_item/${socialItem?.parentId}`, {});
        setSocialSortItemParentArr(newDataCatalogRootItem);
        socialSortItemParent = newDataCatalogRootItem;
        try {
          const serviceRootInfo = await fetchApi('GET', `services/get_item/${newDataCatalogRootItem?.parentId}`, {});
          setRootId(serviceRootInfo?._id);
        } catch (error) {
          console.error('Error fetching social item:', error);
        } finally {
        }
      } catch (error) {
        console.error('Error fetching social item:', error);
        navigate('/404');
      } finally {
        setIsLoading(false);
        setIsLoadingData(false);
      }
    };

    await fetchCatalogRootItem();

    if (!socialSortItemParent) {
      // Если fetchCatalogRootItem не удалось получить данные, возвращаем пустой массив
      return [];
    }

    const categoryOptions = socialSortItem
      .filter((item: SocialDataItem) => item.type === 'category') // Отфильтровать только категории
      .map((category: SocialDataItem) => ({
        text: category.customName ? category.customName : category.name,
        link: '/services/' + socialSortItemParent!.page.seoUrl + '/' + category.page.seoUrl,
        id: category._id,
        icon: category.page.icon.small.filepath
      }));

    // Создаем элемент "Все услуги"
    if (socialSortItemParent) {
      const allCategory = {
        text: 'Все услуги',
        link: '/services/' + socialSortItemParent.page.seoUrl, // Здесь нужно указать ссылку на основную страницу блога
        id: 'all_articles',
        icon: socialSortItemParent.page.icon.small.filepath, // Можно указать иконку для "Все статьи", если есть
        parent: '' // У "Все статьи" нет родительской категории, поэтому пустая строка
      };
      categoryOptions.unshift(allCategory);
    }

    return categoryOptions;
  };

  // Функция для получения массива элементов
  const getServicesElements = (data: SocialDataItem[]): {}[] => {
    const socialSortItem = data.filter((item: SocialDataItem) => item.type != 'category' && item.type != 'root_category');
    const categoryOptions = data;
    return categoryOptions;
  };

  useEffect(() => {
    if (pathDataState) {
      const resultPath = location.pathname;
      const pathItem = pathDataState.find((item) => item.seo_url === resultPath);
      if (pathItem) {
        setId(pathItem.elementId);
        setPathItem(pathItem);
      }
    }
  }, [seo_url, pathDataState]);

  useEffect(() => {
    if (_id) {
      const fetchCatalogRootItem = async () => {
        try {
          const newDataCatalogRootItem = await fetchApi('GET', `services/get_item/${_id}`, {});
          setSocialItem(newDataCatalogRootItem);
        } catch (error) {
          console.error('Error fetching social item:', error);
          // navigate('/404');
        } finally {
          setIsLoading(false);
          setIsLoadingData(false);
        }
      };
      fetchCatalogRootItem();
    }
  }, [socialDataState, _id, navigate]);

  useEffect(() => {
    if (socialItem) {
      const fetchCatalogRootData = async () => {
        try {
          if (socialItem && socialItem.parentId) {
            const newDataCatalogRoot = await fetchApi('GET', `services/get_categories/${socialItem?.parentId}`, {});
            const categories = await getCategoryOptions(newDataCatalogRoot);
            setSocialDataCategoryArr(categories);
            setSocialDataCategoryArrFull(newDataCatalogRoot);
          } else {
            console.error("No social item to fetch");
          }
        } catch (error) {
          console.error('Error fetching social item:', error);
        } finally {
          setIsLoading(false);
        }
      };
      fetchCatalogRootData();

      // const parentDataId = socialDataState.find((item) => item._id === socialItem.parentId);
      // if (parentDataId) {
      //   const rootDataId = socialDataState.find((item) => item._id === parentDataId.parentId);
      //   if (rootDataId) {
      //     setRootId(rootDataId._id);
      //   }
      // }
    }
  }, [socialItem]);

  return (
    socialItem && (
      <>
        <Helmet>
          <title>{socialItem.page.title ? socialItem.page.title : socialItem.customName ? socialItem.customName : socialItem.name}</title>
          <meta name="description" content={socialItem.page.description ? socialItem.page.description : socialItem.customName ? socialItem.customName : socialItem.name} />
          {/* Другие мета-теги */}
        </Helmet>
        <InnerBreadcrumbs pathElement={pathItem} _id={_id} chapterLink={'/services'} chapterString={'Услуги'} />
        <div className="inner_single_top">
          <div className="inner_single_top_content">
            <h1 className="inner_single_top_content_title">{socialItem.page.h1 ? socialItem.page.h1 : socialItem.customName ? socialItem.customName : socialItem.name}</h1>
            <div className="inner_single_top_content_text">{socialItem.page.banner.text ? socialItem.page.banner.text : ''}</div>
            {/* <BtnOutline className="btn_wide" btnText="Начать раскрутку" /> */}
            <a className="btn btn_wide" href="#orderThirdTab">
              <span>Начать раскрутку</span>
            </a>
          </div>
          <div className="inner_single_top_content_img">
            <img src={socialItem.page.banner.image.filepath ? socialItem.page.banner.image.filepath : '/content/not/big_red.png'} alt={socialItem.page.h1 ? socialItem.page.h1 : socialItem.customName ? socialItem.customName : socialItem.name} />
          </div>
        </div>
        <a className="btn btn_wide service_btn_mob" href="#orderThirdTab">
          <span>Начать раскрутку</span>
        </a>
        <div className="inner_col_content_title" id="startService">
          {'Выберите услугу и вставьте ссылку!'}
        </div>
        <div className="main_personal_wrap_grid">
          <PersonalOrderCreate socialDataState={socialDataState} isLogged={userToken ? true : false} activeSoc={rootId} activeSocCat={socialItem.parentId} activeSocEl={socialItem._id} />
        </div>
        <div className="single_content"></div>
        <div className="inner_col_content_nested">
          <ServicesText innerText={socialItem.page.text} />
          <div className="inner_section inner_faq_section">
            <MainFaq socialDataState={faqDataState} typeString={'faq'} />
          </div>
        </div>
      </>
    )
  );
};

export default ServicesInnerItemElement;
