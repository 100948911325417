import React, { FormEvent, useEffect, useState } from 'react';
import Switch from '../admin-ui-kit/input/Switch';
import InnerForm from '../admin-ui-kit/form/InnerForm';
import InputBase from '../admin-ui-kit/input/InputBase';
import InputLabelFor from '../admin-ui-kit/input/InputLabelFor';
import TextareaLabelFor from '../admin-ui-kit/input/TextareaLabelFor';
import BtnBase from '../admin-ui-kit/btn/BtnBase';
import FileUploadWithPreview from '../admin-ui-kit/input/FileUploadWithPreview';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import BaseSelect from '../admin-ui-kit/input/BaseSelect';
import { fetchApi } from '../../../content/components/helpers/api';
import { Authentification } from '../../../content/components/helpers/auth';

// Определение общего типа для элементов в массиве socialDataState
type SocialDataItem = {
  _id: string;
  login: string;
  active: boolean;
  email: string;
  balance: string;
  role: {
    registred: boolean;
  };
};

// Определение общего типа для элементов в массиве socialDataState
type ProductDataItem = {
  id: string;
  service: string;
  product: string;
  price: string;
  count: string;
  rate: string;
  link: string;
  promo: string;
};

// Определение общего типа для элементов в массиве socialDataState
type OrderDataItem = {
  _id: string;
  userId: string;
  date: number;
  started: boolean;
  payed: boolean;
  price: string;
  products: ProductDataItem[];
};

// Определяем тип для функции обновления данных
type UpdateSocialDataStateFunction = () => Promise<void>;

// Определение типа для пропсов компонента AdminSocial
type AdminToolsItemProps = {
  socialDataState: SocialDataItem[];
  orderDataState: OrderDataItem[];
  updateDataState: UpdateSocialDataStateFunction;
};

const AdminToolsItem = ({ updateDataState, socialDataState, orderDataState }: AdminToolsItemProps) => {
  const navigate = useNavigate();
  const { _id } = useParams();

  // жду выполнения запроса фетча
  const [isLoading, setIsLoading] = useState(true);

  // жду завершения обновления элемента
  const [isLoadingData, setIsLoadingData] = useState(false);

  // объект одного элемента
  const [socialItem, setSocialItem] = useState<any>(null);

  const [orderDataStateArr, setOrderDataStateArr] = useState<any[]>([]);

  // массив с данными для сохранения
  const [savedItemArr, setSavedItemArr] = useState<any>(null);

  //
  const [itemLogin, setItemLogin] = useState<any>('');
  const [itemPassword, setItemPassword] = useState<any>('');
  const [itemActive, setItemActive] = useState<any>(false);
  const [itemEmail, setItemEmail] = useState<any>('');
  const [itemBalance, setItemBalance] = useState<any>('');
  const [itemBalanceNew, setItemBalanceNew] = useState<any>('');

  useEffect(() => {
    const fetchSocialItem = async () => {
      try {
        const socialItem = socialDataState.find((item) => item._id === _id);
        if (!socialItem) {
          navigate('/404');
        } else {
          setSocialItem(socialItem);
        }
      } catch (error) {
        console.error('Error fetching social item:', error);
        navigate('/404');
      } finally {
        setIsLoading(false);
        setIsLoadingData(false);
      }
    };

    if (socialDataState.length > 0) {
      fetchSocialItem();
    }
  }, [socialDataState, _id, navigate]);

  useEffect(() => {
    if (orderDataState && orderDataState.length > 0) {
      const filteredArray = orderDataState.filter((item) => item.userId === _id);
      if (filteredArray && filteredArray.length > 0) {
        setOrderDataStateArr(filteredArray);
      }
    }
  }, [orderDataState]);

  useEffect(() => {
    if (socialItem) {
      setItemLogin(socialItem.login ? socialItem.login : '');
      setItemActive(socialItem.active ?? false);
      setItemEmail(socialItem.email ? socialItem.email : '');
      setItemBalance(socialItem.balance ? socialItem.balance : '');
    }
  }, [socialItem]);

  // функция формирования массива для сохранения
  const handeSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSavedItemArr({
      _id: socialItem._id,
      login: itemLogin,
      active: itemActive,
      email: itemEmail,
      balance: itemBalanceNew ? itemBalanceNew : itemBalance ? itemBalance : '0',
      role: {
        registred: socialItem.role.registred
      }
    });
    setItemBalanceNew('');
  };

  // после изменения savedItemArr сохраняю его
  useEffect(() => {
    if (savedItemArr) {
      console.log(savedItemArr);
      (async () => {
        // console.log(savedItemArr);
        if (savedItemArr.balance) {
          await fetchApi('POST', `balance/change/${_id}`, { balance: savedItemArr.balance });
        }
        setSocialItem(await fetchApi('POST', `/users/updateInfo/${_id}`, savedItemArr));
        updateDataState();
        alert('Данные обновлены');
      })();
    }
  }, [savedItemArr]);

  const handleCancelOrder = async (itemId : string) => {
    const cancelOrder = await fetchApi('POST', '/services/cancel_order', { orderId: itemId });
    console.log(cancelOrder);
    if (cancelOrder.status && cancelOrder.status == 'fail') {
      alert("Отмена не прошла с ошибкой: " +cancelOrder.message)
    } else {
      alert("Заказ " + itemId + "отменён")
    }
  
    console.log('cancel');
  };

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : !socialItem ? (
        <div>Item not found</div>
      ) : (
        socialItem && (
          <InnerForm onSubmit={handeSubmit}>
            <div className="admin_heading">
              <div className="admin_heading_title">{`Пользователь: ${itemLogin}`}</div>
              <BtnBase className={'admin_submit'} btnText={'Обновить'} type={'submit'} />
            </div>
            <div className={`admin_container ${isLoadingData ? 'loading' : ''}`}>
              <div className="admin_form">
                <div className="admin_form_section_wrap">
                  <div className="admin_form_section_title">Системные данные</div>
                  <div className="admin_form_row">
                    <Switch text={'Активно'} id={'active'} name={'active'} isChecked={itemActive} onChange={() => setItemActive(!itemActive)} />
                  </div>
                  <div className="admin_form_row">
                    <InputLabelFor label={'Логин'} value={itemLogin} id={'login'} name={'login'} type={'text'} onChange={(event) => setItemLogin(event.target.value)} />
                  </div>
                  <div className="admin_form_row">
                    <InputLabelFor label={'Email'} value={itemEmail} id={'email'} name={'email'} type={'text'} onChange={(event) => setItemEmail(event.target.value)} />
                  </div>
                  <div className="admin_form_section_wrap_grid">
                    <div className="admin_form_section_wrap_grid_child">
                      <div className="admin_form_row">
                        <InputLabelFor
                          label={'Текущий баланс (RUB)'}
                          value={itemBalance}
                          id={'balance'}
                          name={'balance'}
                          type={'text'}
                          onChange={(event) => setItemBalance(event.target.value)}
                          readOnly={true}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="admin_form_section_wrap_grid_child">
                      <div className="admin_form_row">
                        <InputLabelFor
                          label={'Новый баланс (RUB)'}
                          value={itemBalanceNew}
                          id={'balance_new'}
                          name={'balance_new'}
                          type={'text'}
                          onChange={(event) => setItemBalanceNew(event.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="admin_form_row">
                    <InputLabelFor label={'ID'} value={socialItem._id} id={'id'} name={'id'} type={'text'} readOnly={true} disabled={true} />
                  </div>
                </div>
                {orderDataStateArr && orderDataStateArr.length > 0 && (
                  <div className="admin_form_section_wrap">
                    {orderDataStateArr &&
                      orderDataStateArr.map((item: any, index: number) => (
                        <div className="admin_form_row order_item_row" key={index}>
                          <div className="admin_form_section_title">
                            ID заказа: <strong>{item._id}</strong>
                          </div>
                          {item.order_id && (!item.status || item.status === 'Pending' || item.status === 'Partial' || item.status === 'Processing' || item.status === 'In progress') && (
                            <BtnBase className={'admin_submit order_cancel'} btnText={'Отменить заказ'} type={'button'} onClick={()=>handleCancelOrder(item?._id)} />
                          )}
                          {item.service_provider && (
                            <div className="admin_form_section_subtitle">
                              Провайдер: <strong>{item?.service_provider}</strong>
                            </div>
                          )}
                          {item.order_id && (
                            <div className="admin_form_section_subtitle">
                              Номер заказа (провайдер): <strong>{item?.order_id}</strong>
                            </div>
                          )}
                          <div className="admin_form_section_wrap_grid">
                            <div className="admin_form_section_wrap_grid_child">
                              <div className="admin_form_row">
                                <InputLabelFor label={'Дата заказа'} value={format(new Date(item.date), 'dd.MM.yyyy, HH:mm')} id={'date'} name={'date'} type={'text'} readOnly={true} disabled={true} />
                              </div>
                              <div className="admin_form_row">
                                <InputLabelFor label={'Итоговая стоимость заказа (RUB)'} value={item.price} id={'price'} name={'price'} type={'text'} readOnly={true} disabled={true} />
                              </div>
                            </div>
                            <div className="admin_form_section_wrap_grid_child">
                              <div className="admin_form_row">
                                <InputLabelFor
                                  className={item.payed ? 'filled_green' : 'filled_red'}
                                  label={'Статус оплаты'}
                                  value={item.payed ? 'Оплачен' : 'Не оплачен'}
                                  id={'payed'}
                                  name={'payed'}
                                  type={'text'}
                                  readOnly={true}
                                  disabled={true}
                                />
                              </div>
                              <div className="admin_form_row">
                                <InputLabelFor
                                  className={item.started ? 'filled_green' : 'filled_red'}
                                  label={'Статус заказа (started)'}
                                  value={item.started ? (item.status ? item.status : 'Начат') : 'Ожидает'}
                                  id={'started'}
                                  name={'started'}
                                  type={'text'}
                                  readOnly={true}
                                  disabled={true}
                                />
                              </div>
                            </div>
                          </div>
                          {item.products &&
                            Object.values(item.products).map((innerItem: any, innerIndex: number) => (
                              <div className="order_product_list">
                                <div className="admin_form_section_subtitle">ID: {innerItem.id}</div>
                                <div className="admin_form_section_wrap_grid">
                                  <div className="admin_form_section_wrap_grid_child">
                                    <InputLabelFor
                                      label={'Название'}
                                      value={innerItem.product}
                                      id={`order_product_${innerItem.id}`}
                                      name={`order_product_${innerItem.id}`}
                                      type={'text'}
                                      readOnly={true}
                                      disabled={true}
                                    />
                                  </div>
                                  <div className="admin_form_section_wrap_grid_child">
                                    <InputLabelFor
                                      label={'Номер сервиса'}
                                      value={innerItem.service}
                                      id={`order_service_${innerItem.id}`}
                                      name={`order_service_${innerItem.id}`}
                                      type={'text'}
                                      readOnly={true}
                                      disabled={true}
                                    />
                                  </div>
                                  <div className="admin_form_section_wrap_grid_child">
                                    <InputLabelFor
                                      label={'Количество'}
                                      value={innerItem.count}
                                      id={`order_count_${innerItem.id}`}
                                      name={`order_count_${innerItem.id}`}
                                      type={'text'}
                                      readOnly={true}
                                      disabled={true}
                                    />
                                  </div>
                                  <div className="admin_form_section_wrap_grid_child">
                                    <InputLabelFor
                                      label={'Стоимость (цена за штуку - {rate})'}
                                      value={innerItem.price}
                                      id={`order_price_${innerItem.id}`}
                                      name={`order_price_${innerItem.id}`}
                                      type={'text'}
                                      readOnly={true}
                                      disabled={true}
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      ))}
                  </div>
                )}
                <BtnBase className={'admin_submit admin_submit_center'} btnText={'Обновить'} type={'submit'} />
              </div>
            </div>
          </InnerForm>
        )
      )}
    </>
  );
};

export default AdminToolsItem;
