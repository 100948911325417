import React, { FormEvent, useEffect, useState } from 'react';
import Switch from '../admin-ui-kit/input/Switch';
import InnerForm from '../admin-ui-kit/form/InnerForm';
import InputBase from '../admin-ui-kit/input/InputBase';
import InputLabelFor from '../admin-ui-kit/input/InputLabelFor';
import TextareaLabelFor from '../admin-ui-kit/input/TextareaLabelFor';
import BtnBase from '../admin-ui-kit/btn/BtnBase';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { fetchApi } from '../../../content/components/helpers/api';
import BaseSelect from '../admin-ui-kit/input/BaseSelect';

// Определение типа для объекта страницы
type PageType = {
  h1: string;
  title: string;
  updated: number;
  description: string;
  breadcrumbs: string;
  text: string;
  seoUrl: string;
  banner: {
    text: string;
    image: string;
  };
  icon: {
    big: string;
    small: string;
  };
};

// Определение общего типа для элементов в массиве socialDataState
type SocialDataItem = {
  _id: string;
  parentId: string;
  name: string;
  type: string;
  active: boolean;
  category: string;
  page: PageType;
};

// Определение общего типа для элементов в массиве socialDataState
type SocialReviewItem = {
  _id: string;
  parentId: string;
  name: string;
  type: string;
  active: boolean;
  isNew: boolean;
  updated: number;
  date: number;
  text: string;
};

// Определяем тип для функции обновления данных
type UpdateSocialDataStateFunction = () => Promise<void>;

// Определение типа для пропсов компонента AdminSocial
type AdminReviewItemProps = {
  socialDataState: SocialReviewItem[];
  socialAllServices?: SocialDataItem[];
  typeString: string;
  updateDataState: UpdateSocialDataStateFunction;
};

const AdminReviewItem = ({ socialDataState, typeString, updateDataState }: AdminReviewItemProps) => {
  const navigate = useNavigate();
  const { _id } = useParams();
  // жду выполнения запроса фетча
  const [isLoading, setIsLoading] = useState(true);

  // жду завершения обновления элемента
  const [isLoadingData, setIsLoadingData] = useState(false);

  const [socialItem, setSocialItem] = useState<any>(null);

  // список категорий для выбора
  const [socialDataCategoryArr, setSocialDataCategoryArr] = useState<any[]>([]);

  const [itemCategory, setItemCategory] = useState<any>(null);
  const [itemElementId, setItemElementId] = useState<any>(null);
  const [itemName, setItemName] = useState<any>(null);
  const [itemType, setItemType] = useState<any>(null);
  const [itemActive, setItemActive] = useState<any>(null);
  const [itemIsNew, setItemIsNew] = useState<any>(null);
  const [itemUpdated, setItemUpdated] = useState<any>(null);
  const [itemDate, setItemDate] = useState<any>(null);
  const [itemText, setItemText] = useState<any>(null);

  const [socialAllServices, setSocialAllServices] = useState<any>(null);

  const [socialCatServices, setSocialCatServices] = useState<any>(null);

  const [socialServicesPath, setSocialServicesPath] = useState<any>(null);

  const [socialServicesName, setSocialServicesName] = useState<any>(null);

  const fetchCatalogRootData = async () => {
    try {
      const newDataCatalogRoot = await fetchApi('GET', 'admin/services/get_root_categories', {});
      setSocialAllServices(newDataCatalogRoot);
    } catch (error) {
      console.error('Error fetching social item:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCatalogCatData = async (id: any) => {
    try {
      if (id) {
        const newDataCatalogCat = await fetchApi('GET', `admin/services/get_categories/${id}`, {});
        const categoriesCat = getCategoryOptions(newDataCatalogCat);
        setSocialCatServices(categoriesCat);
      } else {
        console.error("no social item to fetch");
      }
    } catch (error) {
      console.error('Error fetching social item:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCatalogPath = async (id: any) => {
    try {
      const newDataCatalogPath = await fetchApi('GET', `path/get/${id}`, {});
      if (newDataCatalogPath) {
        setSocialServicesPath(newDataCatalogPath[0].seo_url);
      }
    } catch (error) {
      console.error('Error fetching social item:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCatalogRootData();
  }, [_id]);

  useEffect(() => {
    if (itemCategory) {
      fetchCatalogCatData(itemCategory);
    }
  }, [itemCategory]);

  useEffect(() => {
    if (itemElementId && socialCatServices && socialCatServices.length > 0) {
      const elName = socialCatServices.find((item: any) => item.value === itemElementId);
      if (elName) {
        setSocialServicesName(elName?.text);
      }
      fetchCatalogPath(itemElementId);
    }
  }, [itemElementId, socialCatServices]);

  useEffect(() => {
    const fetchSocialItem = async () => {
      try {
        const socialItem = socialDataState.find((item) => item._id === _id);
        if (!socialItem) {
          navigate('/404');
        } else {
          setSocialItem(socialItem);
        }
      } catch (error) {
        console.error('Error fetching social item:', error);
        navigate('/404');
      } finally {
        setIsLoadingData(false);
        if (socialAllServices && socialAllServices.length > 0) {
          setIsLoading(false);
        }
      }
    };

    if (socialDataState.length > 0) {
      fetchSocialItem();
    }
  }, [socialDataState, _id, navigate]);

  useEffect(() => {
    if (socialItem) {
      setItemCategory(socialItem.parentId);
      setItemElementId(socialItem.elementId);
      setSocialServicesPath(socialItem.elementLink);
      setSocialServicesName(socialItem.elementName);
      setItemName(socialItem.name);
      setItemType(socialItem.type);
      setItemActive(socialItem.active);
      setItemIsNew(false);
      setItemUpdated(socialItem.updated);
      setItemDate(socialItem.date);
      setItemText(socialItem.text);
    }
  }, [socialItem]);

  const [savedItemArr, setSavedItemArr] = useState<any>(null);

  const handeSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setSavedItemArr({
      _id: socialItem._id,
      parentId: itemCategory,
      elementId: itemElementId,
      elementLink: socialServicesPath,
      elementName: socialServicesName,
      // name: itemName,
      name: itemName,
      type: itemType,
      active: itemActive,
      isNew: itemIsNew,
      updated: Date.now(),
      date: itemDate,
      text: itemText
    });
  };
  // после изменения savedItemArr сохраняю его
  useEffect(() => {
    if (savedItemArr) {
      (async () => {
        console.log(savedItemArr);
        // setIsLoadingData(true);
        let response;
        response = await fetchApi('POST', `/review/update/${_id}`, savedItemArr);
        if (response && !response.error) {
          setSocialItem(response);
          updateDataState();
          alert('Отзыв успешно обновлён!');
          navigate('/admin/reviews'); // Редирект на страницу блога
        } else {
          console.error('Failed to save item:', response.error);
          alert('Что-то пошло не так...');
          // Обработка ошибки, например, отображение сообщения об ошибке для пользователя
        }
      })();
    }
  }, [savedItemArr]);

  // Функция для получения массива категорий
  const getCategoryOptions = (data: SocialDataItem[]): { text: string; value: string }[] => {
    const categoryOptions = [
      ...data.map((category: SocialDataItem) => ({
        text: category.name, // Использовать название категории в качестве текста
        value: category._id // Использовать _id категории в качестве значения
      }))
    ];

    return categoryOptions;
  };

  // заполняю массив категориями для их выбора в элементе
  useEffect(() => {
    if (socialAllServices && socialAllServices.length > 0) {
      const categories = getCategoryOptions(socialAllServices);
      setSocialDataCategoryArr(categories);
      setIsLoading(false);
    }
  }, [socialAllServices]);

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : !socialItem ? (
        <div>Item not found</div>
      ) : (
        socialItem &&
        socialAllServices &&
        socialAllServices.length > 0 && (
          <InnerForm onSubmit={handeSubmit}>
            <div className="admin_heading">
              <div className="admin_heading_title">{itemName}</div>
              <BtnBase className={'admin_submit'} btnText={'Обновить'} type={'submit'} />
            </div>
            <div className="admin_container">
              <div className="admin_form">
                <div className="admin_form_section_wrap">
                  <div className="admin_form_section_title">Системные данные</div>
                  <div className="admin_form_row">
                    <Switch text={'Активно'} id={'active'} name={'active'} isChecked={itemActive} onChange={() => setItemActive(!itemActive)} />
                  </div>
                  <div className="admin_form_row">
                    <InputLabelFor label={'ID'} value={socialItem._id ? socialItem._id : ''} id={'id'} name={'id'} type={'text'} readOnly={true} disabled={true} />
                  </div>
                  <div className="admin_form_row">
                    <InputLabelFor
                      label={'Дата создания'}
                      // value={itemDate ? format(new Date(itemDate), 'dd.MM.yyyy, HH:mm') : format(Date.now(), 'dd.MM.yyyy, HH:mm')}
                      value={format(new Date(itemDate), 'yyyy-MM-dd')}
                      id={'itemDate'}
                      name={'itemDate'}
                      type={'date'}
                      onChange={(event) => {
                        setItemDate(event.target.value);
                      }}
                    // readOnly={true}
                    // disabled={true}
                    />
                  </div>
                  <div className="admin_form_row">
                    <InputLabelFor
                      label={'Дата обновления'}
                      value={itemUpdated ? format(new Date(itemUpdated), 'dd.MM.yyyy, HH:mm') : format(Date.now(), 'dd.MM.yyyy, HH:mm')}
                      id={'itemUpdated'}
                      name={'itemUpdated'}
                      type={'text'}
                      readOnly={true}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="admin_form_section_wrap">
                  <div className="admin_form_section_title">Содержимое</div>
                  <div className="admin_form_row">
                    <InputLabelFor label={'Имя'} value={itemName ? itemName : ''} id={'faq_name'} name={'faq_name'} type={'text'} onChange={(event) => setItemName(event.target.value)} />
                  </div>
                  <div className="admin_form_row">
                    <TextareaLabelFor label={'Текстовый блок'} value={itemText ? itemText : ''} id={'text'} name={'text'} onChange={(event) => setItemText(event.target.value)} />
                  </div>
                  <div className="admin_form_row">
                    <label htmlFor={'type_of_item'} className={`text_label`}>
                      {'Выберите соц. сеть'}
                    </label>
                    {!socialDataCategoryArr || !socialDataCategoryArr || socialDataCategoryArr?.length === 0 ? (
                      'loading...'
                    ) : (
                      <BaseSelect
                        className={'personal_select base_input_wide'}
                        selectItems={socialDataCategoryArr}
                        name={'type_of_item'}
                        id={'type_of_item'}
                        innerValue={itemCategory ? itemCategory : ''}
                        onChange={(value) => setItemCategory(value)}
                      />
                    )}
                  </div>
                  <div className="admin_form_row">
                    <label htmlFor={'type_of_item'} className={`text_label`}>
                      {'Выберите категорию'}
                    </label>
                    {!socialCatServices || !socialCatServices || socialCatServices?.length === 0 ? (
                      'loading...'
                    ) : (
                      <BaseSelect
                        className={'personal_select base_input_wide'}
                        selectItems={socialCatServices}
                        name={'type_of_item'}
                        id={'type_of_item'}
                        innerValue={itemElementId ? itemElementId : ''}
                        onChange={(value) => setItemElementId(value)}
                      />
                    )}
                  </div>
                  <div className="admin_form_row">
                    <InputLabelFor label={'Ссылка на услугу'} value={socialServicesPath ? socialServicesPath : ''} id={'link'} name={'link'} type={'text'} readOnly={true} disabled={true} />
                  </div>
                </div>
                <BtnBase className={'admin_submit admin_submit_center'} btnText={'Обновить'} type={'submit'} />
              </div>
            </div>
          </InnerForm>
        )
      )}
    </>
  );
};

export default AdminReviewItem;
