import React, { useRef, useState } from 'react';
import MainAccordionItem from './MainAccordionItem';

// Определение общего типа для элементов в массиве socialDataState
type SocialReviewItem = {
  _id: string;
  parentId: string;
  name: string;
  type: string;
  active: boolean;
  updated: number;
  text: string;
};

// Определение типа для пропсов компонента AdminReview
type MainFaqProps = {
  socialDataState: SocialReviewItem[];
  typeString: string;
};

const MainFaq = ({ socialDataState, typeString }: MainFaqProps) => {
  const [active, setActive] = useState<string | null>(null);
  const handleToggle = (index: string) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };

  return (
    <div className="main_section main_faq_section">
      <div className="container">
        <div className="main_faq">
          <div className="main_section_title">FAQ</div>
          <div className="main_faq_wrap">
            {socialDataState.length > 0 && socialDataState.map((faq, index) => <MainAccordionItem key={index} active={active} handleToggle={handleToggle} faq={faq} />)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainFaq;
