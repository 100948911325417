import React, { useContext, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import AdminLayout from './components/AdminLayout';
import { Authentification } from '../content/components/helpers/auth';
import { UserDataContext } from '../content/hooks/UserDataContext';

type AdminRouteProps = {
  userName?: string;
  isLogged: boolean;
};

const AdminRoute = ({ isLogged, userName }: AdminRouteProps) => {
  const [isAdmin, setIsAdmin] = useState<any>(null);
  const [tokenInst, setTokenInst] = useState<any>(false);
  const navigate = useNavigate();
  const isUserLogged = useContext(UserDataContext).isUserLogin;

  useEffect(() => {
    const loadToken = async () => {
      const isAdminInst = Authentification.getInstance().isAdmin();
      const token = Authentification.getInstance().getToken();
      setIsAdmin(isAdminInst);
      setTokenInst(token);

      if (!token || token.length === 0) {
        navigate('/');
      }
    };

    loadToken();
  }, [isLogged, navigate, isUserLogged]);

  if (!tokenInst || tokenInst.length === 0) {
    return null; // Не отображаем компонент пока нет токена
  }

  return isAdmin ? <AdminLayout /> : <Navigate to="/personal" />;
};

export default AdminRoute;
