import React, { useEffect, useState } from 'react';
import BtnBase from '../../admin-ui-kit/btn/BtnBase';
import { format } from 'date-fns';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { fetchApi } from '../../../../content/components/helpers/api';

// Определение типа для объекта страницы
type PageType = {
  h1: string;
  title: string;
  updated: number;
  description: string;
  breadcrumbs: string;
  text: string;
  seoUrl: string;
  banner: {
    text: string;
    image: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
  icon: {
    big: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
    small: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
};

// Определение общего типа для элементов в массиве socialDataState
type SocialDataItem = {
  _id: string;
  parentId: string;
  name: string;
  customName: string;
  type: string;
  active: boolean;
  page: PageType;
};

// Определение типа для пропсов компонента AdminBlog
type AdminCatalogItemListProps = {
  socialDataState?: SocialDataItem[];
  typeString?: string;
};

const AdminCatalogItemList = (props: AdminCatalogItemListProps) => {
  const navigate = useNavigate();
  const { _id, _rootId, _parentId } = useParams();

  const [customSocialArr, setCustomSocialArr] = useState<any[]>([]);

  // жду выполнения запроса фетча
  const [isLoading, setIsLoading] = useState(true);

  // Отдельно функция для обновления Smm tools
  const fetchCatalogItemsData = async () => {
    try {
      if (_parentId) {
        const newDataCatalogItems = await fetchApi('GET', `admin/services/get_category_items/${_parentId}`, {});
        // console.log('_parentId');
        // console.log(newDataCatalogItems);
        setCustomSocialArr(newDataCatalogItems);
      } else if (_rootId) {
        const newDataCatalogItems = await fetchApi('GET', `admin/services/get_items/${_rootId}`, {});
        // console.log('_rootId');
        // console.log(newDataCatalogItems);
        setCustomSocialArr(newDataCatalogItems);
      } else {
        const newDataCatalogItems = await fetchApi('GET', `admin/services/get_items`, {});
        // console.log('all');
        // console.log(newDataCatalogItems);
        setCustomSocialArr(newDataCatalogItems);
      }
    } catch (error) {
      console.error('Error fetching social item:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCatalogItemsData();
  }, [_rootId, _parentId]);

  return (
    <>
      <div className="admin_heading">
        <div className="admin_heading_title">{'Список элементов'}</div>
        {/* <BtnBase btnLink="new" className={'admin_submit'} btnText={'Создать'} type={'submit'} /> */}
      </div>
      <>
        {isLoading ? (
          <div>Loading...</div>
        ) : !customSocialArr ? (
          <div>Items not found</div>
        ) : (
          <div className="admin_table_wrap">
            <table className="admin_table_list">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Provider</th>
                  <th>ID</th>
                  <th>Status</th>
                  <th>Updated</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {customSocialArr.map((item) => (
                  <tr key={item._id}>
                    <td>{item.name} {item.customName ? ( <hr/> ) : null } {item.customName}</td>
                    <td>{item.serviceProvider}</td>
                    <td>{item._id}</td>
                    <td>
                      {item.activeApi === false ? (
                        <span className="status_el status_disabled">Disabled</span>
                      ) : item.active ? (
                        <span className="status_el status_active">Active</span>
                      ) : (
                        <span className="status_el status_disabled">Disabled</span>
                      )}
                    </td>
                    <td>{format(new Date(item.page.updated), 'dd.MM.yyyy, HH:mm')}</td>
                    <td>
                      <NavLink to={`edit/${item._id}`}>Edit</NavLink>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </>
    </>
  );
};

export default AdminCatalogItemList;
