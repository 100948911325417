import React, { useEffect, useState } from 'react';
import LeftMenu from '../components/template_parts/LeftMenu';
import BtnOutline from '../components/ui-kit/BtnOutline';

// Передаю менюхи
type Items = {
  name: string;
  link: string;
  icon: {
    parentId?: number;
    id: string;
    filename: string;
    filetype: string;
    datetime: string;
    filesize: string;
    filepath: string;
  };
  index: string;
};

// Определение общего типа для элементов в массиве socialDataState
type HeaderProps = {
  _id: string;
  name: string;
  type: string;
  subtype: string;
  items: Items[];
};

type ThankYouPageProps = {
  isMobile?: boolean;
  menuDataState: HeaderProps[];
};

const ThankYouPage = ({ menuDataState, isMobile }: ThankYouPageProps) => {
  const [leftMenuTopList, setLeftMenuTopList] = useState<any>(null);
  const [leftMenuBottomList, setLeftMenuBottomList] = useState<any>(null);

  useEffect(() => {
    if (menuDataState) {
      // Заполняю менюхи
      const foundSidebarOneMenu = menuDataState.find((item) => item.subtype === 'sitebar_menu_1');
      const foundSidebarTwoMenu = menuDataState.find((item) => item.subtype === 'sitebar_menu_2');
      if (foundSidebarOneMenu && foundSidebarTwoMenu) {
        setLeftMenuTopList(foundSidebarOneMenu.items);
        setLeftMenuBottomList(foundSidebarTwoMenu.items);
      }
    }
  }, [menuDataState]);
  return (
    <div className="inner">
      <div className="container">
        {isMobile ? (
          <div className="inner_col_content">
            <div className="not_found_title">{`Спасибо за заказ!`}</div>
            <div className="thank_you_page">
              <img src="/content/ThankYouPage.png" alt="" />
              <BtnOutline btnLink={`/`} className="btn_wide inner_col_content_nested_services_item_btn" btnText="На главную" />
            </div>
          </div>
        ) : (
          <div className="inner_wrap">
            <div className="inner_col shadow_border border_rounded">
              <LeftMenu leftMenuTopList={leftMenuTopList} leftMenuBottomList={leftMenuBottomList} />
            </div>
            <div className="inner_col_content">
              <div className="not_found_title">{`Спасибо за заказ!`}</div>
              <div className="thank_you_page">
                <img src="/content/ThankYouPage.png" alt="" />
                <BtnOutline btnLink={`/`} className="btn_wide inner_col_content_nested_services_item_btn" btnText="На главную" />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ThankYouPage;
