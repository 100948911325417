import React, { FormEvent, useState, useEffect, useContext } from 'react';
import BtnBase from '../ui-kit/btn/BtnBase';
import PersonalLoginForm from '../ui-kit/form/PersonalLoginForm';
import InputBase from '../ui-kit/input/InputBase';
import BtnOutline from '../ui-kit/BtnOutline';
import { Authentification } from '../helpers/auth';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { UserDataContext } from '../../hooks/UserDataContext';

type Props = {
  isLogged: boolean;
};

const PersonalLogin = (props: Props) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLogged, setIsLogged] = useState(props.isLogged);
  const [isError, setIsError] = useState<any>(null);
  const navigate = useNavigate();
  const lastUrl = useContext(UserDataContext).lastUrl;
  const isUserLogged = useContext(UserDataContext).isUserLogin;
  const location = useLocation();

  useEffect(() => {
    if (Authentification.getInstance().getToken()) {
      redirectToLastUrl();
    }
  }, [isLogged]);

  const redirectToLastUrl = () => {
    navigate(lastUrl ? (lastUrl !== '/login' ? lastUrl : '/personal') : '/');
  }

  const handeSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // console.log('username:', username);
    // console.log('password:', password);
    const result = await Authentification.getInstance().login({ login: username, password: password });
    if (result) {
      setIsLogged(true);
      setIsError(false);
      redirectToLastUrl();
    } else {
      setIsError(true);
    }
  };
  return (
    <div className="personal">
      <div className="container">
        <div className="personal_wrap">
          <div className="personal_title">Логин</div>
          <div className="personal_login personal_form">
            <PersonalLoginForm onSubmit={handeSubmit} classMame="personal_login_form">
              <div className="personal_input_row">
                <InputBase
                  type={'text'}
                  name={'useremail'}
                  value={username}
                  placeholder={'Введите логин'}
                  className=""
                  inputClassName={`base_input_wide ${isError && 'error'}`}
                  hasLink={true}
                  innerLink={'/forgot'}
                  textLink={'Забыл пароль?'}
                  label={'Login'}
                  onChange={(event) => {
                    setUsername(event.target.value);
                    setIsError(false);
                  }}
                />
              </div>
              <div className="personal_input_row">
                <InputBase
                  label={'Пароль'}
                  name={'password'}
                  type={'password'}
                  placeholder={'Введите пароль'}
                  className=""
                  inputClassName={`base_input_wide ${isError && 'error'}`}
                  hasLink={true}
                  innerLink={'/'}
                  // textLink={'Забыли пароль?'}
                  onChange={(event) => {
                    setPassword(event.target.value);
                    setIsError(false);
                  }}
                />
              </div>
              {isError && (
                <div className="personal_input_row">
                  <div className="form_text_row">Неверный логин или пароль!</div>
                </div>
              )}
              <BtnOutline className="btn_wide_full login_btn" btnText="Войти" type={'submit'} />
              <div className="form_text_row">
                <div className="form_text_row_text">Нет аккаунта?</div>
                <NavLink to={'/register'} className="label_box_row_link">
                  Зарегистрироваться
                </NavLink>
              </div>
            </PersonalLoginForm>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalLogin;
