import React, { FormEvent, useEffect, useState } from 'react';
import Switch from '../admin-ui-kit/input/Switch';
import InnerForm from '../admin-ui-kit/form/InnerForm';
import InputBase from '../admin-ui-kit/input/InputBase';
import InputLabelFor from '../admin-ui-kit/input/InputLabelFor';
import TextareaLabelFor from '../admin-ui-kit/input/TextareaLabelFor';
import BtnBase from '../admin-ui-kit/btn/BtnBase';
import FileUploadWithPreview from '../admin-ui-kit/input/FileUploadWithPreview';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import BaseSelect from '../admin-ui-kit/input/BaseSelect';
import { fetchApi } from '../../../content/components/helpers/api';

// Определение общего типа для элементов в массиве socialDataState
type SocialDataItem = {
  _id: string;
  login: string;
  active: boolean;
  email: string;
  role: {
    registred: boolean;
  };
};

// Определение общего типа для элементов в массиве socialDataState
type ProductDataItem = {
  id: string;
  service: string;
  product: string;
  price: string;
  count: string;
  rate: string;
  link: string;
  promo: string;
};

// Определение общего типа для элементов в массиве socialDataState
type OrderDataItem = {
  _id: string;
  userId: string;
  date: string;
  started: boolean;
  payed: boolean;
  price: string;
  products: ProductDataItem[];
};

// Определяем тип для функции обновления данных
type UpdateSocialDataStateFunction = () => Promise<void>;

// Определение типа для пропсов компонента AdminSocial
type AdminOrdersItemProps = {
  socialDataState: SocialDataItem[];
  orderDataState: OrderDataItem[];
  updateDataState: UpdateSocialDataStateFunction;
};

const AdminOrdersItem = ({ updateDataState, socialDataState, orderDataState }: AdminOrdersItemProps) => {
  const navigate = useNavigate();
  const { _id } = useParams();

  // жду выполнения запроса фетча
  const [isLoading, setIsLoading] = useState(true);

  // жду завершения обновления элемента
  const [isLoadingData, setIsLoadingData] = useState(false);

  // объект одного элемента
  const [socialItem, setSocialItem] = useState<any>(null);

  const [userDataStateArr, setUserDataStateArr] = useState<any[]>([]);

  // массив с данными для сохранения
  const [savedItemArr, setSavedItemArr] = useState<any>(null);

  //
  const [itemId, setItemId] = useState<any>('');
  const [userId, setUserId] = useState<any>('');
  const [userName, setUserName] = useState<any>('');
  const [userBalance, setUserBalance] = useState<any>('');
  const [itemDate, setItemDate] = useState<any>('');
  const [itemStarted, setItemStarted] = useState<any>(false);
  const [itemPayed, setItemPayed] = useState<any>(false);
  const [itemPrice, setItemPrice] = useState<any>('');

  useEffect(() => {
    const fetchSocialItem = async () => {
      try {
        const socialItem = orderDataState.find((item) => item._id === _id);
        if (!socialItem) {
          navigate('/404');
        } else {
          const usersArr = socialDataState.find((item) => item._id === socialItem.userId);
          if (usersArr) {
            // setUserName(usersArr.login);
            setUserName(usersArr.email);
          }
          setSocialItem(socialItem);
        }
      } catch (error) {
        console.error('Error fetching social item:', error);
        navigate('/404');
      } finally {
        setIsLoading(false);
        setIsLoadingData(false);
      }
    };

    if (orderDataState.length > 0 && socialDataState.length > 0) {
      fetchSocialItem();
    }
  }, [orderDataState, socialDataState, _id, navigate]);

  useEffect(() => {
    if (socialItem) {
      setItemId(socialItem._id ? socialItem._id : '');
      setUserId(socialItem.userId ? socialItem.userId : '');
      setItemDate(socialItem.date ? socialItem.date : '');
      setItemStarted(socialItem.started ?? false);
      setItemPayed(socialItem.payed ?? false);
      setItemPrice(socialItem.price ? socialItem.price : '');
    }
  }, [socialItem]);

  // функция формирования массива для сохранения
  const handeSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // setSavedItemArr({
    //   _id: socialItem._id,
    //   login: itemLogin,
    //   active: itemActive,
    //   email: itemEmail,
    //   role: {
    //     registred: socialItem.role.registred
    //   }
    // });
  };

  // после изменения savedItemArr сохраняю его
  useEffect(() => {
    if (savedItemArr) {
      (async () => {
        console.log(savedItemArr);
        // setSocialItem(await fetchApi('POST', `/users/updateInfo/${_id}`, savedItemArr));
        // updateDataState();
      })();
    }
  }, [savedItemArr]);

  const handleCancelOrder = async () => {
    const cancelOrder = await fetchApi('POST', '/services/cancel_order', { orderId: itemId });
    console.log(cancelOrder);
    if (cancelOrder.status && cancelOrder.status == 'fail') {
      alert("Отмена не прошла с ошибкой: " + cancelOrder.message)
    } else {
      alert("Заказ " + itemId + "отменён")
    }
    
    console.log('cancel');
  };

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : !socialItem ? (
        <div>Item not found</div>
      ) : (
        socialItem && (
          <InnerForm onSubmit={handeSubmit}>
            <div className="admin_heading">
              <div className="admin_heading_title">
                Заказ: <strong>{itemId}</strong>
              </div>
            </div>
            <div className={`admin_container ${isLoadingData ? 'loading' : ''}`}>
              <div className="admin_form">
                <div className="admin_form_section_wrap order_inner">
                {socialItem.order_id && (!socialItem.status || socialItem.status === 'Pending' || socialItem.status === 'Partial' || socialItem.status === 'Processing' || socialItem.status === 'In progress') && (
                  <BtnBase className={'admin_submit order_cancel'} btnText={'Отменить заказ'+socialItem.status} type={'button'} onClick={handleCancelOrder} />
                )}
                  <div className="admin_form_section_title">
                    Пользователь: {userName} (ID пользователя: {userId})
                  </div>
                  <div className="admin_form_row">
                    <div className="admin_form_section_wrap_grid">
                      <div className="admin_form_section_wrap_grid_child">
                        <div className="admin_form_row">
                          <InputLabelFor label={'Дата заказа'} value={format(new Date(socialItem.date), 'dd.MM.yyyy, HH:mm')} id={'date'} name={'date'} type={'text'} readOnly={true} disabled={true} />
                        </div>
                        <div className="admin_form_row">
                          <InputLabelFor label={'Итоговая стоимость заказа (RUB)'} value={itemPrice} id={'price'} name={'price'} type={'text'} readOnly={true} disabled={true} />
                        </div>
                      </div>
                      <div className="admin_form_section_wrap_grid_child">
                        <div className="admin_form_row">
                          <InputLabelFor
                            className={itemPayed ? 'filled_green' : 'filled_red'}
                            label={'Статус оплаты'}
                            value={itemPayed ? 'Оплачен' : 'Не оплачен'}
                            id={'payed'}
                            name={'payed'}
                            type={'text'}
                            readOnly={true}
                            disabled={true}
                          />
                        </div>
                        <div className="admin_form_row">
                          <InputLabelFor
                            className={itemStarted ? 'filled_green' : 'filled_red'}
                            label={'Статус заказа (started)'}
                            value={itemStarted ? (socialItem.status ? socialItem.status : 'Начат') : 'Ожидает'}
                            id={'started'}
                            name={'started'}
                            type={'text'}
                            readOnly={true}
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>
                    {socialItem.products &&
                      Object.values(socialItem.products).map(
                        (innerItem: any, innerIndex: number) => (
                          console.log(innerItem),
                          (
                            <div className="order_product_list" key={innerIndex}>
                              <div className="admin_form_section_subtitle">
                                Провайдер: <strong>{socialItem?.service_provider}</strong>, номер заказа (провайдер): <strong>{socialItem?.order_id}</strong>
                              </div>
                              <div className="admin_form_section_subtitle">ID продукта:{innerItem.id}</div>
                              <div className="admin_form_section_wrap_grid">
                                <div className="admin_form_section_wrap_grid_child">
                                  <InputLabelFor
                                    label={'Название'}
                                    value={innerItem.product}
                                    id={`order_product_${innerItem.id}`}
                                    name={`order_product_${innerItem.id}`}
                                    type={'text'}
                                    readOnly={true}
                                    disabled={true}
                                  />
                                </div>
                                <div className="admin_form_section_wrap_grid_child">
                                  <InputLabelFor
                                    label={'Номер сервиса'}
                                    value={innerItem.service}
                                    id={`order_service_${innerItem.id}`}
                                    name={`order_service_${innerItem.id}`}
                                    type={'text'}
                                    readOnly={true}
                                    disabled={true}
                                  />
                                </div>
                                <div className="admin_form_section_wrap_grid_child">
                                  <InputLabelFor
                                    label={'Количество'}
                                    value={innerItem.count}
                                    id={`order_count_${innerItem.id}`}
                                    name={`order_count_${innerItem.id}`}
                                    type={'text'}
                                    readOnly={true}
                                    disabled={true}
                                  />
                                </div>
                                <div className="admin_form_section_wrap_grid_child">
                                  <InputLabelFor
                                    label={'Стоимость (цена за штуку - {rate})'}
                                    value={innerItem.price}
                                    id={`order_price_${innerItem.id}`}
                                    name={`order_price_${innerItem.id}`}
                                    type={'text'}
                                    readOnly={true}
                                    disabled={true}
                                  />
                                </div>
                              </div>
                            </div>
                          )
                        )
                      )}
                  </div>
                </div>
                {/* <BtnBase className={'admin_submit admin_submit_center'} btnText={'Обновить'} type={'submit'} /> */}
              </div>
            </div>
          </InnerForm>
        )
      )}
    </>
  );
};

export default AdminOrdersItem;
