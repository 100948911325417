import React, { FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import Switch from '../admin-ui-kit/input/Switch';
import InnerForm from '../admin-ui-kit/form/InnerForm';
import InputBase from '../admin-ui-kit/input/InputBase';
import InputLabelFor from '../admin-ui-kit/input/InputLabelFor';
import TextareaLabelFor from '../admin-ui-kit/input/TextareaLabelFor';
import BtnBase from '../admin-ui-kit/btn/BtnBase';
import FileUploadWithPreview from '../admin-ui-kit/input/FileUploadWithPreview';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import BaseSelect from '../admin-ui-kit/input/BaseSelect';
import { fetchApi } from '../../../content/components/helpers/api';
import { Authentification } from '../../../content/components/helpers/auth';

type SubItems = {
  name: string;
  link: string;
  index: string;
  newWindow: boolean;
};

type Items = {
  name: string;
  link: string;
  icon: {
    parentId?: number;
    id: string;
    filename: string;
    filetype: string;
    datetime: string;
    filesize: string;
    filepath: string;
  };
  index: string;
  newWindow: boolean;
  subItems: SubItems[];
};

// Определение общего типа для элементов в массиве socialDataState
type SocialDataItem = {
  _id: string;
  name: string;
  type: string;
  subtype: string;
  items: Items[];
};

// Определяем тип для функции обновления данных
type UpdateSocialDataStateFunction = () => Promise<void>;

// Определение типа для пропсов компонента AdminSocial
type AdminClientMenuItemProps = {
  socialDataState: SocialDataItem[];
  typeString: string;
  updateDataState: UpdateSocialDataStateFunction;
};

const AdminClientMenuItem = ({ updateDataState, socialDataState, typeString }: AdminClientMenuItemProps) => {
  const navigate = useNavigate();
  const { _id } = useParams();

  const [subItemList, setSubItemList] = useState<SubItems[][]>([]);

  const [socialTypeString, setSocialTypeString] = useState(typeString);

  const [socialDataStateArr, setSocialDataStateArr] = useState<any[]>([]);
  const [socialItem, setSocialItem] = useState<any>(null);

  // жду выполнения запроса фетча
  const [isLoading, setIsLoading] = useState(true);

  // жду завершения обновления элемента
  const [isLoadingData, setIsLoadingData] = useState(false);

  const [itemName, setItemName] = useState<any>(null);
  const [itemType, setItemType] = useState<any>(null);
  const [itemSubType, setItemSubType] = useState<any>(null);

  const [iconId, setIconId] = useState<any>(null);
  const [iconFilename, setIconFilename] = useState<any>(null);
  const [iconFiletype, setIconFiletype] = useState<any>(null);
  const [iconFilesize, setIconFilesize] = useState<any>(null);
  const [iconFilepath, setIconFilepath] = useState<any>(null);
  const [iconDatetime, setIconDatetime] = useState<any>(null);

  const [itemList, setItemList] = useState<Items[]>([]);

  const [itemListIcon, setItemListIcon] = useState<Items[]>([]);

  const [savedItemArr, setSavedItemArr] = useState<any>(null);

  const handleAddSocialItem = () => {
    console.log('Adding new social item');
    setItemList((prevItems) => [
      ...prevItems,
      {
        name: '',
        link: '',
        icon: {
          id: '',
          filetype: '',
          datetime: '',
          filesize: '',
          filepath: '',
          filename: ''
        },
        newWindow: false,
        index: '100',
        subItems: []
      }
    ]);
  };

  const handleRemoveSocialItem = (index: number) => {
    setItemList((prevItems) => {
      const updatedSocialItems = [...prevItems];
      updatedSocialItems.splice(index, 1);
      return updatedSocialItems;
    });
  };

  const handleSocialNameChange = (index: number, value: string) => {
    const updatedSocialItems = [...itemList];
    updatedSocialItems[index].name = value;
    setItemList(updatedSocialItems);
  };

  const handleSocialLinkChange = (index: number, value: string) => {
    const updatedSocialItems = [...itemList];
    updatedSocialItems[index].link = value;
    setItemList(updatedSocialItems);
  };

  const handleSocialIndexChange = (index: number, value: string) => {
    const updatedSocialItems = [...itemList];
    updatedSocialItems[index].index = value;
    setItemList(updatedSocialItems);
  };

  const handleSocialNewChange = (index: number) => {
    const updatedSocialItems = [...itemList];
    updatedSocialItems[index].newWindow = !updatedSocialItems[index].newWindow;
    setItemList(updatedSocialItems);
  };

  const handleSocialIconChange = (imageData: any, index: number) => {
    const updatedSocialItems = itemListIcon;
    updatedSocialItems[index] = {
      ...updatedSocialItems[index],
      icon: {
        parentId: index,
        id: imageData.id,
        filename: imageData.filename,
        filetype: imageData.filetype,
        filesize: imageData.filesize,
        filepath: imageData.filepath,
        datetime: imageData.datetime
      }
    };
    setItemListIcon(updatedSocialItems);
  };

  const handleAddSubItem = (index: number) => {
    setSubItemList((prevItems) => {
      const updatedItems = [...prevItems];
      if (!updatedItems[index]) {
        updatedItems[index] = [];
      }
      updatedItems[index].push({
        name: '',
        link: '',
        newWindow: false,
        index: '100'
      });
      return updatedItems;
    });
  };

  const handleRemoveSubItem = (parentIndex: number, subIndex: number) => {
    setSubItemList((prevItems) => {
      const updatedItems = [...prevItems];
      if (updatedItems[parentIndex]) {
        updatedItems[parentIndex].splice(subIndex, 1);
      }
      return updatedItems;
    });
  };

  const handleSubItemNameChange = (parentIndex: number, subIndex: number, value: string) => {
    const updatedItems = [...subItemList];
    if (updatedItems[parentIndex]) {
      updatedItems[parentIndex][subIndex].name = value;
    }
    setSubItemList(updatedItems);
  };

  const handleSubItemLinkChange = (parentIndex: number, subIndex: number, value: string) => {
    const updatedItems = [...subItemList];
    if (updatedItems[parentIndex]) {
      updatedItems[parentIndex][subIndex].link = value;
    }
    setSubItemList(updatedItems);
  };

  const handleSubItemNewChange = (parentIndex: number, subIndex: number) => {
    const updatedItems = [...subItemList];
    if (updatedItems[parentIndex]) {
      updatedItems[parentIndex][subIndex].newWindow = !updatedItems[parentIndex][subIndex].newWindow;
    }
    setSubItemList(updatedItems);
  };

  const handleSubItemIndexChange = (parentIndex: number, subIndex: number, value: string) => {
    const updatedItems = [...subItemList];
    if (updatedItems[parentIndex]) {
      updatedItems[parentIndex][subIndex].index = value;
    }
    setSubItemList(updatedItems);
  };

  useEffect(() => {
    const fetchSocialItem = async () => {
      try {
        setSocialDataStateArr(socialDataState);
        // Проверяем существование _id в вашем JSON
        const socialItem = socialDataState.find((item) => item._id === _id);
        if (!socialItem) {
          navigate('/404');
        } else {
          const [extractedItem] = [socialItem];
          setSocialItem(extractedItem);
        }
      } catch (error) {
        console.error('Error fetching social item:', error);
        navigate('/404');
      } finally {
        setIsLoading(false);
      }
    };

    if (socialDataState.length > 0) {
      fetchSocialItem();
    }
  }, [socialDataState, _id, navigate]);

  useEffect(() => {
    if (socialItem) {
      setItemName(socialItem.name);
      setItemType(socialItem.type);
      setItemSubType(socialItem.subtype);
      setSubItemList((prevItems) => {
        let updatedItems = [...prevItems];
        if (prevItems.length === 0) {
          for (let i = 0; i < socialItem.items.length; i++) {
            if (socialItem.items[i].subItems) {
              updatedItems = [...updatedItems, socialItem.items[i].subItems];
            }
          }
        }
        return updatedItems;
      });

      // Сортировка элементов по индексу
      setItemList((prevItems) => {
        const sortedItems = socialItem.items.sort((a: Items, b: Items) => parseInt(a.index) - parseInt(b.index));
        return [...sortedItems];
      });
    }
  }, [socialItem]);

  const handeSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // тут заполняю отдельный массив с иконками и затем вкладываю его с результирующим
    const finalIconArr = itemList;
    for (let i = 0; i < finalIconArr.length; i++) {
      if (subItemList[i]) {
        finalIconArr[i].subItems = subItemList[i];
      }
      if (itemListIcon[i]) {
        finalIconArr[i].icon = {
          id: itemListIcon[i].icon.id,
          filename: itemListIcon[i].icon.filename,
          filetype: itemListIcon[i].icon.filetype,
          filesize: itemListIcon[i].icon.filesize,
          filepath: itemListIcon[i].icon.filepath,
          datetime: itemListIcon[i].icon.datetime
        };
      }
    }

    const savedItemArrObjs = {
      _id: socialItem._id,
      name: itemName,
      type: itemType,
      subtype: itemSubType,
      // new: itemList
      items: finalIconArr
    };
    setSavedItemArr(savedItemArrObjs);

    const saveItem = async () => {
      try {
        if (savedItemArrObjs) {
          setIsLoadingData(true);
          const response = await fetchApi('POST', '/menu/update', savedItemArrObjs);
          if (response) {
            setSocialItem(response);
            updateDataState();
            alert('Меню обновлено!');
          }
        }
      } catch (error) {
        console.error('Error saving item:', error);
        // Обработка ошибки, например, отображение сообщения об ошибке для пользователя
      } finally {
        setIsLoadingData(false);
      }
    };

    saveItem();
  };

  return (
    socialItem && (
      <>
        {isLoading ? (
          <div>Loading...</div>
        ) : !socialItem ? (
          <div>Item not found</div>
        ) : (
          socialItem && (
            <InnerForm onSubmit={handeSubmit}>
              <div className="admin_heading">
                <div className="admin_heading_title">{itemName}</div>
                <BtnBase className={'admin_submit'} btnText={'Обновить'} type={'submit'} />
              </div>
              <div className={`admin_container ${isLoadingData ? 'loading' : ''}`}>
                <div className="admin_form">
                  <div className="admin_form_section_wrap">
                    <div className="admin_form_section_title admin_form_section_title_flex">
                      <div className="admin_form_section_title_text">Элементы меню</div>
                      <BtnBase className={'admin_submit'} btnText={'Добавить'} onClick={handleAddSocialItem} type="button" />
                    </div>
                    {itemList.map((item, index) => (
                      <div className="fileupload_view_row" key={index}>
                        <div className="admin_form_section_wrap_grid_sixth align_center">
                          <div className="admin_form_row">
                            <InputLabelFor
                              label={'Название'}
                              value={item.name}
                              id={`social_name_${index}`}
                              name={`social_name_${index}`}
                              type={'text'}
                              onChange={(event) => handleSocialNameChange(index, event.target.value)}
                            />
                          </div>
                          <div className="admin_form_row">
                            <InputLabelFor
                              label={'Ссылка'}
                              value={item.link}
                              id={`social_link_${index}`}
                              name={`social_name_${index}`}
                              type={'text'}
                              onChange={(event) => handleSocialLinkChange(index, event.target.value)}
                            />
                          </div>
                          <div className="admin_form_row">
                            <InputLabelFor
                              label={'Сортировка'}
                              value={item.index}
                              id={`social_sort_${index}`}
                              name={`social_name_${index}`}
                              type={'text'}
                              onChange={(event) => handleSocialIndexChange(index, event.target.value)}
                            />
                          </div>
                          <div className="admin_form_row">
                            <Switch
                              text={'В новом окне'}
                              id={`social_new_${index}`}
                              name={`social_name_${index}`}
                              isChecked={item.newWindow}
                              onChange={() => handleSocialNewChange(index)}
                            />
                          </div>
                          <FileUploadWithPreview
                            labelText={'Иконка'}
                            name={`social_icon_${index}`}
                            savedId={item.icon ? (item.icon.id ? item.icon.id : '') : ''}
                            savedFilename={item.icon ? (item.icon.filename ? item.icon.filename : '') : ''}
                            savedFiletype={item.icon ? (item.icon.filetype ? item.icon.filetype : '') : ''}
                            savedFilesize={item.icon ? (item.icon.filesize ? item.icon.filesize : '') : ''}
                            savedFilepath={item.icon ? (item.icon.filepath ? item.icon.filepath : '') : ''}
                            savedDatetime={item.icon ? (item.icon.datetime ? item.icon.datetime : '') : ''}
                            saveImage={(imageData: any) => handleSocialIconChange(imageData, index)}
                          />
                          <div className="admin_form_row">
                            <BtnBase className={'admin_submit admin_submit_center'} btnText={'Удалить'} onClick={() => handleRemoveSocialItem(index)} type="button" />
                            <BtnBase className={'admin_submit admin_submit_center'} btnText={'Добавить подменю'} onClick={() => handleAddSubItem(index)} type="button" />
                          </div>
                        </div>
                        {subItemList[index] &&
                          subItemList[index].map((subItem, subIndex) => (
                            <div className="fileupload_view_row fileupload_view_row_m0" key={subIndex}>
                              <div className="admin_form_section_wrap_grid_fiveth align_center">
                                <div className="admin_form_row">
                                  <InputLabelFor
                                    label={'Название подменю'}
                                    value={subItem.name}
                                    id={`sub_name_${index}_${subIndex}`}
                                    name={`sub_name_${index}_${subIndex}`}
                                    type={'text'}
                                    onChange={(event) => handleSubItemNameChange(index, subIndex, event.target.value)}
                                  />
                                </div>
                                <div className="admin_form_row">
                                  <InputLabelFor
                                    label={'Ссылка подменю'}
                                    value={subItem.link}
                                    id={`sub_link_${index}_${subIndex}`}
                                    name={`sub_link_${index}_${subIndex}`}
                                    type={'text'}
                                    onChange={(event) => handleSubItemLinkChange(index, subIndex, event.target.value)}
                                  />
                                </div>
                                <div className="admin_form_row">
                                  <InputLabelFor
                                    label={'Сортировка подменю'}
                                    value={subItem.index}
                                    id={`sub_index_${index}_${subIndex}`}
                                    name={`sub_index_${index}_${subIndex}`}
                                    type={'text'}
                                    onChange={(event) => handleSubItemIndexChange(index, subIndex, event.target.value)}
                                  />
                                </div>
                                <div className="admin_form_row">
                                  <Switch
                                    text={'В новом окне'}
                                    id={`sub_new_${index}`}
                                    name={`sub_newWindow_${index}`}
                                    isChecked={subItem.newWindow}
                                    onChange={() => handleSubItemNewChange(index, subIndex)}
                                  />
                                </div>
                                <BtnBase className={'admin_submit admin_submit_center'} btnText={'Удалить'} onClick={() => handleRemoveSubItem(index, subIndex)} type="button" />
                              </div>
                            </div>
                          ))}
                      </div>
                    ))}
                    <BtnBase className={'admin_submit'} btnText={'Добавить'} onClick={handleAddSocialItem} type="button" />
                  </div>
                  <BtnBase className={'admin_submit admin_submit_center'} btnText={'Обновить'} type={'submit'} />
                </div>
              </div>
            </InnerForm>
          )
        )}
      </>
    )
  );
};

export default AdminClientMenuItem;
