import React from 'react';

type PersonalOrderSelectServiceProps = {
  socialName: string;
  socialId: number;
  socialIcon: string;
  active: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

const PersonalOrderSelectService = ({ socialName, socialId, socialIcon, active, onClick }: PersonalOrderSelectServiceProps) => {
  return (
    <div className={`personal_row personal_row_pointer personal_row_hover ${active ? 'active' : ''}`} data-service-id={socialId} onClick={onClick}>
      <div className="personal_row_soc">
        <div className="personal_menu_item">
          <img src={socialIcon} alt={socialName} />
          <div className="personal_menu_link">{socialName}</div>
        </div>
        <div className="personal_menu_item_arrow">{'>'}</div>
      </div>
    </div>
  );
};

export default PersonalOrderSelectService;
