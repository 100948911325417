import React, { useEffect, useState } from 'react';
import { NavLink, Navigate, generatePath, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import BtnBase from '../admin-ui-kit/btn/BtnBase';
import { fetchApi } from '../../../content/components/helpers/api';

// Определение типа для объекта страницы
type PageType = {
  h1: string;
  title: string;
  updated: number;
  description: string;
  breadcrumbs: string;
  text: string;
  seoUrl: string;
  banner: {
    text: string;
    image: string;
  };
  icon: {
    big: string;
    small: string;
  };
};

// Определение общего типа для элементов в массиве socialDataState
type SocialDataItem = {
  _id: string;
  parentId: string;
  name: string;
  type: string;
  active: boolean;
  category: string;
  page: PageType;
};

// Определение общего типа для элементов в массиве socialDataState
type SocialReviewItem = {
  _id: string;
  parentId: string;
  name: string;
  type: string;
  active: boolean;
  isNew: boolean;
  updated: number;
  date: number;
  text: string;
};

// Определяем тип для функции обновления данных
type UpdateSocialDataStateFunction = () => Promise<void>;

// Определение типа для пропсов компонента AdminReview
type AdminReviewProps = {
  socialDataState: SocialReviewItem[];
  socialAllServices: SocialDataItem[];
  typeString: string;
  updateDataState: UpdateSocialDataStateFunction;
};

const AdminReview = ({ socialDataState, socialAllServices, typeString, updateDataState }: AdminReviewProps) => {
  const [socialDataStateArr, setSocialDataStateArr] = useState<any[]>([]);
  const [socialTypeString, setSocialTypeString] = useState(typeString);

  const [customSocialArr, setCustomSocialArr] = useState<any[]>([]);

  // жду выполнения запроса фетча
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setSocialTypeString(typeString);
  }, [typeString]);

  useEffect(() => {
    const fetchSocialItem = async () => {
      try {
        const filteredArray = socialDataState.filter((item) => item.type === socialTypeString);
        const sortedArray = filteredArray.slice().sort((a, b) => a.name.localeCompare(b.name));
        // const sortedArray = filteredArray;
        setCustomSocialArr(sortedArray);
      } catch (error) {
        console.error('Error fetching social item:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (socialDataState.length > 0) {
      fetchSocialItem();
    }
  }, [socialDataState, socialTypeString]);

  // получаю название родительской категории
  const getParentCategoryName = (parentId: string): string => {
    const parentCategory = socialAllServices.find((category) => category._id === parentId);
    return parentCategory ? parentCategory.name : '';
  };

  // Отдельно функция для обновления отзывов
  const deleteCat = async (id: any) => {
    const deleteReview = await fetchApi('GET', `review/delete/${id}`, {});
    if (deleteReview) {
      alert(`Отзыв (id: ${id}) удалён.`);
    }
    updateDataState();
  };

  return (
    <>
      <div className="admin_heading">
        <div className="admin_heading_title">Отзывы</div>
        <BtnBase btnLink="new" className={'admin_submit'} btnText={'Добавить'} type={'submit'} />
      </div>
      {isLoading ? (
        <div>Loading...</div>
      ) : !customSocialArr ? (
        <div>Items not found</div>
      ) : (
        <div className="admin_table_wrap">
          <table className="admin_table_list">
            <thead>
              <tr>
                <th>Name</th>
                <th>ID</th>
                <th>Category</th>
                <th>Status</th>
                <th>New</th>
                <th>Updated</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {customSocialArr.map((item) => (
                <tr key={item._id}>
                  <td>{item.name}</td>
                  <td>{item._id}</td>
                  {/* <th>{getParentCategoryName(item.parentId)}</th> */}
                  <th>{item.elementName}</th>
                  <td>{item.active === true ? <span className="status_el status_active">Active</span> : <span className="status_el status_disabled">Disabled</span>}</td>
                  <td>{item.isNew && <span className="status_el status_active">New</span>}</td>
                  <td>{format(new Date(item.date), 'dd.MM.yyyy, HH:mm')}</td>
                  <td>
                    <NavLink to={`edit/${item._id}`}>Edit</NavLink>
                  </td>
                  <td>
                    <a
                      className="del_item"
                      onClick={(e) => {
                        e.preventDefault();
                        deleteCat(item._id);
                      }}
                      href="#"
                    >
                      Delete
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default AdminReview;
