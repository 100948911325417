import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

type LogoProps = {
  imgSrc: string;
  siteName: string;
  className?: string;
};

const Logo = ({ imgSrc, siteName, className }: LogoProps) => {
  const isHomePage = window.location.pathname === '/';
  return (
    <>
      {isHomePage ? (
        <img src={imgSrc} alt={siteName} className={className ? className : ''} />
      ) : (
        <NavLink to={'/'}>
          <img src={imgSrc} alt={siteName} className={className ? className : ''} />
        </NavLink>
      )}
    </>
  );
};

export default Logo;
