import React from 'react';

type Props = {};

const SecondAdvantages = (props: Props) => {
  return (
    <div className="main_section second_advantages_section">
      <div className="container">
        <div className="second_advantages_wrap">
          <div className="second_advantages_item">
            <svg xmlns="http://www.w3.org/2000/svg" width="535" height="307" viewBox="0 0 535 307" fill="none">
              <path
                d="M489.38 243.781C431.319 247.401 360.814 282.046 360.814 282.046C415.766 243.781 471.236 232.922 471.236 232.922C442.205 198.796 400.562 180.347 400.562 180.347C391.229 200.342 375.329 213.443 375.329 213.443C443.759 123.128 405.745 93.4766 405.745 93.4766C397.45 185.18 298.603 173.455 298.603 173.455L271.647 381.997L535 303.077L489.38 243.781Z"
                fill="white"
                fillOpacity="0.2"
              />
              <path
                d="M129.255 93.4766C129.255 93.4766 91.2407 123.128 159.671 213.443C159.671 213.443 143.771 200.346 134.438 180.347C134.438 180.347 92.7949 198.792 63.7644 232.922C63.7644 232.922 119.234 243.781 174.186 282.046C174.186 282.046 103.681 247.401 45.6203 243.781L0 303.08L263.353 382L236.397 173.458C236.397 173.458 137.55 185.183 129.255 93.48V93.4766Z"
                fill="white"
                fillOpacity="0.2"
              />
              <path
                d="M323.836 141.826C363.577 134.586 378.181 89.6885 378.181 89.6885C314.676 110.631 267.498 113.735 267.498 113.735C267.498 113.735 220.324 110.631 156.816 89.6885C156.816 89.6885 171.419 134.586 211.16 141.826C250.908 149.065 261.626 120.802 261.626 120.802L267.498 121.143L273.371 120.802C273.371 120.802 284.088 149.065 323.836 141.826Z"
                fill="white"
                fillOpacity="0.2"
              />
              <path
                d="M267.502 103.132C437.022 90.7232 483.68 44.6996 452.058 35.3911C420.436 26.0826 374.295 35.1316 374.295 35.1316L377.055 55.0394C377.055 55.0394 357.705 -38.5546 347.856 -77.3355C338.007 -116.117 306.381 -93.3642 289.795 -81.4708C273.208 -69.5773 267.502 -73.1969 267.502 -73.1969C267.502 -73.1969 261.798 -69.5773 245.208 -81.4708C228.619 -93.3642 196.997 -116.117 187.147 -77.3355C177.298 -38.5546 157.948 55.0394 157.948 55.0394L160.708 35.1316C160.708 35.1316 114.571 26.0826 82.9458 35.3911C51.3237 44.6996 97.9814 90.7198 267.498 103.132H267.502ZM316.92 -86.125C331.435 -90.2603 338.351 -71.2995 338.351 -71.2995L360.466 23.1505C345.366 1.96542 323.488 -65.0949 308.973 -59.2375C294.458 -53.3801 280.29 -62.6886 280.29 -62.6886C296.532 -63.3761 302.405 -81.9898 316.92 -86.1284V-86.125Z"
                fill="white"
                fillOpacity="0.2"
              />
            </svg>
            <div className="second_advantages_title">100% анонимно</div>
            <div className="second_advantages_text">никто не узнает что вы используете накрутку</div>
          </div>
          <div className="second_advantages_item">
            <svg xmlns="http://www.w3.org/2000/svg" width="395" height="307" viewBox="0 0 395 307" fill="none">
              <path
                opacity="0.2"
                d="M355.094 -8.06982C296.19 -16.2852 251.145 -41.1517 224.584 -59.6592C208.413 -70.9438 186.955 -70.7416 170.912 -59.2732C144.92 -40.6739 100.407 -15.9176 39.9424 -7.92279C17.0343 -4.89029 0 14.9221 0 38.0978V68.5515C0 297.258 138.881 358.955 183.724 372.905C192.7 375.698 202.3 375.698 211.276 372.905C256.101 358.955 395 297.258 395 68.5515V37.914C395 14.7751 378.002 -4.89029 355.113 -8.08821L355.094 -8.06982ZM296.998 88.2536L182.604 236.736C178.401 242.195 172.05 245.576 165.184 246.017C164.671 246.054 164.157 246.073 163.643 246.073C157.31 246.073 151.216 243.555 146.718 239.052L100.021 192.296C90.678 182.941 90.678 167.76 100.021 158.406C109.364 149.051 124.526 149.051 133.869 158.406L161.33 185.9L259.093 59.0129C267.17 48.537 282.203 46.5888 292.666 54.6755C303.129 62.7622 305.075 77.8144 296.998 88.2904V88.2536Z"
                fill="white"
              />
            </svg>
            <div className="second_advantages_title">100% безопасно</div>
            <div className="second_advantages_text">за накрутку не блокируют, это единственный безопасный способ быстро раскачать соц сети</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondAdvantages;
