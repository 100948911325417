import React from 'react';
import BtnOutline from '../components/ui-kit/BtnOutline';
import { Helmet } from 'react-helmet-async';
import InnerBreadcrumbs from '../components/ui-kit/InnerBreadcrumbs';

type Props = {};

const Contact = (props: Props) => {
  return (
    <>
      <Helmet>
        <title>{'Контакты'}</title>
        <meta name="description" content={'Контакты'} />
        {/* Другие мета-теги */}
      </Helmet>
      <InnerBreadcrumbs chapterLink={'/contact'} chapterString={'Контакты'} />
      <div className="contact_wrap">
        <div className="inner_single_top">
          <div className="inner_single_top_content">
            <div className="inner_single_top_content_title">Контакты</div>
            <div className="inner_single_top_content_text">Напишите нам в телеграмме или в онлайн чат на сайте и мы ответим в ближайшее время!</div>
            <div className="contact_info_wrap">
              <div className="contact_row">
                <strong>Email:&nbsp;</strong>
                <a href="mailto:media2booster@gmail.com">media2booster@gmail.com</a>
              </div>
              <div className="contact_row">
                <strong>Telegram:&nbsp;</strong>
                <span>
                  <a href="https://t.me/media_booster_ru" target="_blank">
                    @media_booster_ru
                  </a>
                </span>
              </div>
              <div className="contact_row">
                <span>Сачкова С. С.</span>
              </div>
              <div className="contact_row">
                <strong>ИНН:&nbsp;</strong>
                <span>773579567123</span>
              </div>
            </div>
            {/* <div className="contact_row_btn">
              <BtnOutline btnLink="https://t.me/media_booster_ru" className="btn_wide" btnText="Написать в телеграм" />
              <img src="/content/main_banner/icons/telega.svg" alt="Написать в телеграм" width={52} height={52} />
            </div> */}
            <div className="contact_row_btn">
              <BtnOutline btnLink="https://t.me/media_booster_ru" className="btn_wide btn_bg_gradient" btnText="Написать в online-чат" />
              <img src="/content/chat.svg" alt="Написать в online-чат" width={52} height={52} />
            </div>
          </div>
          <div className="inner_single_top_content_img">
            <img src="/content/contact.png" alt="" />
          </div>
        </div>
        <div className="contact_row_btn contact_row_btn_mob">
          <BtnOutline className="btn_wide" btnText="Написать в телеграм" />
          <img src="/content/main_banner/icons/telega.svg" alt="Написать в телеграм" width={52} height={52} />
        </div>
        <div className="contact_row_btn contact_row_btn_mob">
          <BtnOutline className="btn_wide btn_bg_gradient" btnText="Написать в online-чат" />
          <img src="/content/chat.svg" alt="Написать в online-чат" width={52} height={52} />
        </div>
      </div>
    </>
  );
};

export default Contact;
