import React from 'react';
import BtnOutline from '../ui-kit/BtnOutline';
import { NavLink } from 'react-router-dom';

type Props = {};

const ReferalInfo = (props: Props) => {
  return (
    <div className="main_section referal_info_section">
      <div className="container">
        <div className="main_section_title_mb main_section_title_non_mb">Зарабатывай без вложений!</div>
        <div className="referal_info">
          <div className="referal_info_wrap">
            <div className="referal_info_title">Рекомендуй нас и получай 5% с каждой оплаты</div>
            <div className="referal_info_text">Выйди на пассивный доход с помощью партнёрской программы.</div>
            <img className="mobile_pc" src="/content/main/referal_pc_min.png" alt="" />
            <NavLink to={'/promo'} end>
              <BtnOutline className="btn_wide btn_bg_red" btnText="Узнать подробнее" />
            </NavLink>
          </div>
          <img className="desc_pc" src="/content/main/referal_pc.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default ReferalInfo;
