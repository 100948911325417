import React from 'react';
import { NavLink } from 'react-router-dom';

type BtnBaseProps = {
  btnLink?: string;
  className?: string;
  name?: string;
  btnText?: string;
  type: 'submit' | 'reset' | 'button';
  onClick?: () => void;
};

const BtnBase = ({ btnLink, className, name, btnText, onClick, type }: BtnBaseProps) => {
  return (
    <>
      {btnLink !== undefined && btnLink.length > 0 ? (
        <NavLink to={`${btnLink}`} className={'btn_link'}>
          <button className={className ? className : ''} type={type} onClick={onClick} name={name}>
            {btnText}
          </button>
        </NavLink>
      ) : (
        <button className={className ? className : ''} type={type} onClick={onClick} name={name}>
          {btnText}
        </button>
      )}
    </>
  );
};

export default BtnBase;
