import React, { FormEvent, useEffect, useState } from 'react';
import Switch from '../admin-ui-kit/input/Switch';
import InnerForm from '../admin-ui-kit/form/InnerForm';
import InputBase from '../admin-ui-kit/input/InputBase';
import InputLabelFor from '../admin-ui-kit/input/InputLabelFor';
import TextareaLabelFor from '../admin-ui-kit/input/TextareaLabelFor';
import BtnBase from '../admin-ui-kit/btn/BtnBase';
import FileUploadWithPreview from '../admin-ui-kit/input/FileUploadWithPreview';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import BaseSelect from '../admin-ui-kit/input/BaseSelect';

// Определение типа для объекта страницы
type PageType = {
  h1: string;
  title: string;
  updated: number;
  description: string;
  breadcrumbs: string;
  text: string;
  seoUrl: string;
  banner: {
    text: string;
    image: string;
  };
  icon: {
    big: string;
    small: string;
  };
};

// Определение общего типа для элементов в массиве socialDataState
type SocialDataItem = {
  _id: string;
  parentId: string;
  name: string;
  type: string;
  active: boolean;
  category: string;
  page: PageType;
};

// Определение типа для пропсов компонента AdminSocial
type AdminPageItemProps = {
  socialDataState: SocialDataItem[];
  typeString: string;
};

const AdminPageItem = ({ socialDataState, typeString }: AdminPageItemProps) => {
  const { _id } = useParams();

  const [socialTypeString, setSocialTypeString] = useState(typeString);

  const [socialDataStateArr, setSocialDataStateArr] = useState<any[]>([]);
  const [socialItem, setSocialItem] = useState<any>(null);

  const navigate = useNavigate();

  useEffect(() => {
    // Имитация асинхронной загрузки данных (если это нужно)
    setTimeout(() => {
      setSocialDataStateArr(socialDataState);
      // Проверяем существование _id в вашем JSON
      const socialItem = socialDataState.find((item) => item._id === _id);
      if (!socialItem) {
        navigate('/404');
      } else {
        const [extractedItem] = [socialItem];
        setSocialItem(extractedItem);
      }
    }, 500);
  }, [socialDataState]);

  const [itemName, setItemName] = useState<any>(null);
  const [itemType, setItemType] = useState<any>(null);
  const [itemActive, setItemActive] = useState<any>(null);
  const [itemH1, setItemH1] = useState<any>(null);
  const [itemTitle, setItemTitle] = useState<any>(null);
  const [itemDate, setItemDate] = useState<any>(null);
  const [itemDescription, setItemDescription] = useState<any>(null);
  const [itemBreadcrumbs, setItemBreadcrumbs] = useState<any>(null);
  const [itemText, setItemText] = useState<any>(null);
  const [itemBannerText, setItemBannerText] = useState<any>(null);
  const [itemSeoUrl, setItemSeoUrl] = useState<any>(null);
  const [itemBannerImg, setItemBannerImg] = useState<any>(null);
  const [itemBigIconImg, setItemBigIconImg] = useState<any>(null);
  const [itemSmallIconImg, setItemSmallIconImg] = useState<any>(null);

  useEffect(() => {
    if (socialItem) {
      setItemName(socialItem.name);
      setItemActive(socialItem.active);
      setItemH1(socialItem.page.h1);
      setItemTitle(socialItem.page.title);
      setItemDate(socialItem.page.updated);
      setItemDescription(socialItem.page.description);
      setItemBreadcrumbs(socialItem.page.breadcrumbs);
      setItemText(socialItem.page.text);
      setItemSeoUrl(socialItem.page.seoUrl);
    }
  }, [socialItem]);

  const [savedItemArr, setSavedItemArr] = useState<any>(null);

  const handeSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setSavedItemArr({
      _id: socialItem._id,
      name: itemName,
      active: itemActive,
      page: {
        h1: itemH1,
        title: itemTitle,
        updated: Date.now(),
        description: itemDescription,
        breadcrumbs: itemBreadcrumbs,
        text: itemText,
        seoUrl: itemSeoUrl
      }
    });
  };
  useEffect(() => {
    if (savedItemArr) {
      // console.log(savedItemArr);
    }
  }, [savedItemArr]);

  return (
    socialItem && (
      <>
        <InnerForm onSubmit={handeSubmit}>
          <div className="admin_heading">
            <div className="admin_heading_title">{itemName}</div>
            <BtnBase className={'admin_submit'} btnText={'Обновить'} type={'submit'} />
          </div>
          <div className="admin_container">
            <div className="admin_form">
              <div className="admin_form_section_wrap">
                <div className="admin_form_section_title">Системные данные</div>
                <div className="admin_form_row">
                  <Switch text={'Активно'} id={'active'} name={'active'} isChecked={itemActive} onChange={() => setItemActive(!itemActive)} />
                </div>
                <div className="admin_form_row">
                  <InputLabelFor label={'ID'} value={socialItem._id} id={'id'} name={'id'} type={'text'} readOnly={true} disabled={true} />
                </div>
                <div className="admin_form_row">
                  <InputLabelFor
                    label={'Дата обновления'}
                    value={format(new Date(socialItem.page.updated), 'dd.MM.yyyy, HH:mm')}
                    id={'updated'}
                    name={'updated'}
                    type={'text'}
                    readOnly={true}
                    disabled={true}
                  />
                </div>
                <div className="admin_form_row">
                  <InputLabelFor label={'Название страницы'} value={socialItem.name} id={'name'} name={'name'} type={'text'} readOnly={true} disabled={true} />
                </div>
                <div className="admin_form_row">
                  <InputLabelFor label={'SEO URL'} value={itemSeoUrl} id={'seo_url'} name={'seo_url'} type={'text'} onChange={(event) => setItemSeoUrl(event.target.value)} />
                </div>
              </div>
              <div className="admin_form_section_wrap">
                <div className="admin_form_section_title">Содержимое</div>
                <div className="admin_form_row">
                  <TextareaLabelFor label={'Текстовый блок'} value={itemText} id={'text'} name={'text'} onChange={(event) => setItemText(event.target.value)} rows={8} />
                </div>
              </div>
              <div className="admin_form_section_wrap">
                <div className="admin_form_section_title">Мета</div>
                <div className="admin_form_row">
                  <InputLabelFor label={'Заголовок - H1'} value={itemH1} id={'h1'} name={'h1'} type={'text'} onChange={(event) => setItemH1(event.target.value)} />
                </div>
                <div className="admin_form_row">
                  <InputLabelFor label={'Хлебные крошки'} value={itemBreadcrumbs} id={'breadcrumbs'} name={'breadcrumbs'} type={'text'} onChange={(event) => setItemBreadcrumbs(event.target.value)} />
                </div>
                <div className="admin_form_row">
                  <InputLabelFor label={'Meta-title'} value={itemTitle} id={'meta-title'} name={'meta-title'} type={'text'} onChange={(event) => setItemTitle(event.target.value)} />
                </div>
                <div className="admin_form_row">
                  <InputLabelFor
                    label={'Meta-description'}
                    value={itemDescription}
                    id={'meta-description'}
                    name={'meta-description'}
                    type={'text'}
                    onChange={(event) => setItemDescription(event.target.value)}
                  />
                </div>
              </div>
              <BtnBase className={'admin_submit admin_submit_center'} btnText={'Обновить'} type={'submit'} />
            </div>
          </div>
        </InnerForm>
      </>
    )
  );
};

export default AdminPageItem;
