import React from 'react';

type Props = {};

const ReviewMethods = (props: Props) => {
  return (
    <div className="main_section main_review_methods_section">
      <div className="container">
        <div className="main_review_methods">
          <div className="main_section_title_mb">Мы используем самые надежные и безопасные методы накрутки</div>
          <div className="main_review_methods_wrap">
            <div className="main_review_methods_item">
              <div className="main_review_methods_item_title">Постепенный рост показателей</div>
              <div className="main_review_methods_item_img">
                <img src="/content/main/main_01.png" alt="Постепенный рост показателей" />{' '}
              </div>
              <div className="main_review_methods_item_text">Накрутка не заметна, потому что прирост показателей происходит по интервалам, последовательно и гармонично</div>
            </div>
            <div className="main_review_methods_item">
              <div className="main_review_methods_item_title">Живые коментарии по теме</div>
              <div className="main_review_methods_item_img">
                <img src="/content/main/main_02.png" alt="" />
              </div>
              <div className="main_review_methods_item_text">Живые комментарии по теме поста от реальных пользователей помогут увеличить доверие</div>
            </div>
            <div className="main_review_methods_item">
              <div className="main_review_methods_item_title">
                Аккаунты, неотличимые
                <br />
                от реальных людей
              </div>
              <div className="main_review_methods_item_img">
                <img src="/content/main/main_03.png" alt="" />
              </div>
              <div className="main_review_methods_item_text">Они помогут сделать имидж аккаунту и увеличить доверие при небольших затратах</div>
            </div>
            <div className="main_review_methods_item main_review_methods_item_rev">
              <div className="main_review_methods_item_rev_user">
                <svg xmlns="http://www.w3.org/2000/svg" width="101" height="101" viewBox="0 0 101 101" fill="none">
                  <rect width="101" height="101" rx="50.5" fill="#4083FD" />
                  <path
                    d="M51.138 50.3011H51.1413C58.0506 50.3011 63.6738 44.6833 63.676 37.7782C63.676 34.4369 62.3725 31.2922 60.002 28.9239C57.6316 26.5556 54.4858 25.25 51.1434 25.25H51.1402C44.2308 25.25 38.6076 30.8678 38.6055 37.7728C38.6055 41.1141 39.9089 44.2578 42.2794 46.6271C44.6487 48.9954 47.7946 50.3011 51.138 50.3011ZM51.1402 27.2533H51.1423C53.9501 27.2533 56.5938 28.3505 58.5841 30.3408C60.5744 32.3311 61.6705 34.9726 61.6705 37.7771C61.6695 43.5785 56.9459 48.2967 51.1402 48.2967H51.138C48.3302 48.2967 45.6865 47.1995 43.6962 45.2092C41.7059 43.2189 40.6098 40.5774 40.6098 37.7728C40.6109 31.9715 45.3345 27.2533 51.1402 27.2533Z"
                    fill="white"
                  />
                  <path
                    d="M67.4225 60.0135C64.2604 57.2737 59.8921 55.7025 55.4364 55.7014L46.8402 55.6992H46.8358C43.4168 55.6992 40.1036 56.5956 37.2526 58.2921C33.5506 60.4962 29.1369 64.9218 29.1348 73.491C29.1348 74.6336 30.0635 75.5623 31.205 75.5634L71.0618 75.572C72.2033 75.572 73.132 74.6433 73.132 73.5007C73.132 67.9143 71.1579 63.2501 67.4214 60.0124L67.4225 60.0135ZM71.0618 73.5688L31.2061 73.5601C31.1693 73.5601 31.1391 73.5299 31.1391 73.4921C31.1413 67.3678 33.543 62.8332 38.2774 60.0146C40.8174 58.5027 43.7764 57.7036 46.8358 57.7036H46.8402L55.4364 57.7057C62.9959 57.7079 71.1309 62.6507 71.1299 73.5018C71.1299 73.5396 71.0996 73.5698 71.0629 73.5698L71.0618 73.5688Z"
                    fill="white"
                  />
                </svg>
                <div className="main_review_methods_item_rev_layout">
                  <img className="main_review_methods_item_rev_layout_bg" src="/content/main/layout_as.svg" alt="" />
                  <div className="main_review_methods_item_rev_layout_top">
                    <svg className="main_review_methods_item_rev_layout_top_stars" xmlns="http://www.w3.org/2000/svg" width="235" height="42" viewBox="0 0 235 42" fill="none">
                      <path
                        d="M8.208 41.5549L12.3569 25.9873L-0.00195312 15.8716L15.846 15.0724L21.4919 0L27.1379 15.0724L42.9859 15.8716L30.627 25.9873L34.7759 41.5549L21.4919 32.7337L8.208 41.5549Z"
                        fill="#FEDA77"
                      />
                      <path
                        d="M55.7668 41.5549L60.054 25.9873L47.2832 15.8716L63.6594 15.0724L69.4936 0L75.3278 15.0724L91.7039 15.8716L78.9331 25.9873L83.2203 41.5549L69.4936 32.7337L55.7668 41.5549Z"
                        fill="#FEDA77"
                      />
                      <path
                        d="M104.214 41.5549L108.363 25.9873L96.0039 15.8716L111.852 15.0724L117.498 0L123.144 15.0724L138.992 15.8716L126.633 25.9873L130.782 41.5549L117.498 32.7337L104.214 41.5549Z"
                        fill="#FEDA77"
                      />
                      <path
                        d="M151.775 41.5549L156.062 25.9873L143.291 15.8716L159.667 15.0724L165.501 0L171.336 15.0724L187.712 15.8716L174.941 25.9873L179.228 41.5549L165.501 32.7337L151.775 41.5549Z"
                        fill="#FEDA77"
                      />
                      <path
                        d="M200.22 41.5549L204.369 25.9873L192.01 15.8716L207.858 15.0724L213.504 0L219.15 15.0724L234.998 15.8716L222.639 25.9873L226.788 41.5549L213.504 32.7337L200.22 41.5549Z"
                        fill="#FEDA77"
                      />
                    </svg>
                    <div className="main_review_methods_item_rev_layout_top_text">Оценка: 5.0</div>
                  </div>
                  <div className="main_review_methods_item_rev_layout_text">
                    Надежный и качественный сервис. Результат меня порадовал, оправдал мои ожидания. Буду обращаться к сервису еще, там работают настоящие профессионалы.
                  </div>
                </div>
              </div>
            </div>
            <div className="main_review_methods_item_rev_mobile shadow_border border_rounded">
              <div className="main_review_methods_item_rev_mobile_top">
                <svg xmlns="http://www.w3.org/2000/svg" width="101" height="101" viewBox="0 0 101 101" fill="none">
                  <rect width="101" height="101" rx="50.5" fill="#4083FD" />
                  <path
                    d="M51.138 50.3011H51.1413C58.0506 50.3011 63.6738 44.6833 63.676 37.7782C63.676 34.4369 62.3725 31.2922 60.002 28.9239C57.6316 26.5556 54.4858 25.25 51.1434 25.25H51.1402C44.2308 25.25 38.6076 30.8678 38.6055 37.7728C38.6055 41.1141 39.9089 44.2578 42.2794 46.6271C44.6487 48.9954 47.7946 50.3011 51.138 50.3011ZM51.1402 27.2533H51.1423C53.9501 27.2533 56.5938 28.3505 58.5841 30.3408C60.5744 32.3311 61.6705 34.9726 61.6705 37.7771C61.6695 43.5785 56.9459 48.2967 51.1402 48.2967H51.138C48.3302 48.2967 45.6865 47.1995 43.6962 45.2092C41.7059 43.2189 40.6098 40.5774 40.6098 37.7728C40.6109 31.9715 45.3345 27.2533 51.1402 27.2533Z"
                    fill="white"
                  />
                  <path
                    d="M67.4225 60.0135C64.2604 57.2737 59.8921 55.7025 55.4364 55.7014L46.8402 55.6992H46.8358C43.4168 55.6992 40.1036 56.5956 37.2526 58.2921C33.5506 60.4962 29.1369 64.9218 29.1348 73.491C29.1348 74.6336 30.0635 75.5623 31.205 75.5634L71.0618 75.572C72.2033 75.572 73.132 74.6433 73.132 73.5007C73.132 67.9143 71.1579 63.2501 67.4214 60.0124L67.4225 60.0135ZM71.0618 73.5688L31.2061 73.5601C31.1693 73.5601 31.1391 73.5299 31.1391 73.4921C31.1413 67.3678 33.543 62.8332 38.2774 60.0146C40.8174 58.5027 43.7764 57.7036 46.8358 57.7036H46.8402L55.4364 57.7057C62.9959 57.7079 71.1309 62.6507 71.1299 73.5018C71.1299 73.5396 71.0996 73.5698 71.0629 73.5698L71.0618 73.5688Z"
                    fill="white"
                  />
                </svg>
                <div className="main_review_methods_item_rev_mobile_top_wrap">
                  <svg className="main_review_methods_item_rev_layout_top_stars" xmlns="http://www.w3.org/2000/svg" width="235" height="42" viewBox="0 0 235 42" fill="none">
                    <path
                      d="M8.208 41.5549L12.3569 25.9873L-0.00195312 15.8716L15.846 15.0724L21.4919 0L27.1379 15.0724L42.9859 15.8716L30.627 25.9873L34.7759 41.5549L21.4919 32.7337L8.208 41.5549Z"
                      fill="#FEDA77"
                    />
                    <path
                      d="M55.7668 41.5549L60.054 25.9873L47.2832 15.8716L63.6594 15.0724L69.4936 0L75.3278 15.0724L91.7039 15.8716L78.9331 25.9873L83.2203 41.5549L69.4936 32.7337L55.7668 41.5549Z"
                      fill="#FEDA77"
                    />
                    <path
                      d="M104.214 41.5549L108.363 25.9873L96.0039 15.8716L111.852 15.0724L117.498 0L123.144 15.0724L138.992 15.8716L126.633 25.9873L130.782 41.5549L117.498 32.7337L104.214 41.5549Z"
                      fill="#FEDA77"
                    />
                    <path
                      d="M151.775 41.5549L156.062 25.9873L143.291 15.8716L159.667 15.0724L165.501 0L171.336 15.0724L187.712 15.8716L174.941 25.9873L179.228 41.5549L165.501 32.7337L151.775 41.5549Z"
                      fill="#FEDA77"
                    />
                    <path
                      d="M200.22 41.5549L204.369 25.9873L192.01 15.8716L207.858 15.0724L213.504 0L219.15 15.0724L234.998 15.8716L222.639 25.9873L226.788 41.5549L213.504 32.7337L200.22 41.5549Z"
                      fill="#FEDA77"
                    />
                  </svg>
                  <div className="main_review_methods_item_rev_mobile_top_text">Оценка: 5.0</div>
                </div>
              </div>
              <div className="main_review_methods_item_rev_mobile_content">
                Надежный и качественный сервис. Результат меня порадовал, оправдал мои ожидания. Буду обращаться к сервису еще, там работают настоящие профессионалы.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewMethods;
