import React, { useEffect, useRef, useState } from 'react';
import BtnOutline from '../ui-kit/BtnOutline';

type ServicesVideoReviewsWrapProps = {
  showAll?: boolean;
};

const ServicesVideoReviewsWrap = ({ showAll }: ServicesVideoReviewsWrapProps) => {
  const [expanded, setExpanded] = useState(false);
  const [maxHeight, setMaxHeight] = useState(''); // Состояние для установки максимальной высоты
  const [paddingBottom, setPaddingBottom] = useState(''); // Состояние для установки максимальной высоты

  const reviewsWrapRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // После монтирования компонента или изменения состояния expanded
    // Вычисляем высоту блока и устанавливаем максимальную высоту
    if (reviewsWrapRef.current) {
      const reviewsWrapHeight = reviewsWrapRef.current.scrollHeight;
      setMaxHeight(expanded ? `${reviewsWrapHeight + 10}px` : '');
      setPaddingBottom(expanded ? `10px` : '');
    }
  }, [expanded]);

  const handleShowAllClick = () => {
    setExpanded(!expanded);
  };
  return (
    <div className="reviews_video_section_wrap">
      <div className="reviews_wrap reviews_video_wrap" ref={reviewsWrapRef} style={{ maxHeight: maxHeight, paddingBottom: paddingBottom }}>
        <div className="review_item shadow_border border_rounded">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/7PIji8OubXU?si=ftlqt4K6u8l4S095"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
        <div className="review_item shadow_border border_rounded">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/7PIji8OubXU?si=ftlqt4K6u8l4S095"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
        <div className="review_item shadow_border border_rounded">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/7PIji8OubXU?si=ftlqt4K6u8l4S095"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      {/* {showAll ? (
        <></>
      ) : (
        <BtnOutline
          className="btn_outline btn_bg_transparent btn_wide_full reviews_show_all reviews_vieo_show_all"
          btnText={expanded ? 'Скрыть' : 'Показать всё'}
          btnLink={'/reviews'}
          // onClick={handleShowAllClick}
        />
      )} */}
    </div>
  );
};

export default ServicesVideoReviewsWrap;
