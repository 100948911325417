import React, { useState } from 'react';
import ServicesTextReviewsWrap from '../components/Services/ServicesTextReviewsWrap';
import ServicesVideoReviewsWrap from '../components/Services/ServicesVideoReviewsWrap';
import SendReviewForm from '../components/ui-kit/form/SendReviewForm';
import SendReview from './SendReview';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import InnerBreadcrumbs from '../components/ui-kit/InnerBreadcrumbs';

// Определение типа для объекта страницы
type PageType = {
  h1: string;
  title: string;
  updated: number;
  description: string;
  breadcrumbs: string;
  text: string;
  seoUrl: string;
  showOnMain: boolean;
  excerpt: string;
  banner: {
    text: string;
    image: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
  icon: {
    big: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
    small: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
};

// Определение общего типа для элементов в массиве socialDataState
type SocialDataItem = {
  _id: string;
  name: string;
  __v: number;
  active: boolean;
  activeApi: boolean;
  category: string;
  page: PageType;
  parentId: string;
  rate: string;
  service: string;
  socialMedia: string;
  type: string;
};

// Определение общего типа для элементов в массиве socialDataState
type SocialReviewItem = {
  _id: string;
  parentId: string;
  name: string;
  type: string;
  active: boolean;
  isNew: boolean;
  updated: number;
  date: number;
  text: string;
};

// Определение типа для пропсов компонента AdminSocial
type ReviewsProps = {
  socialDataState: SocialReviewItem[];
  socialAllServices: SocialDataItem[];
  typeString: string;
};

const Reviews = ({ socialDataState, socialAllServices, typeString }: ReviewsProps) => {
  return (
    <>
      <Helmet>
        <title>{'Отзывы'}</title>
        <meta name="description" content={'Отзывы'} />
        {/* Другие мета-теги */}
      </Helmet>
      <InnerBreadcrumbs chapterLink={'/reviews'} chapterString={'Отзывы'} />
      <div className="inner_section reviews_section">
        <div className="main_section_title inner_section_title">Отзывы</div>
        <ServicesVideoReviewsWrap />
        <SendReview socialDataState={socialAllServices} />
        <ServicesTextReviewsWrap socialDataState={socialAllServices} socialAllServices={socialDataState} typeString={typeString} />
      </div>
    </>
  );
};

export default Reviews;
