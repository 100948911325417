import React, { CSSProperties, ChangeEvent, HTMLInputTypeAttribute, ReactNode } from 'react';

type InputBaseProps = {
  children?: ReactNode;
  className?: string;
  id?: string;
  label?: string;
  name?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  style?: CSSProperties;
  type: HTMLInputTypeAttribute;
  value?: string;
};

const InputBase = ({ children, className, id, label, name, onChange, placeholder, style, type, value }: InputBaseProps) => {
  return (
    <>
      {label ? (
        <label className={className ? className : ''} style={style}>
          <span>{label}</span>
          <input type={type} name={name} value={value} id={id} placeholder={placeholder} onChange={onChange} />
        </label>
      ) : (
        <input type={type} name={name} value={value} id={id} placeholder={placeholder} onChange={onChange} />
      )}
    </>
  );
};

export default InputBase;
