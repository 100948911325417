import React, { FormEvent, useEffect, useState } from 'react';
import Switch from '../admin-ui-kit/input/Switch';
import InnerForm from '../admin-ui-kit/form/InnerForm';
import InputBase from '../admin-ui-kit/input/InputBase';
import InputLabelFor from '../admin-ui-kit/input/InputLabelFor';
import TextareaLabelFor from '../admin-ui-kit/input/TextareaLabelFor';
import BtnBase from '../admin-ui-kit/btn/BtnBase';
import FileUploadWithPreview from '../admin-ui-kit/input/FileUploadWithPreview';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import BaseSelect from '../admin-ui-kit/input/BaseSelect';
import { fetchApi } from '../../../content/components/helpers/api';

// Определение общего типа для элементов в массиве socialDataState
type SocialDataItem = {
  // _id: '65b9a474e4384e10fd1d4da1';
  parentId: string;
  name: string;
  type: string;
  active: boolean;
  updated: number;
  text: string;
};

// Определяем тип для функции обновления данных
type UpdateSocialDataStateFunction = () => Promise<void>;

// Определение типа для пропсов компонента AdminSocial
type AdminFaqItemNewProps = {
  socialDataState: SocialDataItem[];
  typeString: string;
  updateDataState: UpdateSocialDataStateFunction;
};

const AdminFaqItemNew = ({ socialDataState, typeString, updateDataState }: AdminFaqItemNewProps) => {
  const navigate = useNavigate();
  const { _id } = useParams();

  // жду выполнения запроса фетча
  const [isLoading, setIsLoading] = useState(true);

  // жду завершения обновления элемента
  const [isLoadingData, setIsLoadingData] = useState(false);

  // типа одного элемента
  const [socialTypeString, setSocialTypeString] = useState(typeString);

  const [socialItem, setSocialItem] = useState<any>(null);

  // массив с данными для сохранения
  const [savedItemArr, setSavedItemArr] = useState<any>(null);

  //
  const [itemCategory, setItemCategory] = useState<any>(null);
  const [itemName, setItemName] = useState<any>(null);
  const [itemType, setItemType] = useState<any>(null);
  const [itemActive, setItemActive] = useState<any>(null);
  const [itemUpdated, setItemUpdated] = useState<any>(null);
  const [itemText, setItemText] = useState<any>(null);

  useEffect(() => {
    setItemCategory('');
    setItemName('');
    setItemType(socialTypeString);
    setItemActive(true);
    setItemUpdated(Date.now());
    setItemText('');
  }, [socialTypeString]);

  const handeSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setSavedItemArr({
      // _id: socialItem._id,
      parentId: itemCategory,
      name: itemName,
      type: itemType,
      active: itemActive,
      updated: itemUpdated,
      text: itemText
    });
  };

  useEffect(() => {
    const saveItem = async () => {
      try {
        setIsLoadingData(true);
        let response;
        console.log(savedItemArr);
        response = await fetchApi('POST', `/faq/new`, savedItemArr);

        if (response && !response.error) {
          // Успешно сохранено, выполните необходимые действия
          updateDataState();
          navigate('/admin/faq'); // Редирект на страницу блога
        } else {
          console.error('Failed to save item:', response.error);
          // Обработка ошибки, например, отображение сообщения об ошибке для пользователя
        }
      } catch (error) {
        console.error('Error saving item:', error);
        // Обработка ошибки, например, отображение сообщения об ошибке для пользователя
      } finally {
        setIsLoadingData(false);
      }
    };

    if (savedItemArr) {
      saveItem();
    }
  }, [savedItemArr, typeString, updateDataState, navigate]);

  return (
    <>
      <InnerForm onSubmit={handeSubmit}>
        <div className="admin_heading">
          <div className="admin_heading_title">{itemName}</div>
          <BtnBase className={'admin_submit'} btnText={'Обновить'} type={'submit'} />
        </div>
        <div className="admin_container">
          <div className="admin_form">
            <div className="admin_form_section_wrap">
              <div className="admin_form_section_title">Системные данные</div>
              <div className="admin_form_row">
                <Switch text={'Активно'} id={'active'} name={'active'} isChecked={itemActive} onChange={() => setItemActive(!itemActive)} />
              </div>
              {/* <div className="admin_form_row">
                <InputLabelFor label={'ID'} value={socialItem._id} id={'id'} name={'id'} type={'text'} readOnly={true} disabled={true} />
              </div> */}
              {/* <div className="admin_form_row">
                <InputLabelFor
                  label={'Дата обновления'}
                  value={format(new Date(socialItem.updated), 'dd.MM.yyyy, HH:mm')}
                  id={'itemUpdated'}
                  name={'itemUpdated'}
                  type={'text'}
                  readOnly={true}
                  disabled={true}
                />
              </div> */}
            </div>
            <div className="admin_form_section_wrap">
              <div className="admin_form_section_title">Содержимое</div>
              <div className="admin_form_row">
                <InputLabelFor label={'Название вопроса'} value={itemName} id={'faq_name'} name={'faq_name'} type={'text'} onChange={(event) => setItemName(event.target.value)} />
              </div>
              <div className="admin_form_row">
                <TextareaLabelFor label={'Текстовый блок'} value={itemText} id={'text'} name={'text'} onChange={(event) => setItemText(event.target.value)} />
              </div>
            </div>
            <BtnBase className={'admin_submit admin_submit_center'} btnText={'Обновить'} type={'submit'} />
          </div>
        </div>
      </InnerForm>
    </>
  );
};

export default AdminFaqItemNew;
