import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import AdminMenuList from './AdminMenu/AdminMenuList';

type Props = {};

const AdminLayout = (props: Props) => {
  return (
    <div className="admin_wrap">
      <div className="admin_sidebar">
        <div className="admin_menu">
          <AdminMenuList />
        </div>
      </div>
      <div className="admin_inner">
        <Outlet />
      </div>
    </div>
  );
};

export default AdminLayout;
