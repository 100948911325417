import React, { useEffect, useState } from 'react';
import ServicesSocList from '../Services/ServicesSocList';
import ServicesText from '../Services/ServicesText';
import { Helmet } from 'react-helmet-async';
import InnerBreadcrumbs from '../ui-kit/InnerBreadcrumbs';

// Определение типа для объекта страницы
type PageType = {
  h1: string;
  title: string;
  updated: number;
  description: string;
  breadcrumbs: string;
  text: string;
  seoUrl: string;
  showOnMain: boolean;
  excerpt: string;
  banner: {
    text: string;
    image: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
  icon: {
    big: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
    small: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
};

// Определение общего типа для элементов в массиве socialDataState
type SocialDataItem = {
  _id: string;
  name: string;
  __v: number;
  active: boolean;
  activeApi: boolean;
  category: string;
  page: PageType;
  parentId: string;
  rate: string;
  service: string;
  socialMedia: string;
  type: string;
};

// Определение типа для пропсов компонента AdminBlog
type SmmToolsProps = {
  socialDataState: SocialDataItem[];
  typeString?: string;
};
const SmmTools = ({ socialDataState }: SmmToolsProps) => {
  // объекты всех услуг
  const [socialItemsArr, setSocialItemsArr] = useState<any>(null);

  useEffect(() => {
    if (socialDataState) {
      // заполняю массив с элементами
      const filteredArray = socialDataState.filter((item) => item.type === 'tools');
      const sortedArray = filteredArray.slice().sort((a, b) => a.name.localeCompare(b.name));
      setSocialItemsArr(sortedArray);
    }
  }, [socialDataState]);
  return (
    <>
      <Helmet>
        <title>{'SMM инструменты'}</title>
        <meta name="description" content={'SMM инструменты'} />
        {/* Другие мета-теги */}
      </Helmet>
      <InnerBreadcrumbs chapterLink={'/smm-tools'} chapterString={'SMM инструменты'} />
      <h1 className="inner_col_content_title">{`SMM инструменты`}</h1>
      <div className="inner_col_content_nested_services inner_section">
        <ServicesSocList servicesSocList={socialItemsArr} />
      </div>
      <ServicesText />
    </>
  );
};

export default SmmTools;
