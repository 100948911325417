import React, { useEffect, useState } from 'react';
import PersonalMenu from './PersonalMenu';
import BtnOutline from '../ui-kit/BtnOutline';
import { Authentification } from '../helpers/auth';
import { fetchApi } from '../helpers/api';
import { NavLink } from 'react-router-dom';
import { useUserData } from '../../hooks/UserDataContext';

interface PersonalMenuItem {
  name: string;
  title: string;
  iconSrc: string;
  link: string;
}

interface PersonalLeftColProps {
  currentLocation: string;
  userName?: string;
  personalMenuList: PersonalMenuItem[];
}

const PersonalLeftCol = ({ personalMenuList, currentLocation, userName }: PersonalLeftColProps) => {
  // Получите значения из контекста
  const { personalEmail, personalName, personalPhone, personalBalance, fetchUserDataContext } = useUserData();

  const token = Authentification.getInstance().getToken();

  return (
    <div className="personal_menu">
      <div className="personal_info personal_row">
        <div className="personal_name">
          <svg xmlns="http://www.w3.org/2000/svg" width="86" height="86" viewBox="0 0 86 86" fill="none">
            <rect width="86" height="86" rx="43" fill="#E73D74" />
            <path
              d="M43.5424 42.8306H43.5451C49.4284 42.8306 54.2164 38.0471 54.2182 32.1676C54.2182 29.3225 53.1084 26.6448 51.09 24.6283C49.0716 22.6117 46.3929 21.5 43.547 21.5H43.5442C37.661 21.5 32.8729 26.2835 32.8711 32.163C32.8711 35.0081 33.981 37.6848 35.9994 39.7023C38.0168 41.7189 40.6955 42.8306 43.5424 42.8306ZM43.5442 23.2057H43.546C45.9369 23.2057 48.1879 24.14 49.8826 25.8347C51.5773 27.5294 52.5107 29.7786 52.5107 32.1667C52.5097 37.1065 48.4877 41.1239 43.5442 41.1239H43.5424C41.1516 41.1239 38.9005 40.1897 37.2058 38.495C35.5111 36.8002 34.5778 34.551 34.5778 32.163C34.5787 27.2232 38.6008 23.2057 43.5442 23.2057Z"
              fill="white"
            />
            <path
              d="M57.4081 51.1003C54.7156 48.7674 50.9961 47.4295 47.2021 47.4286L39.8825 47.4268H39.8788C36.9676 47.4268 34.1464 48.19 31.7188 49.6346C28.5666 51.5114 24.8085 55.2796 24.8066 62.5762C24.8066 63.5491 25.5974 64.3399 26.5694 64.3408L60.5069 64.3482C61.4789 64.3482 62.2697 63.5573 62.2697 62.5845C62.2697 57.8277 60.5887 53.8562 57.4071 51.0994L57.4081 51.1003ZM60.5069 62.6424L26.5703 62.635C26.5391 62.635 26.5133 62.6093 26.5133 62.5771C26.5151 57.3624 28.5602 53.5012 32.5915 51.1012C34.7542 49.8139 37.2738 49.1334 39.8788 49.1334H39.8825L47.2021 49.1353C53.6389 49.1371 60.5658 53.3458 60.5648 62.5854C60.5648 62.6176 60.5391 62.6433 60.5078 62.6433L60.5069 62.6424Z"
              fill="white"
            />
          </svg>
          <div className="personal_name_text">
            {personalName && (
              <>
                {personalName} <br />
              </>
            )}
            {personalEmail && (
              <>
                {personalEmail} <br />
              </>
            )}
            <span>
              Баланс: <strong> {personalBalance} RUB</strong>
            </span>
          </div>
        </div>
        <NavLink to="/personal/balance">
          <BtnOutline className="btn_wide_full btn_bg_gradient" btnText="Пополнить баланс" />
        </NavLink>
      </div>
      <PersonalMenu personalMenuList={personalMenuList} currentLocation={currentLocation} />
    </div>
  );
};

export default PersonalLeftCol;
