import React from 'react';
import { NavLink } from 'react-router-dom';
import BtnOutline from '../ui-kit/BtnOutline';

interface MenuItem {
  text: string;
  id: string;
  link: string;
  icon?: string;
  parent?: string;
}

interface ServicesInnerListProps {
  menuList: MenuItem[];
}

const ServicesInnerList = ({ menuList }: ServicesInnerListProps) => {
  return (
    <>
      {menuList.map((item: MenuItem) => (
        <NavLink to={item.link} className={'single_content_menu_item'} key={item.id} end>
          <img src={item.icon ? item.icon : '/content/not/icon_small.svg'} alt={item.text} width="46" height="46" />
          <div className="single_content_menu_item_title" data-parent-id={item.parent}>
            {item.text}
          </div>
        </NavLink>
      ))}
    </>
  );
};

export default ServicesInnerList;
