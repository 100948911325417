import React, { useEffect, useState } from 'react';
import { fetchApi } from '../helpers/api';
import { NavLink } from 'react-router-dom';

export interface PathElement {
  _id: string;
  path: string;
  seo_url: string;
  elementId: string;
  __v: number;
}

export interface ElementInfo {
  _id: string;
  name: string;
  customName: string;
  __v: number;
  active: boolean;
  activeApi: boolean;
  category: string;
  page: {
    h1: string;
    title: string;
    updated: number;
    description: string;
    breadcrumbs: string;
    text: string;
    seoUrl: string;
    banner: {
      text: string;
      image: {
        id: string;
        filename: string;
        filetype: string;
        filesize: string;
        filepath: string;
        datetime: string;
      };
    };
    icon: {
      big: {
        id: string;
        filename: string;
        filetype: string;
        filesize: string;
        filepath: string;
        datetime: string;
      };
      small: {
        id: string;
        filename: string;
        filetype: string;
        filesize: string;
        filepath: string;
        datetime: string;
      };
    };
  };
  parentId: string;
  rate: string;
  service: string;
  serviceProvider: string;
  socialMedia: string;
  type: string;
}

type InnerBreadcrumbsProps = {
  _id?: string;
  pathElement?: PathElement;
  chapterString: string;
  chapterLink: string;
};

const InnerBreadcrumbs = ({ _id, pathElement, chapterString, chapterLink }: InnerBreadcrumbsProps) => {
  const [breadcrumbs, setBreadcrumbs] = useState<ElementInfo[]>([]);

  useEffect(() => {
    const buildBreadcrumbs = async () => {
      if (pathElement) {
        const pathIds = pathElement.path.split('/').filter((id) => id);
        const breadcrumbElements: ElementInfo[] = [];

        for (const id of pathIds) {
          if (chapterString === 'Услуги') {
            if (id != 'services') {
              const elementInfo = await fetchApi('GET', `services/get_item/${id}`, {});
              breadcrumbElements.push(elementInfo);
            }
          } else if (chapterString === 'Блог') {
            if (id != 'blog') {
              const elementInfo = await fetchApi('GET', `blog/get_one/${id}`, {});
              breadcrumbElements.push(elementInfo[0]);
            }
          } else if (chapterString === 'SMM инструменты') {
            if (id != 'smm-tools') {
              const elementInfo = await fetchApi('GET', `blog/get_one/${id}`, {});
              breadcrumbElements.push(elementInfo[0]);
            }
          }
        }

        setBreadcrumbs(breadcrumbElements);
      }
    };
    if (pathElement) {
      buildBreadcrumbs();
    }
  }, [pathElement]);

  useEffect(() => {
    if (_id) {
    }
  }, [_id]);

  let accumulatedPath = chapterLink;

  return pathElement ? (
    breadcrumbs.length > 0 ? (
      <div className="inner_single_breadcrumbs">
        <div className="inner_single_breadcrumbs_item">
          <NavLink to={'/'}>Главная</NavLink>
        </div>
        <div className="inner_single_breadcrumbs_item">
          <span className="inner_single_breadcrumbs_arr">{'>'}</span>
          <NavLink to={accumulatedPath}>{chapterString}</NavLink>
        </div>
        {breadcrumbs.map(
          (breadcrumb, index) =>
            breadcrumb &&
            ((accumulatedPath += `/${breadcrumb.page.seoUrl}`),
            (
              <div key={breadcrumb._id} className="inner_single_breadcrumbs_item">
                <span className="inner_single_breadcrumbs_arr">{'>'}</span>
                {index > 0 && ''}
                {breadcrumbs.length - 1 === index ? (
                  <span className="inner_single_breadcrumbs_item_last">{breadcrumb.page.breadcrumbs.length > 0 ? breadcrumb.page.breadcrumbs : breadcrumb.customName ? breadcrumb.customName : breadcrumb.name}</span>
                ) : (
                  <NavLink to={accumulatedPath}>{breadcrumb.page.breadcrumbs.length > 0 ? breadcrumb.page.breadcrumbs : breadcrumb.customName ? breadcrumb.customName : breadcrumb.name}</NavLink>
                )}
              </div>
            ))
        )}
      </div>
    ) : (
      <>Loading...</>
    )
  ) : (
    <div className="inner_single_breadcrumbs">
      <div className="inner_single_breadcrumbs_item">
        <NavLink to={'/'}>Главная</NavLink>
      </div>
      <div className="inner_single_breadcrumbs_item">
        <span className="inner_single_breadcrumbs_arr">{'>'}</span>
        <span className="inner_single_breadcrumbs_item_last">{chapterString}</span>
      </div>
    </div>
  );
};

export default InnerBreadcrumbs;
