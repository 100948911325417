import React, { CSSProperties, ChangeEvent, HTMLInputTypeAttribute, ReactNode } from 'react';

type InputLabelForProps = {
  id: string;
  className?: string;
  label: string;
  name: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type: HTMLInputTypeAttribute;
  value?: string;
  readOnly?: boolean;
  disabled?: boolean;
};

const InputLabelFor = ({ id, label, name, onChange, placeholder, type, value, readOnly, disabled, className }: InputLabelForProps) => {
  return (
    <>
      <label htmlFor={id} className={`text_label`}>
        {label}
      </label>
      <input
        type={type}
        id={id}
        name={name}
        value={value}
        className={className ? `text_input ${className}` : `text_input`}
        readOnly={readOnly}
        disabled={disabled}
        placeholder={placeholder}
        onChange={onChange}
      />
    </>
  );
};

export default InputLabelFor;
