import React from 'react';
import { NavLink } from 'react-router-dom';
import { format } from 'date-fns';

// Определение типа для объекта страницы
type PageType = {
  h1: string;
  title: string;
  updated: number;
  description: string;
  breadcrumbs: string;
  text: string;
  seoUrl: string;
  showOnMain: boolean;
  excerpt: string;
  banner: {
    text: string;
    image: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
  icon: {
    big: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
    small: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
};

// Определение общего типа для элементов в массиве socialDataState
type SocialDataItem = {
  _id: string;
  name: string;
  __v: number;
  active: boolean;
  activeApi: boolean;
  category: string;
  page: PageType;
  parentId: string;
  rate: string;
  service: string;
  socialMedia: string;
  type: string;
};

// Определение типа для пропсов компонента AdminBlog
type BlogItemsListProps = {
  menuList: SocialDataItem[];
  typeString?: string;
};

const BlogItemsList = ({ menuList }: BlogItemsListProps) => {
  return (
    menuList && (
      <>
        <div className="inner_section main_popular_services_wrap single_content_wrap">
          {menuList.map((item: any, index: number) => (
            <NavLink to={item.link} className={index > 1 ? 'blog_item_wrap shadow_border border_rounded' : `blog_item_wrap blog_item_wrap_big shadow_border border_rounded`} key={index}>
              <img src={item.page.banner.image.filepath ? item.page.banner.image.filepath : '/content/not/icon_red.png'} alt={item.name} />
              <div className="blog_item">
                <div className="blog_item_name">{item.name}</div>
                <div className="blog_item_bottom">
                  <img src={item.categoryIcon ? item.categoryIcon : '/content/not/blg_new_small.png'} alt={item.page.h1 ? item.page.h1 : item.name} />
                  <div className="blog_item_info">
                    <strong>{item.categoryName}</strong> / {format(new Date(item.page.updated), 'dd.MM.yyyy')}
                  </div>
                </div>
              </div>
            </NavLink>
          ))}
        </div>
      </>
    )
  );
};

export default BlogItemsList;
