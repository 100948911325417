import React, { useEffect, useState } from 'react';
import BtnOutline from '../ui-kit/BtnOutline';
import { NavLink } from 'react-router-dom';
import { fetchApi } from '../helpers/api';

// Передаю менюхи
type Items = {
  name: string;
  link: string;
  icon: {
    parentId?: number;
    id: string;
    filename: string;
    filetype: string;
    datetime: string;
    filesize: string;
    filepath: string;
  };
  index: string;
};

// Определение общего типа для элементов в массиве менюх
type HeaderProps = {
  _id: string;
  name: string;
  type: string;
  subtype: string;
  items: Items[];
};

type BannerProps = {
  menuDataState: HeaderProps[];
  isMobile?: boolean;
};

const Banner = ({ menuDataState, isMobile }: BannerProps) => {
  const [bannerMenuTopList, setBannerMenuTopList] = useState<any>(null);

  const [homeH1, setHomeH1] = useState<string>('');
  const [homeBannerSubtitle, setHomeBannerSubtitle] = useState<string>('');

  const fetchCatalogRootItem = async () => {
    try {
      const mainH1 = await fetchApi('GET', `settings/get/mainH1`, {});
      if (mainH1) {
        setHomeH1(mainH1.value);
      }
      const mainSubtitle = await fetchApi('GET', `settings/get/mainSubtitle`, {});
      if (mainSubtitle) {
        setHomeBannerSubtitle(mainSubtitle.value);
      }
    } catch (error) {
      console.error('Error fetching social item:', error);
    } finally {
    }
  };
  useEffect(() => {
    fetchCatalogRootItem();
  }, []);

  useEffect(() => {
    if (menuDataState) {
      // Заполняю менюхи
      const foundSidebarOneMenu = menuDataState.find((item) => item.subtype === 'sitebar_menu_1');
      if (foundSidebarOneMenu) {
        const sortedArr = foundSidebarOneMenu.items.sort((a, b) => parseInt(a.index) - parseInt(b.index));
        setBannerMenuTopList(sortedArr);
      }
    }
  }, [menuDataState]);

  return (
    <div className="main_banner_container">
      <div className="main_banner_wrap">
        <div className="container">
          <div className="main_banner">
            <img className="main_banner_img" src="/content/main_banner/main_banner.png" alt="MediaBoost - начни продвижение и заработок в соц сетях уже сегодня!" />
            <div className="main_banner_content">
              <h1 className="main_banner_title">{homeH1}</h1>
              <div className="main_banner_subtitle">{homeBannerSubtitle}</div>
              {bannerMenuTopList && (
                <div className="main_banner_social_wrap">
                  {bannerMenuTopList.map((item: any, index: number) => (
                    <NavLink to={item.link} className={`main_banner_social_item`} key={index}>
                      <img src={item.icon.filepath} alt={item.name} />
                    </NavLink>
                  ))}
                </div>
              )}

              {!isMobile && (
                <div className="main_banner_btn_wrap">
                  <BtnOutline btnLink="/services" className="btn_wide_full" btnText="Каталог услуг" />
                  <BtnOutline btnLink="/personal" className="btn_wide_full btn_outline btn_bg_transparent" btnText="Личный кабинет" />
                </div>
              )}
            </div>
            <div className="main_banner_social_top">
              <div className="main_banner_social_top_title">Присоединяйся</div>
              <div className="main_banner_social_top_wrap">
                <a href="#" className="main_banner_social_top_item" title="Instagram">
                  <img src="/content/main_banner/icons/insta.svg" alt="Instagram" width={37} height={37} />
                </a>
                <a href="#" className="main_banner_social_top_item" title="Telegram">
                  <img src="/content/main_banner/icons/telega.svg" alt="Telegram" width={37} height={37} />
                </a>
                <a href="#" className="main_banner_social_top_item" title="Youtube">
                  <img src="/content/main_banner/icons/youtube.svg" alt="Youtube" width={37} height={37} />
                </a>
                <a href="#" className="main_banner_social_top_item" title="Twitch">
                  <img src="/content/main_banner/icons/twitch.svg" alt="Twitch" width={37} height={37} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isMobile && (
        <div className="main_banner_btn_wrap">
          <BtnOutline btnLink="/services" className="btn_wide_full" btnText="Каталог услуг" />
          <BtnOutline btnLink="/personal" className="btn_wide_full btn_outline btn_bg_transparent" btnText="Личный кабинет" />
        </div>
      )}
    </div>
  );
};

export default Banner;
