import React from 'react';
import MenuLink from './../../ui-kit/MenuLink';

// Определение типа для объекта страницы
type Items = {
  name: string;
  link: string;
  icon: {
    parentId?: number;
    id: string;
    filename: string;
    filetype: string;
    datetime: string;
    filesize: string;
    filepath: string;
  };
  index: string;
};

// Определение общего типа для элементов в массиве socialDataState
type MenuProps = {
  _id: string;
  name: string;
  type: string;
  subtype: string;
  items: Items[];
};

type FooterMenuProps = {
  menuTitle: string;
  mainMenuState: MenuProps[];
};

const FooterMenu = ({ menuTitle, mainMenuState }: FooterMenuProps) => {
  return (
    <div className="footer_menu_wrap">
      <div className="footer_menu_title">{menuTitle}</div>
      <div className="footer_menu_list">
        {mainMenuState && mainMenuState.map((item: any, index: number) => <MenuLink menuLink={item.link} menuText={item.name} className="footer_menu_item" key={index} newWindow={item.newWindow} />)}
      </div>
    </div>
  );
};

export default FooterMenu;
