// global.ts

import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

  
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

  * {
    outline: none !important;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* ... */
  }

  html {
    /* overflow-x: hidden;
    margin-right: calc(-1 * (100vw - 100%)); */
  }

  body {
    font-family: 'Montserrat', sans-serif;
    background: linear-gradient(288deg, #D9E6FF 0%, #FFF 100%);
    color: #5F5F5F;
  }

  .admin_wrap {
    display: flex;
  }
  .admin_sidebar {
    top: 0px;
    bottom: 0px;
    /* overflow-y: auto; */
    width: 300px;
    min-height: 100vh;
    border-right: 1px solid #eeeeef;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    z-index: 50;
    background: rgb(255, 255, 255);
    transition: left 0.25s ease-in-out 0s;

    font-size: 14px;
    padding: 16px 24px;
  }
  .admin_menu_list {
    list-style: none;
  }
  .admin_menu_list li {
    list-style: none;
  }
  .admin_menu_item:not(:last-child) {
    margin-bottom: 4px;
  }
  .admin_menu_item a {
    display: flex;
    align-items: center;
    height: 38px;
    padding: 7px 16px;
    font-weight: 400;
    color: #0c1e29;
    background-color: transparent;
    border-radius: 4px;
    text-decoration: none;
    transition: all 0.1s ease-in 0s;
    user-select: none;
  }
  .admin_menu_item a.active {
    background-color: rgba(48, 64, 214, 0.2);
    color: #3040d6;
    font-weight: 600;
  }
  .admin_menu_item a.active svg {
    stroke: #3040d6;
  }
  .admin_menu_item a:hover {
    background: #f0f0f0;
    color: #0c1e29;
  }
  .admin_menu_item a:hover svg {
    stroke: #898a9a;
  }
  .admin_menu_item a svg {
    margin-right: 16px;
    stroke: #898a9a;
    transition: all 0.1s ease-in 0s;
  }

  .admin_menu_item.is_parent {
    position: relative;
  }
  .admin_menu_item.is_parent .child_list {
    display: none;
    /* display: block; */

    position: absolute;
    top: 0px;
    right: -100%;
    width: 100%;
    background-color: #fff;
    border: 1px solid #eeeeef;
    border-radius: 4px;
  }
  .admin_menu_item.is_parent:hover .child_list {
    display: block;
  }
  /* .admin_menu_item.is_parent .child_list li:not(:last-child) {
    border-bottom: 1px solid #eeeeef;
  } */

  .admin_inner {
    flex: 1;
    padding: 24px 32px;
  }
  .admin_container {
    position: relative;
    padding: 20px 24px 24px;
    background-color: #FFF;
    border-radius: 10px;
  }
  .admin_container:before {
    position: absolute;
    content: '';
    top: 0px;
    left: 0px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%),rgba(255, 255, 255, 0.10);
    backdrop-filter: blur(2px);
    border-radius: 10px;
    transition: .25s opacity;
    opacity: 0;
    z-index: 99;
  }
  .admin_container.loading:before {
    width: 100%;
    height: 100%;
    opacity: 1;
  }

  .admin_table_wrap {
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(57, 73, 86, 0.05);
    background-color: #FFF;
    padding: 0;
  }
  .admin_table_list {
    border-collapse: collapse;
    border-spacing: 0;
    position: relative;
    color: #0c1e29;
    width: 100%;
  }
  .admin_table_list thead {
    background-color: rgba(0, 0, 0, 0.01);
  }
  .admin_table_list thead tr {
    border-bottom: 1px solid rgb(238, 238, 239);
  }
  .admin_table_list th {
    padding: 12px 16px;
    font-size: 12px;
    font-weight: 700;
    text-decoration: none;
    white-space: nowrap;
    text-align: left;
    line-height: 16px;
    vertical-align: middle;
  }
  .admin_table_list tbody tr:not(:last-child) {
    border-bottom: 1px solid rgb(238, 238, 239);
  }
  .admin_table_list td {
    max-width: 370px;
    display: table-cell;
    border-top: 1px solid rgb(238, 238, 239);
    font-size: 14px;
    line-height: 16px;
    word-break: break-word;
    vertical-align: middle;
    padding: 16px;
  }
  .admin_table_list td.category_td {
    max-width: 230px;
  }
  .admin_table_list td a {
    color: #3040d6;
  }
  .admin_table_list td a.del_item {
    color: red;
  }
  .status_el {
    padding: 4px 10px;
    background-color: #3040d6;
    color: #fff;
    font-weight: 600;
    border-radius: 8px;
  }
  .status_disabled {
    background-color: red;
  }

  .admin_heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 32px;
    line-height: 40px;
    font-weight: 400;
    margin-bottom: 32px;
  }
  .btn_link {
    text-decoration: none;
  }
  .admin_submit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 40px;
    font-size: 16px;
    font-weight: 600;
    background-color: #3040d6;
    color: #FFF;
    border: 1px solid #3040d6;
    border-radius: 8px;
    cursor: pointer;
    transition: .25s;
  }
  .admin_submit_center {
    margin: 0 auto;
    margin-bottom: 4px;
  }
  .admin_submit:hover {
    background-color: transparent;
    color: #3040d6;
    border-color: #3040d6;
  }
  .order_cancel {
    background-color: red;
    border: 1px solid red;
    margin-bottom: 20px;
  }
  .order_cancel:hover {
    color: red;
    border-color: red;
  }
  .admin_form_section_title {
    font-size: 24px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 24px;
  }
  .admin_form_section_subtitle {
    font-size: 18px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 14px;
  }
  .admin_form_section_title_flex {
    display: flex;
    align-items: center;
  }
  .admin_form_section_title_text {
    margin-right: 12px;
  }
  .order_product_list {
    margin-bottom: 24px;
    padding: 30px;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
    border-radius: 6px;
    
    /* margin-bottom: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px dashed rgb(187, 195, 203); */
  }
  .order_product_list:first-child {
    border-top: 1px dashed rgb(187, 195, 203);
  }
  .order_item_row {
    margin-bottom: 24px;
    padding: 30px;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
    border-radius: 6px;
  }
  .order_product_list:last-child {
    margin-bottom: 0px;
    border-bottom: none;
  }
  .admin_form_row {
    width: 100%;
    margin-bottom: 16px;
  }
  .admin_form_section_wrap {
    margin-bottom: 24px;
    padding-bottom: 12px;
    border-bottom: 1px dashed rgb(187, 195, 203);
  }
  .admin_form_section_wrap:last-child {
    margin-bottom: 0px;
    padding-bottom: 0px;
    border-bottom: none;
  }
  .text_label {
    display: block;
    color: #9f9f9f;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 8px;
  }
  .text_input {
    width: 100%;
    border-radius: 6px !important;
    min-height: 40px;
    background: transparent;
    border: 1px solid rgb(187, 195, 203);
    border-radius: 2px;
    font-family: inherit;
    font-size: 14px;
    line-height: 24px;
    padding: 4px 8px;
  }
  .text_input[type="date"] {
    width: auto;
  }
  .text_input[disabled] {
    opacity: .5;
  }
  .order_inner .text_input[disabled] {
    opacity: 1;
  }
  .text_input.filled_green {
    border: 2px solid rgb(94 236 191);
    opacity: 1;
  }
  .text_input.filled_red {
    border: 2px solid rgb(194 0 18);
    opacity: 1;
  }

  .admin_form_row_checked {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 240px;
    padding: 5px 8px;
    border: 1px solid rgb(187, 195, 203);
    border-radius: 6px!important;
    position: relative;
    &.disabled {
      opacity: .5;
      pointer-events: none;
    }
    label {
      flex: 1;
      display: block;
      margin: 0px;
      padding: 0px;
      cursor: pointer;
      word-wrap: break-word;
    }

    label::before,
    label::after {
      position: absolute;
      display: block;
      content: '';
    }

    label::before {
      top: 50%;
      transform: translateY(-50%);
      right: 16px;
      width: 20px;
      height: 1px;
      border-top: 1px solid #5a5a5a;
    }

    label::after {
      top: 50%;
      transform: translateY(-50%);
      right: 28px;
      background-color: #fff;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: solid 2px #5a5a5a;
      transition:all 200ms ease-in;
    }

    input[type="checkbox"] {
      display: none;
    }

    input[type="checkbox"]:checked + label::after {
      transform:translate(20px, -50%) ;
      background-color:#3040d6;
      border-color:#3040d6;
    }
  }

  .admin_form_section_wrap_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
  }

  .admin_form_section_wrap_grid_third {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
  }
  .admin_form_section_wrap_grid_fourth {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 24px;
  }
  .admin_form_section_wrap_grid_fiveth {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 24px;
    margin-bottom: 4px;
  }
  .admin_form_section_wrap_grid_sixth {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 24px;
    margin-bottom: 4px;
  }

  .align_center {
    align-items: center;
  }
  .admin_form_section_wrap_grid_sixth .fileupload_view_drag_box,
  .admin_form_section_wrap_grid_fiveth .fileupload_view_drag_box,
  .admin_form_section_wrap_grid_fourth .fileupload_view_drag_box,
  .admin_form_section_wrap_grid_third .fileupload_view_drag_box {
    min-height: 50px;
  }
  .admin_form_section_wrap_grid_sixth .fileupload_view,
  .admin_form_section_wrap_grid_fiveth .fileupload_view,
  .admin_form_section_wrap_grid_fourth .fileupload_view,
  .admin_form_section_wrap_grid_third .fileupload_view {
    margin-bottom: 0px;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 16%);
  }
  .admin_form_section_wrap_grid_sixth .fileupload_view,
  .admin_form_section_wrap_grid_fiveth .fileupload_view,
  .admin_form_section_wrap_grid_fourth .fileupload_view {
    padding: 25px;
  }

  .admin_form_section_wrap_grid_fiveth.fileupload_view_row .fileupload_view_list_box,
  .admin_form_section_wrap_grid_sixth.fileupload_view_row .fileupload_view_list_box {
    flex-direction: column;
  }
  .admin_form_section_wrap_grid_sixth.fileupload_view_row .fileupload_view_list_box .file-detail,
  .admin_form_section_wrap_grid_fiveth.fileupload_view_row .fileupload_view_list_box .file-detail {
    width: 100%;
  }

  .fileupload_view_row {
    margin-bottom: 24px;
    padding: 30px;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 16%);
    border-radius: 6px;
    overflow: hidden;
  }
  .fileupload_view_row_m0 {
    margin-top: 4px;
    margin-bottom: 0px;
  }
  
  .fileupload_view {
    margin-bottom: 24px;
    padding: 30px;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
    /* border: 1px solid #bbc3cb; */
    border-radius: 6px;
    overflow: hidden;
  }
  .fileupload_view_title {
    margin-bottom: 10px;
    font-size: 16px;
    /* text-align: center; */
  }
  .fileupload_view_drag {
    /* margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px dashed #eaecf1; */
  }
  .fileupload_view_drag_box {
    border: 1px dashed #b6bed1;
    background-color: #f0f2f7;
    border-radius: 4px;
    min-height: 150px;
    position: relative;
    overflow: hidden;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8194aa;
    font-weight: 400;
    font-size: 15px;
  }
  .fileupload_input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
  .fileupload_link {
    color: #3040d6;
    font-weight: 500;
    text-decoration: underline;
    margin-left: 3px;
  }
  .fileupload_view_list_box {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 15px;
    padding-top: 20px;
    border-top: 1px dashed #eaecf1;
  }
  .fileupload_view_list_box:last-child {
    margin-bottom: 0px;
  }
  .file-image {
    width: 130px;
    height: 85px;
    background-size: cover;
    border-radius: 5px;
    margin-right: 15px;
    background-color: #eaecf1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #475f7b;
    padding: 3px;
  }
  .file-image img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 4px;
  }
  .file-detail {
    flex: 1;
    width: calc(100% - 210px);
  }
  .file-detail-name {
    word-break: break-all;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 8px;
  }
  .file-detail p {
    font-size: 12px;
    color: #8194aa;
    line-height: initial;
    font-weight: 400;
    margin-bottom: 8px;
  }
  .file-detail-modified {
    margin-left: 10px;
  }
  .file-action-btn {
    font-size: 13px;
    color: #8194aa;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    text-decoration: underline;
    margin-right: 15px;
    cursor: pointer;
  }
  .select_base {
    width: 100%;
    max-width: 240px;

    display: flex;
    align-items: center;
    width: 100%;
    max-width: 240px;
    padding: 5px 8px;
    border: 1px solid rgb(187, 195, 203);
    border-radius: 6px!important;
    position: relative;
    color: #5F5F5F;
    font-family: 'Montserrat', sans-serif;
  }

`;
