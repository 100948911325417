import React, { useEffect, useState } from 'react';
import SendReviewForm from '../components/ui-kit/form/SendReviewForm';
import BtnOutline from '../components/ui-kit/BtnOutline';
import TextArea from '../components/ui-kit/input/TextArea';
import ReviewSelect from '../components/ui-kit/input/ReviewSelect';
import { useUserData } from '../hooks/UserDataContext';
import { set } from 'date-fns';
import { fetchApi } from '../components/helpers/api';

// Определение типа для объекта страницы
type PageType = {
  h1: string;
  title: string;
  updated: number;
  description: string;
  breadcrumbs: string;
  text: string;
  seoUrl: string;
  showOnMain: boolean;
  excerpt: string;
  banner: {
    text: string;
    image: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
  icon: {
    big: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
    small: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
};

// Определение общего типа для элементов в массиве socialDataState
type SocialDataItem = {
  _id: string;
  name: string;
  __v: number;
  active: boolean;
  activeApi: boolean;
  category: string;
  page: PageType;
  parentId: string;
  rate: string;
  service: string;
  socialMedia: string;
  type: string;
};

// Определение типа для пропсов компонента AdminSocial
type SendReviewProps = {
  socialDataState: SocialDataItem[];
};

const SendReview = ({ socialDataState }: SendReviewProps) => {
  // ативный второй селект
  const [activeServiceTab, setActiveServiceTab] = useState(false);
  // ативный третий селект
  const [activeOrderTab, setActiveOrderTab] = useState(false);

  // текущая активная строка в первой влкдаке
  const [activeSocial, setActiveSocial] = useState<any>();
  // текущая активная строка в второй влкдаке
  const [activeSocialCurrentService, setActiveSocialCurrentService] = useState<any>();

  // формируется массив тарифов, которуе доступны для выбранной услуги
  const [activeSocialCurrentServices, setActiveSocialCurrentServices] = useState<any>();

  // ID выбранного элемента
  const [selectedElementId, setSelectedElementId] = useState<any>();
  // название выбранного элемента
  const [selectedElementName, setSelectedElementName] = useState<any>();

  // соц сети
  const [socialRootCategoryArr, setSocialRootCategoryArr] = useState<any>(null);
  // соц сети в формате для селекта
  const [socialRootCategoryArrSelect, setSocialRootCategoryArrSelect] = useState<any>(null);

  // категории
  const [socialCategoryArr, setSocialCategoryArr] = useState<any>(null);

  // элементы
  const [socialElArr, setSocialElArr] = useState<any>(null);

  // Валидность текста
  const [isValidReviewText, setIsValidReviewText] = useState<any>(true);
  // Валидность первого селекта
  const [isValidSocialSelect, setIsValidSocialSelect] = useState<any>(true);
  // Валидность второго селекта
  const [isValidSocialCatSelect, setIsValidSocialCatSelect] = useState<any>(true);
  // Валидность третьего селекта
  const [isValidSocialElSelect, setIsValidSocialElSelect] = useState<any>(true);

  // Текст отзыва
  const [reviewText, setReviewText] = useState<string>('');
  const [reviewLink, setReviewLink] = useState<any>(null);

  // Массив данных отзыва для отправки
  const [reviewFinalArr, setReviewFinalArr] = useState<any>(null);
  const [reviewSendStatus, setreviewSendStatus] = useState<any>(null);

  const fetchCatalogRootData = async () => {
    try {
      const newDataCatalogRoot = await fetchApi('GET', 'services/get_root_categories', {});
      const rootCategories = getRootCategory(newDataCatalogRoot);
      setSocialRootCategoryArr(rootCategories);
    } catch (error) {
      console.error('Error fetching social item:', error);
    } finally {
    }
  };

  const fetchCatalogCatData = async (id: any) => {
    try {
      if (id) {
        const newDataCatalogCat = await fetchApi('GET', `services/get_categories/${id}`, {});
        const categories = getCategory(newDataCatalogCat);
        setSocialCategoryArr(categories);
      } else {
        console.error("No social item to fetch");
      }
    } catch (error) {
      console.error('Error fetching social item:', error);
    } finally {
    }
  };

  const fetchCatalogPath = async (id: any) => {
    try {
      const newDataCatalogPath = await fetchApi('GET', `path/get/${id}`, {});
      if (newDataCatalogPath) {
        setReviewLink(newDataCatalogPath[0].seo_url);
        console.log(newDataCatalogPath);
      }
    } catch (error) {
      console.error('Error fetching social item:', error);
    } finally {
    }
  };

  // Функция для получения массива соц сетей
  const getRootCategory = (data: SocialDataItem[]): {}[] => {
    // const socialSortItem = data.filter((item) => item.type === 'root_category'); // Отфильтровать только категории
    const socialSortItem = data;
    return socialSortItem;
  };

  // Функция для получения массива категорий
  const getCategory = (data: SocialDataItem[], activeSocial?: any): {}[] => {
    // const socialSortItem = data.filter((item) => item.type === 'category');
    // const socialSortItemFiltered = socialSortItem.filter((item) => item.parentId === activeSocial);
    const socialSortItem = data;
    const socialSortItemFiltered = socialSortItem;
    return socialSortItemFiltered;
  };

  // Функция для получения массива элементов
  // const getElements = (data: SocialDataItem[], activeSocial: any): {}[] => {
  const getElements = (data: SocialDataItem[], activeSocial: string): SocialDataItem[] => {
    // const socialSortItemAll = data.filter((item) => item.type != 'category' && item.type != 'root_category');
    const socialSortItemAll = data;
    // const socialSortItemFiltered = socialSortItemAll.filter((item) => item.parentId === activeSocial);
    const socialSortItemFiltered = socialSortItemAll.filter((item) => item._id === activeSocial);
    return socialSortItemFiltered;
  };

  // Функция для получения массива элементов
  const getElementById = (data: SocialDataItem[], itemId: any) => {
    const socialSortItem = data.find((item) => item._id === itemId);
    return socialSortItem;
  };

  // первый выбранный элемент
  const handleFirstSelect = (selectedValue: any) => {
    setIsValidSocialSelect(true);
    setActiveOrderTab(false);
    setActiveSocialCurrentService(0);
    setActiveSocialCurrentServices([]);

    let currentSocialData = selectedValue;
    setActiveServiceTab(true);
    setActiveSocial(currentSocialData);
    // назначаю массив категорий
    const categories = getCategory(socialDataState, currentSocialData);
    setSocialCategoryArr(categories);
  };

  // второй выбранный элемент
  const handleSecondSelect = (selectedValue: any) => {
    setIsValidSocialCatSelect(true);
    let currentSocialServiceData = selectedValue;
    // setActiveOrderTab(true);
    setActiveSocialCurrentService(currentSocialServiceData);

    console.log(socialCategoryArr);

    const elements = getElements(socialCategoryArr, currentSocialServiceData);
    console.log(elements);
    // setSocialElArr(elements);

    if (elements[0]._id) {
      fetchCatalogPath(elements[0]._id);
      setSelectedElementId(elements[0]._id);
      setSelectedElementName(elements[0].name);
    }
  };

  // третий выбранный элемент
  const handleThirdSelect = (selectedValue: any) => {
    setIsValidSocialElSelect(true);
    // назначаю массив категорий
    const element = getElementById(socialDataState, selectedValue);
    if (element) {
      setSelectedElementId(element._id);
      setSelectedElementName(element.name);
    }
  };

  // Получаю инфу о юзере из контекста
  const { personalEmail, personalName, personalPhone, personalBalance, fetchUserDataContext, token, userId } = useUserData();

  // Отправка отзыва
  const handleSendReview = () => {
    let formValid = true;
    if (!activeSocial) {
      setIsValidSocialSelect(false);
      formValid = false;
    }

    if (!activeSocialCurrentService) {
      setIsValidSocialCatSelect(false);
      formValid = false;
    }

    if (!selectedElementId) {
      setIsValidSocialElSelect(false);
      formValid = false;
    }

    if (reviewText.length === 0) {
      setIsValidReviewText(false);
      formValid = false;
    }

    if (formValid) {
      const reviewFinalArrObjs = {
        name: personalName,
        userId: userId,
        text: reviewText,
        date: Date.now(),
        parentId: activeSocial,
        elementName: selectedElementName,
        elementId: selectedElementId,
        elementLink: reviewLink
      };
      if (reviewFinalArrObjs) {
        console.log(reviewFinalArrObjs);
        setReviewFinalArr(reviewFinalArrObjs);
        fetchApi('POST', 'review/new', reviewFinalArrObjs).then((result) => {
          setreviewSendStatus(result);
          alert('Ваш отзыв отправлен');
          setReviewText('');
          setActiveSocial('');
          setActiveSocialCurrentService('');
        });
      }
    }
  };

  useEffect(() => {
    fetchCatalogRootData();
  }, []);

  useEffect(() => {
    if (activeSocial) {
      fetchCatalogCatData(activeSocial);
    }
  }, [activeSocial]);

  // useEffect(() => {
  //   if (reviewFinalArr) {
  //     console.log(reviewFinalArr);
  //     fetchApi('POST', 'review/new', reviewFinalArr).then((result) => {
  //       setreviewSendStatus(result);
  //       alert('Ваш отзыв отправлен');
  //     });
  //   }
  // }, [reviewFinalArr]);

  return (
    <div className="reviews_video_section_wrap review_wrap">
      {token && token.length > 0 ? (
        <>
          <div className="inner_col_content_subtitle_top">Оставьте отзыв:</div>
          {socialDataState.length > 0 ? (
            <SendReviewForm classMame="personal_login_form">
              <div className="review_row">
                <TextArea
                  className={`base_input base_input_wide review_textarea ${isValidReviewText ? '' : 'error'}`}
                  placeholder={'Оставьте ваш отзыв'}
                  value={reviewText ? reviewText : ''}
                  onChange={(event) => {
                    setReviewText(event.target.value);
                    setIsValidReviewText(true);
                  }}
                />
                {!isValidReviewText && <div className="base_input_wide_error">Введите отзыв!</div>}
              </div>
              <div className="review_row review_row_flex">
                <div className="review_select_list">
                  <ReviewSelect
                    className={`personal_select base_input_wide ${isValidSocialSelect ? '' : 'error'}`}
                    selectItems={socialRootCategoryArr && socialRootCategoryArr}
                    name={'choose_social'}
                    firstValue={'Выберите соц.сеть'}
                    selectedItem={activeSocial ? activeSocial : 'Выберите соц.сеть'}
                    onChange={handleFirstSelect}
                  />
                  {activeServiceTab === true && (
                    <ReviewSelect
                      className={`personal_select base_input_wide ${isValidSocialCatSelect ? '' : 'error'}`}
                      selectItems={socialCategoryArr && socialCategoryArr}
                      name={'choose_cat'}
                      firstValue={'Выберите категорию'}
                      selectedItem={activeSocialCurrentService ? activeSocialCurrentService : 'Выберите категорию'}
                      onChange={handleSecondSelect}
                    />
                  )}
                  {/* {activeOrderTab === true && (
                    <ReviewSelect
                      className={`personal_select base_input_wide ${isValidSocialElSelect ? '' : 'error'}`}
                      selectItems={socialElArr && socialElArr}
                      name={'choose_el'}
                      firstValue={'Выберите услугу'}
                      onChange={handleThirdSelect}
                    />
                  )} */}
                </div>
                <BtnOutline className="btn_wide login_btn" btnText="Отправить" type={'button'} onClick={handleSendReview} />
              </div>
            </SendReviewForm>
          ) : (
            <div>Loading...</div>
          )}
        </>
      ) : (
        <div className="review_login_wrap">
          <div className="review_login_wrap_text">Войдите, чтобы оставить отзыв!</div>
          <BtnOutline className="btn" btnText="Вход" type={'button'} btnLink={'/login'} />
        </div>
      )}
    </div>
  );
};

export default SendReview;
