import React, { useEffect, useState } from 'react';
import { NavLink, Navigate, generatePath, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import BtnBase from '../admin-ui-kit/btn/BtnBase';

// Определение типа для объекта страницы
type PageType = {
  h1: string;
  title: string;
  updated: number;
  description: string;
  breadcrumbs: string;
  text: string;
  seoUrl: string;
};

// Определение общего типа для элементов в массиве socialDataState
type SocialDataItem = {
  _id: string;
  name: string;
  type: string;
  active: boolean;
  page: PageType;
};

// Определение типа для пропсов компонента AdminPage
type AdminPageProps = {
  socialDataState: SocialDataItem[];
  typeString: string;
};

const AdminPage = ({ socialDataState, typeString }: AdminPageProps) => {
  const [socialDataStateArr, setSocialDataStateArr] = useState<any[]>([]);
  const [socialTypeString, setSocialTypeString] = useState(typeString);

  const [customSocialArr, setCustomSocialArr] = useState<any[]>([]);

  useEffect(() => {
    setSocialTypeString(typeString);
  }, [typeString]);

  useEffect(() => {
    // Имитация асинхронной загрузки данных (если это нужно)
    setTimeout(() => {
      setSocialDataStateArr(socialDataState);
      const sortedArray = socialDataState.slice().sort((a, b) => a.name.localeCompare(b.name));
      setCustomSocialArr(sortedArray);
    }, 500);
  }, [socialDataState, socialTypeString]);

  // получаю название родительской категории
  const getParentCategoryName = (parentId: string): string => {
    const parentCategory = socialDataStateArr.find((category) => category._id === parentId);
    return parentCategory ? parentCategory.name : '';
  };

  return (
    <>
      <div className="admin_heading">
        <div className="admin_heading_title">Страницы</div>
        <BtnBase className={'admin_submit'} btnText={'Добавить'} type={'submit'} />
      </div>
      {/* <div className="admin_container"></div> */}
      <div className="admin_table_wrap">
        <table className="admin_table_list">
          <thead>
            <tr>
              <th>ID</th>
              {socialTypeString === 'item' && <th>Сategory</th>}
              <th>Name</th>
              <th>Status</th>
              <th>Updated</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {customSocialArr.map((item) => (
              <tr key={item._id}>
                <td>{item._id}</td>
                {socialTypeString === 'item' && <td className="category_td">{getParentCategoryName(item.parentId)}</td>}
                <td>{item.name}</td>
                <td>
                  {item.activeApi === false ? (
                    <span className="status_el status_disabled">Disabled</span>
                  ) : item.active ? (
                    <span className="status_el status_active">Active</span>
                  ) : (
                    <span className="status_el status_disabled">Disabled</span>
                  )}
                </td>
                <td>{format(new Date(item.page.updated), 'dd.MM.yyyy, HH:mm')}</td>
                <td>
                  <NavLink to={`edit/${item._id}`}>Edit</NavLink>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AdminPage;
