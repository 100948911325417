import React, { createContext, useContext, useEffect, useState } from 'react';
import { fetchApi } from '../components/helpers/api';
import { Authentification } from '../components/helpers/auth';
import { useLocation } from 'react-router-dom';

// Создайте контекст
export const UserDataContext = createContext({
  personalEmail: '',
  personalName: '',
  personalPhone: '',
  personalBalance: '',
  token: '',
  userId: '',
  isUserLogin: false,
  lastUrl: '',
  fetchUserDataContext: () => { } // Пустая функция по умолчанию
});

// Создайте компонент-провайдер, который будет предоставлять значения вашего контекста
export const UserDataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [personalEmail, setPersonalEmail] = useState('');
  const [personalName, setPersonalName] = useState('');
  const [personalPhone, setPersonalPhone] = useState('');
  const [personalBalance, setPersonalBalance] = useState('');
  const [token, setToken] = useState<any>(null);
  const [userId, setUserId] = useState<any>(null);
  const [isUserLogin, setIsUserLogin] = useState<any>(null);
  const [lastUrl, setLastUrl] = useState<string>(window.location.pathname);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== '/login') setLastUrl(location.pathname);
  }, [location]);

  useEffect(() => {
    const tokenEl = Authentification.getInstance().getToken();
    Authentification.getInstance().checkToken().then((tokenIsTrue) => {
      setIsUserLogin(tokenIsTrue ?? false);
      if (tokenIsTrue) {
        setToken(tokenEl);
        setUserId(Authentification.getInstance().getUserId());
      }
    })
  });

  useEffect(() => {
    // Выполните ваш асинхронный запрос при монтировании компонента
    fetchUserDataContext();
  }, [token]);

  const fetchUserDataContext = () => {
    fetchApi('GET', `users/get_info/${Authentification.getInstance().getUserId()}`, {}).then((res) => {
      if (res) {
        setPersonalEmail(res.email);
        setPersonalName(res.name);
        setPersonalPhone(res.phone);
        setPersonalBalance(res.balance ? res.balance : '0');
      }
    });
  };

  // Предоставьте значения контекста всем дочерним компонентам
  return (
    <UserDataContext.Provider value={{ personalEmail, personalName, personalPhone, personalBalance, fetchUserDataContext, token, userId, isUserLogin, lastUrl }}>{children}</UserDataContext.Provider>
  );
};

// Используйте этот хук в ваших компонентах, чтобы получить значения контекста
export const useUserData = () => useContext(UserDataContext);
