import { MD5, SHA256, enc } from 'crypto-js';
import { Buffer } from 'buffer';
import { fetchApi } from './api';

interface Order {
    acquiring: string,
    _id: string,
    userId: string,
    date: string,
    products: object,
    amount: number,
    payed: boolean,
    order: string,
    status: string,
    type: string,
    __v: number
}

interface PayeerParams {
    m_shop: string;
    m_orderid: string;
    m_amount: string;
    m_curr: string;
    m_desc: string;
    m_sign: string;
    lang: string;
};

export function sendOrder(order: Order, acquiring?: String): void {
    switch (acquiring) {
        case "Cryptomus":
            return sendOrderToCrypto(order);
        case "Yookassa":
            return sendOrderToYookassa(order);
        default:
            sendOrderToPayeer(order).then((result) => { return result; });
    }
}

export async function sendOrderToPayeer(order: Order): Promise<void> {
    const keys = await fetchApi('POST', 'settings/get', { settingsList: ['apiKeyPayeer', 'apiIdPayeer'] });
    const m_shop: string = keys.apiIdPayeer; // shop ID
    const m_orderid: string = order._id;
    const m_amount: string = order.amount.toFixed(2);
    const m_curr: string = 'RUB';
    const m_desc: string = Buffer.from('Заказ #' + order._id + ' на сайте media-booster.ru').toString('base64');
    const m_key: string = keys.apiKeyPayeer; // секретный ключ

    const arHash: string[] = [
        m_shop,
        m_orderid,
        m_amount,
        m_curr,
        m_desc,
        m_key
    ];

    const sign: string = SHA256(arHash.join(':')).toString().toUpperCase();

    const payeerParams: PayeerParams = {
        m_shop,
        m_orderid,
        m_amount,
        m_curr,
        m_desc,
        m_sign: sign,
        lang: "ru"
    };

    const queryString = Object.keys(payeerParams)
        .map(key => `${key}=${encodeURIComponent(payeerParams[key as keyof PayeerParams])}`)
        .join('&');

    (async () => { await fetchApi("POST", "balance/save_payment_link", { id: order._id, link: `https://payeer.com/merchant/?${queryString}` }) })();
    window.location.href = `https://payeer.com/merchant/?${queryString}`;
}

export function sendOrderToCrypto(order: Order): void {
    (async () => {
        await fetchApi("POST", "/services/order_create_crypto_payment", order).then(async (response) => {
            if (response && response.redirect) {
                window.location.href = response.redirect;
            } else {
                console.log(response)
            }
        })
    })();
}

export function sendOrderToYookassa(order: Order): void {
    (async () => {
        await fetchApi("POST", "/services/order_create_yookassa_payment", order).then(async (response) => {
            if (response && response.redirect) {
                window.location.href = response.redirect;
            } else {
                console.log(response)
            }
        })
    })();
}
