import React, { useDebugValue, useEffect, useState } from 'react';
import PersonalOrderCreateSecondTab from './PersonalOrderCreateSecondTab';
import PersonalOrderCreateThirdTab from './PersonalOrderCreateThirdTab';
import PersonalOrderCreateFirstTab from './PersonalOrderCreateFirstTab';
import { Authentification } from '../../../helpers/auth';
import { fetchApi } from '../../../helpers/api';

// Определение типа для объекта страницы
type PageType = {
  h1: string;
  title: string;
  updated: number;
  description: string;
  breadcrumbs: string;
  text: string;
  seoUrl: string;
  showOnMain: boolean;
  excerpt: string;
  banner: {
    text: string;
    image: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
  icon: {
    big: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
    small: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
};

// Определение общего типа для элементов в массиве socialDataState
type SocialDataItem = {
  _id: string;
  name: string;
  __v: number;
  active: boolean;
  activeApi: boolean;
  category: string;
  page: PageType;
  parentId: string;
  rate: string;
  service: string;
  socialMedia: string;
  type: string;
};

// Определение типа для пропсов компонента AdminBlog
type PersonalOrderCreateProps = {
  socialDataState: SocialDataItem[];
  typeString?: string;
  isLogged: boolean;
  activeSoc?: string;
  activeSocCat?: string;
  activeSocEl?: string;
};

const PersonalOrderCreate = ({ socialDataState, isLogged, activeSoc, activeSocCat, activeSocEl }: PersonalOrderCreateProps) => {
  // активная вторая влкдака
  const [activeServiceTab, setActiveServiceTab] = useState(false);
  // активная третья влкдака
  const [activeOrderTab, setActiveOrderTab] = useState(false);

  // текущая активная строка в первой влкдаке
  const [activeSocial, setActiveSocial] = useState<any>();
  // изображение выбранной рут-категории
  const [activeSocialIcon, setActiveSocialIcon] = useState<any>();
  // формируется массив услуг, которуе доступны на выбранной площадке
  const [activeSocialServices, setActiveSocialServices] = useState<any>();

  // текущая активная строка в второй влкдаке
  const [activeSocialCurrentService, setActiveSocialCurrentService] = useState<any>();
  // формируется массив тарифов, которуе доступны для выбранной услуги
  const [activeSocialCurrentServices, setActiveSocialCurrentServices] = useState<any>();

  // все объекты
  const [socialItems, setSocialItems] = useState<any>(null);
  // соц сети
  const [socialRootCategoryArr, setSocialRootCategoryArr] = useState<any>(null);
  // категории
  const [socialCategoryArr, setSocialCategoryArr] = useState<any>(null);
  // элементы
  const [socialElArr, setSocialElArr] = useState<any>(null);

  // Функция для получения массива соц сетей
  const getRootCategory = (data: SocialDataItem[]): {}[] => {
    const socialSortItem = data.filter((item) => item.type === 'root_category'); // Отфильтровать только категории
    return socialSortItem;
  };

  // Функция для получения массива категорий
  const getCategory = (data: SocialDataItem[], activeSocial: any): {}[] => {
    const socialSortItem = data.filter((item) => item.type === 'category');
    const socialSortItemFiltered = socialSortItem.filter((item) => item.parentId === activeSocial);
    return socialSortItemFiltered;
  };

  // Функция для получения массива элементов
  const getElements = (data: SocialDataItem[], activeSocial: any): {}[] => {
    const socialSortItemAll = data.filter((item) => item.type != 'category' && item.type != 'root_category');
    const socialSortItemFiltered = socialSortItemAll.filter((item) => item.parentId === activeSocial);
    return socialSortItemFiltered;
  };

  // Функция для получения массива элементов
  const getElementById = (data: SocialDataItem[], itemId: any) => {
    const socialSortItem = data.find((item) => item._id === itemId);
    return socialSortItem;
  };

  const chooseSocial = (e: React.MouseEvent<HTMLElement>) => {
    setActiveOrderTab(false);
    setActiveSocialCurrentService(0);
    setActiveSocialCurrentServices([]);

    let currentSocialData = e.currentTarget.getAttribute('data-social-id');
    setActiveServiceTab(true);
    setActiveSocial(currentSocialData);
    // назначаю массив категорий
    const categories = getCategory(socialDataState, currentSocialData);
    setSocialCategoryArr(categories);
    const el = getElementById(socialDataState, currentSocialData);
    setActiveSocialIcon(el?.page.icon.small.filepath);

    const secondTabEl = document.getElementById('orderSecondTab');
    secondTabEl?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  };

  const chooseSocialService = (e: React.MouseEvent<HTMLElement>) => {
    let currentSocialServiceData = e.currentTarget.getAttribute('data-service-id');
    setActiveOrderTab(true);
    setActiveSocialCurrentService(currentSocialServiceData);

    // назначаю массив категорий
    const elements = getElements(socialDataState, currentSocialServiceData);
    setSocialElArr(elements);

    const thirdTabEl = document.getElementById('orderThirdTab');
    thirdTabEl?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  };

  const [rootAPIState, setRootAPIState] = useState<any[]>([]);
  // Информация о всех рут категориях (созданных вручную)
  const fetchCatalogRootData = async () => {
    try {
      const newDataCatalogRoot = await fetchApi('GET', 'services/get_root_categories', {});
      setRootAPIState(newDataCatalogRoot);
    } catch (error) {
      console.error('Error fetching social item:', error);
    } finally {
    }
  };
  useEffect(() => {
    fetchCatalogRootData();
  }, []);
  //
  // Информация о всех категориях (созданных вручную)
  const fetchCatalogCatData = async () => {
    try {
      if (activeSocial) {
        const newDataCatalogCat = await fetchApi('GET', `services/get_categories/${activeSocial}`, {});
        setRootAPICatState(newDataCatalogCat);
      } else {
        console.error("No social item to fetch");
      }
    } catch (error) {
      console.error('Error fetching social item:', error);
    } finally {
    }
  };
  const [rootAPICatState, setRootAPICatState] = useState<any[]>([]);
  useEffect(() => {
    fetchCatalogCatData();
  }, [rootAPIState, activeSocial]);
  //
  // Информация о всех элементах (созданных вручную)
  const fetchCatalogItemsData = async () => {
    try {
      const newDataCatalogItems = await fetchApi('GET', `services/get_category_items/${activeSocialCurrentService}`, {});
      setRootAPIItemsState(newDataCatalogItems);
    } catch (error) {
      console.error('Error fetching social item:', error);
    } finally {
    }
  };
  const [rootAPIItemsState, setRootAPIItemsState] = useState<any[]>([]);
  useEffect(() => {
    if (activeSocialCurrentService) {
      fetchCatalogItemsData();
    }
  }, [rootAPIState, activeSocial, activeSocialCurrentService]);

  //
  useEffect(() => {
    if (socialDataState) {
      setSocialItems(socialDataState);
      // назначаю массив соц сетей
      const rootCategories = getRootCategory(socialDataState);
      setSocialRootCategoryArr(rootCategories);
    }
  }, [socialDataState]);
  //
  useEffect(() => {
    if (activeSoc) {
      setActiveSocial(activeSoc);
      // открываю вкладку
      setActiveServiceTab(true);
      // назначаю активную категорию
      setSocialCategoryArr(getCategory(socialDataState, activeSoc));
      // открываю вкладку
      setActiveOrderTab(true);
      setActiveSocialCurrentService(activeSocCat);
      // назначаю массив категорий
      setSocialElArr(getElements(socialDataState, activeSocCat));
      // назначаю активный элемент
    }
  }, [activeSoc]);
  //

  return socialDataState.length > 0 ? (
    <>
      <PersonalOrderCreateFirstTab
        tabName={'Выберите площадку'}
        tabNumbering={'1'}
        socialListItems={rootAPIState}
        // socialListItems={socialList}
        activeSocial={activeSocial}
        onClick={chooseSocial}
      />

      {activeServiceTab === true && (
        <PersonalOrderCreateSecondTab
          tabName={'Выберите тип услуги'}
          tabNumbering={'2'}
          activeSocialService={activeSocialCurrentService}
          serviceList={rootAPICatState}
          activeSocialIcon={activeSocialIcon}
          onClick={chooseSocialService}
        />
      )}

      {activeOrderTab === true && (
        <PersonalOrderCreateThirdTab tabName={'Ваш итоговый заказ'} tabNumbering={'3'} socialListItemsCurrent={rootAPIItemsState} activeSocEl={activeSocEl ? activeSocEl : ''} isLogged={isLogged} />
      )}
    </>
  ) : (
    <div>Loading...</div>
  );
};

export default PersonalOrderCreate;
