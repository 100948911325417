import React, { FormEvent, useEffect, useState } from 'react';
import InputBase from '../../../ui-kit/input/InputBase';
import BtnBase from '../../../ui-kit/btn/BtnBase';
import BtnOutline from '../../../ui-kit/BtnOutline';
import BaseSelect from '../../../ui-kit/input/BaseSelect';
import InnerForm from '../../../ui-kit/form/InnerForm';
import { fetchApi } from '../../../helpers/api';
import { sendOrder } from '../../../helpers/paymentSystem';
import { Authentification } from '../../../helpers/auth';
import { useUserData } from '../../../../hooks/UserDataContext';
import { useNavigate } from 'react-router-dom';

// Определение типа для объекта страницы
type PageType = {
  h1: string;
  title: string;
  updated: number;
  description: string;
  breadcrumbs: string;
  text: string;
  seoUrl: string;
  showOnMain: boolean;
  excerpt: string;
  banner: {
    text: string;
    image: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
  icon: {
    big: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
    small: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
};

// Определение общего типа для элементов в массиве socialDataState
type SocialDataItem = {
  _id: string;
  name: string;
  customName: string;
  __v: number;
  active: boolean;
  activeApi: boolean;
  category: string;
  page: PageType;
  parentId: string;
  rate: string;
  price: number;
  service: string;
  socialMedia: string;
  type: string;
};

interface socialListArr {
  parentId: number;
  _id: number;
  name: string;
  rate: number;
  price: number;
}
interface PersonalOrderCreateThirdTabProps {
  tabName: string;
  tabNumbering: string;
  isLogged: boolean;
  socialListItemsCurrent: SocialDataItem[];
  activeSocEl?: string;
}

const PersonalOrderCreateThirdTab = ({ tabName, tabNumbering, socialListItemsCurrent, isLogged, activeSocEl }: PersonalOrderCreateThirdTabProps) => {
  // Показывать email если юзер залогинен
  const [showEmailInput, setShowEmailInput] = useState<any>(false);
  const [userEmail, setUserEmail] = useState<any>('');

  // Список элементов для селекта
  const [serviceListRow, setServiceListRow] = useState<any>([]);

  // Список элементов для селекта с оплатой
  const [servicePaymentList, setServicePaymentList] = useState<any>([]);
  // _id выбранного элемента с оплатой
  const [preSelectedPayment, setPreSelectedPayment] = useState<any>('');
  // const [selectedPayment, setSelectedPayment] = useState<any>('');
  const [selectedPayment, setSelectedPayment] = useState<any>('balance');

  // _id выбранного элемента
  const [selectedId, setSelectedId] = useState<any>('');

  // Данные выбранного элемента
  const [serviceActiveItem, setServiceActiveItem] = useState<any>([]);

  // Введённая ссылка
  const [link, setLink] = useState<any>('');
  // Введённое количество
  const [count, setCount] = useState<any>('');
  // Корректность введённого количества
  const [isCountCorrect, setIsCountCorrect] = useState<any>(true);
  // Введённый промокод (пока не используется)
  const [promo, setPromo] = useState<any>('');
  // Введённый Email
  const [email, setEmail] = useState<any>('');
  // Итоговая цена
  const [totalPrice, setTotalPrice] = useState<any>('');

  // ошибки в форме
  const [errors, setErrors] = useState<any>({});
  const [emailError, setEmailError] = useState<string>('');
  const [paymentError, setPaymentError] = useState<string>('');

  const navigate = useNavigate();

  // Функция для получения массива элементов
  const getElementById = (data: SocialDataItem[], itemId: any) => {
    const socialSortItem = data.find((item) => item._id === itemId);
    return socialSortItem;
  };

  // Получите значения из контекста
  const { personalEmail, personalName, personalPhone, personalBalance, fetchUserDataContext } = useUserData();

  // Корректность введённого количества
  const [isLoading, setIsLoading] = useState<any>(false);
  const [isLoadingText, setIsLoadingText] = useState<any>(false);

  isLogged = Authentification.getInstance().getToken() !== false;

  const handeSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let formValid = true;
    const newErrors: any = {};

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!isLogged) {
      if (!emailPattern.test(email)) {
        setEmailError('Введите корректный email');
        formValid = false;
      } else {
        const isEmailFree = await fetchApi('POST', 'users/check_email', { email: email });
        if (isEmailFree.message && isEmailFree.message === 'User not found') {
          setEmailError('');
        } else {
          setEmailError('Введённый Email уже занят');
          formValid = false;
        }
      }
    }

    if (selectedPayment.length === 0) {
      formValid = false;
      setPaymentError('Выберите способ оплаты');
    }

    if (!link.trim()) {
      newErrors.link = 'Введите ссылку';
      formValid = false;
    }

    if (!count.trim() || isNaN(Number(count))) {
      newErrors.count = 'Введите корректное количество';
      setIsCountCorrect(false);
      formValid = false;
    }

    setErrors(newErrors);

    if (formValid) {
      // способ оплаты
      console.log(selectedPayment);

      setIsLoading(true);
      let emailString = isLogged ? userEmail : email;
      const finalSend = {
        userEmail: emailString,
        userPromo: promo,
        userLink: link,
        userCount: count,
        userTotalPrice: totalPrice,
        selectedItem: serviceActiveItem
      };
      const order = await fetchApi('POST', 'services/order', finalSend);
      console.log(order);
      if (order['notEnoughBalance'] && order['notEnoughBalance'] > 0) {
        if (order['newUser']) {
          const result = await Authentification.getInstance().login({ login: order['newUser'].login, password: order['newUser'].password });
          if (result) {
            alert(
              'Вы зарегистрированы! Данные для доступа в личный кабинет высланы вам на почту. Для запуска сервиса вам нужно перейти в ЛК и пополнить баланс минимум на ' +
                order['notEnoughBalance'] +
                'руб.'
            );
          }
        } else {
          alert('Недостаточный баланс! Пополните счёт в личном кабинете минимум на ' + order['notEnoughBalance'] + 'руб.');
        }
        navigate('/personal/balance');
        return;
      } else if (order) {
        if (isLogged) {
          alert(`Заказ ID: ${order._doc ? order._doc._id : order._id} на сумму ${order._doc ? order._doc.price : order.price} RUB создан` + (order.started ? ` и запущен на выполнение` : ``) + `!`);
          fetchUserDataContext();
        } else {
          alert(
            `На почту ${emailString} высланы данные для входа в личный кабинет! 
          Заказ ID: ${order._id} на сумму ${order.price} RUB создан` +
              (order.started ? ` и запущен на выполнение` : ``) +
              `!`
          );
        }
        if (order.newUser) {
          console.log('New user login');
          console.log(await Authentification.getInstance().login(order.newUser));
        }
        setIsLoading(false);
        setLink('');
        setTotalPrice('');
        setCount('');
        setPromo('');
        setEmail('');
      }
      // if (order && !order.started) {
      //   const payment = await fetchApi('POST', '/balance/deposit', { amount: order.price, type: 'order', order: order._id });
      //   if (isLogged) {
      //     fetchUserDataContext();
      //   }
      //   if (payment) {
      //     sendOrder(payment, selectedPayment);
      //     if (isLogged) {
      //       fetchUserDataContext();
      //     }
      //   }
      // }

      console.log(finalSend);
    }
  };

  // ловлю id выбранного элемента
  const handleSelectChange = (selectedValue: any) => {
    setSelectedId(selectedValue);
  };

  // ловлю id выбранного элемента
  const handleSelectChangePayment = (selectedValue: any) => {
    // console.log(selectedValue);
    setPaymentError('');
    setSelectedPayment(selectedValue);
  };

  useEffect(() => {
    if (socialListItemsCurrent.length > 0) {
      setIsLoadingText(false);
      let selectItemsArr = [];
      for (let i = 0; i < socialListItemsCurrent.length; i++) {
        // заполяню массив с данными для селекта
        selectItemsArr.push({
          text: `${socialListItemsCurrent[i].customName ? socialListItemsCurrent[i].customName : socialListItemsCurrent[i].name} - ${parseFloat(socialListItemsCurrent[i].rate).toFixed(2)} ₽ за 1000`,
          value: socialListItemsCurrent[i].price,
          id: socialListItemsCurrent[i]._id,
          key: i
        });
      }
      setSelectedId(socialListItemsCurrent[0]._id); // назначаю первый ID как активный
      setServiceListRow(selectItemsArr); // назначаю массив для селекта, чтобы передать в компонент
    } else {
      setIsLoadingText(true);
    }
  }, [socialListItemsCurrent]);

  // Информация о текущем элементе
  const fetchCatalogRootItem = async () => {
    try {
      const newDataCatalogRootItem = await fetchApi('GET', `services/get_item/${selectedId}`, {});
      setServiceActiveItem(newDataCatalogRootItem);
      // console.log(newDataCatalogRootItem);
    } catch (error) {
      console.error('Error fetching social item:', error);
    } finally {
    }
  };

  useEffect(() => {
    if (selectedId) {
      fetchCatalogRootItem();
    }
  }, [selectedId]);

  useEffect(() => {
    if (serviceActiveItem) {
      // console.log(serviceActiveItem);
      setCount('');
      setIsCountCorrect(true);
      setTotalPrice('');
    }
  }, [serviceActiveItem]);

  useEffect(() => {
    setShowEmailInput(isLogged);

    if (isLogged) {
      // console.log(paymentMethod);
      setServicePaymentList([
        {
          id: 'Payeer',
          text: 'Payeer',
          value: 'Payeer'
        },
        {
          id: 'Crypto',
          text: 'Cryptomus',
          value: 'Cryptomus'
        },
        {
          id: 'balance',
          text: 'Личный баланс',
          value: 'balance'
        }
      ]);
      setPreSelectedPayment('balance');

      const userEmailAPI = Authentification.getInstance().getUserEmail();
      if (userEmailAPI) {
        setUserEmail(userEmailAPI);
      }
    } else {
      setServicePaymentList([
        {
          id: 'Payeer',
          text: 'Payeer',
          value: 'Payeer'
        },
        {
          id: 'Crypto',
          text: 'Cryptomus',
          value: 'Cryptomus'
        }
      ]);
    }
  }, [isLogged]);

  return (
    serviceActiveItem && (
      <div id="orderThirdTab" className={`personal_col personal_col_content ${isLoading ? 'loading' : ''}`}>
        <div className="personal_col_heading">
          <div className="personal_col_heading_wrap">
            <div className="personal_col_step">{tabNumbering}</div>
            <div className="personal_col_heading_title">{isLoadingText ? 'Загрузка...' : tabName}</div>
          </div>
        </div>
        <div className="personal_col_inner_padding">
          {isLoadingText ? (
            'Загрузка...'
          ) : (
            <InnerForm onSubmit={handeSubmit}>
              <div className="personal_input_row">
                <BaseSelect
                  className={'personal_select base_input_wide'}
                  selectItems={serviceListRow}
                  name={'choose_tarif'}
                  // firstValue={'Выберите тариф'}
                  label={'Выберите тариф'}
                  link={'https://t.me/i2O_2o'}
                  linkText={'Что выбрать?'}
                  onChange={handleSelectChange}
                  selectedItem={activeSocEl}
                />
              </div>
              <div className="personal_input_row">
                <InputBase
                  type={'text'}
                  name={'personal_link'}
                  placeholder={'site.com/page'}
                  className=""
                  inputClassName={`base_input_wide ${errors.link && 'error'}`}
                  label={'Ссылка'}
                  value={link}
                  onChange={(event) => {
                    setLink(event.target.value);
                    setErrors((prevErrors: any) => ({ ...prevErrors, link: '' }));
                  }}
                />
              </div>
              <div className="personal_input_row">
                <InputBase
                  type={'text'}
                  name={'personal_price'}
                  placeholder={`-`}
                  className=""
                  inputClassName={`base_input_count`}
                  label={'Цена за 1000'}
                  isReadonly={true}
                  disabled={true}
                  value={parseFloat(serviceActiveItem.rate).toFixed(2)}
                />
              </div>
              <div className="personal_input_row">
                <InputBase
                  type={'text'}
                  name={'personal_count'}
                  placeholder={`от ${serviceActiveItem?.min} до ${serviceActiveItem?.max}`}
                  className=""
                  inputClassName={`base_input_count ${!isCountCorrect && errors.count && 'error'}`}
                  label={'Укажите количество'}
                  isCount={true}
                  isCountText={`Лимиты: от ${serviceActiveItem?.min} до ${serviceActiveItem?.max}`}
                  isCountSum={`= ${totalPrice ? totalPrice : '0'} RUB`}
                  value={count}
                  onChange={(event) => {
                    const value = event.target.value;
                    if (!/^\d*$/.test(value)) return;
                    if (value === '0' && value.length === 1) return;
                    const numericValue = Number(value);
                    setCount(value);
                    if (numericValue >= serviceActiveItem.min && numericValue <= serviceActiveItem.max) {
                      setIsCountCorrect(true);
                      const totalPrice = ((serviceActiveItem.rate / 1000) * numericValue).toFixed(10);
                      setTotalPrice(String(parseFloat(totalPrice)));
                    } else {
                      setIsCountCorrect(false);
                      setTotalPrice('');
                    }
                  }}
                />
              </div>
              {/* <div className="personal_input_row">
              <InputBase
                type={'text'}
                name={'personal_promo'}
                placeholder={'Промокод'}
                className=""
                inputClassName="base_input_wide"
                label={'Промокод'}
                hasLink={true}
                hasActivateLink={true}
                innerLink={'/'}
                textLink={'Получить промокод'}
                value={promo}
                onChange={(event) => setPromo(event.target.value)}
              />
            </div> */}
              {!showEmailInput && (
                <div className="personal_input_row">
                  <InputBase
                    type={'text'}
                    name={'personal_email'}
                    placeholder={'example@mail.com'}
                    className=""
                    inputClassName={`base_input_wide ${emailError.length > 0 && 'error'}`}
                    label={'Укажите Email'}
                    value={email}
                    onChange={(event) => {
                      setEmail(event.target.value);
                      setEmailError('');
                    }}
                  />
                  {emailError.length > 0 && <div className="base_input_wide_error">{emailError}</div>}
                </div>
              )}
              {/* <div className="personal_input_row">
                <BaseSelect
                  className={`personal_select base_input_wide ${paymentError.length > 0 && 'error'}`}
                  selectItems={servicePaymentList}
                  name={'choose_payment'}
                  firstValue={'Способ оплаты'}
                  label={'Выберите способ оплаты'}
                  link={'https://t.me/i2O_2o'}
                  onChange={handleSelectChangePayment}
                  selectedItem={preSelectedPayment}
                />
                {paymentError.length > 0 && <div className="base_input_wide_error">{paymentError}</div>}
              </div> */}
              <BtnOutline className="btn_wide_full btn_outline nav_fast_order" btnText="Продолжить" type={'submit'} />
            </InnerForm>
          )}
        </div>
      </div>
    )
  );
};

export default PersonalOrderCreateThirdTab;
