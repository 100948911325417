import React, { FormEvent, ReactNode } from 'react';

type BaseRegisterForm = {
  classMame?: string;
  children?: ReactNode;
  id?: string;
  onSubmit?: (event: FormEvent<HTMLFormElement>) => void;
};

const PersonalRegisterForm = ({ children, onSubmit, classMame, id }: BaseRegisterForm) => {
  return (
    <form onSubmit={onSubmit} className={`personal_form ${classMame}`} id={id}>
      {children}
    </form>
  );
};

export default PersonalRegisterForm;
