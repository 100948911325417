import React, { ChangeEvent, useEffect, useState } from 'react';

interface socialListArr {
  text?: string;
  value?: any;
  id?: number;
  key?: number;
}
interface BaseSelectProps {
  innerValue?: string;
  name?: string;
  id?: string;
  firstValue?: string;
  className?: string;
  label?: string;
  link?: string;
  linkText?: string;
  selectItems: socialListArr[];
  onChange?: (value: string) => void; // Добавьте проп onChange
}

const BaseSelect = ({ innerValue, name, id, firstValue, className, label, link, linkText, selectItems, onChange }: BaseSelectProps) => {
  const [selected, setSelected] = useState<any>(null);
  useEffect(() => {
    if (innerValue) {
      setSelected(innerValue);
    }
    // console.log(selectItems);
  }, [selectItems]);

  const handleChange = (event: any) => {
    setSelected(event.target.value);
    if (onChange) {
      onChange(event.target.value); // Вызываем колбэк onChange, если он предоставлен
    }
  };

  return (
    <div className="select_base_wrap">
      {label && (
        <div className="label_box_row label_box_text">
          <div className="select_base_row_text">{label}</div>
          {link && (
            <a href={link} className="label_box_row_link">
              {linkText}
            </a>
          )}
        </div>
      )}
      <select value={selected ? selected : ''} className={`select_base ${className ? className : ''}`} name={name} id={id} onChange={handleChange}>
        {firstValue && (
          <option value={firstValue} selected disabled hidden>
            {firstValue}
          </option>
        )}
        {selectItems.map((item: any, index: number) => (
          <option value={item.value} key={index} id={item.id}>
            {item.text}
          </option>
        ))}
      </select>
    </div>
  );
};

export default BaseSelect;
