import React, { useRef } from 'react';

interface FaqItem {
  _id: string;
  parentId: string;
  name: string;
  type: string;
  active: boolean;
  updated: number;
  text: string;
}

interface MainAccordionItemProps {
  handleToggle: (id: string) => void;
  active: string | null;
  faq: FaqItem;
}

const MainAccordionItem = ({ handleToggle, active, faq }: MainAccordionItemProps) => {
  const contentEl = useRef<HTMLDivElement>(null);
  const { name, _id, text } = faq;
  return (
    <div className="main_faq_item">
      <div className="main_faq_header">
        <div className={`main_faq_item_toggle ${active === _id ? 'active' : ''}`} onClick={() => handleToggle(_id)}>
          <h5 className="main_faq_item_title">{name}</h5>
          <div className="main_faq_item_icon">+</div>
        </div>
      </div>
      <div ref={contentEl} className={`main_faq_item_collapse ${active === _id ? 'show' : ''}`} style={active === _id ? { height: contentEl.current?.scrollHeight } : { height: '0px' }}>
        <div className="main_faq_item_body">
          <div className="main_faq_item_text">{text}</div>
        </div>
      </div>
    </div>
  );
};

export default MainAccordionItem;
