import React, { useEffect, useState } from 'react';
import { NavLink, Navigate, generatePath, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import BtnBase from '../admin-ui-kit/btn/BtnBase';

// Определение типа для объекта страницы
type PageType = {
  h1: string;
  title: string;
  updated: number;
  description: string;
  breadcrumbs: string;
  text: string;
  seoUrl: string;
  banner: {
    text: string;
    image: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
  icon: {
    big: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
    small: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
};

// Определение общего типа для элементов в массиве socialDataState
type SocialDataItem = {
  _id: string;
  parentId: string;
  name: string;
  type: string;
  active: boolean;
  page: PageType;
};

// Определение типа для пропсов компонента AdminTools
type AdminToolsProps = {
  socialDataState: SocialDataItem[];
  typeString: string;
};

const AdminTools = ({ socialDataState, typeString }: AdminToolsProps) => {
  const [socialDataStateArr, setSocialDataStateArr] = useState<any[]>([]);
  const [socialTypeString, setSocialTypeString] = useState(typeString);

  const [customSocialArr, setCustomSocialArr] = useState<any[]>([]);

  // жду выполнения запроса фетча
  const [isLoading, setIsLoading] = useState(true);

  // получаю название родительской категории
  const getParentCategoryName = (parentId: string): string => {
    const parentCategory = socialDataStateArr.find((category) => category._id === parentId);
    return parentCategory ? parentCategory.name : '';
  };

  useEffect(() => {
    setSocialTypeString(typeString);
  }, [typeString]);

  useEffect(() => {
    const fetchSocialItem = async () => {
      try {
        const filteredArray = socialDataState.filter((item) => item.type === socialTypeString);
        const sortedArray = filteredArray.slice().sort((a, b) => a.name.localeCompare(b.name));
        setCustomSocialArr(sortedArray);
      } catch (error) {
        console.error('Error fetching social item:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (socialDataState.length > 0) {
      fetchSocialItem();
    }
  }, [socialDataState, socialTypeString]);

  return (
    <>
      <div className="admin_heading">
        <div className="admin_heading_title">{socialTypeString === 'category' ? 'Блог - Категории' : socialTypeString === 'tools' ? 'SMM-инструменты' : 'Блог - Статьи'}</div>
        <BtnBase btnLink="new" className={'admin_submit'} btnText={'Создать'} type={'submit'} />
      </div>
      <>
        {isLoading ? (
          <div>Loading...</div>
        ) : !customSocialArr ? (
          <div>Items not found</div>
        ) : (
          <div className="admin_table_wrap">
            <table className="admin_table_list">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Status</th>
                  <th>Updated</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {customSocialArr.map((item) => (
                  <tr key={item._id}>
                    <td>{item._id}</td>
                    <td>{item.name}</td>
                    <td>
                      {item.activeApi === false ? (
                        <span className="status_el status_disabled">Disabled</span>
                      ) : item.active ? (
                        <span className="status_el status_active">Active</span>
                      ) : (
                        <span className="status_el status_disabled">Disabled</span>
                      )}
                    </td>
                    <td>{format(new Date(item.page.updated), 'dd.MM.yyyy, HH:mm')}</td>
                    <td>
                      <NavLink to={`edit/${item._id}`}>Edit</NavLink>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </>
    </>
  );
};

export default AdminTools;
