import React, { useEffect, useState } from 'react';
import { NavLink, Navigate, generatePath, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import BtnBase from '../admin-ui-kit/btn/BtnBase';

type Items = {
  name: string;
  icon: string;
};

// Определение общего типа для элементов в массиве socialDataState
type SocialDataItem = {
  _id: string;
  name: string;
  type: string;
  subtype: string;
  items: Items;
};

// Определение типа для пропсов компонента AdminClientMenu
type AdminClientMenuProps = {
  socialDataState: SocialDataItem[];
  typeString: string;
};

const AdminClientMenu = ({ socialDataState, typeString }: AdminClientMenuProps) => {
  const [socialDataStateArr, setSocialDataStateArr] = useState<any[]>([]);
  const [socialTypeString, setSocialTypeString] = useState(typeString);

  const [customSocialArr, setCustomSocialArr] = useState<any[]>([]);

  // жду выполнения запроса фетча
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setSocialTypeString(typeString);
  }, [typeString]);

  useEffect(() => {
    const fetchSocialItem = async () => {
      try {
        setSocialDataStateArr(socialDataState);
        const filteredArray = socialDataState.filter((item) => item.type === socialTypeString);
        const sortedArray = filteredArray.slice().sort((a, b) => a.name.localeCompare(b.name));
        setCustomSocialArr(sortedArray);
      } catch (error) {
        console.error('Error fetching social item:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (socialDataState.length > 0) {
      fetchSocialItem();
    }
  }, [socialDataState, socialTypeString]);

  // получаю название родительской категории
  const getParentCategoryName = (parentId: string): string => {
    const parentCategory = socialDataStateArr.find((category) => category._id === parentId);
    return parentCategory ? parentCategory.name : '';
  };

  return (
    <>
      <div className="admin_heading">
        <div className="admin_heading_title">Меню</div>
      </div>
      <>
        {isLoading ? (
          <div>Loading...</div>
        ) : !customSocialArr ? (
          <div>Items not found</div>
        ) : (
          <div className="admin_table_wrap">
            <table className="admin_table_list">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {customSocialArr.map((item) => (
                  <tr key={item._id}>
                    <td>{item._id}</td>
                    <td>{item.name}</td>
                    <td>
                      <NavLink to={`edit/${item._id}`}>Edit</NavLink>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </>
    </>
  );
};

export default AdminClientMenu;
