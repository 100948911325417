import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import LeftMenu from './template_parts/LeftMenu';

// Передаю менюхи
type Items = {
  name: string;
  link: string;
  icon: {
    parentId?: number;
    id: string;
    filename: string;
    filetype: string;
    datetime: string;
    filesize: string;
    filepath: string;
  };
  index: string;
};

// Определение общего типа для элементов в массиве socialDataState
type HeaderProps = {
  _id: string;
  name: string;
  type: string;
  subtype: string;
  items: Items[];
};

type InnerLayoutProps = {
  isMobile?: boolean;
  menuDataState: HeaderProps[];
};

const InnerLayout = ({ menuDataState, isMobile }: InnerLayoutProps) => {
  const [leftMenuTopList, setLeftMenuTopList] = useState<any>(null);
  const [leftMenuBottomList, setLeftMenuBottomList] = useState<any>(null);

  useEffect(() => {
    if (menuDataState) {
      // Заполняю менюхи
      const foundSidebarOneMenu = menuDataState.find((item) => item.subtype === 'sitebar_menu_1');
      const foundSidebarTwoMenu = menuDataState.find((item) => item.subtype === 'sitebar_menu_2');
      if (foundSidebarOneMenu && foundSidebarTwoMenu) {
        setLeftMenuTopList(foundSidebarOneMenu.items);
        setLeftMenuBottomList(foundSidebarTwoMenu.items);
      }
    }
  }, [menuDataState]);

  return (
    <>
      <div className="inner">
        <div className="container">
          {isMobile ? (
            <div className="inner_col_content">
              <Outlet />
            </div>
          ) : (
            <div className="inner_wrap">
              <div className="inner_col shadow_border border_rounded">
                <LeftMenu leftMenuTopList={leftMenuTopList} leftMenuBottomList={leftMenuBottomList} />
              </div>
              <div className="inner_col_content">
                <Outlet />
              </div>
            </div>
          )}
          {/* <div className="inner_wrap">
            <div className="inner_col shadow_border border_rounded">
              <LeftMenu />
            </div>
            <div className="inner_col_content">
              <Outlet />
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default InnerLayout;
