import React from 'react';
import { NavLink } from 'react-router-dom';

// Определение общего типа для элементов в массиве socialDataState
type PaginationProps = {
  pageCount: number;
  currentPage: number;
  resultPathEl: string;
  setCurrentPage: (page: number) => void;
};

const Pagination = ({ pageCount, currentPage, setCurrentPage, resultPathEl }: PaginationProps) => {
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      {pageCount > 1 ? (
        <div className="pagen">
          <NavLink to={`${resultPathEl}`} className={`pagen_item pagen_item_first ${currentPage > 1 ? '' : 'pagen_disabled'}`} onClick={() => handlePageChange(1)}>
            {'<<'}
          </NavLink>
          <NavLink
            to={`${resultPathEl}${currentPage > 1 ? (currentPage - 1 === 1 ? '' : `?page_num=${currentPage - 1}`) : ''}`}
            className={`pagen_item pagen_item_prev ${currentPage > 1 ? '' : 'pagen_disabled'}`}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            {'<'}
          </NavLink>
          {[...Array(pageCount)].map((_, index) => (
            <NavLink
              to={`${resultPathEl}${index + 1 > 1 ? `?page_num=${index + 1}` : ``}`}
              key={index + 1}
              className={`pagen_item ${currentPage === index + 1 ? 'active_pagen' : ''}`}
              onClick={() => handlePageChange(index + 1)}
              end
            >
              {index + 1}
            </NavLink>
          ))}
          <NavLink
            to={`${resultPathEl}${currentPage < pageCount ? `?page_num=${currentPage + 1}` : ''}`}
            className={`pagen_item pagen_item_next ${currentPage < pageCount ? '' : 'pagen_disabled'}`}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            {'>'}
          </NavLink>
          <NavLink
            to={`${resultPathEl}${currentPage === pageCount ? '' : `?page_num=${pageCount}`}`}
            className={`pagen_item pagen_item_last ${currentPage === pageCount ? 'pagen_disabled' : ''}`}
            onClick={() => handlePageChange(pageCount)}
          >
            {'>>'}
          </NavLink>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default Pagination;
