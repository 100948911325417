import React, { useEffect } from 'react';
import PersonalOrderSelectService from './PersonalOrderSelectService';

interface socialListArr {
  id: number;
  name: string;
  icon: string;
}

// Определение типа для объекта страницы
type PageType = {
  h1: string;
  title: string;
  updated: number;
  description: string;
  breadcrumbs: string;
  text: string;
  seoUrl: string;
  showOnMain: boolean;
  excerpt: string;
  banner: {
    text: string;
    image: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
  icon: {
    big: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
    small: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
};

// Определение общего типа для элементов в массиве socialDataState
type SocialDataItem = {
  _id: string;
  name: string;
  customName: string;
  __v: number;
  active: boolean;
  activeApi: boolean;
  category: string;
  page: PageType;
  parentId: string;
  rate: string;
  service: string;
  socialMedia: string;
  type: string;
};

interface PersonalOrderCreateSecondTabProps {
  tabName: string;
  tabNumbering: string;
  activeSocialService: number;
  serviceList: SocialDataItem[];
  activeSocialIcon?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const PersonalOrderCreateSecondTab = ({ tabName, tabNumbering, activeSocialService, serviceList, onClick, activeSocialIcon }: PersonalOrderCreateSecondTabProps) => {
  return (
    serviceList && (
      <div id="orderSecondTab" className="personal_col personal_col_content">
        <div className="personal_col_heading">
          <div className="personal_col_heading_wrap">
            <div className="personal_col_step">{tabNumbering}</div>
            <div className="personal_col_heading_title">{tabName}</div>
          </div>
        </div>
        {serviceList.map((item: any, index: number) => (
          <PersonalOrderSelectService
            socialName={item.customName ? item.customName : item.name}
            socialId={item._id}
            socialIcon={item.page.icon.small.filepath ? item.page.icon.small.filepath : activeSocialIcon ? activeSocialIcon : '/content/not/icon_small.svg'}
            onClick={onClick}
            active={activeSocialService === item._id ? true : false}
            key={index}
          />
        ))}
      </div>
    )
  );
};

export default PersonalOrderCreateSecondTab;
