import React from 'react';
import BtnOutline from '../ui-kit/BtnOutline';
import { NavLink } from 'react-router-dom';

type Props = {};

const MainLastBlock = (props: Props) => {
  return (
    <div className="main_section main_callback_section">
      <div className="container">
        <div className="referal_info">
          <div className="referal_info_wrap">
            <div className="referal_info_title">Не знаете какую услугу лучше выбрать?</div>
            <div className="referal_info_text">Напишите нам в мессенеджер и мы поможем выбрать самую эффективную накрутку под ваши задачи.</div>
            <NavLink to={'https://t.me/i2O_2o'} end target="_blank">
              <BtnOutline className="btn_wide" btnText="Узнать подробнее" />
            </NavLink>
          </div>
          <img className="desc_pc" src="/content/main/main_last.png" alt="" />
          <img className="mobile_pc" src="/content/main/main_last_min.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default MainLastBlock;
