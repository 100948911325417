import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

// Определение типа для объекта страницы
type Items = {
  name: string;
  link: string;
  icon: {
    parentId?: number;
    id: string;
    filename: string;
    filetype: string;
    datetime: string;
    filesize: string;
    filepath: string;
  };
  index: string;
};

// Определение общего типа для элементов в массиве socialDataState
type MenuProps = {
  _id: string;
  name: string;
  type: string;
  subtype: string;
  items: Items[];
};

interface LeftMenuTopProps {
  menuTitle: string;
  menuList: Items[];
  linkClicked?: () => void;
}

const LeftMenuTop = ({ menuTitle, menuList, linkClicked }: LeftMenuTopProps) => {
  const [itemList, setItemList] = useState<Items[]>([]);

  useEffect(() => {
    // Выбираем первый элемент из menuList для сортировки его items
    if (menuList) {
      const sortedArr = menuList.sort((a, b) => parseInt(a.index) - parseInt(b.index));
      setItemList(sortedArr);
    }
  }, [menuList]);

  return (
    <>
      <div className="th_title">{menuTitle}</div>
      {itemList &&
        itemList.map((item: any, index: number) => (
          <NavLink to={item.link} className={`tr_link`} key={index} onClick={linkClicked}>
            <img src={item.icon.filepath} alt={item.name} width={26} height={26} />
            <span>{item.name}</span>
          </NavLink>
        ))}
    </>
  );
};

export default LeftMenuTop;
