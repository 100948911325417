import React, { FormEvent, useEffect, useState } from 'react';
import Switch from '../admin-ui-kit/input/Switch';
import InnerForm from '../admin-ui-kit/form/InnerForm';
import InputBase from '../admin-ui-kit/input/InputBase';
import InputLabelFor from '../admin-ui-kit/input/InputLabelFor';
import TextareaLabelFor from '../admin-ui-kit/input/TextareaLabelFor';
import BtnBase from '../admin-ui-kit/btn/BtnBase';
import FileUploadWithPreview from '../admin-ui-kit/input/FileUploadWithPreview';
import { Navigate, useParams, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { fetchApi } from '../../../content/components/helpers/api';

type Contact = {
  phone: string;
  adress: string;
  email: string;
  work_time: string;
  tg_link: string;
};

type Main = {
  h1: string;
  subtitle: string;
  title: string;
  description: string;
};

type OurSocialItem = {
  name: string;
  icon: string;
};

type API = {
  payment: string;
  service: string;
};

type SocialDataItem = {
  _id: string;
  name: string;
  type: string;
  active: boolean;
  contact: Contact;
  main: Main;
  our_social: OurSocialItem[];
  api: API;
};

type AdminSettingProps = {
  socialDataState: SocialDataItem[];
  typeString: string;
};

const AdminSetting = ({ socialDataState, typeString }: AdminSettingProps) => {
  const { _id } = useParams();

  const [socialTypeString, setSocialTypeString] = useState(typeString);

  const [socialDataStateArr, setSocialDataStateArr] = useState<any[]>([]);
  const [socialItem, setSocialItem] = useState<any>(null);

  const navigate = useNavigate();

  const fetchSettings = async () => {
    try {
      const settingsList = [
        'apiKeyPayeer',
        'apiIdPayeer',
        'apiKeyCryptomus',
        'apiIdCryptomus',
        'apiKeyYookassa',
        'apiIdYookassa',
        'apiKeyFastsmm',
        /*"apiKeySmmprime", "apiKeyCheatbot",*/ 'contactPhone',
        'contactAdress',
        'contactEmail',
        'contactWorkTime',
        'contactTgLink',
        'mainH1',
        'mainSubtitle',
        'mainMetaTitle',
        'mainMetaDescription',
        'elementsPerPage',
        '/ourSocialName_.*/',
        '/ourSocialIcon_.*/'
      ];
      const settings = await fetchApi('POST', 'settings/get', { settingsList });
      console.log(settings);
      if (settings) {
        setSocialItem(settings);
      }
    } catch (error) {
      console.error('Error fetching social item:', error);
    } finally {
    }
  };

  useEffect(() => {
    fetchSettings();
    console.log('Fetch settings');
  }, []);

  // useEffect(() => {
  //   setSocialDataStateArr(socialDataState);
  //   const socialItem = socialDataState.find((item) => item.type === socialTypeString);
  //   const [extractedItem] = [socialItem];
  //   setSocialItem(extractedItem);
  // }, [socialDataState]);

  //

  const [itemName, setItemName] = useState<any>(null);
  const [itemActive, setItemActive] = useState<any>(null);

  const [apiKeyPayeer, setApiKeyPayeer] = useState<any>(null);
  const [apiIdPayeer, setApiIdPayeer] = useState<any>(null);
  const [apiKeyCryptomus, setApiKeyCryptomus] = useState<any>(null);
  const [apiIdCryptomus, setApiIdCryptomus] = useState<any>(null);
  const [apiKeyYookassa, setApiKeyYookassa] = useState<any>(null);
  const [apiIdYookassa, setApiIdYookassa] = useState<any>(null);
  const [apiKeyFastsmm, setApiKeyFastsmm] = useState<any>(null);
  const [apiKeySmmprime, setApiKeySmmprime] = useState<any>(null);
  const [apiKeyCheatbot, setApiKeyCheatbot] = useState<any>(null);

  const [contactPhone, setContactPhone] = useState<any>(null);
  const [contactAdress, setContactAdress] = useState<any>(null);
  const [contactEmail, setContactEmail] = useState<any>(null);
  const [contactWorkTime, setContactWorkTime] = useState<any>(null);
  const [contactTgLink, setContactTgLink] = useState<any>(null);

  const [mainH1, setMainH1] = useState<any>(null);
  const [mainSubtitle, setMainSubtitle] = useState<any>(null);
  const [mainTitle, setMainTitle] = useState<any>(null);
  const [mainDescription, setMainDescription] = useState<any>(null);
  const [elementsPerPage, setElementsPerPage] = useState<any>(null);

  const [ourSocial, setOurSocial] = useState<OurSocialItem[]>([]);

  const handleAddSocialItem = () => {
    setOurSocial([...ourSocial, { name: '', icon: '' }]);
  };

  const handleRemoveSocialItem = (index: number) => {
    const updatedSocialItems = [...ourSocial];
    updatedSocialItems.splice(index, 1);
    setOurSocial(updatedSocialItems);
  };

  const handleSocialNameChange = (index: number, value: string) => {
    const updatedSocialItems = [...ourSocial];
    updatedSocialItems[index].name = value;
    setOurSocial(updatedSocialItems);
  };

  const handleSocialIconChange = (index: number, value: string) => {
    const updatedSocialItems = [...ourSocial];
    updatedSocialItems[index].icon = value;
    setOurSocial(updatedSocialItems);
  };
  //

  useEffect(() => {
    if (socialItem) {
      setItemName(socialItem.name);
      // setItemActive(socialItem.active);

      setApiKeyPayeer(socialItem.apiKeyPayeer);
      setApiIdPayeer(socialItem.apiIdPayeer);
      setApiKeyCryptomus(socialItem.apiKeyCryptomus);
      setApiIdCryptomus(socialItem.apiIdCryptomus);
      setApiKeyYookassa(socialItem.apiKeyYookassa);
      setApiIdYookassa(socialItem.apiIdYookassa);
      setApiKeyFastsmm(socialItem.apiKeyFastsmm);
      // setApiKeyCheatbot(socialItem.apiKeyCheatbot);
      // setApiKeySmmprime(socialItem.apiKeySmmprime);

      setContactPhone(socialItem.contactPhone);
      setContactAdress(socialItem.contactAdress);
      setContactEmail(socialItem.contactEmail);
      setContactWorkTime(socialItem.contactWorkTime);
      setContactTgLink(socialItem.contactTgLink);

      setMainH1(socialItem.mainH1);
      setMainSubtitle(socialItem.mainSubtitle);
      setMainTitle(socialItem.mainMetaTitle);
      setMainDescription(socialItem.mainMetaDescription);
      setElementsPerPage(socialItem.elementsPerPage);

      // setOurSocial(socialItem.our_social);
    }
  }, [socialItem]);

  const [savedItemArr, setSavedItemArr] = useState<any>(null);

  const handeSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let settingsArray = [
      { key: 'apiKeyPayeer', value: apiKeyPayeer },
      { key: 'apiIdPayeer', value: apiIdPayeer },
      { key: 'apiKeyCryptomus', value: apiKeyCryptomus },
      { key: 'apiIdCryptomus', value: apiIdCryptomus },
      { key: 'apiKeyYookassa', value: apiKeyYookassa },
      { key: 'apiIdYookassa', value: apiIdYookassa },
      { key: 'apiKeyFastsmm', value: apiKeyFastsmm },
      { key: 'apiKeySmmprime', value: apiKeySmmprime },
      { key: 'apiKeyCheatbot', value: apiKeyCheatbot },
      { key: 'contactPhone', value: contactPhone },
      { key: 'contactAdress', value: contactAdress },
      { key: 'contactEmail', value: contactEmail },
      { key: 'contactWorkTime', value: contactWorkTime },
      { key: 'contactTgLink', value: contactTgLink },
      { key: 'mainH1', value: mainH1 },
      { key: 'mainSubtitle', value: mainSubtitle },
      { key: 'mainMetaTitle', value: mainTitle },
      { key: 'mainMetaDescription', value: mainDescription },
      { key: 'elementsPerPage', value: elementsPerPage }
    ];
    ourSocial.map((social, index) => {
      settingsArray.push({ key: 'ourSocialName_' + index, value: social.name });
      settingsArray.push({ key: 'ourSocialIcon_' + index, value: social.icon });
    });

    // (async () => {
    //   await fetchApi('POST', 'settings/update', settingsArray);
    // })();

    const saveItem = async () => {
      try {
        if (settingsArray) {
          const response = await fetchApi('POST', '/settings/update', settingsArray);
          console.log(response);
          if (response) {
            console.log('object');
            setSavedItemArr(settingsArray);
            alert('Настройки обновлены!');
          }
        }
      } catch (error) {
        console.error('Error saving item:', error);
      } finally {
      }
    };

    saveItem();
  };

  useEffect(() => {
    if (savedItemArr) {
      console.log(savedItemArr);
    }
  }, [savedItemArr]);

  return (
    socialItem && (
      <>
        <InnerForm onSubmit={handeSubmit}>
          <div className="admin_heading">
            <div className="admin_heading_title">{itemName}</div>
            <BtnBase className={'admin_submit'} btnText={'Обновить'} type={'submit'} />
          </div>
          <div className="admin_container">
            <div className="admin_form">
              <div className="admin_form_section_wrap">
                <div className="admin_form_section_title">Системные данные</div>
                <div className="admin_form_row order_item_row">
                  <div className="admin_form_section_subtitle">
                    API эквайринга: <strong>Payeer</strong>
                  </div>
                  <div className="admin_form_section_wrap_grid">
                    <div className="admin_form_section_wrap_grid_child">
                      <div className="admin_form_row">
                        <InputLabelFor label={'KEY'} value={apiKeyPayeer} id={'apiKeyPayeer'} name={'apiKeyPayeer'} type={'text'} onChange={(event) => setApiKeyPayeer(event.target.value)} />
                      </div>
                    </div>
                    <div className="admin_form_section_wrap_grid_child">
                      <div className="admin_form_row">
                        <InputLabelFor label={'ID'} value={apiIdPayeer} id={'apiIdPayeer'} name={'apiIdPayeer'} type={'text'} onChange={(event) => setApiIdPayeer(event.target.value)} />
                      </div>
                    </div>
                  </div>
                  <div className="admin_form_section_subtitle">
                    API эквайринга: <strong>Cryptomus</strong>
                  </div>
                  <div className="admin_form_section_wrap_grid">
                    <div className="admin_form_section_wrap_grid_child">
                      <div className="admin_form_row">
                        <InputLabelFor
                          label={'KEY'}
                          value={apiKeyCryptomus}
                          id={'apiKeyCryptomus'}
                          name={'apiKeyCryptomus'}
                          type={'text'}
                          onChange={(event) => setApiKeyCryptomus(event.target.value)}
                        />
                      </div>
                    </div>
                    <div className="admin_form_section_wrap_grid_child">
                      <div className="admin_form_row">
                        <InputLabelFor label={'ID'} value={apiIdCryptomus} id={'apiIdCryptomus'} name={'apiIdCryptomus'} type={'text'} onChange={(event) => setApiIdCryptomus(event.target.value)} />
                      </div>
                    </div>
                  </div>
                  <div className="admin_form_section_subtitle">
                    API эквайринга: <strong>Yookassa</strong>
                  </div>
                  <div className="admin_form_section_wrap_grid">
                    <div className="admin_form_section_wrap_grid_child">
                      <div className="admin_form_row">
                        <InputLabelFor
                          label={'KEY'}
                          value={apiKeyYookassa}
                          id={'apiKeyYookassa'}
                          name={'apiKeyYookassa'}
                          type={'text'}
                          onChange={(event) => setApiKeyYookassa(event.target.value)}
                        />
                      </div>
                    </div>
                    <div className="admin_form_section_wrap_grid_child">
                      <div className="admin_form_row">
                        <InputLabelFor label={'ID'} value={apiIdYookassa} id={'apiIdYookassa'} name={'apiIdYookassa'} type={'text'} onChange={(event) => setApiIdYookassa(event.target.value)} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="admin_form_row">
                  <InputLabelFor
                    label={'API Key fastsmm-online.ru'}
                    value={apiKeyFastsmm}
                    id={'apiKeyFastsmm'}
                    name={'apiKeyFastsmm'}
                    type={'text'}
                    onChange={(event) => setApiKeyFastsmm(event.target.value)}
                  />
                </div>
                {/*<div className="admin_form_row">
                  <InputLabelFor label={'API Key smmprime.ru'} value={apiKeySmmprime} id={'apiKeySmmprime'} name={'apiKeySmmprime'} type={'text'} onChange={(event) => setApiKeySmmprime(event.target.value)} />
                </div>
                <div className="admin_form_row">
                  <InputLabelFor label={'API Key cheatbot.ru'} value={apiKeyCheatbot} id={'apiKeyCheatbot'} name={'apiKeyCheatbot'} type={'text'} onChange={(event) => setApiKeyCheatbot(event.target.value)} />
                </div>*/}
              </div>
              <div className="admin_form_section_wrap">
                <div className="admin_form_section_title">Контактные данные магазина</div>
                <div className="admin_form_row">
                  <InputLabelFor label={'Номер телефона'} value={contactPhone} id={'contact_phone'} name={'contact_phone'} type={'text'} onChange={(event) => setContactPhone(event.target.value)} />
                </div>
                <div className="admin_form_row">
                  <InputLabelFor
                    label={'Адрес магазина'}
                    value={contactAdress}
                    id={'contact_adress'}
                    name={'contact_adress'}
                    type={'text'}
                    onChange={(event) => setContactAdress(event.target.value)}
                  />
                </div>
                <div className="admin_form_row">
                  <InputLabelFor label={'Контактный Email'} value={contactEmail} id={'contact_email'} name={'contact_email'} type={'text'} onChange={(event) => setContactEmail(event.target.value)} />
                </div>
                <div className="admin_form_row">
                  <InputLabelFor
                    label={'График работы'}
                    value={contactWorkTime}
                    id={'contact_work_time'}
                    name={'contact_work_time'}
                    type={'text'}
                    onChange={(event) => setContactWorkTime(event.target.value)}
                  />
                </div>
                <div className="admin_form_row">
                  <InputLabelFor
                    label={'Ссылка на телеграм для связи'}
                    value={contactTgLink}
                    id={'contact_tg_link'}
                    name={'contact_tg_link'}
                    type={'text'}
                    onChange={(event) => setContactTgLink(event.target.value)}
                  />
                </div>
              </div>
              <div className="admin_form_section_wrap">
                <div className="admin_form_section_title">Настройки главной страницы</div>
                <div className="admin_form_row">
                  <InputLabelFor label={'H1 (на баннере)'} value={mainH1} id={'mainH1'} name={'mainH1'} type={'text'} onChange={(event) => setMainH1(event.target.value)} />
                </div>
                <div className="admin_form_row">
                  <InputLabelFor
                    label={'Подзаголовок (на баннере)'}
                    value={mainSubtitle}
                    id={'mainSubtitle'}
                    name={'mainSubtitle'}
                    type={'text'}
                    onChange={(event) => setMainSubtitle(event.target.value)}
                  />
                </div>
                <div className="admin_form_row">
                  <InputLabelFor label={'Meta-title'} value={mainTitle} id={'mainMetaTitle'} name={'mainMetaTitle'} type={'text'} onChange={(event) => setMainTitle(event.target.value)} />
                </div>
                <div className="admin_form_row">
                  <InputLabelFor
                    label={'Meta-description'}
                    value={mainDescription}
                    id={'mainMetaDescription'}
                    name={'mainMetaDescription'}
                    type={'text'}
                    onChange={(event) => setMainDescription(event.target.value)}
                  />
                </div>
              </div>
              <div className="admin_form_section_wrap">
                <div className="admin_form_section_title">Настройки сайта</div>
                <div className="admin_form_row">
                  <InputLabelFor
                    label={'Количество элементов на странице'}
                    value={elementsPerPage}
                    id={'elementsPerPage'}
                    name={'elementsPerPage'}
                    type={'text'}
                    onChange={(event) => setElementsPerPage(event.target.value)}
                  />
                </div>
              </div>
              <div className="admin_form_section_wrap">
                <div className="admin_form_section_title admin_form_section_title_flex">
                  <div className="admin_form_section_title_text">Социальные сети (на главной сверху-справа и в футере)</div>
                  <BtnBase className={'admin_submit'} btnText={'Добавить'} onClick={handleAddSocialItem} type="button" />
                </div>
                {ourSocial.map((social, index) => (
                  <div className="admin_form_section_wrap_grid_third align_center fileupload_view" key={index}>
                    <div className="admin_form_row">
                      <InputLabelFor
                        label={'Название'}
                        value={social.name}
                        id={`social_name_${index}`}
                        name={`social_name_${index}`}
                        type={'text'}
                        onChange={(event) => handleSocialNameChange(index, event.target.value)}
                      />
                    </div>
                    <FileUploadWithPreview labelText={'Иконка'} name={`social_icon_${index}`} />
                    {/* <div className="admin_form_row">
                      <InputLabelFor
                        label={'Иконка'}
                        value={social.icon}
                        id={`social_icon_${index}`}
                        name={`social_icon_${index}`}
                        type={'text'}
                        onChange={(event) => handleSocialIconChange(index, event.target.value)}
                      />
                    </div> */}
                    <BtnBase className={'admin_submit admin_submit_center'} btnText={'Удалить'} onClick={() => handleRemoveSocialItem(index)} type="button" />
                  </div>
                ))}
              </div>
              <BtnBase className={'admin_submit admin_submit_center'} btnText={'Обновить'} type={'submit'} />
            </div>
          </div>
        </InnerForm>
      </>
    )
  );
};

export default AdminSetting;
