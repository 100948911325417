import React, { useEffect, useState } from 'react';
import { NavLink, Navigate, generatePath, useParams } from 'react-router-dom';
import { format } from 'date-fns';

// Определение общего типа для элементов в массиве socialDataState
type ProductDataItem = {
  id: string;
  service: string;
  product: string;
  price: string;
  count: string;
  rate: string;
  link: string;
  promo: string;
};

// Определение общего типа для элементов в массиве socialDataState
type OrderDataItem = {
  _id: string;
  userId: string;
  date: number;
  started: boolean;
  payed: boolean;
  price: string;
  products: ProductDataItem[];
};

// Определение типа для пропсов компонента AdminOrders
type AdminOrdersProps = {
  socialDataState: OrderDataItem[];
};

const AdminOrders = ({ socialDataState }: AdminOrdersProps) => {
  const [socialDataStateArr, setSocialDataStateArr] = useState<any[]>([]);

  // жду выполнения запроса фетча
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (socialDataState && socialDataState.length > 0) {
      setSocialDataStateArr(socialDataState);
      setIsLoading(false);
    }
  }, [socialDataState]);

  return (
    <>
      <div className="admin_heading">{'Пользователи'}</div>
      <>
        {isLoading ? (
          <div>Loading...</div>
        ) : !socialDataState ? (
          <div>Items not found</div>
        ) : (
          <div className="admin_table_wrap">
            <table className="admin_table_list">
              <thead>
                <tr>
                  <th>ID заказа</th>
                  <th>Price</th>
                  <th>User ID</th>
                  <th>Started</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {socialDataState.map((item) => (
                  <tr key={item._id}>
                    <td>{item._id}</td>
                    <td>{item.price}</td>
                    <td>{item.userId}</td>
                    <td>{item.started === false ? <span className="status_el status_disabled">Disabled</span> : <span className="status_el status_active">Active</span>}</td>
                    <td>{item.payed === false ? <span className="status_el status_disabled">Disabled</span> : <span className="status_el status_active">Active</span>}</td>
                    <td>
                      <NavLink to={`edit/${item._id}`}>Edit</NavLink>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </>
    </>
  );
};

export default AdminOrders;
