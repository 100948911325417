import React, { useEffect, useRef, useState } from 'react';
import BtnOutline from '../ui-kit/BtnOutline';
import { format } from 'date-fns';

// Определение типа для объекта страницы
type PageType = {
  h1: string;
  title: string;
  updated: number;
  description: string;
  breadcrumbs: string;
  text: string;
  seoUrl: string;
  showOnMain: boolean;
  excerpt: string;
  banner: {
    text: string;
    image: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
  icon: {
    big: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
    small: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
};

// Определение общего типа для элементов в массиве socialDataState
type SocialDataItem = {
  _id: string;
  name: string;
  __v: number;
  active: boolean;
  activeApi: boolean;
  category: string;
  page: PageType;
  parentId: string;
  rate: string;
  service: string;
  socialMedia: string;
  type: string;
};

// Определение общего типа для элементов в массиве socialDataState
type SocialReviewItem = {
  _id: string;
  parentId: string;
  name: string;
  elementName?: string;
  elementLink?: string;
  type: string;
  active: boolean;
  isNew: boolean;
  updated: number;
  date: number;
  text: string;
};

// Определение типа для пропсов компонента AdminSocial
type ServicesTextReviewsWrapProps = {
  socialDataState: SocialDataItem[];
  socialAllServices: SocialReviewItem[];
  typeString: string;
  showAll?: boolean;
};

const ServicesTextReviewsWrap = ({ socialDataState, socialAllServices, typeString, showAll }: ServicesTextReviewsWrapProps) => {
  const [expanded, setExpanded] = useState(false);
  const [maxHeight, setMaxHeight] = useState(''); // Состояние для установки максимальной высоты
  const [paddingBottom, setPaddingBottom] = useState(''); // Состояние для установки максимальной высоты

  const reviewsWrapRef = useRef<HTMLDivElement>(null);

  // объекты всех услуг
  const [socialRevArr, setSocialRevArr] = useState<any>([]);

  useEffect(() => {
    if (socialAllServices) {
      const elementsSortedArr = socialAllServices.sort((a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime());
      setSocialRevArr(elementsSortedArr);
    }
  }, [socialAllServices]);

  useEffect(() => {
    // После монтирования компонента или изменения состояния expanded
    // Вычисляем высоту блока и устанавливаем максимальную высоту
    if (reviewsWrapRef.current) {
      const reviewsWrapHeight = reviewsWrapRef.current.scrollHeight;
      setMaxHeight(expanded ? `${reviewsWrapHeight + 10}px` : '');
      setPaddingBottom(expanded ? `10px` : '');
    }
  }, [expanded]);

  const handleShowAllClick = () => {
    setExpanded(!expanded);
  };
  return (
    <>
      {socialRevArr.length > 0 && (
        <div className="reviews_text_section_wrap">
          <div className="reviews_wrap reviews_text_wrap" ref={reviewsWrapRef} style={{ maxHeight: maxHeight, paddingBottom: paddingBottom }}>
            {socialRevArr.map(
              (item: any, index: number) =>
                item.active === true && (
                  <div className="reviews_text_item shadow_border border_rounded" key={index}>
                    <div className="reviews_text_top">
                      <div className="reviews_text_user">
                        <svg className="reviews_text_icon" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                          <rect width="50" height="50" rx="25" fill="#4083FD" />
                          <path
                            d="M25.3175 24.9015H25.3191C28.7396 24.9015 31.5233 22.1204 31.5244 18.7021C31.5244 17.048 30.8791 15.4912 29.7056 14.3188C28.5322 13.1464 26.9748 12.5 25.3202 12.5H25.3186C21.8981 12.5 19.1144 15.2811 19.1133 18.6994C19.1133 20.3535 19.7586 21.9098 20.932 23.0827C22.105 24.2552 23.6623 24.9015 25.3175 24.9015ZM25.3186 13.4917H25.3196C26.7097 13.4917 28.0184 14.0349 29.0037 15.0202C29.989 16.0055 30.5316 17.3132 30.5316 18.7016C30.5311 21.5735 28.1927 23.9093 25.3186 23.9093H25.3175C23.9275 23.9093 22.6188 23.3661 21.6335 22.3808C20.6482 21.3955 20.1055 20.0878 20.1055 18.6994C20.1061 15.8275 22.4445 13.4917 25.3186 13.4917Z"
                            fill="white"
                          />
                          <path
                            d="M33.3781 29.71C31.8128 28.3537 29.6503 27.5758 27.4444 27.5753L23.1889 27.5742H23.1867C21.4941 27.5742 19.8539 28.0179 18.4425 28.8578C16.6099 29.949 14.4249 32.1398 14.4238 36.382C14.4238 36.9477 14.8836 37.4074 15.4487 37.408L35.1798 37.4122C35.7449 37.4122 36.2047 36.9525 36.2047 36.3868C36.2047 33.6213 35.2274 31.3123 33.3776 29.7095L33.3781 29.71ZM35.1798 36.4205L15.4492 36.4162C15.431 36.4162 15.4161 36.4013 15.4161 36.3826C15.4171 33.3508 16.6061 31.1059 18.9499 29.7105C20.2073 28.9621 21.6722 28.5665 23.1867 28.5665H23.1889L27.4444 28.5675C31.1867 28.5686 35.214 31.0155 35.2135 36.3874C35.2135 36.4061 35.1985 36.4211 35.1803 36.4211L35.1798 36.4205Z"
                            fill="white"
                          />
                        </svg>
                        <div className="reviews_text_name">{item.name}</div>
                      </div>
                      <div className="reviews_text_date">{format(new Date(item.date), 'dd.MM.yyyy')}</div>
                    </div>
                    <div className="reviews_text_tag">
                      Услуга: <a href={item.elementLink}>{item.elementName}</a>
                    </div>
                    <div className="reviews_text_content">{item.text}</div>
                  </div>
                )
            )}
          </div>

          {/* {showAll ? (
            <></>
          ) : (
            <BtnOutline
              className="btn_outline btn_bg_transparent btn_wide_full reviews_show_all reviews_vieo_show_all"
              btnText={expanded ? 'Скрыть' : 'Показать всё'}
              btnLink={'/reviews'}
              // onClick={handleShowAllClick}
            />
          )} */}
        </div>
      )}
    </>
  );
};

export default ServicesTextReviewsWrap;
