import React, { ChangeEvent, useEffect, useState } from 'react';

type Props = {
  text: string;
  id: string;
  name: string;
  isChecked?: boolean;
  disabled?: boolean;
  // onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onChange?: () => void; // Добавьте проп onChange
};

const Switch = ({ text, id, name, isChecked, disabled, onChange }: Props) => {
  const [checked, setChecked] = useState(isChecked || false);
  useEffect(() => {
    // Обновляем состояние, если isChecked изменился
    setChecked(isChecked || false);
  }, [isChecked]);

  const handleToggle = () => {
    if (!disabled) {
      setChecked(!checked);
      if (onChange) {
        onChange(); // Вызываем колбэк onChange, если он предоставлен
      }
    }
  };
  return (
    <div className={`admin_form_row_checked ${disabled ? 'disabled' : ''}`}>
      <input type="checkbox" id={id} name={name} checked={checked} onChange={handleToggle} disabled={disabled} />
      <label htmlFor={id} className="text_label">
        {text}
      </label>
    </div>
  );
};

export default Switch;
