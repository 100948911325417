import React, { FormEvent, ReactNode } from 'react';

type InnerFormProps = {
  className?: string;
  children?: ReactNode;
  onSubmit?: (event: FormEvent<HTMLFormElement>) => void;
};

const InnerForm = ({ className, children, onSubmit }: InnerFormProps) => {
  return (
    <form className={className} onSubmit={onSubmit}>
      {children}
    </form>
  );
};

export default InnerForm;
