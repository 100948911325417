import React, { CSSProperties, ChangeEvent, HTMLInputTypeAttribute, ReactNode } from 'react';

type TextareaLabelForProps = {
  id: string;
  label: string;
  name: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  value?: string;
  readOnly?: boolean;
  disabled?: boolean;
  rows?: number;
};

const TextareaLabelFor = ({ id, label, name, onChange, placeholder, value, readOnly, disabled, rows }: TextareaLabelForProps) => {
  return (
    <>
      <label htmlFor={id} className={`text_label`}>
        {label}
      </label>
      <textarea id={id} name={name} value={value} className={`text_input text_area`} readOnly={readOnly} disabled={disabled} placeholder={placeholder} onChange={onChange} rows={rows} />
    </>
  );
};

export default TextareaLabelFor;
