import React, { useContext, useEffect, useRef, useState } from 'react';
import Banner from '../components/main_page/Banner';
import Advantages from '../components/main_page/Advantages';
import MainQuickOrder from '../components/main_page/MainQuickOrder';
import PopularServices from '../components/main_page/PopularServices';
import ReviewMethods from '../components/main_page/ReviewMethods';
import BeVisible from '../components/main_page/BeVisible';
import SecondAdvantages from '../components/main_page/SecondAdvantages';
import ReferalInfo from '../components/main_page/ReferalInfo';
import MainFaq from '../components/main_page/MainFaq';
import MainLastBlock from '../components/main_page/MainLastBlock';
import { Helmet } from 'react-helmet-async';
import { ScrollContext } from '../../Routes/Layout';
import { fetchApi } from '../components/helpers/api';

// Определение типа для объекта страницы
type PageType = {
  h1: string;
  title: string;
  updated: number;
  description: string;
  breadcrumbs: string;
  text: string;
  seoUrl: string;
  showOnMain: boolean;
  excerpt: string;
  banner: {
    text: string;
    image: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
  icon: {
    big: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
    small: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
};

// Определение общего типа для элементов в массиве socialDataState
type SocialDataItem = {
  _id: string;
  name: string;
  __v: number;
  active: boolean;
  activeApi: boolean;
  category: string;
  page: PageType;
  parentId: string;
  rate: string;
  service: string;
  socialMedia: string;
  type: string;
};

// Определение общего типа для элементов в массиве socialDataState
type SocialFaqItem = {
  _id: string;
  parentId: string;
  name: string;
  type: string;
  active: boolean;
  updated: number;
  text: string;
};

// Передаю менюхи
type Items = {
  name: string;
  link: string;
  icon: {
    parentId?: number;
    id: string;
    filename: string;
    filetype: string;
    datetime: string;
    filesize: string;
    filepath: string;
  };
  index: string;
};

// Определение общего типа для элементов в массиве менюх
type HeaderProps = {
  _id: string;
  name: string;
  type: string;
  subtype: string;
  items: Items[];
};

// Определение типа для пропсов компонента AdminBlog
type HomeProps = {
  socialDataState: SocialDataItem[];
  faqDataState: SocialFaqItem[];
  menuDataState: HeaderProps[];
  typeString?: string;
  isMobile?: boolean;
  isLogged: boolean;
};

const Home = ({ menuDataState, socialDataState, faqDataState, isMobile, isLogged }: HomeProps) => {
  const { scrollToMainQuickOrder, mainQuickOrderRef } = useContext(ScrollContext); // Получаем функцию и ref из контекста

  const [homeTitle, setHomeTitle] = useState<string>('');
  const [homeDescr, setHomeDescr] = useState<string>('');

  const fetchCatalogRootItem = async () => {
    try {
      const mainMetaTitle = await fetchApi('GET', `settings/get/mainMetaTitle`, {});
      if (mainMetaTitle) {
        setHomeTitle(mainMetaTitle.value);
      }
      const mainMetaDescription = await fetchApi('GET', `settings/get/mainMetaDescription`, {});
      if (mainMetaTitle) {
        setHomeDescr(mainMetaDescription.value);
      }
    } catch (error) {
      console.error('Error fetching social item:', error);
    } finally {
    }
  };

  useEffect(() => {
    fetchCatalogRootItem();
  }, []);

  return (
    <>
      <Helmet>
        <title>{homeTitle}</title>
        <meta name="description" content={homeDescr} />
        {/* Другие мета-теги */}
      </Helmet>
      <Banner isMobile={isMobile} menuDataState={menuDataState} />
      <Advantages />
      <MainQuickOrder ref={mainQuickOrderRef} socialDataState={socialDataState} isLogged={isLogged} />
      <PopularServices socialDataState={socialDataState} />
      <ReviewMethods />
      <BeVisible scrollToMainQuickOrder={scrollToMainQuickOrder} />
      <SecondAdvantages />
      <ReferalInfo />
      <MainFaq socialDataState={faqDataState} typeString={'faq'} />
      <MainLastBlock />
    </>
  );
};

export default Home;
