import React from 'react';
import { useNavigate } from 'react-router-dom';
import BtnOutline from '../../ui-kit/BtnOutline';
import Logo from '../header_parts/Logo';
import FooterMenu from './FooterMenu';
import FooterPayment from './FooterPayment';

const footerLogoSrc = '/content/footer/logo_white.png';
const siteName = 'MediaBoost'; // тут можно под сео сделать типа 'title для главной' из админки
const siteCopyright = '2011 - 2024'; // тут можно под сео сделать типа 'Copyright' из админки
const siteFooterDescription =
  'Накрутка просмотров ВК, Накрутка просмотров YouTube, Instagram, Tik Tok, VK, YouTube, Telegram, Twitch, Facebook, Отзывы, Twitter, Одноклассники, SoundCloud, Google, Яндекс, Мобильные Приложения, Discord, Linkedin, Яндекс Дзен, Трафик на сайт, Reddit, Shazam, Clubhouse, Крипто Маркетинг, Другое, Trovo, Quora.';
// тут можно под сео сделать типа 'Описание в футере для главной' из админки

const footerMenuName_01 = 'О сервисе';

const footerMenuName_02 = 'Клиенту';

const footerPayment = [
  {
    payment_title: 'Сбер',
    payment_icon: '/content/footer/sber.png'
  },
  {
    payment_title: 'SBP',
    payment_icon: '/content/footer/sbp.png'
  },
  {
    payment_title: 'Payeer',
    payment_icon: '/content/footer/payeer.png'
  },
  {
    payment_title: 'Cryptomus',
    payment_icon: '/content/footer/cryptomus.png'
  },
  {
    payment_title: 'Yookassa',
    payment_icon: '/content/footer/yookassa.svg'
  },
];

// Определение типа для объекта страницы
type Items = {
  name: string;
  link: string;
  icon: {
    parentId?: number;
    id: string;
    filename: string;
    filetype: string;
    datetime: string;
    filesize: string;
    filepath: string;
  };
  index: string;
};

// Определение общего типа для элементов в массиве socialDataState
type MenuProps = {
  _id: string;
  name: string;
  type: string;
  subtype: string;
  items: Items[];
};

type FooterProps = {
  isUserLogin?: boolean;
  userName?: string;
  footerOneMenuState: MenuProps[];
  footerTwoMenuState: MenuProps[];
};

const Footer = ({ footerOneMenuState, footerTwoMenuState }: FooterProps) => {
  const navigate = useNavigate();

  const btnNavigate = (link: string) => () => {
    navigate(link);
  };
  return (
    <div className="footer">
      <div className="container">
        <div className="footer_wrap">
          <div className="footer_col_1">
            <Logo imgSrc={footerLogoSrc} siteName={siteName} className="footer_logo" />
            <div className="footer_siteinfo">
              <div className="footer_siteinfo_text">
                {siteName} © {siteCopyright}
              </div>
              <div className="footer_siteinfo_text">Все права защищены</div>
            </div>
            <div className="footer_description">{siteFooterDescription}</div>
          </div>
          <div className="footer_col footer_col_2">
            <Logo imgSrc={footerLogoSrc} siteName={siteName} className="footer_logo footer_logo_mob" />
            <FooterMenu menuTitle={footerMenuName_01} mainMenuState={footerOneMenuState} />
          </div>
          <div className="footer_col footer_col_3">
            <FooterMenu menuTitle={footerMenuName_02} mainMenuState={footerTwoMenuState} />
          </div>
          <div className="footer_col footer_col_4">
            <div className="footer_menu_title">Нужна помощь?</div>
            <BtnOutline className="btn_outline footer_need_help btn_wide_full" btnLink='https://t.me/media_booster_ru' btnText="Написать менеджеру" newWindow={true} />
            <div className="footer_menu_title">Принимаем к оплате:</div>
            <FooterPayment paymentList={footerPayment} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
