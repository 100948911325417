import React, { useEffect, useRef, useState } from 'react';
import MenuLink from '../../ui-kit/MenuLink';
import { useNavigate } from 'react-router-dom';
import MenuBtnAction from './MenuBtnAction';
import LeftMenu from '../LeftMenu';
import { useUserData } from '../../../hooks/UserDataContext';

// Определение типа для объекта страницы
type Items = {
  name: string;
  link: string;
  icon: {
    parentId?: number;
    id: string;
    filename: string;
    filetype: string;
    datetime: string;
    filesize: string;
    filepath: string;
  };
  index: string;
};

// Определение общего типа для элементов в массиве socialDataState
type MenuProps = {
  _id: string;
  name: string;
  type: string;
  subtype: string;
  items: Items[];
};

// Определение общего типа для элементов в массиве socialDataState
type HeaderProps = {
  _id: string;
  name: string;
  type: string;
  subtype: string;
  items: Items[];
};

type HeaderMenuMobileProps = {
  isActiveMobileMenu?: boolean;
  isUserLogin?: boolean;
  userName?: string;
  menuDataState: HeaderProps[];
  mainMenuState: MenuProps[];
  setIsActiveMobileMenu: (event: any) => void;
};

const HeaderMenuMobile = ({ setIsActiveMobileMenu, isActiveMobileMenu, menuDataState, mainMenuState }: HeaderMenuMobileProps) => {
  const navigate = useNavigate();

  const [leftMenuTopList, setLeftMenuTopList] = useState<any>(null);
  const [leftMenuBottomList, setLeftMenuBottomList] = useState<any>(null);
  const [mainItemList, setMainItemList] = useState<Items[]>([]);

  const btnNavigate = (link: string) => () => {
    navigate(link);
  };

  const wrapRef = useRef<any>(null);
  const handleClick = (event: any) => {
    if (wrapRef.current && !wrapRef.current.contains(event.target)) {
      setIsActiveMobileMenu(false);
    }
  };

  const handleMenuLinkClick = () => {
    setIsActiveMobileMenu(false);
  }

  useEffect(() => {
    if (menuDataState) {
      // Заполняю менюхи
      const foundSidebarOneMenu = menuDataState.find((item) => item.subtype === 'sitebar_menu_1');
      const foundSidebarTwoMenu = menuDataState.find((item) => item.subtype === 'sitebar_menu_2');
      if (foundSidebarOneMenu && foundSidebarTwoMenu) {
        setLeftMenuTopList(foundSidebarOneMenu.items);
        setLeftMenuBottomList(foundSidebarTwoMenu.items);
      }
    }
  }, [menuDataState]);

  // useEffect(() => {
  //   // Выбираем первый элемент из menuList для сортировки его items
  //   if (mainMenuState) {
  //     const sortedArr = mainMenuState.sort((a, b) => parseInt(a.index) - parseInt(b.index));
  //     setMainItemList(sortedArr);
  //   }
  // }, [mainMenuState]);

  // Получите значения из контекста
  const { personalEmail, personalName, personalPhone, personalBalance, fetchUserDataContext, token, userId, isUserLogin } = useUserData();

  return (
    <div className={`mobile_menu_wrap ${isActiveMobileMenu ? 'active' : ''}`} onClick={handleClick}>
      <div className="mobile_menu_bg"></div>
      <div className="nav_items nav_items_mobile" ref={wrapRef}>
        <MenuBtnAction isUserLogIn={isUserLogin} isPersonal={true} btnText={isUserLogin ? personalName : 'Войти'} btnLink={isUserLogin ? '/personal' : '/login'} linkClicked={handleMenuLinkClick}/>
        <MenuBtnAction isUserLogIn={false} btnText="Пополнить баланс" linkClicked={handleMenuLinkClick}/>
        {mainMenuState && mainMenuState.map((item: any, index: number) => <MenuLink className="nav_item text" menuLink={item.link} menuText={item.name} key={index} subItems={item.subItems}  linkClicked={handleMenuLinkClick}/>)}
        <LeftMenu leftMenuTopList={leftMenuTopList} leftMenuBottomList={leftMenuBottomList} linkClicked={handleMenuLinkClick}/>
      </div>
    </div>
  );
};

export default HeaderMenuMobile;
