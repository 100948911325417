import React, { useEffect, useState } from 'react';
import Footer from '../components/template_parts/footer_parts/Footer';
import Header from '../components/template_parts/header_parts/Header';
import LeftMenu from '../components/template_parts/LeftMenu';
import BtnOutline from '../components/ui-kit/BtnOutline';
import GlobalStyles from '../../styles/global';

// Передаю менюхи
type Items = {
  name: string;
  link: string;
  icon: {
    parentId?: number;
    id: string;
    filename: string;
    filetype: string;
    datetime: string;
    filesize: string;
    filepath: string;
  };
  index: string;
};

// Определение общего типа для элементов в массиве socialDataState
type HeaderProps = {
  _id: string;
  name: string;
  type: string;
  subtype: string;
  items: Items[];
};

type OrderSuccessProps = {
  isMobile?: boolean;
  menuDataState: HeaderProps[];
};

const OrderSuccess = ({ isMobile, menuDataState }: OrderSuccessProps) => {
  const [leftMenuTopList, setLeftMenuTopList] = useState<any>(null);
  const [leftMenuBottomList, setLeftMenuBottomList] = useState<any>(null);

  useEffect(() => {
    if (menuDataState) {
      // Заполняю менюхи
      const foundSidebarOneMenu = menuDataState.find((item) => item.subtype === 'sitebar_menu_1');
      const foundSidebarTwoMenu = menuDataState.find((item) => item.subtype === 'sitebar_menu_2');
      if (foundSidebarOneMenu && foundSidebarTwoMenu) {
        setLeftMenuTopList(foundSidebarOneMenu.items);
        setLeftMenuBottomList(foundSidebarTwoMenu.items);
      }
    }
  }, [menuDataState]);
  return (
    <>
      <div className="inner">
        <div className="container">
          {isMobile ? (
            <div className="inner_col_content">
              <div className="not_found_title">{`Успешно!`}</div>
              <div className="order_wrap">
                <h1 className="inner_col_content_title">Платёж успешно получен, выполнение услуги начнётся в ближайшее время!</h1>
                <BtnOutline btnLink={`/`} className="btn_wide inner_col_content_nested_services_item_btn" btnText="На главную" />
              </div>
            </div>
          ) : (
            <div className="inner_wrap">
              <div className="inner_col shadow_border border_rounded">
                <LeftMenu leftMenuTopList={leftMenuTopList} leftMenuBottomList={leftMenuBottomList} />
              </div>
              <div className="inner_col_content">
                <div className="not_found_title">{`Успешно!`}</div>
                <div className="order_wrap">
                  <h1>Платёж успешно получен, выполнение услуги начнётся в ближайшее время!</h1>
                  <BtnOutline btnLink={`/`} className="btn_wide inner_col_content_nested_services_item_btn" btnText="На главную" />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <GlobalStyles />
    </>
  );
};

export default OrderSuccess;
