import React, { FormEvent, useEffect, useState } from 'react';
import Switch from '../admin-ui-kit/input/Switch';
import InnerForm from '../admin-ui-kit/form/InnerForm';
import InputBase from '../admin-ui-kit/input/InputBase';
import InputLabelFor from '../admin-ui-kit/input/InputLabelFor';
import TextareaLabelFor from '../admin-ui-kit/input/TextareaLabelFor';
import BtnBase from '../admin-ui-kit/btn/BtnBase';
import FileUploadWithPreview from '../admin-ui-kit/input/FileUploadWithPreview';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import BaseSelect from '../admin-ui-kit/input/BaseSelect';
import { fetchApi } from '../../../content/components/helpers/api';

// Определение типа для объекта страницы
type PageType = {
  h1: string;
  title: string;
  updated: number;
  description: string;
  breadcrumbs: string;
  text: string;
  seoUrl: string;
  banner: {
    text: string;
    image: string;
  };
  icon: {
    big: string;
    small: string;
  };
};

// Определение общего типа для элементов в массиве socialDataState
type SocialDataItem = {
  _id: string;
  parentId: string;
  name: string;
  type: string;
  active: boolean;
  category: string;
  page: PageType;
};

// Определяем тип для функции обновления данных
type UpdateSocialDataStateFunction = () => Promise<void>;

// Определение типа для пропсов компонента AdminSocial
type AdminBlogItemNewProps = {
  socialDataState: SocialDataItem[];
  typeString: string;
  updateDataState: UpdateSocialDataStateFunction;
};

const AdminBlogItemNew = ({ socialDataState, typeString, updateDataState }: AdminBlogItemNewProps) => {
  const navigate = useNavigate();
  const { _id } = useParams();

  // жду выполнения запроса фетча
  const [isLoading, setIsLoading] = useState(true);

  // жду завершения обновления элемента
  const [isLoadingData, setIsLoadingData] = useState(false);

  // типа одного элемента
  const [socialTypeString, setSocialTypeString] = useState(typeString);

  const [socialItem, setSocialItem] = useState<any>(null);
  const [socialDataStateArr, setSocialDataStateArr] = useState<any[]>([]);

  // массив с данными для сохранения
  const [savedItemArr, setSavedItemArr] = useState<any>(null);

  // список категорий для выбора
  const [socialDataCategoryArr, setSocialDataCategoryArr] = useState<any[]>([]);

  //
  const [itemName, setItemName] = useState<any>(null);
  const [itemType, setItemType] = useState<any>(null);
  const [itemCategory, setItemCategory] = useState<any>(null);
  const [itemActive, setItemActive] = useState<any>(null);
  const [itemH1, setItemH1] = useState<any>(null);
  const [itemTitle, setItemTitle] = useState<any>(null);
  const [itemDate, setItemDate] = useState<any>(null);
  const [itemDescription, setItemDescription] = useState<any>(null);
  const [itemBreadcrumbs, setItemBreadcrumbs] = useState<any>(null);
  const [itemText, setItemText] = useState<any>(null);
  const [itemBannerText, setItemBannerText] = useState<any>(null);
  const [itemSeoUrl, setItemSeoUrl] = useState<any>(null);

  const [bannerId, setBannerId] = useState<any>(null);
  const [bannerFilename, setBannerFilename] = useState<any>(null);
  const [bannerFiletype, setBannerFiletype] = useState<any>(null);
  const [bannerFilesize, setBannerFilesize] = useState<any>(null);
  const [bannerFilepath, setBannerFilepath] = useState<any>(null);
  const [bannerDatetime, setBannerDatetime] = useState<any>(null);

  const [smallId, setSmallId] = useState<any>(null);
  const [smallFilename, setSmallFilename] = useState<any>(null);
  const [smallFiletype, setSmallFiletype] = useState<any>(null);
  const [smallFilesize, setSmallFilesize] = useState<any>(null);
  const [smallFilepath, setSmallFilepath] = useState<any>(null);
  const [smallDatetime, setSmallDatetime] = useState<any>(null);

  const [bigId, setBigId] = useState<any>(null);
  const [bigFilename, setBigFilename] = useState<any>(null);
  const [bigFiletype, setBigFiletype] = useState<any>(null);
  const [bigFilesize, setBigFilesize] = useState<any>(null);
  const [bigFilepath, setBigFilepath] = useState<any>(null);
  const [bigDatetime, setBigDatetime] = useState<any>(null);

  useEffect(() => {
    setItemName('');
    setItemType(socialTypeString);
    setItemActive(true);
    setItemH1('');
    setItemTitle('');
    setItemDate(Date.now());
    setItemDescription('');
    setItemBreadcrumbs('');
    setItemText('');
    setItemSeoUrl('');
    setItemBannerText('');

    setBannerId('');
    setBannerFilename('');
    setBannerFiletype('');
    setBannerFilesize('');
    setBannerFilepath('');
    setBannerDatetime('');

    setSmallId('');
    setSmallFilename('');
    setSmallFiletype('');
    setSmallFilesize('');
    setSmallFilepath('');
    setSmallDatetime('');

    setBigId('');
    setBigFilename('');
    setBigFiletype('');
    setBigFilesize('');
    setBigFilepath('');
    setBigDatetime('');
  }, [socialTypeString]);

  // Функция для сохранения данных про баннер
  const handleImageSaveBanner = (imageData: any) => {
    setBannerId(imageData.id);
    setBannerFilename(imageData.filename);
    setBannerFiletype(imageData.filetype);
    setBannerFilesize(imageData.filesize);
    setBannerFilepath(imageData.filepath);
    setBannerDatetime(imageData.datetime);
  };

  // Функция для сохранения данных про маленькую иконку
  const handleImageSaveIconSmall = (imageData: any) => {
    setSmallId(imageData.id);
    setSmallFilename(imageData.filename);
    setSmallFiletype(imageData.filetype);
    setSmallFilesize(imageData.filesize);
    setSmallFilepath(imageData.filepath);
    setSmallDatetime(imageData.datetime);
  };

  // Функция для сохранения данных про маленькую иконку
  const handleImageSaveIconBig = (imageData: any) => {
    setBigId(imageData.id);
    setBigFilename(imageData.filename);
    setBigFiletype(imageData.filetype);
    setBigFilesize(imageData.filesize);
    setBigFilepath(imageData.filepath);
    setBigDatetime(imageData.datetime);
  };

  const transliterateToUrl = (text: string): string => {
    return text
      .replace(/([а-яё])|([\s\*-])|([^a-z\d])/gi, (all, ch, space, words) => {
        if (space || words) {
          return space || space == '*' || words == '*' ? '-' : '';
        }
        const code = ch.charCodeAt(0);
        const index = code === 1025 || code === 1105 ? 0 : code > 1071 ? code - 1071 : code - 1039;
        const t = ['yo', 'a', 'b', 'v', 'g', 'd', 'e', 'zh', 'z', 'i', 'y', 'k', 'l', 'm', 'n', 'o', 'p', 'r', 's', 't', 'u', 'f', 'h', 'c', 'ch', 'sh', 'shch', '', 'y', '', 'e', 'yu', 'ya'];
        return t[index];
      })
      .toLowerCase();
  };

  const handeSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const savedItemArrObjs = {
      // _id: socialItem._id,
      parentId: itemCategory ? itemCategory : '0',
      name: itemName,
      type: itemType,
      active: itemActive,
      page: {
        h1: itemH1,
        title: itemTitle,
        updated: Date.now(),
        description: itemDescription,
        breadcrumbs: itemBreadcrumbs,
        text: itemText,
        seoUrl: transliterateToUrl(itemSeoUrl),
        banner: {
          text: itemBannerText,
          image: {
            id: bannerId,
            filename: bannerFilename,
            filetype: bannerFiletype,
            filesize: bannerFilesize,
            filepath: bannerFilepath,
            datetime: bannerDatetime
          }
        },
        icon: {
          big: {
            id: bigId,
            filename: bigFilename,
            filetype: bigFiletype,
            filesize: bigFilesize,
            filepath: bigFilepath,
            datetime: bigDatetime
          },
          small: {
            id: smallId,
            filename: smallFilename,
            filetype: smallFiletype,
            filesize: smallFilesize,
            filepath: smallFilepath,
            datetime: smallDatetime
          }
        }
      }
    };
    setSavedItemArr(savedItemArrObjs);

    const saveItem = async () => {
      try {
        setIsLoadingData(true);
        let response;

        if (typeString === 'category') {
          response = await fetchApi('POST', `/blog/new_category`, savedItemArrObjs);
        } else if (typeString === 'item') {
          response = await fetchApi('POST', `/blog/new`, savedItemArrObjs);
        }

        if (response && !response.error) {
          // Успешно сохранено, выполните необходимые действия
          updateDataState();
          if (typeString === 'category') {
            alert('Категория создана!');
            navigate('/admin/blog'); // Редирект на страницу блога
          } else if (typeString === 'item') {
            alert('Статья создана!');
            navigate('/admin/blog/items'); // Редирект на страницу блога
          }
        } else {
          console.error('Failed to save item:', response.error);
          // Обработка ошибки, например, отображение сообщения об ошибке для пользователя
        }
      } catch (error) {
        console.error('Error saving item:', error);
        // Обработка ошибки, например, отображение сообщения об ошибке для пользователя
      } finally {
        setIsLoadingData(false);
      }
    };

    if (itemName.length != 0) {
      saveItem();
    } else {
      alert('Введите название статьи!');
    }
  };

  // useEffect(() => {

  // }, [savedItemArr, typeString, updateDataState, navigate]);

  // Функция для получения массива категорий
  const getCategoryOptions = (data: SocialDataItem[]): { text: string; value: string }[] => {
    const categoryOptions = [
      { text: 'Без категории', value: '0' },
      ...data
        .filter((item: SocialDataItem) => item.type === 'category') // Отфильтровать только категории
        .map((category: SocialDataItem) => ({
          text: category.name, // Использовать название категории в качестве текста
          value: category._id // Использовать _id категории в качестве значения
        }))
    ];

    return categoryOptions;
  };

  // заполняю массив категориями для их выбора в элементе
  useEffect(() => {
    const categories = getCategoryOptions(socialDataState);
    setSocialDataCategoryArr(categories);
  }, [socialDataState]);

  return (
    <>
      <InnerForm onSubmit={handeSubmit}>
        <div className="admin_heading">
          <div className="admin_heading_title">{socialTypeString === 'category' ? 'Добавить категорию' : 'Добавить элемент'}</div>
          <BtnBase className={'admin_submit'} btnText={'Обновить'} type={'submit'} />
        </div>
        <div className="admin_container">
          <div className="admin_form">
            <div className="admin_form_section_wrap">
              <div className="admin_form_section_title">Системные данные</div>
              <div className="admin_form_row">
                <Switch text={'Активно'} id={'active'} name={'active'} isChecked={itemActive} onChange={() => setItemActive(!itemActive)} />
              </div>
              <div className="admin_form_row">
                <InputLabelFor
                  label={'Название'}
                  value={itemName}
                  id={'name'}
                  name={'name'}
                  type={'text'}
                  onChange={(event) => {
                    setItemName(event.target.value);
                    setItemSeoUrl(transliterateToUrl(event.target.value));
                  }}
                />
              </div>
              <div className="admin_form_row">
                <InputLabelFor label={'SEO URL'} value={itemSeoUrl} id={'seo_url'} name={'seo_url'} type={'text'} onChange={(event) => setItemSeoUrl(event.target.value)} />
              </div>
              {itemType === 'item' && (
                <div className="admin_form_row">
                  <label htmlFor={'type_of_item'} className={`text_label`}>
                    {'Выберите категорию'}
                  </label>
                  <BaseSelect
                    className={'personal_select base_input_wide'}
                    selectItems={socialDataCategoryArr}
                    name={'type_of_item'}
                    id={'type_of_item'}
                    innerValue={itemCategory}
                    onChange={(value) => setItemCategory(value)}
                  />
                </div>
              )}
            </div>
            <div className="admin_form_section_wrap">
              <div className="admin_form_section_title">Содержимое</div>
              {itemType === 'category' && (
                <>
                  <div className="admin_form_row admin_form_row_upload">
                    <FileUploadWithPreview
                      labelText={'Большое изображение'}
                      savedId={bannerId}
                      savedFilename={bannerFilename}
                      savedFiletype={bannerFiletype}
                      savedFilesize={bannerFilesize}
                      savedFilepath={bannerFilepath}
                      savedDatetime={bannerDatetime}
                      saveImage={handleImageSaveBanner}
                    />
                  </div>
                  <div className="admin_form_row">
                    <TextareaLabelFor label={'Контент в баннере'} value={itemBannerText} id={'innerBannerText'} name={'innerBannerText'} onChange={(event) => setItemBannerText(event.target.value)} />
                  </div>
                </>
              )}
              <div className="admin_form_section_wrap_grid">
                <div className="admin_form_section_wrap_grid_child">
                  <div className="admin_form_row">
                    <FileUploadWithPreview
                      labelText={'Иконка 26х26'}
                      savedId={smallId}
                      savedFilename={smallFilename}
                      savedFiletype={smallFiletype}
                      savedFilesize={smallFilesize}
                      savedFilepath={smallFilepath}
                      savedDatetime={smallDatetime}
                      saveImage={handleImageSaveIconSmall}
                    />
                  </div>
                </div>
                <div className="admin_form_section_wrap_grid_child">
                  <div className="admin_form_row">
                    <FileUploadWithPreview
                      labelText={'Иконка 46х46'}
                      savedId={bigId}
                      savedFilename={bigFilename}
                      savedFiletype={bigFiletype}
                      savedFilesize={bigFilesize}
                      savedFilepath={bigFilepath}
                      savedDatetime={bigDatetime}
                      saveImage={handleImageSaveIconBig}
                    />
                  </div>
                </div>
              </div>
              <div className="admin_form_row">
                <TextareaLabelFor label={'Текстовый блок'} value={itemText} id={'text'} name={'text'} onChange={(event) => setItemText(event.target.value)} />
              </div>
            </div>
            <div className="admin_form_section_wrap">
              <div className="admin_form_section_title">Мета</div>
              <div className="admin_form_row">
                <InputLabelFor label={'Заголовок - H1'} value={itemH1} id={'h1'} name={'h1'} type={'text'} onChange={(event) => setItemH1(event.target.value)} />
              </div>
              <div className="admin_form_row">
                <InputLabelFor label={'Хлебные крошки'} value={itemBreadcrumbs} id={'breadcrumbs'} name={'breadcrumbs'} type={'text'} onChange={(event) => setItemBreadcrumbs(event.target.value)} />
              </div>
              <div className="admin_form_row">
                <InputLabelFor label={'Meta-title'} value={itemTitle} id={'meta-title'} name={'meta-title'} type={'text'} onChange={(event) => setItemTitle(event.target.value)} />
              </div>
              <div className="admin_form_row">
                <InputLabelFor
                  label={'Meta-description'}
                  value={itemDescription}
                  id={'meta-description'}
                  name={'meta-description'}
                  type={'text'}
                  onChange={(event) => setItemDescription(event.target.value)}
                />
              </div>
            </div>
            <BtnBase className={'admin_submit admin_submit_center'} btnText={'Обновить'} type={'submit'} />
          </div>
        </div>
      </InnerForm>
    </>
  );
};

export default AdminBlogItemNew;
