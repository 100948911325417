import React, { useEffect, useState } from 'react';
import ServicesInnerList from '../Services/ServicesInnerList';
import ServicesItemsList from '../Services/ServicesItemsList';
import BlogItemsList from './BlogItemsList';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import InnerBreadcrumbs from '../ui-kit/InnerBreadcrumbs';

// Определение типа для объекта страницы
type PageType = {
  h1: string;
  title: string;
  updated: number;
  description: string;
  breadcrumbs: string;
  text: string;
  seoUrl: string;
  showOnMain: boolean;
  excerpt: string;
  banner: {
    text: string;
    image: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
  icon: {
    big: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
    small: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
};

// Определение общего типа для элементов в массиве socialDataState
type SocialDataItem = {
  _id: string;
  name: string;
  __v: number;
  active: boolean;
  activeApi: boolean;
  category: string;
  page: PageType;
  parentId: string;
  rate: string;
  service: string;
  socialMedia: string;
  type: string;
};

// Определение типа для пропсов компонента AdminBlog
type BlogProps = {
  socialDataState: SocialDataItem[];
  typeString?: string;
};

const Blog = ({ socialDataState }: BlogProps) => {
  const navigate = useNavigate();
  const { _id } = useParams();

  // список категорий соц. сети
  const [socialDataCategoryArr, setSocialDataCategoryArr] = useState<any[]>([]);

  // объекты всех услуг
  const [socialItemsArr, setSocialItemsArr] = useState<any>(null);

  // Функция для получения массива категорий
  const getCategoryOptions = (data: SocialDataItem[]): {}[] => {
    // Создаем элемент "Все статьи"
    const allArticles = {
      text: 'Все статьи',
      link: '/blog', // Здесь нужно указать ссылку на основную страницу блога
      id: 'all_articles',
      icon: '/content/not/blg_new_small.png', // Можно указать иконку для "Все статьи", если есть
      parent: '' // У "Все статьи" нет родительской категории, поэтому пустая строка
    };

    const categoryOptions = data
      .filter((item: SocialDataItem) => item.type === 'category') // Отфильтровать только категории
      .map((category: SocialDataItem) => ({
        text: category.name,
        link: category.page.seoUrl,
        id: category._id,
        icon: category.page.icon.small.filepath,
        parent: category.parentId
      }));

    // Вставляем "Все статьи" как первый элемент в массив категорий
    categoryOptions.unshift(allArticles);

    return categoryOptions;
  };

  // Функция для получения массива элементов
  const getServicesElements = (data: SocialDataItem[]): {}[] => {
    const socialSortItem = data.filter((item: SocialDataItem) => item.type === 'item');
    let categoryOptions: {}[] = [];

    // Получаем статьи с parentId === '0'
    const rootItems = socialSortItem.filter((item: SocialDataItem) => item.parentId === '0');

    // Формируем элементы для статей с parentId === '0'
    const rootItemsWithCategoryName = rootItems.map((item) => ({
      ...item,
      link: '/blog/' + item.page.seoUrl, // Используем только ID, так как parentId === '0'
      categoryName: 'Без категории', // Можно задать свое название для статей без категории
      categoryIcon: '' // Можно задать пустую строку или другую иконку
    }));

    categoryOptions = [...categoryOptions, ...rootItemsWithCategoryName];

    const categoryOptionsAll = data
      .filter((item: SocialDataItem) => item.type === 'category')
      .map((category: SocialDataItem) => ({
        text: category.name,
        link: category.page.seoUrl,
        id: category._id,
        icon: category.page.icon.small.filepath,
        parent: category.parentId,
        categoryName: category.name
      }));

    categoryOptionsAll.forEach((serviceItem: any) => {
      if (serviceItem.id !== '0') {
        const filteredItems = socialSortItem.filter((item) => item.parentId === serviceItem.id);
        const itemsWithCategoryName = filteredItems.map((item) => ({
          ...item,
          link: '/blog/' + serviceItem.link + '/' + item.page.seoUrl,
          categoryName: serviceItem.categoryName,
          categoryIcon: serviceItem.icon
        }));
        categoryOptions = [...categoryOptions, ...itemsWithCategoryName];
      }
    });

    const elementsSortedArr = categoryOptions.sort((a: any, b: any) => new Date(b.page.updated).getTime() - new Date(a.page.updated).getTime());
    return elementsSortedArr;
  };

  useEffect(() => {
    if (socialDataState) {
      // заполняю массив категориями для их выбора в элементе
      const categories = getCategoryOptions(socialDataState);
      setSocialDataCategoryArr(categories);

      // заполняю массив с элементами
      const elements = getServicesElements(socialDataState);
      setSocialItemsArr(elements);
    }
  }, [socialDataState]);

  return (
    <>
      <Helmet>
        <title>{'Блог'}</title>
        <meta name="description" content={'Блог'} />
        {/* Другие мета-теги */}
      </Helmet>
      <InnerBreadcrumbs chapterLink={'/blog'} chapterString={'Блог'} />
      <div className="inner_single_top">
        <div className="inner_single_top_content">
          <h1 className="inner_single_top_content_title">Блог</h1>
          <div className="inner_single_top_content_text">Идеи, мнения, короткие и длинные истории от команды MediaBoost</div>
        </div>
        <div className="inner_single_top_content_img">
          <img src="/content/not/blg_new.png" alt="" />
        </div>
      </div>
      <div className="single_content">
        <div className="inner_section main_popular_services_wrap single_content_menu">
          <ServicesInnerList menuList={socialDataCategoryArr} />
        </div>
        <BlogItemsList menuList={socialItemsArr} />
      </div>
    </>
  );
};

export default Blog;
