import React, { FormEvent, useEffect, useState } from 'react';
import InputBase from '../../../ui-kit/input/InputBase';
import BtnOutline from '../../../ui-kit/BtnOutline';
import InnerForm from '../../../ui-kit/form/InnerForm';
import { fetchApi } from '../../../helpers/api';
import { Authentification } from '../../../helpers/auth';

type Props = {};

const PersonalSetting = (props: Props) => {
  const [personalName, setPersonalName] = useState<any>('');
  const [personalPhone, setPersonalPhone] = useState<any>('');

  const [personalEmail, setPersonalEmail] = useState<any>('');
  const [personalOldPass, setPersonalOldPass] = useState<any>('');
  const [personalNewPass, setPersonalNewPass] = useState<any>('');

  const [isError, setIsError] = useState<any>(false);

  useEffect(() => {
    fetchApi('GET', 'users/get_info/' + Authentification.getInstance().getUserId(), {}).then((res) => {
      if (res) {
        setPersonalEmail(res.email);
        setPersonalName(res.name);
        setPersonalPhone(res.phone);
      }
    });
  }, []);

  const handeSubmitName = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log(await fetchApi('POST', '/users/updateInfo/' + Authentification.getInstance().getUserId(), { name: personalName, phone: personalPhone }));
    console.log({ name: personalName, phone: personalPhone });
    console.log(`Имя: ${personalName}`);
    console.log(`Телефон: ${personalPhone}`);
  };

  const handeSubmitPersonal = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const apiUpdateInfo = await fetchApi('POST', '/users/updateInfo/' + Authentification.getInstance().getUserId(), { email: personalEmail, oldPassword: personalOldPass, password: personalNewPass });
    if (apiUpdateInfo === false) {
      setIsError(true);
      setPersonalOldPass('');
    } else {
      setIsError(false);
      setPersonalOldPass('');
    }
    console.log(apiUpdateInfo);
    // console.log(await fetchApi('POST', '/users/updateInfo/' + Authentification.getInstance().getUserId(), { email: personalEmail, oldPassword: personalOldPass, password: personalNewPass }));
    // console.log({ email: personalEmail, oldPass: personalOldPass, newPass: personalNewPass });
    console.log(`Email: ${personalEmail}`);
    // console.log(`Старый пароль: ${personalOldPass}`);
    // console.log(`Новый пароль: ${personalNewPass}`);
  };

  return (
    <>
      <div className="personal_col personal_col_content">
        <div className="personal_col_heading">
          <div className="personal_col_heading_wrap">
            <img className="personal_col_icon_img" src="/content/personal/inner/02.png" alt="" />
            <div className="personal_col_heading_title">Персональные данные</div>
          </div>
        </div>
        <InnerForm onSubmit={handeSubmitName} className="personal_col_inner_padding">
          <div className="personal_input_row">
            <InputBase
              type={'text'}
              name={'personal_settings_name'}
              placeholder={'Введите данные'}
              className=""
              inputClassName="base_input_wide"
              label={'Имя Фамилия'}
              value={personalName ? personalName : ''}
              onChange={(event) => setPersonalName(event.target.value)}
            />
          </div>
          <div className="personal_input_row">
            <InputBase
              type={'text'}
              name={'personal_settings_phone'}
              placeholder={'Введите номер'}
              className=""
              inputClassName="base_input_wide"
              label={'Телефон'}
              value={personalPhone ? personalPhone : ''}
              onChange={(event) => {
                const value = event.target.value;
                if (!/^\+?\d*$/.test(value)) return;
                if (value === '0' && value.length === 1) return;
                setPersonalPhone(value);
              }}
            />
          </div>
          <BtnOutline className="nav_fast_order btn_wide_full btn_outline" btnText="Сохранить" type={'submit'} />
        </InnerForm>
      </div>
      <div className="personal_col personal_col_content">
        <div className="personal_col_heading">
          <div className="personal_col_heading_wrap">
            <div className="personal_col_heading_title">Настройки почты и пароля</div>
          </div>
        </div>
        <InnerForm onSubmit={handeSubmitPersonal} className="personal_col_inner_padding">
          <div className="personal_input_row">
            <InputBase
              type={'text'}
              name={'personal_settings_email'}
              placeholder={'Email'}
              className=""
              inputClassName="base_input_wide"
              label={'Email'}
              value={personalEmail}
              onChange={(event) => setPersonalEmail(event.target.value)}
              hasLink={true}
              innerLink={'/'}
              disabled={true}
              isReadonly={true}
              // textLink={'редактировать'}
            />
          </div>
          <div className="personal_input_row">
            <InputBase
              type={'password'}
              name={'personal_settings_oldPass'}
              placeholder={'введите старый пароль'}
              className=""
              inputClassName={`base_input_wide ${isError && 'error'}`}
              label={'Старый пароль'}
              value={personalOldPass}
              autocomplete={'off'}
              onChange={(event) => {
                setPersonalOldPass(event.target.value);
                setIsError(false);
              }}
            />
          </div>
          <div className="personal_input_row">
            <InputBase
              type={'password'}
              name={'personal_settings_newPass'}
              placeholder={'введите новый пароль'}
              className=""
              inputClassName="base_input_wide"
              label={'Новый пароль'}
              value={personalNewPass}
              onChange={(event) => setPersonalNewPass(event.target.value)}
            />
          </div>
          {isError && <div className="personal_input_row">Проверьте корректность данных!</div>}
          {/* тут ещё допилить про disabled (сначала должно быть true) */}
          <BtnOutline className="nav_fast_order btn_wide_full btn_outline btn_mb_22" btnText="Сохранить" type={'submit'} disabled={false} />
        </InnerForm>
      </div>
    </>
  );
};

export default PersonalSetting;
