import React from 'react';

interface PaymentItem {
  payment_title: string;
  payment_icon: string;
}

interface FooterPaymentProps {
  paymentList: PaymentItem[];
}

const FooterPayment = ({ paymentList }: FooterPaymentProps) => {
  return (
    <div className="footer_payment_list">
      {paymentList.map((item: any, index: number) => (
        <div className="footer_payment_item" title={item.payment_title} key={index}>
          <img src={item.payment_icon} alt={item.payment_title} />
        </div>
      ))}
    </div>
  );
};

export default FooterPayment;
