import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import PersonalInner from '../components/personal/PersonalInner';
import PersonalLogin from '../components/personal/PersonalLogin';
import { fetchApi } from '../components/helpers/api';
import { format } from 'date-fns';
import { Authentification } from '../components/helpers/auth';
import { Helmet } from 'react-helmet-async';

// Определение общего типа для элементов в массиве socialDataState
type ProductDataItem = {
  id: string;
  service: string;
  product: string;
  price: string;
  count: string;
  rate: string;
  link: string;
  promo: string;
};

// Определение общего типа для элементов в массиве socialDataState
type OrderDataItem = {
  _id: string;
  userId: string;
  date: number;
  started: boolean;
  payed: boolean;
  price: string;
  products: ProductDataItem[];
};

type UserProps = {
  userId: string;
  userToken: string;
  orderData: OrderDataItem[];
  isLogged?: boolean;
  token?: string;
};

const Personal = ({ userId, userToken, isLogged, token }: UserProps) => {
  const [message, setMessage] = useState('');
  const { userName } = useParams();

  const [orderData, setOrderData] = useState<any[]>([]);
  const [orderDataState, setOrderDataState] = useState<any[]>([]);
  const [payments, setPayments] = useState<any[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const fetchDataOrders = async () => {
    const token = Authentification.getInstance().getToken();
    if (token) {
      const login = Authentification.getInstance().getLogin();
      const id = Authentification.getInstance().getUserId();
      // setUserName(login ? login : '');
      // setUserToken(token ? token : '');
      // setUserId(id ? id : '');
    }
    // Получаю список заказов
    const ordersAPI = await fetchApi('GET', '/services/orders/get/' + Authentification.getInstance().getUserId(), {});
    if (ordersAPI && ordersAPI.length === 0) {
      setIsLoading(false);
    }
    setOrderData(ordersAPI);
    const paymentsAPI = await fetchApi('GET', '/balance/payments/', {});
    if (paymentsAPI && paymentsAPI.length !== 0) {
      let sortedPayments: any[] = [];
      paymentsAPI.map((item: any, index: any) => {
        if (item.status != 'payed' && item.type == 'order') {
          sortedPayments[item.order] = item;
        }
      });
      setPayments(sortedPayments);
    }
  };

  // показываю подробную инфу о заказе в ЛК
  const handleToggle = (index: number) => {
    setOrderDataState((prevState) =>
      prevState.map((order, i) => ({
        ...order,
        isOpen: i === index ? !order.isOpen : order.isOpen // Изменяем isOpen у выбранного заказа
      }))
    );
  };

  // старт сервиса с личного баланса
  const handleStart = async (itemId: number) => {
    const startService = await fetchApi('POST', '/services/orders/start', { orderId: itemId });
    if (startService && startService.status && startService.status === 'fail') {
      alert(startService.message);
      if (startService.redirect) {
        navigate('/personal/balance');
      }
    } else {
      alert('Your service started');
    }
  };

  // функция формирования массива для сохранения
  const handleStop = async (itemId: number) => {
    const startService = await fetchApi('POST', '/services/cancel_order', { orderId: itemId });
    console.log(startService);
    if (startService && startService.status && startService.status === 'fail') {
      alert(startService.message);
    //   if (startService.redirect) {
    //     navigate('/personal/balance');
    //   }
    } else {
      if (startService && startService.message) alert(startService.message);
    }
  };

  useEffect(() => {
    fetchDataOrders();
  }, [userId, userToken]);

  useEffect(() => {
    if (orderData && userId) {
      if (orderData.length > 0) {
        const sortedArr = orderData.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
        setOrderDataState(sortedArr);
        setIsLoading(false);
      }
    }
  }, [orderData]);
  // useEffect(() => {
  //   console.log(orderDataState)
  // }, [orderDataState]);

  return (
    <>
      <Helmet>
        <title>{'Личный кабинет'}</title>
        <meta name="description" content={'Личный кабинет'} />
        {/* Другие мета-теги */}
      </Helmet>
      <div className="personal_col personal_col_wide personal_col_content">
        <div className="personal_col_heading">
          <div className="personal_col_heading_wrap">
            <img src="/content/personal/inner/01.png" alt="" />
            <div className="personal_col_heading_title">Ваши заказы</div>
          </div>
        </div>
        {isLoading ? (
          <div className="personal_col_inner">
            <div className="personal_order_list_td">Загрузка...</div>
          </div>
        ) : orderDataState.length > 0 ? (
          <div className="personal_col_inner">
            <div className="personal_order_list_heading personal_order_list_row order_row">
              <div className="personal_order_list_td personal_order_list_td_th">Дата и время</div>
              <div className="personal_order_list_td personal_order_list_td_th">ID Заказа</div>
              <div className="personal_order_list_td personal_order_list_td_th">Сумма</div>
              <div className="personal_order_list_td personal_order_list_td_th">Статус</div>
              <div className="personal_order_list_td personal_order_list_td_th"></div>
            </div>
            <div className="personal_order_list_content">
              {orderDataState.map((item: any, index: number) => (
                <div className="personal_order_list_row order_row" key={index}>
                  <div className="personal_order_list_td">{format(new Date(item.date), 'dd.MM.yyyy, HH:mm')}</div>
                  <div className="personal_order_list_td">{item._id}</div>
                  <div className="personal_order_list_td">{item.price} RUB</div>
                  <div className={`personal_order_list_td personal_order_list_status`}>
                    <div className={`personal_order_list_status_${item.started ? 'ready' : 'progress'}`}>
                      {(item.payed === true && item.started === false) ||
                        (item.payed === true && item.started === true && !item?.status && !item?.order_status?.status) ||
                        (item.payed === true && item.started === true && item?.order_status?.status === 'Pending')
                        ? 'В ожидании'
                        : item.payed === true && item.started === true && (item?.order_status?.status === 'Partial' || item?.order_status?.status === 'Processing')
                          ? 'В работе'
                          : item.payed === true && item.started === true && item?.order_status?.status === 'In progress'
                            ? 'В процессе'
                            : item?.order_status?.remains === '0' || item?.status === 'Completed' || item?.order_status?.status === 'Completed'
                              ? 'Выполнен'
                              : item?.status === 'Canceled' || item?.order_status?.status === 'Canceled'
                                ? 'Отменён'
                                : item.payed === false
                                  ? 'Ждём оплату'
                                  : 'Выполняется'}
                    </div>
                  </div>
                  <div className="personal_order_list_td personal_order_list_status">
                    <div className={`order_more ${item.isOpen ? 'order_more_active' : ''}`} onClick={() => handleToggle(index)}>
                      {item.isOpen ? 'Скрыть' : 'Подробнее'}
                    </div>
                  </div>
                  {item.products &&
                    Object.values(item.products).map((innerItem: any, innerIndex: number) => (
                      <div className="personal_order_prod" key={innerIndex} style={{ display: item.isOpen ? 'block' : 'none' }}>
                        {item.payed === true && item.started === false && (
                          <>
                            <div className="personal_order_prod_td">
                              <div className={'payment_btn'} onClick={() => handleStart(item._id)}>
                                Запустить
                              </div>
                            </div>
                          </>
                        )}
                        {((item.payed === true && item.started === true && item.order_id && (!item?.status && !item?.order_status?.status ||
                          item?.order_status?.status === 'Pending' || item?.order_status?.status === 'Partial' || item?.order_status?.status === 'Processing' || item?.order_status?.status === 'In progress'))) && (
                            <>
                              <div className="personal_order_prod_td">
                                <div className={'payment_btn cancel_btn'} onClick={() => handleStop(item._id)}>
                                  Отменить
                                </div>
                              </div>
                            </>
                          )}
                        <div className="personal_order_prod_th">ID:</div>
                        <div className="personal_order_prod_td">{innerItem.id}</div>
                        <div className="personal_order_prod_th">Название:</div>
                        <div className="personal_order_prod_td">{innerItem.product}</div>
                        <div className="personal_order_prod_th">Кол-во:</div>
                        <div className="personal_order_prod_td">{innerItem.count}</div>
                        <div className="personal_order_prod_th">Цена:</div>
                        <div className="personal_order_prod_td">{innerItem.price} RUB</div>
                        <div className="personal_order_prod_th">Ссылка:</div>
                        <div className="personal_order_prod_td">{innerItem.link}</div>
                      </div>
                    ))}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="personal_col_inner">
            <div className="personal_order_list_td">Список заказов пуст</div>
          </div>
        )}
      </div>
    </>
  );
};

export default Personal;
