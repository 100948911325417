import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import BtnOutline from '../ui-kit/BtnOutline';
import { fetchApi } from '../helpers/api';

// Определение типа для объекта страницы
type PageType = {
  h1: string;
  title: string;
  updated: number;
  description: string;
  breadcrumbs: string;
  text: string;
  seoUrl: string;
  banner: {
    text: string;
    image: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
  icon: {
    big: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
    small: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
};

// Определение общего типа для элементов в массиве socialDataState
type SocialDataItem = {
  _id: string;
  name: string;
  customName: string;
  __v: number;
  active: boolean;
  activeApi: boolean;
  category: string;
  page: PageType;
  parentId: string;
  rate: string;
  service: string;
  socialMedia: string;
  type: string;
};

// Определение типа для пропсов компонента AdminBlog
type ServicesSocListProps = {
  servicesSocList: SocialDataItem[];
};

// interface ServicesSocListProps {
//   menuList: SocialDataItem[];
// }

const ServicesSocList = ({ servicesSocList }: ServicesSocListProps) => {
  // жду выполнения запроса фетча
  const [isLoading, setIsLoading] = useState(true);

  const [socialDataState, setSocialDataState] = useState<any[]>([]);

  // Отдельно функция для обновления Smm tools
  const fetchCatalogRootData = async () => {
    try {
      // const newDataCatalogRoot = await fetchApi('GET', 'catalog/get_root_categories', {});
      const newDataCatalogRoot = await fetchApi('GET', 'services/get_root_categories', {});
      setSocialDataState(newDataCatalogRoot);
    } catch (error) {
      console.error('Error fetching social item:', error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchCatalogRootData();
  }, []);
  return isLoading ? (
    <div>Loading...</div>
  ) : servicesSocList ? (
    <>
      {servicesSocList.map((item: any, index: number) => (
        <NavLink to={item.page.seoUrl} className={'inner_col_content_nested_services_item shadow_border border_rounded'} key={index}>
          <div className="inner_col_content_nested_services_item_title">{item.customName ? item.customName : item.name}</div>
          <img src={item.page.icon.big.filepath ? item.page.icon.big.filepath : '/content/not/icon_grey.png'} alt={item.customName ? item.customName : item.name} className="inner_col_content_nested_services_item_img" />
          {item.page.excerpt && <div className="inner_col_content_nested_services_item_text">{item.page.excerpt}</div>}
          <BtnOutline className="btn_wide_full inner_col_content_nested_services_item_btn" btnText="Подробнее" />
        </NavLink>
      ))}
    </>
  ) : (
    socialDataState && (
      <>
        {socialDataState.map((item: any, index: number) => (
          <NavLink to={item.page.seoUrl} className={'inner_col_content_nested_services_item shadow_border border_rounded'} key={index}>
            <div className="inner_col_content_nested_services_item_title">{item.customName ? item.customName : item.name}</div>
            <img src={item.page.icon.big.filepath ? item.page.icon.big.filepath : '/content/not/icon_grey.png'} alt={item.customName ? item.customName : item.name} className="inner_col_content_nested_services_item_img" />
            {item.page.excerpt && <div className="inner_col_content_nested_services_item_text">{item.page.excerpt}</div>}
            <BtnOutline className="btn_wide_full inner_col_content_nested_services_item_btn" btnText="Подробнее" />
          </NavLink>
        ))}
      </>
    )
  );
};

export default ServicesSocList;
