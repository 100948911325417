import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { fetchApi } from '../../../../content/components/helpers/api';

interface SelectedFile {
  id: string;
  filename: string;
  filetype: string;
  fileimage: string;
  datetime: string;
  filesize: string;
  name?: string;
  filepath?: string;
}

// Определение типа для пропсов компонента AdminSocial
type FileUploadWithPreviewProps = {
  savedId?: string;
  savedFilename?: string;
  savedFiletype?: string;
  savedFileimage?: string;
  savedFilesize?: string;
  savedFilepath?: string;
  savedDatetime?: string;

  name?: string;

  labelText?: string;
  saveImage?: (imageData: SelectedFile, index?: any) => void;
};

const FileUploadWithPreview = ({ savedId, savedFilename, savedFiletype, savedFileimage, savedFilesize, savedFilepath, savedDatetime, saveImage, labelText }: FileUploadWithPreviewProps) => {
  const [selectedfile, setSelectedFile] = useState<SelectedFile | null>(null);
  const [files, setFiles] = useState<SelectedFile[]>([]);

  useEffect(() => {
    if (savedId && savedFilename && savedFiletype && savedFilesize && savedDatetime) {
      setSelectedFile({
        id: savedId,
        filename: savedFilename,
        filetype: savedFiletype,
        fileimage: '',
        filesize: savedFilesize,
        filepath: savedFilepath,
        datetime: savedDatetime
      });
    }
  }, [savedId, savedFilename, savedFiletype, savedFileimage, savedFilesize, savedFilepath, savedDatetime]);

  const filesizes = (bytes: number, decimals: number = 2): string => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  const inputChange = (e: ChangeEvent<HTMLInputElement>) => {
    // --For Single File Input
    let reader = new FileReader();
    let file = e.target.files![0];

    reader.onloadend = () => {
      (async () => {
        const form = new FormData();
        if (selectedfile) {
          fetchApi('POST', 'upload/blog/delete', {filename: selectedfile.filepath}).then((result)=>console.log(result));
        }
        form.append('file', e.target.files![0]);
        const pathFileObj = await fetchApi('POST', 'upload/blog', form, true);
        // console.log(pathFileObj.path);
        // console.log(await fetchApi('POST', 'upload/blog', form, true))
        // console.log(pathFileObj.path.replace('public', ''));
        setSelectedFile({
          filepath: pathFileObj.path.replace('public', ''),
          id: uuidv4(),
          filename: e.target.files![0].name,
          filetype: e.target.files![0].type,
          fileimage: reader.result as string,
          datetime: new Date(e.target.files![0].lastModified).toLocaleString('en-IN'),
          filesize: filesizes(e.target.files![0].size)
        });
      })();
    };
    if (e.target.files![0]) {
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (saveImage && selectedfile) {
      saveImage(selectedfile);
      // console.log(selectedfile);
    }
  }, [saveImage, selectedfile]);

  const deleteSelectFile = (id: string) => {
    if (window.confirm('Are you sure you want to delete this Image?')) {
      const result = selectedfile ? [] : files.filter((data) => data.id !== id);
      if (selectedfile) {
        fetchApi('POST', 'upload/blog/delete', {filename: selectedfile.filepath}).then((result)=>console.log(result));
      }
      if (saveImage) {
        saveImage({
          id: '',
          filename: '',
          filetype: '',
          fileimage: '',
          filesize: '',
          filepath: '',
          datetime: ''
        });
      }
      setSelectedFile(null);
      setFiles(result);
    }
  };

  const fileUploadSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // form reset on submit
    const form = e.target as HTMLFormElement;
    form.reset();

    if (selectedfile !== null) {
      setFiles((prevValue) => [...prevValue, selectedfile]);
      setSelectedFile(null);
    } else {
      alert('Please select file');
    }
  };

  const deleteFile = (id: string) => {
    if (window.confirm('Are you sure you want to delete this Image?')) {
      const result = files.filter((data) => data.id !== id);
      setFiles(result);
    }
  };
  return (
    <>
      <div className="fileupload_view">
        <div className="fileupload_view_title">{labelText}</div>
        <div className="fileupload_view_drag">
          <div className="fileupload_view_drag_box">
            <input type="file" id="fileupload" className="fileupload_input" onChange={inputChange} />
            <span>
              Drag and drop or <span className="fileupload_link">Choose your file</span>
            </span>
          </div>
        </div>
        <div className="fileupload_view_list">
          {selectedfile !== null ? (
            <div className="fileupload_view_list_box">
              {selectedfile.filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ? (
                <div className="file-image">
                  {' '}
                  <img src={selectedfile.filepath ? selectedfile.filepath : selectedfile.fileimage} alt="" />
                </div>
              ) : (
                <div className="file-image">
                  <i className="far fa-file-alt"></i>
                </div>
              )}
              <div className="file-detail">
                <div className="file-detail-name">{selectedfile.filename}</div>
                <p>
                  <span>Size : {selectedfile.filesize} </span>
                  <span className="file-detail-modified"> Modified Time : {selectedfile.datetime}</span>
                </p>
              </div>
              <div className="file-actions">
                <button className="file-action-btn" onClick={() => deleteSelectFile(selectedfile.id)}>
                  Delete
                </button>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        {files.length > 0
          ? // <div className="kb-attach-box">
            //   <hr />
            //   {files.map((data, index) => (
            //     <div className="file-atc-box" key={index}>
            //       {data.filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ? (
            //         <div className="file-image">
            //           {' '}
            //           <img src={data.fileimage} alt="" />
            //         </div>
            //       ) : (
            //         <div className="file-image">
            //           <i className="far fa-file-alt"></i>
            //         </div>
            //       )}
            //       <div className="file-detail">
            //         <h6>{data.filename}</h6>
            //         <p>
            //           <span>Size : {data.filesize}</span>
            //           <span className="ml-3">Modified Time : {data.datetime}</span>
            //         </p>
            //         <div className="file-actions">
            //           <button className="file-action-btn" onClick={() => deleteFile(data.id)}>
            //             Delete
            //           </button>
            //           <a href={data.fileimage} className="file-action-btn" download={data.filename}>
            //             Download
            //           </a>
            //         </div>
            //       </div>
            //     </div>
            //   ))}
            // </div>
            ''
          : ''}
      </div>
    </>
  );
};

export default FileUploadWithPreview;
