import React from 'react';

type AdminInnerProps = {
  adminToken: string;
};

const AdminInner = ({ adminToken }: AdminInnerProps) => {
  return (
    <div>
      <h1>Добро пожаловать</h1>
      <p>Вы вошли в админку, ваш токен {adminToken}</p>
    </div>
  );
};

export default AdminInner;
