import React from 'react';
import BtnOutline from '../ui-kit/BtnOutline';
import { NavLink } from 'react-router-dom';

type Props = {
  scrollToMainQuickOrder?: () => void;
};

const BeVisible = ({ scrollToMainQuickOrder }: Props) => {
  return (
    <div className="main_section main_be_visible_section">
      <div className="container">
        <div className="main_be_visible">
          <div className="main_section_title_mb">Станьте заметнее в соц сетях!</div>
          <div className="main_be_visible_wrap">
            <div className="main_be_visible_content">
              <ul className="main_be_visible_content_list">
                <li>Развивай свой бренд</li>
                <li>Увеличивай доверие аудитории</li>
                <li>Становись популярнее</li>
                <li>Зарабатывай больше</li>
              </ul>
              <div className="main_be_visible_result main_be_visible_result_mobile">
                <div className="main_be_visible_result_item">
                  <div className="main_be_visible_result_text">До:</div>
                  <img src="/content/main/before_1.png" alt="" />
                </div>
                <div className="main_be_visible_result_item">
                  <div className="main_be_visible_result_text">После:</div>
                  <img src="/content/main/after_1.png" alt="" />
                </div>
              </div>
              <div className="main_be_visible_content_bottom_text">Начни развивать соц сети и зарабатывать уже сейчас!</div>
              <div className="main_be_visible_content_bottom_wrap">
                <BtnOutline btnLink="/services" className="btn_outline btn_bg_transparent btn_wide_full" btnText="Каталог услуг" />
                <BtnOutline onClick={scrollToMainQuickOrder} className="btn_wide_full btn_bg_gradient" btnText="Быстрый заказ" />
              </div>
            </div>
            <div className="main_be_visible_result">
              <div className="main_be_visible_result_item">
                <div className="main_be_visible_result_text">До:</div>
                <img src="/content/main/before_1.png" alt="" />
              </div>
              <div className="main_be_visible_result_item">
                <div className="main_be_visible_result_text">После:</div>
                <img src="/content/main/after_1.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeVisible;
