import React, { useState } from 'react';
import MainAccordionItem from '../components/main_page/MainAccordionItem';
import { Helmet } from 'react-helmet-async';
import InnerBreadcrumbs from '../components/ui-kit/InnerBreadcrumbs';
import { NavLink } from 'react-router-dom';

// Определение общего типа для элементов в массиве socialDataState
type SocialReviewItem = {
  _id: string;
  parentId: string;
  name: string;
  type: string;
  active: boolean;
  updated: number;
  text: string;
};

// Определение типа для пропсов компонента AdminReview
type FaqProps = {
  socialDataState: SocialReviewItem[];
  typeString: string;
};

const Faq = ({ socialDataState, typeString }: FaqProps) => {
  const [active, setActive] = useState<string | null>(null);
  const handleToggle = (index: string) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };
  return (
    <>
      <Helmet>
        <title>{'Популярные вопросы'}</title>
        <meta name="description" content={'Популярные вопросы'} />
        {/* Другие мета-теги */}
      </Helmet>
      <InnerBreadcrumbs chapterLink={'/faq'} chapterString={'Ответы на вопросы'} />
      <div className="main_section main_faq_section">
        <div className="container">
          <div className="main_faq">
            <div className="inner_col_content_subtitle">
              <div className="inner_col_content_subtitle_top">Популярные вопросы</div>
              <div className="inner_col_content_subtitle_bottom">
                Если не нашли ответ на свой вопрос, <NavLink to={`https://t.me/media_booster_ru`} className="link" target="_blank"><span className="link">напишите нам</span></NavLink>.
              </div>
            </div>
            <div className="main_faq_wrap">
              {socialDataState.length > 0 && socialDataState.map((faq, index) => <MainAccordionItem key={index} active={active} handleToggle={handleToggle} faq={faq} />)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
