import React, { FormEvent, useState } from 'react';
import AdminLoginForm from './admin-ui-kit/form/AdminLoginForm';
import InputBase from './admin-ui-kit/input/InputBase';
import BtnBase from './admin-ui-kit/btn/BtnBase';

type Props = {};

const AdminLogin = (props: Props) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handeSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log('username:', username);
    console.log('password:', password);
  };
  return (
    <AdminLoginForm onSubmit={handeSubmit}>
      <InputBase label={'Логин'} type={'text'} name={'username'} value={username} onChange={(event) => setUsername(event.target.value)} placeholder={'Введите логин'} />
      <InputBase type={'password'} name={'password'} value={password} onChange={(event) => setPassword(event.target.value)} placeholder={'Введите пароль'} />
      <BtnBase type={'submit'} btnText={'Войти'} />
    </AdminLoginForm>
  );
};

export default AdminLogin;
