import React, { useEffect, useState } from 'react';
import { NavLink, Navigate, generatePath, useParams } from 'react-router-dom';
import { format } from 'date-fns';

// Определение типа для объекта страницы
type PageType = {
  h1: string;
  title: string;
  updated: number;
  description: string;
  breadcrumbs: string;
  text: string;
  seoUrl: string;
  showOnMain: boolean;
  excerpt: string;
  banner: {
    text: string;
    image: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
  icon: {
    big: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
    small: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
};

// Определение общего типа для элементов в массиве socialDataState
type SocialDataItem = {
  _id: string;
  name: string;
  __v: number;
  active: boolean;
  activeApi: boolean;
  category: string;
  page: PageType;
  parentId: string;
  rate: string;
  service: string;
  socialMedia: string;
  type: string;
};

// Определение типа для пропсов компонента AdminSocial
type AdminSocialProps = {
  socialDataState: SocialDataItem[];
  typeString: string;
};

const AdminSocial = ({ socialDataState, typeString }: AdminSocialProps) => {
  const [socialDataStateArr, setSocialDataStateArr] = useState<any[]>([]);
  const [socialTypeString, setSocialTypeString] = useState(typeString);

  const [customSocialArr, setCustomSocialArr] = useState<any[]>([]);

  // жду выполнения запроса фетча
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setSocialTypeString(typeString);
  }, [typeString]);

  useEffect(() => {
    setSocialDataStateArr(socialDataState);
    if (socialTypeString !== 'root_category' && socialTypeString !== 'category') {
      // Если socialTypeString НЕ 'root_category' И НЕ 'category', то заполняем filteredArray всеми элементами
      const filteredArray = socialDataState.filter((item) => item.type !== 'root_category' && item.type !== 'category');
      const sortedArray = filteredArray.slice().sort((a, b) => a.socialMedia.localeCompare(b.socialMedia));
      setCustomSocialArr(sortedArray);
    } else {
      // Иначе фильтруем по socialTypeString
      const filteredArray = socialDataState.filter((item) => item.type === socialTypeString);
      // console.log(socialTypeString);
      // console.log(socialDataState);
      const sortedArray = filteredArray.slice().sort((a, b) => a.socialMedia.localeCompare(b.socialMedia));
      setCustomSocialArr(sortedArray);
    }
  }, [socialDataState, socialTypeString]);

  useEffect(() => {
    if (customSocialArr.length > 0) {
      setIsLoading(false);
    }
  }, [customSocialArr]);

  // получаю название родительской категории
  const getParentCategoryName = (parentId: string): string => {
    const parentCategory = socialDataStateArr.find((category) => category._id === parentId);
    return parentCategory ? parentCategory.name : '';
  };
  return (
    <>
      <div className="admin_heading">{socialTypeString === 'root_category' ? 'Социальные сети' : socialTypeString === 'category' ? 'Все категории' : 'Услуги'}</div>
      {/* <div className="admin_container"></div> */}
      <>
        {isLoading ? (
          <div>Loading...</div>
        ) : !customSocialArr ? (
          <div>Items not found</div>
        ) : (
          <div className="admin_table_wrap">
            <table className="admin_table_list">
              <thead>
                <tr>
                  <th>ID</th>
                  {((socialTypeString !== 'root_category' && socialTypeString !== 'category') || socialTypeString === 'category') && <th>Сategory</th>}
                  <th>Name</th>
                  <th>Status</th>
                  <th>Updated</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {customSocialArr.map((item) => (
                  <tr key={item._id}>
                    <td>{item._id}</td>
                    {((socialTypeString !== 'root_category' && socialTypeString !== 'category') || socialTypeString === 'category') && (
                      <td className="category_td">{getParentCategoryName(item.parentId)}</td>
                    )}
                    <td>{item.name}</td>
                    <td>
                      {item.activeApi === false ? (
                        <span className="status_el status_disabled">Disabled</span>
                      ) : item.active ? (
                        <span className="status_el status_active">Active</span>
                      ) : (
                        <span className="status_el status_disabled">Disabled</span>
                      )}
                    </td>
                    <td>{format(new Date(item.page.updated), 'dd.MM.yyyy, HH:mm')}</td>
                    <td>
                      <NavLink to={`edit/${item._id}`}>Edit</NavLink>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </>
    </>
  );
};

export default AdminSocial;
