import React from 'react';
import { NavLink } from 'react-router-dom';

interface MenuItem {
  id: number;
  menu_name: string;
  menu_link: string;
  menu_icon: React.ReactNode;
}
interface SubMenuItem {
  id: number;
  parent_id: number;
  menu_name: string;
  menu_link: string;
  menu_icon: React.ReactNode;
}

interface AdminmenuItemProps {
  menuList: MenuItem[];
  subMenuList: SubMenuItem[];
}

const AdminmenuItem = ({ menuList, subMenuList }: AdminmenuItemProps) => {
  return (
    <ul className={'admin_menu_list'}>
      {menuList.map((item: any) => (
        <li className={`admin_menu_item ${subMenuList.some((subItem) => subItem.parent_id === item.id) ? 'is_parent' : ''}`} key={item.id}>
          <NavLink
            to={item.menu_link}
            className={``}
            end={item.menu_link == '/admin' ? true : false}
            // end={subMenuList.some((subItem) => subItem.parent_id === item.id) ? false : true}
          >
            {item.menu_icon}
            <span>{item.menu_name}</span>
          </NavLink>
          {subMenuList.length > 0 && (
            <ul className={'child_list'}>
              {subMenuList.map(
                (subItem: any) =>
                  subItem.parent_id === item.id && (
                    <li className={'admin_menu_item'} key={subItem.id}>
                      <NavLink to={subItem.menu_link} className={``}>
                        {subItem.menu_icon}
                        <span>{subItem.menu_name}</span>
                      </NavLink>
                    </li>
                  )
              )}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );
};

export default AdminmenuItem;
