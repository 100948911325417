import React, { forwardRef } from 'react';
import PersonalOrderCreate from '../personal/sections/PersonalOrderCreate/PersonalOrderCreate';

// Определение типа для объекта страницы
type PageType = {
  h1: string;
  title: string;
  updated: number;
  description: string;
  breadcrumbs: string;
  text: string;
  seoUrl: string;
  showOnMain: boolean;
  excerpt: string;
  banner: {
    text: string;
    image: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
  icon: {
    big: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
    small: {
      id: string;
      filename: string;
      filetype: string;
      datetime: string;
      filesize: string;
      filepath: string;
    };
  };
};

// Определение общего типа для элементов в массиве socialDataState
type SocialDataItem = {
  _id: string;
  name: string;
  __v: number;
  active: boolean;
  activeApi: boolean;
  category: string;
  page: PageType;
  parentId: string;
  rate: string;
  service: string;
  socialMedia: string;
  type: string;
};

// Определение типа для пропсов компонента AdminBlog
type MainQuickOrderProps = {
  socialDataState: SocialDataItem[];
  typeString?: string;
  isLogged: boolean;
};

const MainQuickOrder = forwardRef<HTMLDivElement, MainQuickOrderProps>(({ socialDataState, isLogged }, ref) => {
  return (
    <div ref={ref} className="main_section main_quick_order_section" id="main_quick_order">
      <div className="container">
        <div className="main_quick_order">
          <div className="main_section_title">Быстрый заказ</div>
          <div className="main_personal_wrap_grid">
            <PersonalOrderCreate socialDataState={socialDataState} isLogged={isLogged} />
          </div>
        </div>
      </div>
    </div>
  );
});

export default MainQuickOrder;
